const replaceState = (params: {
  data: unknown;
  unused: string;
  url?: string | URL | null;
}) => {
  window.history.replaceState(params.data, params.unused, params.url);
};

export const HistoryUtils = {
  replaceState,
};
