import React from 'react';
import Icon from '../../../../components/Icon';
import { Divider, Loader } from '@mantine/core';
import { Trans, useTranslation } from 'react-i18next';
import CreateTicketButton from '../../../../components/CreateTicket/CreateTicketButton';
import { useFetchTickets } from '../../../../hooks/queries/useFetchTickets';
import { useFetchIntegrations } from '../../../../hooks/queries/useFetchIntegrations';
import TicketsList from '../../../../components/CreateTicket/TicketsList';
import ClickableRegion from '../../../../components/ClickableRegion';
import { useAuth } from '../../../../hooks/useAuth';

interface Props {
  entityType: 'asset' | 'vulnerability';
  entityId: string;
}
export const Tickets = ({ entityId, entityType }: Props) => {
  const { t } = useTranslation();
  const { checkUserAccess } = useAuth();
  const hasWriteAccess = checkUserAccess('write');
  const { data: fetchedIntegrations, isLoading: isLoadingIntegrations } =
    useFetchIntegrations({
      includeGithub: false,
    });

  const { data: fetchedTickets, isLoading: isLoadingTickets } = useFetchTickets(
    entityId,
    entityType,
  );
  return (
    <div className="card">
      <div className="header">
        <div className="label">
          {t('page.asset.ticketsCreated')}
          {fetchedIntegrations && fetchedIntegrations?.length > 0 && (
            <span className="ticket-count">{fetchedTickets?.length || 0}</span>
          )}
        </div>
        {fetchedIntegrations && fetchedIntegrations?.length > 0 && hasWriteAccess && (
          <CreateTicketButton
            entityId={entityId}
            entityType={entityType}
            showIcon={false}
            shortText={true}
          />
        )}
      </div>
      <Divider my="lg" />
      {isLoadingIntegrations ? (
        <div className="loading-integrations">
          <Loader />
        </div>
      ) : (
        <>
          {fetchedIntegrations && fetchedIntegrations?.length === 0 ? (
            <div className="no-integrations">
              <Trans i18nKey="tickets.setupIntegrationTextWithLink">
                To create a ticket, please configure an integration in &nbsp;
                <ClickableRegion
                  className="login-email-undo"
                  href="/settings/integrations"
                >
                  <span>Settings</span>
                </ClickableRegion>
              </Trans>
            </div>
          ) : (
            <TicketsList tickets={fetchedTickets || []} showTableHeaders />
          )}
        </>
      )}
    </div>
  );
};
