import { useMutation, useQueryClient } from '@tanstack/react-query';
import { COMPONENTS_REACT_QUERY_KEY } from '../../hooks/queries/useFetchComponents';
import { ComponentsApi, UpdateComponentPayload } from './components.api';

export const useUpdateComponent = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (payload: { id: string; body: UpdateComponentPayload }) =>
      ComponentsApi.updateComponent(payload.id, payload.body),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [COMPONENTS_REACT_QUERY_KEY],
      });
    },
  });
};
