import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';

interface Params {
  organizationId: string;
  installationId: string;
}

export const useDeleteGitHubIntegration = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ organizationId, installationId }: Params) => {
      return AxiosProxy.delete({
        url: `integration/github/${organizationId}/${installationId}`,
        withCredentials: true,
        body: {},
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['integrations'] });
      queryClient.invalidateQueries({ queryKey: ['integration-github'] });
    },
  });
};
