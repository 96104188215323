import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetchOrganization } from '../hooks/queries/useFetchOrganization';
import { useOrganizationId } from '../hooks/utils/useOrganizationId';
import styles from './TriageCount.module.scss';
import { useFetchVulnerabilitiesTriages } from './vulnerabilities.hooks';

interface Props {
  assetId: string;
}

export const TriageCount = (props: Props) => {
  const { assetId } = props;
  const { t } = useTranslation();
  const { data, isLoading } = useFetchVulnerabilitiesTriages(assetId);
  const [currentOrgId] = useOrganizationId();
  const { data: fetchedCurrentOrganization, isLoading: isLoadingCurrentOrganization } =
    useFetchOrganization({ organizationId: currentOrgId });

  const acceptedVulnTriageFeatureFlag =
    !isLoadingCurrentOrganization &&
    fetchedCurrentOrganization?.enabledFeatures?.some(
      (feature) => feature === 'accepted-vuln-triage',
    );

  const noActive = useMemo(
    () => (data ? data?.vulnerabilities.total - data.vulnerabilities.active : 0),
    [data],
  );

  const fixedOrNotAffected = useMemo(
    () => (data ? noActive - (data.vulnerabilities.triages.Accepted || 0) : 0),
    [data],
  );

  if (!data?.vulnerabilities || isLoading) return null;

  return (
    <div className={styles.triages_count}>
      <h5>
        {t('page.asset.total-vulns-count', { total: data.vulnerabilities.total })}{' '}
        <b>
          {t(
            acceptedVulnTriageFeatureFlag
              ? 'page.asset.active-fixed-vulns-count-temp'
              : 'page.asset.active-fixed-vulns-count',
            {
              open: data.vulnerabilities.active,
              fixed: fixedOrNotAffected,
              ...(acceptedVulnTriageFeatureFlag && {
                accepted: data.vulnerabilities.triages?.Accepted ?? 0,
              }),
            },
          )}
        </b>
      </h5>
    </div>
  );
};
