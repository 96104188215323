import { Button } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Form } from 'rsuite';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';
import Loading from '../../components/Loading';
import Logo from '../../components/Logo';
import '../../scss/pages/vendor_portal.scss';
import Faq from './faq';

function VendorPortal() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [organizationName, setOrganizationName] = useState('');
  const [vendorName, setVendorName] = useState('');
  const [vendorOrganizationName, setVendorOrganizationName] = useState('');
  const [acrossDomainSignup, setacrossDomainSignup] = useState(false);
  const [invitedVendorEmail, setInvitedVendorEmail] = useState('');
  const [sbomRequest, setSbomRequest] = useState({});
  const [vendorEmail, setVendorEmail] = useState('');
  const [formValid, setFormValid] = useState(false);
  const [formError, setFormError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const token = searchParams.get('token');

  const getRequestFromToken = async () => {
    try {
      if (!token) return;
      const response = await AxiosProxy.get({
        url: `/auth/verifyVendorSbomRequest?token=${token}`,
      });
      if (!response.success) throw new Error('unable to find request');
      const vendorSbomRequest = response.data;
      return vendorSbomRequest;
    } catch (e) {
      return null;
    }
  };
  useEffect(() => {
    // check token
    getRequestFromToken()
      .then((request) => {
        if (!request) return navigate('/');
        const { vendorInvitedEmail, allowSignupAcrossDomain, requestingOrgName } =
          request;
        setInvitedVendorEmail(vendorInvitedEmail);
        setacrossDomainSignup(allowSignupAcrossDomain);
        setOrganizationName(requestingOrgName);
        setSbomRequest(request);
        setIsLoading(false);
      })
      .catch((e) => {
        navigate('/');
      });
  }, []);
  const isLoginInfoValid = (): boolean => {
    // handle login validation logic here and return success or fail
    if (!invitedVendorEmail || !vendorEmail) return false;
    if (vendorEmail.toLocaleLowerCase() === invitedVendorEmail.toLocaleLowerCase())
      return true;
    if (acrossDomainSignup) {
      const enteredDomain = vendorEmail.split('@')[1];
      const invitedDomain = (invitedVendorEmail || '').split('@')[1];
      return invitedDomain === enteredDomain;
    }
    return false;
  };
  const handleFormChange = () => {
    setFormValid(
      vendorName !== '' && vendorOrganizationName !== '' && vendorEmail !== '',
    );
    setFormError(false);
  };

  const handleFormSubmit = async () => {
    setFormError(false);
    try {
      if (isLoginInfoValid()) {
        const vendorInfo = {
          vendorName,
          vendorEmail,
          vendorOrganizationName,
        };
        sessionStorage.setItem('requestInfo', JSON.stringify(sbomRequest));
        sessionStorage.setItem('vendorInfo', JSON.stringify(vendorInfo));
        //need to sign in here
        const body = {
          email: vendorEmail,
          vendorHash: token,
          vendorOrganizationName,
          userName: vendorEmail,
        };
        const createResponse = await AxiosProxy.post({
          url: '/vendor/create',
          withCredentials: true,
          body,
        });
        if (createResponse.success) navigate(`/vendor-portal/upload-center`);
        else throw new Error('Unable to create account for vendor');
      } else {
        throw new Error('Login info is not valid');
      }
    } catch (e) {
      setFormError(true);
      setFormValid(false);
    }
  };

  return (
    <div className="vendor-portal-container">
      {isLoading ? (
        <div className="loader-container">
          <Loading />
        </div>
      ) : (
        <>
          <div className="banner">
            {' '}
            <Logo wordmark={true} />
          </div>
          <div className="login-content">
            <div className="pane pane-split">
              <div className="section-content portal-text">
                <div className="header">
                  {t('page.vendorPortal.header', { organizationName })}
                </div>
                <div className="subHeader">{t('page.vendorPortal.subHeader')}</div>
                <Form
                  onChange={handleFormChange}
                  onSubmit={handleFormSubmit}
                  fluid
                  className="login-form"
                >
                  <Form.Group className="form-field" controlId="name">
                    <Form.ControlLabel>
                      {t('page.vendorPortal.loginForm.name')}
                    </Form.ControlLabel>
                    <Form.Control
                      required
                      onChange={setVendorName}
                      value={vendorName}
                      name="vendorName"
                      placeholder={t('page.vendorPortal.loginForm.namePlaceholder')}
                    />
                  </Form.Group>
                  <Form.Group className="form-field" controlId="organizationName">
                    <Form.ControlLabel>
                      {t('page.vendorPortal.loginForm.organizationName')}
                    </Form.ControlLabel>
                    <Form.Control
                      required
                      onChange={setVendorOrganizationName}
                      value={vendorOrganizationName}
                      name="vendorOrganizationName"
                      placeholder={t(
                        'page.vendorPortal.loginForm.organizationNamePlaceholder',
                      )}
                    />
                  </Form.Group>
                  <Form.Group className="form-field" controlId="email">
                    <Form.ControlLabel>
                      {t('page.vendorPortal.loginForm.email')}
                    </Form.ControlLabel>
                    <Form.Control
                      required
                      onChange={setVendorEmail}
                      value={vendorEmail}
                      name="vendorEmail"
                      type="email"
                      placeholder={t('page.vendorPortal.loginForm.emailPlaceholder')}
                    />
                  </Form.Group>

                  <Form.Group className="form-field">
                    {formError && (
                      <div className="form-error">
                        {t('page.vendorPortal.errorMessage')}
                      </div>
                    )}
                    <Button fullWidth type="submit" disabled={!formValid}>
                      {t(`page.vendorPortal.loginForm.loginButtonLabel`)}
                    </Button>
                    <Form.HelpText>
                      {t('page.vendorPortal.loginForm.helpText')}
                    </Form.HelpText>
                  </Form.Group>
                </Form>
              </div>
            </div>
            <div className="pane pane-split gradient-bg">
              <div className="section-content">
                <Faq />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default VendorPortal;
