import {
  AssetLevelOfSupport,
  InterfaceOrganizationComponent,
} from '@manifest-cyber/types/interface/dbTables';
import { DateTime } from 'luxon';
import { SucessApiResponseWithData } from '../../lib/request/rejectFailedRequest/rejectFailedRequest';
import { InterfaceOrganizationComponentWithVulns } from '../../types/FullInterfaces';
import { TransformedToClientTypes } from '../../types/transformedToClientType';

export interface ComponentsMapperTranslations {
  activelyMaintained: string;
  noMaintained: string;
  abandoned: string;
  unknown: string;
}

export interface MappedOrganizationComponentWithVulns
  extends TransformedToClientTypes<InterfaceOrganizationComponentWithVulns> {
  dateModifiedText?: string;
  lastReleaseDateText?: string;
  levelOfSupportText?: string;
  endOfSupportText?: string;
  nameToDisplay: string;
}

export interface MapComponentsRetunValue {
  data: MappedOrganizationComponentWithVulns[];
  queryInfo: {
    totalCount: number;
    totalReturn: number;
    page: number;
    limit: number;
  };
}

const MAX_COMPONENT_NAME_LENGTH = 40;

const getComponentName = (name: string = ''): string => {
  const truncatedFqn =
    name?.length >= MAX_COMPONENT_NAME_LENGTH
      ? `${name.substring(0, MAX_COMPONENT_NAME_LENGTH)}...`
      : name;

  const split = truncatedFqn.split('/');
  const parsedName = split.length > 0 ? split[split.length - 1] : name;

  return parsedName || '';
};

export const mapComponent = (
  component:
    | TransformedToClientTypes<InterfaceOrganizationComponentWithVulns>
    | TransformedToClientTypes<InterfaceOrganizationComponent>,
  translations?: ComponentsMapperTranslations,
): MappedOrganizationComponentWithVulns => {
  const levetOfSupportMap: Record<AssetLevelOfSupport, string> = {
    ACTIVELY_MAINTAINED: translations?.activelyMaintained || '',
    NO_LONGER_MAINTAINED: translations?.noMaintained || '',
    ABANDONED: translations?.abandoned || '',
    UNKNOWN: translations?.unknown || '',
  };

  const nameToDisplay =
    component.fullyQualifiedName || component.name || component.packageUrlNoVersion || '';

  return {
    ...component,
    licensesData:
      (component as TransformedToClientTypes<InterfaceOrganizationComponentWithVulns>)
        .licensesData || [],
    nameToDisplay: getComponentName(nameToDisplay),
    dateModifiedText: component.dateModified
      ? DateTime.fromISO(component.dateModified).toLocaleString(DateTime.DATETIME_MED)
      : '',
    lastReleaseDateText: component.lastReleaseDate
      ? DateTime.fromISO(component.lastReleaseDate).toLocaleString(DateTime.DATE_MED)
      : '',
    endOfSupportText: component.endOfSupport
      ? DateTime.fromISO(component.endOfSupport).toLocaleString(DateTime.DATE_MED)
      : '',
    levelOfSupportText:
      levetOfSupportMap[component.levelOfSupport as AssetLevelOfSupport] ??
      (component.levelOfSupport || ''),
  };
};

export const mapComponents = (
  response: SucessApiResponseWithData<
    TransformedToClientTypes<InterfaceOrganizationComponentWithVulns>[]
  >,
  translations?: ComponentsMapperTranslations,
): MapComponentsRetunValue => {
  return {
    data: response.data.map((component) => mapComponent(component, translations)),
    queryInfo: {
      totalCount: response.queryInfo?.totalCount || 0,
      totalReturn: response.queryInfo?.totalReturn || 0,
      page: response.queryInfo?.page || 1,
      limit: response.queryInfo?.limit || 100,
    },
  };
};
