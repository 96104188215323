import { useEffect, useRef, useState } from 'react';
import { Checkbox, CheckboxGroup, Slider, Toggle } from 'rsuite';
import '../../../scss/pages/notifications.scss';
// import ReactSlider from 'react-slider'
import { useTranslation } from 'react-i18next';
import { captureExceptionWithMessage } from '../../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';

type TIntegrations = {
  value: string[];
  options: any[];
};
interface INotificationProps {
  notification: {
    id: string;
    name: string;
    description: string;
    active: boolean;
    filters: any[];
    integrations: TIntegrations;
  };
  onSave: (notificationId: string, notificationSettings: any) => Promise<boolean>;
}
function Notification({ notification, onSave }: INotificationProps) {
  const { t } = useTranslation();
  const isInitialLoad = useRef(true);
  const [loading, setLoading] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [notificationActive, setNotificationActive] = useState(notification.active);
  const [filters, setFilters] = useState<any[]>(notification.filters);
  const [integrations, setIntegrations] = useState<TIntegrations>(
    notification.integrations,
  );
  const handleNotificationToggle = (checkedState: boolean) => {
    setNotificationActive(checkedState);
  };

  useEffect(() => {
    if (isInitialLoad.current === true) {
      isInitialLoad.current = false;
      return;
    }
    handleSettingsSave(true);
  }, [notificationActive, filters, integrations]);

  const renderFilterControls = (filter: any) => {
    switch (filter.type) {
      case 'checkboxGroup':
        return renderCheckboxGroup(filter);
      case 'slider':
        return renderSlider(filter);
      default:
        return <div key={filter.id}></div>;
    }
  };

  const renderCheckboxGroup = (filter: any) => {
    return (
      <CheckboxGroup
        inline
        key={filter.id}
        name="checkboxList"
        className="checkbox-group"
        value={filter.value}
        onChange={(value) => {
          setFilters((prevFilters: any) => {
            return prevFilters.map((f: any) => {
              if (f.id === filter.id) {
                const filterCopy = { ...f, value };
                return filterCopy;
              }
              return f;
            });
          });
        }}
      >
        {filter.options.map((option: any) => {
          return (
            <Checkbox key={option.id} value={option.id}>
              {option.name}
            </Checkbox>
          );
        })}
      </CheckboxGroup>
    );
  };

  const renderSlider = (filter: any) => {
    const { options, id } = filter;
    return (
      <>
        <Slider
          progress
          key={filter.id}
          min={options.min}
          max={options.max}
          step={options.step}
          className={options.className || ''}
          defaultValue={filter.value}
          renderTooltip={options.renderTooltip}
          onChangeCommitted={(recordedValue) => {
            setFilters((prevFilters: any) => {
              return prevFilters.map((f: any) => {
                if (f.id === filter.id) {
                  const filterCopy = { ...f, value: recordedValue };
                  return filterCopy;
                }
                return f;
              });
            });
          }}
          onChange={(recordedValue, event) => {
            if (event.type === 'mousemove') return;
            setFilters((prevFilters: any) => {
              return prevFilters.map((f: any) => {
                if (f.id === filter.id) {
                  const filterCopy = { ...f, value: recordedValue };
                  return filterCopy;
                }
                return f;
              });
            });
          }}
        />
        {id === 'cvssScore' && (
          <div className="cvss-labels">
            <div className="cvss-label">None</div>
            <div className="cvss-label">Critical</div>
          </div>
        )}
      </>
    );
  };

  const renderIntegrations = () => {
    return (
      <CheckboxGroup
        inline
        key={'integrations'}
        className="checkbox"
        value={integrations.value}
        onChange={(value) => {
          setIntegrations((prevIntegrations: any) => {
            return { ...prevIntegrations, value };
          });
        }}
      >
        {integrations.options.map((option: any) => {
          return (
            <Checkbox key={option.id} value={option.id} disabled>
              {option.name}
            </Checkbox>
          );
        })}
      </CheckboxGroup>
    );
  };

  const handleSettingsSave = async (activeToggleTrigger: boolean) => {
    setLoading(true);
    try {
      const notificationId = notification.id;
      const notificationSettings = {
        ...notification,
        active: notificationActive,
        filters,
        integrations,
      };
      const savedSuccessfully = await onSave(notificationId, notificationSettings);
      if (savedSuccessfully && !activeToggleTrigger) {
        setSaveSuccess(true);
      }
    } catch (e) {
      captureExceptionWithMessage('handleSettingsSave', e);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="notificaton-container">
      <div className="notification-header">
        <div className="notification-info">
          <div className="notification-name">{notification.name}</div>
          <div className="notification-description">{notification.description}</div>
        </div>
        <Toggle
          className="notification-toggle"
          checked={notificationActive}
          onChange={(checked) => {
            handleNotificationToggle(checked);
          }}
        />
      </div>
      {notificationActive && (
        <div className="notification-content">
          <div className="notification-section">
            <div className="section-header">
              {t(
                'page.userSettings.notifications.vulnerabilityNotifications.notificationFiltersHeader',
              )}
            </div>
            <div className="section-subheader">
              {t(
                'page.userSettings.notifications.vulnerabilityNotifications.notificationFiltersSubHeader',
              )}
            </div>
            <div className="divider" />
            {filters.map((filter) => {
              return (
                <div key={filter.id} className="section-content">
                  <div className="filter-name">{filter.name}</div>
                  <div className="filter-controls-container">
                    <div className={`filter-controls ${filter?.options?.className}`}>
                      {renderFilterControls(filter)}
                    </div>
                    {filter.description && (
                      <div className="filter-description">
                        {filter.description(filter.value)}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          {/* <div className="notification-section">
            <div className="section-header">
              {t(
                'page.userSettings.notifications.vulnerabilityNotifications.notificationDeliveryHeader',
              )}
            </div>
            <div className="divider" />
            <div className="section-content">{renderIntegrations()}</div>
          </div> */}
          {/* <div className="btn-container">
            <Button
              className={`btn-notification-save ${saveSuccess ? 'success' : ''}`}
              disabled={loading || saveSuccess}
              loading={loading}
              onClick={() => {
                handleSettingsSave(false);
              }}
            >
              {saveSuccess ? (
                <>{t('global.saved')}</>
              ) : (
                <>{t('global.save.buttonShort')}</>
              )}
            </Button>
          </div> */}
        </div>
      )}
    </div>
  );
}

export default Notification;
