import {
  ColumnConfigWithLabel,
  MappedPageConfig,
  PageConfig,
} from '../../../../api/customFields/pageConfig.interface';

export type ComponentsColumnId =
  | 'fullyQualifiedName'
  | 'version'
  | 'derivedEcosystem'
  | 'countVulnerabilities.total'
  | 'licensesData.fullName'
  | 'supplier.name'
  | 'description'
  | 'dateModified'
  | 'lastReleaseDate'
  | 'levelOfSupport'
  | 'endOfSupport';

export const getDefaultComponentsColumnsConfig = (
  t: Function,
): ColumnConfigWithLabel<ComponentsColumnId>[] => [
  {
    id: 'fullyQualifiedName',
    label: t('tables.components.headers.name'),
    visible: true,
    locked: true,
  },
  {
    id: 'version',
    label: t('tables.components.headers.version'),
    visible: true,
    locked: false,
  },
  {
    id: 'derivedEcosystem',
    label: t('tables.components.headers.ecosystem'),
    visible: true,
    locked: false,
  },
  {
    id: 'countVulnerabilities.total',
    label: t('tables.components.headers.vulns'),
    visible: true,
    locked: false,
  },
  {
    id: 'licensesData.fullName',
    label: t('tables.components.headers.license'),
    visible: true,
    locked: false,
  },
  {
    id: 'supplier.name',
    label: t('tables.components.headers.source'),
    visible: true,
    locked: false,
  },
  {
    id: 'description',
    label: t('tables.components.headers.description'),
    visible: true,
    locked: false,
  },
  {
    id: 'dateModified',
    label: t('tables.components.headers.lastUpdated'),
    visible: true,
    locked: false,
  },
  {
    id: 'lastReleaseDate',
    label: t('tables.components.headers.lastReleaseDate'),
    visible: false,
    locked: false,
  },
  {
    id: 'levelOfSupport',
    label: t('tables.components.headers.levelOfSupport'),
    visible: false,
    locked: false,
  },
  {
    id: 'endOfSupport',
    label: t('tables.components.headers.endOfSupport'),
    visible: false,
    locked: false,
  },
];

export const mapComponentsPageConfig = ({
  data,
  t,
}: {
  data?: PageConfig<ComponentsColumnId>;
  t: Function;
}): MappedPageConfig<ComponentsColumnId> => {
  if (!data) {
    return { columnsConfig: getDefaultComponentsColumnsConfig(t) };
  }

  return {
    columnsConfig: data.columnsConfig.map((columnConfig) => {
      const defaultColumnConfig = getDefaultComponentsColumnsConfig(t).find(
        (defaultColumnConfig) => defaultColumnConfig.id === columnConfig.id,
      );

      return {
        label: defaultColumnConfig?.label || columnConfig.id,
        id: defaultColumnConfig?.id || columnConfig.id,
        visible: columnConfig.visible,
        locked: columnConfig.locked,
      };
    }),
  };
};
