import { AxiosProxy } from '../api/axiosProxy/axiosProxy';
import { APIResponse } from '../types/api_response.model';
import { AssetVulnerabilitiesTriages } from './vulnerabilities.model';

export const getAssetVulnerabilitiesTriage = async (
  assetID: string,
): Promise<APIResponse<AssetVulnerabilitiesTriages>> => {
  const response = (await AxiosProxy.get({
    url: `vulnerabilities/asset/${assetID}/triages`,
  })) as APIResponse<AssetVulnerabilitiesTriages>;
  return response;
};
