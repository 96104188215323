import { TextComponent } from '@manifest-cyber/design-system/lib/components/TextComponent';
import { InterfaceOrganizationAsset } from '@manifest-cyber/types/interface/dbTables';
import {
  Autocomplete,
  Box,
  Flex,
  Popover,
  SegmentedControl,
  Select,
  Stack,
  Table,
  Text,
  TextInput,
  Tooltip,
} from '@mantine/core';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';
import ClickableRegion from '../../components/ClickableRegion';
import Icon from '../../components/Icon';
import Loading from '../../components/Loading';
import ManifestModal, {
  ManifestModalProps,
} from '../../components/ManifestModal/ManifestModal';
import MessageWithBackground from '../../components/MessageWithBackground/MessageWithBackground';
import { captureExceptionWithMessage } from '../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';
import '../../scss/pages/vulnerability.scss';

interface Props {
  onCancel: () => void;
  onConfirm: () => void;
  opened: boolean;
  cveId: string;
  impactedAssets?: InterfaceOrganizationAsset[];
}

export const CreateSimpleVexModal = ({
  onCancel,
  opened,
  cveId,
  impactedAssets = [],
}: Props) => {
  const { t } = useTranslation();
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [isGenerated, setIsGenerated] = useState<boolean>(false);
  const [displayErrors, setDisplayErrors] = useState<string[]>([]);
  const [productAutocompleteValue, setProductAutocompleteValue] = useState('');

  const firstSuggestions = (impactedAssets: any) => {
    const suggestions = [];
    //sort first
    impactedAssets.sort((a: any, b: any) => {
      return `${a.name}@${a.version}`.localeCompare(`${b.name}@${b.version}`);
    });
    //filter and add to suggestions array for dropdown
    for (const asset of impactedAssets) {
      if (asset.relationshipToOrg !== 'first') continue;
      suggestions.push({
        value: `${
          asset?.coordinates ||
          `${asset?.packageUrlNoVersion || asset?.name}@${asset.version}`
        }`,
        label: `${asset.name}@${asset.version}`,
        group: t('page.vulnerability.vex-simple.modal.impacted-products.dropdown-header'),
        impactedAsset: asset,
      });
    }

    return suggestions;
  };
  const productSuggestions = useMemo(
    () => firstSuggestions(impactedAssets),
    [impactedAssets],
  );

  // This is the dataset that eventually gets sent to API for generation
  const [selectedVexProducts, setSelectedVexProducts] = useState<{
    [key: string]: {
      label?: string;
      value?: string;
      status?: string;
      justification?: string;
      asset?: InterfaceOrganizationAsset;
    };
  }>({});

  const [vexReportData, setVexReportData] = useState<{} | null>(null);
  const [vexId, setVexId] = useState<string | null>(null);
  const [vexFormat, setVexFormat] = useState<string>('csaf');

  const exportVexReport = (firstTime: boolean = false) => {
    if (firstTime) {
      setIsGenerating(false);
      setIsGenerated(true);
    }

    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(vexReportData, null, 2),
    )}`;
    const link = document.createElement('a');
    link.href = jsonString;
    link.download = `vex_${cveId}_${vexFormat}_${DateTime.now().toISO()}.json`;
    link.click();
  };

  const generateVex = async () => {
    setIsGenerating(true);
    setDisplayErrors([]);

    /**
     * Validation
     * not-affected and affected must have justification
     */
    const errors: string[] = [];
    const justificationRequiredStatuses = ['affected', 'not-affected'];
    const validStatues = ['affected', 'not-affected', 'under-investigation', 'fixed'];
    for (const singleVexProduct of Object.values(selectedVexProducts)) {
      // Check for required justification
      if (
        justificationRequiredStatuses.includes(`${singleVexProduct.status}`) &&
        !singleVexProduct.justification
      ) {
        errors.push(
          t(
            `page.vulnerability.vex-simple.modal.generating.validation.missing-justification${
              singleVexProduct.status ? '-' + singleVexProduct.status : ''
            }`,
            { product: singleVexProduct.label },
          ),
        );
      }

      // Check for valid status
      if (!validStatues.includes(`${singleVexProduct.status}`)) {
        errors.push(
          t(
            `page.vulnerability.vex-simple.modal.generating.validation.invalid-status${
              singleVexProduct.status ? '-' + singleVexProduct.status : ''
            }`,
            { product: singleVexProduct.label },
          ),
        );
      }
    }

    if (errors.length > 0) {
      setDisplayErrors(errors);
      setIsGenerating(false);
      return;
    }

    const vexResponse = await AxiosProxy.post({
      url: `vulnerability/${cveId}/vex/${vexId || ''}`,
      withCredentials: true,
      body: {
        impactedProducts: vexId ? undefined : selectedVexProducts,
        format: vexFormat,
      },
    });

    if (vexResponse && vexResponse?.success && vexResponse?.data[0]) {
      const vexId = vexResponse?.data[0]?.vexId;
      const vexDocument = vexResponse?.data[0]?.vexDocument;
      setVexId(vexId);
      setVexReportData(vexDocument);
    } else {
      captureExceptionWithMessage('Error generating VEX', vexResponse);
    }
  };

  // The first time we get vexReportData in our API response, trigger export
  // the `true` will also trigger our isGenerated statechanges.
  useEffect(() => {
    if (vexReportData) {
      exportVexReport(true);
    }
  }, [vexReportData]);

  // For cleaner keys & conflict avoidance, normalize given label (e.g. "My Cool Product")
  // into a cleaner key-friendly value (e.g. "my-cool-product")
  const normalizeVexProductName = (productLabel: string) => {
    return productLabel.replace(' ', '-').toLowerCase();
  };

  // Return a justification placeholder based on given status
  const getJustificationPlaceholder = (status: string) => {
    return t(
      `page.vulnerability.vex-simple.modal.impacted-products.list-justification-placeholders.${
        status || 'unselected'
      }`,
    );
  };

  const handleFireClose = () => {
    setSelectedVexProducts({});
    setIsGenerating(false);
    setIsGenerated(false);
    setVexReportData(null);
    setVexId(null);
    setDisplayErrors([]);
    // setSelectedValues([]);
    onCancel();
  };

  // Return a list of justification reasons based on given status
  // This is used to populate the dropdown of justifications for a given status
  // The dropdown also allows custom notes.
  const getJustificationReasons = ({
    status,
    justification,
  }: {
    status?: string;
    justification?: string;
  }): { label: string; value: string }[] => {
    let allOptions: { label: string; value: string }[] = [];

    if (status === 'not-affected') {
      const notAffectedOptions = [
        'component_not_present',
        'vulnerable_code_not_present',
        'vulnerable_code_not_in_execute_path',
        'vulnerable_code_cannot_be_controlled_by_adversary',
        'inline_mitigations_already_exist',
      ];
      allOptions = notAffectedOptions.map((option) => {
        return {
          label: t(
            `page.vulnerability.vex-simple.modal.impacted-products.justifications.${option}`,
          ),
          value: option,
        };
      });
      if (justification && !notAffectedOptions.includes(justification)) {
        allOptions.push({
          label: justification,
          value: justification,
        });
      }
    } else {
      if (justification) {
        allOptions.push({
          label: justification,
          value: justification,
        });
      }
    }

    return allOptions;
  };

  const handleCreateCustomProduct = (query: string) => {
    const customProduct = {
      value: `${query}`,
      label: query,
      group: t(
        'page.vulnerability.vex-simple.modal.impacted-products.dropdown-header-custom',
      ),
    };
    handleSelectVexProduct(customProduct);
  };

  const handleSelectVexProduct = (vexProduct: any) => {
    setSelectedVexProducts({
      ...selectedVexProducts,
      [`${normalizeVexProductName(`${vexProduct?.label}`)}`]: { ...vexProduct }, //add product to selected products
    });
    setProductAutocompleteValue(''); //clear autocomplete
  };

  const isInitialStep = !isGenerated && !isGenerating;
  const isGeneratingFirstTime = isGenerating && !isGenerated;
  const primaryAction: ManifestModalProps['primaryAction'] = isInitialStep
    ? {
        isLoading: isGenerating,
        isDisabled: Object.keys(selectedVexProducts)?.length < 1,
        onClick: () => generateVex(),
        label: t('page.vulnerability.vex-simple.modal.confirm'),
      }
    : {
        isLoading: isGenerating,
        isDisabled: Object.keys(selectedVexProducts)?.length < 1 || !isGenerated,
        onClick: () => exportVexReport(),
        label: t('page.vulnerability.vex-simple.modal.completed.download'),
      };

  const secondaryAction: ManifestModalProps['secondaryAction'] = isInitialStep
    ? {
        onClick: () => handleFireClose(),
        label: t('global.cancel'),
      }
    : undefined;

  const initialStepTitle = t('page.vulnerability.vex-simple.modal.header', {
    cveId,
  });

  const generatingTitle = t('page.vulnerability.vex-simple.modal.generating.header', {
    cveId,
  });

  const generatedTitle = t('page.vulnerability.vex-simple.modal.completed.header');

  return (
    <ManifestModal
      size={'xl'}
      opened={opened}
      onClose={() => handleFireClose()}
      withCloseButton
      title={
        isInitialStep
          ? initialStepTitle
          : isGeneratingFirstTime
            ? generatingTitle
            : generatedTitle
      }
      subtitle={isInitialStep ? t('page.vulnerability.vex-simple.modal.description') : ''}
      primaryAction={primaryAction}
      secondaryAction={secondaryAction}
      closeOnClickOutside={false}
      closeOnEscape={false}
      body={
        <>
          {isInitialStep && (
            <Stack my="8px" className="vex-simple-modal">
              <div className="vex-product-autocomplete">
                <Autocomplete
                  label={t(
                    'page.vulnerability.vex-simple.modal.impacted-products.header',
                  )}
                  description={t(
                    'page.vulnerability.vex-simple.modal.impacted-products.description',
                  )}
                  limit={productSuggestions?.length || 1000}
                  data={productSuggestions || []}
                  placeholder={t(
                    'page.vulnerability.vex-simple.modal.impacted-products.dropdown-placeholder',
                  )}
                  maxDropdownHeight={200}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                      //@ts-ignore
                      handleCreateCustomProduct(event.target.value);
                      // @ts-ignore
                      event.target?.blur?.(); //hide autocomplete dropdown
                    }
                  }}
                  onItemSubmit={handleSelectVexProduct}
                  onChange={setProductAutocompleteValue}
                  value={productAutocompleteValue}
                />
              </div>

              <Box className="vex-product-triage">
                <Table horizontalSpacing={'xs'} verticalSpacing={'sm'}>
                  <thead>
                    <tr>
                      <th>
                        {t(
                          'page.vulnerability.vex-simple.modal.impacted-products.list-headers.name',
                        )}
                      </th>
                      <th style={{ display: 'flex' }}>
                        {t(
                          'page.vulnerability.vex-simple.modal.impacted-products.list-headers.status',
                        )}
                        <Text color="red">*</Text>
                        <Popover width={250} position="top" withArrow shadow="md">
                          <Popover.Target>
                            <span>
                              <Icon icon="info-circle" />
                            </span>
                          </Popover.Target>
                          <Popover.Dropdown>
                            <Trans
                              i18nKey={
                                'page.vulnerability.vex-simple.modal.impacted-products.list-headers.status-tooltip'
                              }
                            >
                              List of statuses from CISA's
                              <Link
                                to="https://www.cisa.gov/sites/default/files/2023-04/minimum-requirements-for-vex-508c.pdf"
                                target="_blank"
                              >
                                minimum VEX guidance
                              </Link>
                            </Trans>
                          </Popover.Dropdown>
                        </Popover>
                      </th>
                      <th>
                        {t(
                          'page.vulnerability.vex-simple.modal.impacted-products.list-headers.justification',
                        )}
                        <Popover width={250} position="top" withArrow shadow="md">
                          <Popover.Target>
                            <span>
                              <Icon icon="info-circle" />
                            </span>
                          </Popover.Target>
                          <Popover.Dropdown>
                            <Trans
                              i18nKey={
                                'page.vulnerability.vex-simple.modal.impacted-products.list-headers.justification-tooltip'
                              }
                            >
                              List of statuses from CISA's
                              <Link
                                to="https://www.cisa.gov/sites/default/files/2023-04/minimum-requirements-for-vex-508c.pdf"
                                target="_blank"
                              >
                                minimum VEX guidance
                              </Link>
                            </Trans>
                          </Popover.Dropdown>
                        </Popover>
                      </th>
                      <th>{/* Trash Column, No header */}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(selectedVexProducts)?.length === 0 && (
                      <div className="no-products">
                        {t(
                          'page.vulnerability.vex-simple.modal.impacted-products.list-no-products',
                        )}
                      </div>
                    )}
                    {Object.values(selectedVexProducts)?.map((product) => {
                      return (
                        <tr key={normalizeVexProductName(`${product?.label}`)}>
                          <td className="vex-product-label">
                            <TextComponent lineClamp={2}>{product?.label}</TextComponent>
                          </td>
                          <td className="vex-product-status">
                            <Select
                              withinPortal
                              defaultValue={'unselected'}
                              value={product?.status}
                              onChange={(value) => {
                                setSelectedVexProducts((current) => ({
                                  ...current,
                                  [`${normalizeVexProductName(`${product?.label}`)}`]: {
                                    ...product,
                                    status: `${value}`,
                                    justification: '',
                                  },
                                }));
                              }}
                              data={[
                                {
                                  value: 'unselected',
                                  label: t(
                                    'page.vulnerability.vex-simple.modal.impacted-products.list-status.unselected',
                                  ),
                                },
                                {
                                  value: 'not-affected',
                                  label: t(
                                    'page.vulnerability.vex-simple.modal.impacted-products.list-status.not-affected',
                                  ),
                                },
                                {
                                  value: 'affected',
                                  label: t(
                                    'page.vulnerability.vex-simple.modal.impacted-products.list-status.affected',
                                  ),
                                },
                                {
                                  value: 'fixed',
                                  label: t(
                                    'page.vulnerability.vex-simple.modal.impacted-products.list-status.fixed',
                                  ),
                                },
                                {
                                  value: 'under-investigation',
                                  label: t(
                                    'page.vulnerability.vex-simple.modal.impacted-products.list-status.under-investigation',
                                  ),
                                },
                              ]}
                            />
                          </td>
                          <td className="vex-product-justification">
                            {product?.status === 'not-affected' ? (
                              <Select
                                value={product?.justification}
                                data={getJustificationReasons({ ...product })}
                                placeholder={getJustificationPlaceholder(
                                  `${product?.status}`,
                                )}
                                creatable
                                searchable
                                onChange={(value) => {
                                  setSelectedVexProducts((current) => ({
                                    ...current,
                                    [`${normalizeVexProductName(`${product?.label}`)}`]: {
                                      ...product,
                                      justification: `${value}`,
                                    },
                                  }));
                                }}
                                onCreate={(query) => {
                                  const item = { value: `${query}`, label: query };
                                  return item;
                                }}
                                getCreateLabel={(query) => (
                                  <Trans
                                    i18nKey={
                                      'page.vulnerability.vex-simple.modal.impacted-products.justifications.custom'
                                    }
                                    values={{ justification: `${query}` }}
                                  >
                                    <span>
                                      Add Justification: <strong>"{query}"</strong>
                                    </span>
                                  </Trans>
                                )}
                              />
                            ) : (
                              <TextInput
                                value={product?.justification}
                                placeholder={getJustificationPlaceholder(
                                  `${product?.status || 'unselected'}`,
                                )}
                                onChange={(e) => {
                                  setSelectedVexProducts((current) => ({
                                    ...current,
                                    [`${normalizeVexProductName(`${product?.label}`)}`]: {
                                      ...product,
                                      justification: `${e?.target?.value}`,
                                    },
                                  }));
                                }}
                              />
                            )}
                          </td>
                          <td className="vex-product-remove">
                            <Flex justify={'center'}>
                              <ClickableRegion
                                onClick={() => {
                                  setSelectedVexProducts((current) => {
                                    const newVexProducts = { ...current };
                                    delete newVexProducts[
                                      `${normalizeVexProductName(`${product?.label}`)}`
                                    ];
                                    return newVexProducts;
                                  });
                                }}
                                regionLabel={t(
                                  'page.vulnerability.vex-simple.modal.impacted-products.list-headers.remove',
                                )}
                              >
                                <Text color="red">
                                  <Icon icon="trash" />
                                </Text>
                              </ClickableRegion>
                            </Flex>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <Flex
                  className="modal-actions"
                  justify="flex-end"
                  align="center"
                  gap="md"
                >
                  <div>
                    <span style={{ marginRight: '5px' }}>
                      {t('page.vulnerability.vex-simple.modal.format.choose')}
                    </span>
                    <Tooltip
                      label={t('page.vulnerability.vex-simple.modal.format.tooltip')}
                    >
                      <span style={{ position: 'relative', top: '1px' }}>
                        <Icon icon="info-circle" />
                      </span>
                    </Tooltip>
                  </div>

                  <SegmentedControl
                    value={vexFormat}
                    data={[
                      {
                        value: 'csaf',
                        label: t('page.vulnerability.vex-simple.modal.format.csaf'),
                      },
                      {
                        value: 'openvex',
                        label: t('page.vulnerability.vex-simple.modal.format.openvex'),
                      },
                    ]}
                    onChange={(value) => setVexFormat(value)}
                  />
                </Flex>

                {displayErrors.length > 0 && (
                  <Box mt="12px">
                    <MessageWithBackground
                      message={
                        <Box ml="8px">
                          <Box component="strong">
                            {t(
                              'page.vulnerability.vex-simple.modal.generating.validation.error-intro',
                              {
                                count: displayErrors.length,
                              },
                            )}
                          </Box>

                          <Box mt="4px">
                            {displayErrors.map((error, index) => (
                              <li key={index}>{error}</li>
                            ))}
                          </Box>
                        </Box>
                      }
                      messageType={'critical'}
                    />
                  </Box>
                )}
              </Box>
            </Stack>
          )}
          {isGeneratingFirstTime && (
            <Stack className="center-focus">
              <TextComponent>
                {t('page.vulnerability.vex-simple.modal.generating.description')}
              </TextComponent>
              <Loading />
            </Stack>
          )}
          {isGenerated && t('page.vulnerability.vex-simple.modal.completed.description')}
        </>
      }
    />
  );
};
