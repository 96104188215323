import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';

interface Params {
  labels: Record<string, string[]>;
}

export const usePutProductLabels = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ labels }: Params) => {
      return AxiosProxy.put({
        url: `product/updateLabels`,
        withCredentials: true,
        body: {
          labels,
        },
      });
    },
    onSuccess: (_data, { labels }) => {
      Object.keys(labels).forEach((productId) => {
        queryClient.invalidateQueries({
          queryKey: ['product', `/product/${productId}/overview`],
        });
      });
      queryClient.invalidateQueries({
        queryKey: ['products'],
      });
      queryClient.invalidateQueries({
        queryKey: ['labels'],
      });
    },
  });
};
