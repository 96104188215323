import { Flex } from '@mantine/core';
import { ManifestLogo } from '../../../components/Icons/ManifestLogoIcon/ManifestLogoIcon';
import styles from './PortalFooter.module.scss';

export const PortalFooter = ({ description }: { description: string }) => {
  return (
    <div className={styles.footer}>
      <Flex gap="12px" justify={'center'} bottom={0} pt="20px" pb="32px">
        <span className={styles.description}>{description}</span>
        <ManifestLogo width={82} height={20} />
      </Flex>
    </div>
  );
};
