import type { InterfaceOrganizationAsset } from '@manifest-cyber/types/interface/dbTables';
import { useQuery } from '@tanstack/react-query';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';

export const useFetchMostImpactedAssets = () => {
  const filters = [{ field: 'assetActive', value: [true] }];
  const queryParams = new URLSearchParams({ filters: JSON.stringify(filters) });

  const fetchUrl = `assets/most-impacted?${queryParams.toString()}`;

  return useQuery({
    queryKey: ['mostImpactedAssets', `/${fetchUrl}`],
    queryFn: () => AxiosProxy.get({ url: fetchUrl }),
    select: (data) => (data?.data ?? []) as InterfaceOrganizationAsset[],
  });
};
