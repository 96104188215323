import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';
import { LABELS_QUERY_KEY } from '../queries/useFetchLabels';

interface UseDeleteLabelParams {
  id: string;
}

export const useDeleteLabel = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ id }: UseDeleteLabelParams) => {
      const response = await AxiosProxy.delete({ url: `/label/${id}` });

      if (!response.success) {
        return Promise.reject(response.errors);
      }

      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [LABELS_QUERY_KEY],
      });
    },
  });
};
