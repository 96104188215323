import type { InterfaceLicense } from '@manifest-cyber/types/interface/dbTables';
import { useQuery } from '@tanstack/react-query';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';

interface Options {
  refetchOnWindowFocus?: boolean;
}

export const useFetchLicenses = (options?: Options) => {
  let fetchUrl = `licenses`;

  return useQuery({
    queryKey: ['licenses', `/${fetchUrl}`],
    queryFn: () => AxiosProxy.get({ url: fetchUrl }),
    select: (data) => (data?.data ?? []) as InterfaceLicense[],
    refetchOnWindowFocus: options?.refetchOnWindowFocus,
  });
};
