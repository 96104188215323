import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react';
import { useQueryClient } from '@tanstack/react-query';
import type { QueryCacheNotifyEvent } from '@tanstack/react-query';
import { NetworkErrorModal } from './NetworkErrorModal';

interface NetworkErrorContextProps {
  showNetworkError: boolean;
  setShowNetworkError: Dispatch<SetStateAction<boolean>>;
}

const NetworkErrorContext = createContext<NetworkErrorContextProps | undefined>(
  undefined,
);

export const useNetworkError = (): NetworkErrorContextProps => {
  const context = useContext(NetworkErrorContext);
  if (context === undefined) {
    throw new Error('useNetworkError must be used within a NetworkErrorProvider');
  }
  return context;
};

interface NetworkErrorProviderProps {
  children: ReactNode;
}

export const NetworkErrorProvider = ({ children }: NetworkErrorProviderProps) => {
  const queryClient = useQueryClient();
  const [showNetworkError, setShowNetworkError] = useState<boolean>(false);

  useEffect(() => {
    const handleOnline = () => setShowNetworkError(false);
    const handleOffline = () => setShowNetworkError(true);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    const queryCache = queryClient.getQueryCache();

    const unsubscribe = queryCache.subscribe((event: QueryCacheNotifyEvent) => {
      if (
        event.type === 'updated' &&
        event.query.getObserversCount() > 0 &&
        event.query.state.status === 'error'
      ) {
        const error = event.query.state.error;

        if (error instanceof Error && !navigator.onLine) {
          setShowNetworkError(true);
        }
      }
    });

    return () => {
      unsubscribe();
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [queryClient]);

  return (
    <NetworkErrorContext.Provider value={{ showNetworkError, setShowNetworkError }}>
      {showNetworkError && <NetworkErrorModal isOpen />}
      {children}
    </NetworkErrorContext.Provider>
  );
};
