import { ActionIcon, Button, Divider } from '@mantine/core';
import { useMergeLink } from '@mergeapi/react-merge-link';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AxiosProxy } from '../../../api/axiosProxy/axiosProxy';
import ClickableRegion from '../../../components/ClickableRegion';
import Icon from '../../../components/Icon/Icon';
import { captureExceptionWithMessage } from '../../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';
import { IIntegration } from './integrations-data';

interface AddIntegrationProps {
  integrationInfo: IIntegration;
}

// TODO: replace all with useQuery
export const AddIntegration = ({ integrationInfo }: AddIntegrationProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [linkToken, setLinkToken] = useState('');

  const fetchLinkToken = async () => {
    try {
      const { success, data: tokenData } = await AxiosProxy.get({
        url: `/integration/merge/link-token?integration=${integrationInfo.integrationType}`,
      });
      if (!success) throw 'Failed to fetch link token';
      return tokenData.linkToken;
    } catch (e) {
      captureExceptionWithMessage('fetchLinkToken', e);
      return '';
    }
  };
  const fetchIntegrationStatus = async () => {
    try {
      const { success, data: integrations } = await AxiosProxy.get({
        url: `/integration`,
      });
      if (!success) {
        throw new Error('error while trying to fetch integration status');
      }
      return {
        hasIntegration: integrations.some(
          (integration: any) =>
            integration.integrationType === integrationInfo.integrationType,
        ),
      };
    } catch (e) {
      captureExceptionWithMessage('Unable to fetch integration status', e);
      return {};
    }
  };

  useEffect(() => {
    fetchIntegrationStatus()
      .then((integrationStatus) => {
        if (integrationStatus?.hasIntegration === true) {
          return navigate('/settings/integrations', { replace: true });
        }
      })
      .then(() => {
        return fetchLinkToken();
      })
      .then((token) => {
        setLinkToken(token);
      });
  }, []);

  const onSuccess = useCallback(async (public_token: string) => {
    await AxiosProxy.post({
      url: '/integration/merge/account-token',
      withCredentials: true,
      body: {
        integration: integrationInfo.integrationType,
        publicToken: public_token,
      },
    });
    return navigate('/settings/integrations', { replace: true });
  }, []);

  const { open, isReady } = useMergeLink({
    linkToken,
    onSuccess,
  });
  return (
    <div className="integration-install-page anim-slideInDownShort">
      <ClickableRegion href="/settings/integrations">
        <ActionIcon size="xl">
          <Icon icon="arrow-left" />
        </ActionIcon>
      </ClickableRegion>
      <div className="integration-page-header">
        {integrationInfo.icon && (
          <Icon icon={integrationInfo.icon} iconStyle="fab" size="5x" />
        )}
        {integrationInfo.logoSrc && (
          <div className="logo">
            <img src={integrationInfo.logoSrc} />
          </div>
        )}
        <h4>{integrationInfo.subtitle}</h4>
      </div>
      <Divider my="lg" />
      <div className="integration-page-content">
        <h5>{t('page.userSettings.integrations.allowManifestTo')}</h5>
        <ul>
          {integrationInfo.features.map((feature, index) => (
            <li key={index}>
              <Icon icon="check" color="var(--color-success)" /> <div>{feature}</div>
            </li>
          ))}
        </ul>
        <div className="actions">
          <Button
            size="sm"
            onClick={() => {
              open();
            }}
          >
            {integrationInfo.installText}
          </Button>
          <ClickableRegion href="/settings/integrations">
            <Button variant="default" size="sm">
              {t('global.cancel')}
            </Button>
          </ClickableRegion>
        </div>
      </div>
    </div>
  );
};
