import { ActionIcon, Popover, Switch } from '@mantine/core';
import { useListState } from '@mantine/hooks';
import cx from 'clsx';
import { useEffect } from 'react';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import isEqual from 'react-fast-compare';
import Icon from '../Icon';
import styles from './ColumnsConfigPopover.module.scss';
import { StrictModeDroppable } from './StrictModeDroppable';

export interface ColumnsConfigPopoverProps<T = string> {
  columns: ColumnConfig<T>[];
  onChange: (columnsConfig: ColumnConfig<T>[]) => void;
  title: string;
}

export const ColumnsConfigPopover = ({
  title,
  columns,
  onChange,
}: ColumnsConfigPopoverProps) => {
  return (
    <Popover position="bottom-end" zIndex={1000}>
      <Popover.Target>
        <ActionIcon className={styles.tableControlActionIcon}>
          <Icon icon="gear" size="1x" />
        </ActionIcon>
      </Popover.Target>

      <Popover.Dropdown className={styles.dndPopoverContainer}>
        <div className={styles.header}>{title}</div>
        <DragAndDropList columns={columns} onChange={onChange} />
      </Popover.Dropdown>
    </Popover>
  );
};

interface Props<T = string> {
  columns: ColumnConfig<T>[];
  onChange: (columnsConfig: ColumnConfig<T>[]) => void;
}
export interface ColumnConfig<T = string> {
  id: T;
  label: string;
  visible: boolean;
  locked: boolean;
}

const DragAndDropList = ({ columns, onChange }: Props) => {
  const [columnsConfig, handlers] = useListState(columns);

  useEffect(() => {
    if (isEqual(columnsConfig, columns)) {
      return;
    }

    onChange(columnsConfig);
  }, [columnsConfig]);

  useEffect(() => {
    handlers.setState(columns);
  }, [columns]);

  return (
    <DragDropContext
      onDragEnd={({ destination, source }) => {
        handlers.reorder({ from: source.index, to: destination?.index || 0 });
      }}
    >
      <StrictModeDroppable droppableId="dnd-list" direction="vertical">
        {(provided) => {
          return (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {columnsConfig.map((item, index) =>
                item.locked ? (
                  <div key={item.id}>
                    <div className={cx(styles.item)}>
                      <div className={styles.itemContent}>
                        <Switch checked={item.visible} disabled />
                        <span>{item.label}</span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Draggable key={item.id} index={index} draggableId={item.id}>
                    {(provided, snapshot) => {
                      return (
                        <div
                          className={cx(
                            styles.item,
                            snapshot.isDragging && styles.itemDragging,
                          )}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div className={styles.itemContent}>
                            <Switch
                              checked={item.visible}
                              onChange={(event) =>
                                handlers.setItem(index, {
                                  ...item,
                                  visible: event.currentTarget.checked,
                                })
                              }
                            />
                            <span>{item.label}</span>
                          </div>
                          <div className={styles.dragHandle}>
                            <Icon icon="bars" />
                          </div>
                        </div>
                      );
                    }}
                  </Draggable>
                ),
              )}
              {provided.placeholder}
            </div>
          );
        }}
      </StrictModeDroppable>
    </DragDropContext>
  );
};
