import { useEffect, useState } from 'react';
import { FeatureFlag } from '../configs/featureFlags';

export const getNormalizedCookieName = (discriminator: string) =>
  `experimental_${discriminator}`;

// Hook to check if a cookie with a given name has the value 'on'
const useFeatureFlag = (featureFlagName: FeatureFlag): boolean => {
  const [hasCookie, setHasCookie] = useState(false);
  const normalizedCookieName = getNormalizedCookieName(featureFlagName);

  useEffect(() => {
    // Function to get cookie by name
    const getCookieByName = (name: string): string | null => {
      const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
      if (match) return match[2] || null;
      return null;
    };

    // Check if the cookie exists and has the value 'on'
    const checkCookie = (): void => {
      const cookieValue = getCookieByName(normalizedCookieName);
      setHasCookie(cookieValue === 'on');
    };

    // Run on mount and whenever the cookieName changes
    checkCookie();
  }, [normalizedCookieName]);

  return hasCookie;
};

export default useFeatureFlag;
