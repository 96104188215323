import '../../../../scss/pages/integrations/github.scss';
import { Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getGitHubIntegration } from '../integrations-data';
import { IntegrationAddShell } from '../components/IntegrationAddShell';
import { IntegrationAddStart } from '../components/IntegrationAddStart';
import { GitHubIntegrationConfirm } from './components/GitHubIntegrationConfirm';
import { GithubIntegrationOrganizations } from './GitHubIntegrationOrganizations';
import { GitHubIntegrationOrganization } from './GitHubIntegrationOrganization';

export const GitHubIntegration = () => {
  const { t } = useTranslation();
  const gitHubIntegrationInfo = getGitHubIntegration(t);

  return (
    <Routes>
      <Route
        path="add"
        element={
          <IntegrationAddShell integrationInfo={gitHubIntegrationInfo}>
            <IntegrationAddStart
              integrationInfo={gitHubIntegrationInfo}
            />
          </IntegrationAddShell>
        }
      />
      <Route
        path="confirm"
        element={
          <IntegrationAddShell integrationInfo={gitHubIntegrationInfo}>
            <GitHubIntegrationConfirm />
          </IntegrationAddShell>
        }
      />
      <Route
        path="*"
        element={<GithubIntegrationOrganizations integration={gitHubIntegrationInfo} />}
      />
      <Route
        path="organizations"
        element={<GithubIntegrationOrganizations integration={gitHubIntegrationInfo} />}
      />
      <Route
        path="organization/:installationId/*"
        element={<GitHubIntegrationOrganization integration={gitHubIntegrationInfo} />}
      />
    </Routes>
  );
};
