import { useQuery, UseQueryResult } from '@tanstack/react-query';
import {
  ComponentsApi,
  GetComponentUrlParams,
} from '../../api/componentsApi/components.api';
import {
  ComponentsMapperTranslations,
  MapComponentsRetunValue,
} from '../../api/componentsApi/components.mapper';
import { captureExceptionWithMessage } from '../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';

interface Params {
  enabled?: boolean;
  queryParams: GetComponentUrlParams;
  translations?: ComponentsMapperTranslations;
}

export const COMPONENTS_REACT_QUERY_KEY = 'components';

export const useFetchComponents = ({
  enabled = true,
  queryParams: options,
  translations,
}: Params): UseQueryResult<MapComponentsRetunValue, unknown> => {
  return useQuery({
    enabled: enabled,
    keepPreviousData: true,
    queryKey: [
      COMPONENTS_REACT_QUERY_KEY,
      options.assetId && { assetId: options.assetId },
      `/${ComponentsApi.getRequestUrl(options)}`,
    ].filter(Boolean),
    queryFn: () => ComponentsApi.getComponents(options, translations),
    onError: (error) => {
      captureExceptionWithMessage(
        `Unable to fetch components for asset ${options.assetId}: `,
        error,
      );
    },
  });
};
