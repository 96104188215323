import { useParams } from 'react-router-dom';
import { ComponentsTable } from '../../Components/ComponentsTable';

export const AssetComponentsTab = () => {
  const { assetId } = useParams();

  return (
    <div className="asset-components-content">
      <div className="actions-placeholder" />
      <ComponentsTable assetId={assetId} />
    </div>
  );
};
