import {
  Alert,
  Button,
  Collapse,
  Flex,
  Modal,
  Radio,
  Stack,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { modals } from '@mantine/modals';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../../components/Icon';
import { Organization } from '../../../types/organization.interface';
import { OrganizationUserAccess } from '../../../types/organizationUsers';
import styles from './CreateUserModal.module.scss';

interface Props {
  onCancel: () => void;
  onConfirm: (user: any) => Promise<void>;
  opened: boolean;
  validationErrors: Partial<Record<keyof any, string>>;
  isCreating: boolean;
  isParentOrg?: boolean; // Used for some conditional login
  org?: Organization; // Used to check for external emails
}

export const CreateUserModal = ({
  onCancel,
  onConfirm,
  opened,
  validationErrors,
  isCreating,
  isParentOrg,
  org,
}: Props) => {
  const { t } = useTranslation();

  const [isWarningConfirmModalOpened, setIsWarningConfirmModalOpened] = useState(false);
  const [isExternal, setIsExternal] = useState(false);

  const form = useForm<Partial<any>>({
    initialValues: {
      firstName: '',
      lastName: '',
      title: '',
      email: '',
      isOrganizationAdmin: false,
    },
    validate: {
      firstName: (value) => (value ? null : 'First name must not be empty'),
      lastName: (value) => (value ? null : 'Last name must not be empty'),
      email: (value) =>
        value && value?.includes('@') && value?.includes('.') ? null : 'Invalid email',
    },
  });

  // TODO: Make this prettier. Maybe? (Background and 1st modal should be darker)
  const openConfirmModal = (user: any) => {
    modals.openConfirmModal({
      title: (
        <b>
          {t('page.userSettings.organization.people.table.headers.add-external-warn')}
        </b>
      ),
      children: (
        <Stack>
          <Text>
            {t('page.userSettings.organization.people.table.add-external-warn', {
              email: user.email,
            })}
          </Text>
          <Text>
            <b>
              {t('page.userSettings.organization.people.table.warn-are-you-sure', {})}
            </b>
          </Text>
        </Stack>
      ),
      labels: {
        confirm: t('page.userSettings.organization.people.table.confirm-warn'),
        cancel: t('global.cancel'),
      },
      onConfirm: () => {
        onConfirm(user);
        setIsExternal(false);
        setIsWarningConfirmModalOpened(false);
        form.reset();
      },
      onCancel: () => {
        setIsWarningConfirmModalOpened(false);
      },
    });
  };

  // Return a normalized access type
  const getNormalizedAccessType = (): OrganizationUserAccess => {
    const isReadOnly = form.values.isReadOnly;
    const isOrganizationAdmin = form.values.isOrganizationAdmin;

    if (isOrganizationAdmin) {
      return 'admin';
    }
    if (isReadOnly) {
      return 'readonly';
    }
    return 'standard';
  };

  // Return a normalized access type
  const setNormalizedAccessType = (val: OrganizationUserAccess) => {
    console.info('Setting normalized access type to:', val);
    switch (val) {
      case 'readonly':
        form.setFieldValue('isReadOnly', true);
        form.setFieldValue('isOrganizationAdmin', false);
        break;
      case 'admin':
        form.setFieldValue('isReadOnly', false);
        form.setFieldValue('isOrganizationAdmin', true);
        break;
      default:
        form.setFieldValue('isReadOnly', false);
        form.setFieldValue('isOrganizationAdmin', false);
    }
  };

  return (
    <Modal
      opened={opened && !isWarningConfirmModalOpened}
      onClose={() => {
        form.reset();
        setIsExternal(false);
        onCancel();
      }}
      title={
        <Title order={3}>
          {t('page.userSettings.organization.people.header-create')}
        </Title>
      }
    >
      <form
        onSubmit={form.onSubmit(async (newUserValues) => {
          if (!isExternal) {
            await onConfirm(newUserValues);
            form.reset();
          } else {
            setIsWarningConfirmModalOpened(true);
            openConfirmModal(newUserValues);
          }
        })}
      >
        <Stack>
          <Collapse in={isExternal}>
            <Alert icon={<Icon icon="circle-info" />} color="yellow">
              {t('page.userSettings.organization.people.table.headers.invitingExternal')}
            </Alert>
          </Collapse>
          <Flex justify="space-between">
            <TextInput
              label={t('page.userSettings.organization.people.table.headers.firstName')}
              required
              style={{ width: '48%' }}
              error={validationErrors.firstName}
              {...form.getInputProps('firstName')}
            />
            <TextInput
              label={t('page.userSettings.organization.people.table.headers.lastName')}
              required
              style={{ width: '48%' }}
              error={validationErrors.lastName}
              {...form.getInputProps('lastName')}
            />
          </Flex>
          <TextInput
            label={t('page.userSettings.organization.people.table.headers.email')}
            type="email"
            required
            {...form.getInputProps('email')}
            error={validationErrors.email}
            onKeyUp={(e) => {
              const email = e.currentTarget.value;
              const emailDomain = email.includes('@') ? email.split('@')[1] : '';

              if (
                validateEmail(email) && //don't show this warning too early
                emailDomain &&
                !org?.domains?.includes(emailDomain)
              ) {
                setIsExternal(true);
              } else {
                setIsExternal(false);
              }
            }}
          />
          <TextInput
            label={t('page.userSettings.organization.people.table.headers.title')}
            {...form.getInputProps('title')}
          />
          <Radio.Group
            name="roleSelection"
            label={t('page.userSettings.organization.people.table.headers.accessType')}
            value={getNormalizedAccessType()}
            onChange={(value) => setNormalizedAccessType(value as OrganizationUserAccess)}
          >
            <Stack
              mt="xs"
              style={{
                gap: '10px',
              }}
            >
              <Radio
                size="sm"
                value="standard"
                label={t(
                  'page.userSettings.organization.people.table.headers.isStandard',
                )}
              />
              <Flex align="center">
                <Radio
                  size="sm"
                  value="admin"
                  label={t(
                    'page.userSettings.organization.people.table.headers.isOrganizationAdmin',
                  )}
                  style={{
                    display: 'inline-block',
                  }}
                />
                <Text size="sm" color="gray" className={styles.radioItemDescription}>
                  -{' '}
                  {t(
                    'page.userSettings.organization.people.table.headers.isOrganizationAdmin-description',
                  )}
                </Text>
              </Flex>
              <Flex
                align="center"
                style={{
                  marginBottom: '0px',
                  paddingBottom: '0px',
                }}
              >
                <Radio
                  size="sm"
                  value="readonly"
                  label={t(
                    'page.userSettings.organization.people.table.headers.isReadOnly',
                  )}
                  style={{
                    display: 'inline-block',
                  }}
                />
                <Text size="sm" color="gray" className={styles.radioItemDescription}>
                  -{' '}
                  {t(
                    'page.userSettings.organization.people.table.headers.isReadOnly-description',
                  )}
                </Text>
              </Flex>
            </Stack>
          </Radio.Group>
          <Flex justify="flex-end" gap="md">
            <Button
              type="button"
              onClick={() => {
                form.reset();
                setIsExternal(false);
                onCancel();
              }}
              color="dark"
            >
              {t('global.cancel')}
            </Button>
            <Button loading={isCreating} type="submit">
              {t('page.userSettings.organization.people.table.confirm')}
            </Button>
          </Flex>
        </Stack>
      </form>
    </Modal>
  );
};

const validateEmail = (email: string) =>
  !!email.length &&
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
