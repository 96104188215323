import {
  Checkbox,
  CloseButton,
  Divider,
  Flex,
  MultiSelect,
  Slider,
  Stack,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetchSharingPortal } from '../../api/sharingPortal/hooks/useFetchSharingportal/useFetchSharingPortal';
import { useFetchAssetAuthors } from '../../hooks/queries/useFetchAssetAuthors';
import { useFetchAssetCVEIDs } from '../../hooks/queries/useFetchAssetCVEIDs';
import { useFetchAssetSuppliers } from '../../hooks/queries/useFetchAssetSuppliers';
import { useFetchLabels } from '../../hooks/queries/useFetchLabels';
import Icon from '../Icon/Icon';
import '../Labels/Labels.scss';
import styles from './AssetsTableFilters.module.scss';

interface Props {
  filterOnlyHasVulns: boolean;
  filterOnlyHasCriticalVulns: boolean;
  filterOnlyHasLicenseIssues: boolean;
  filterOnlyFirstParty: boolean;
  filterOnlyThirdParty: boolean;
  filterIsActive: boolean;
  filterSharedToPortal: boolean;
  setFilterSharedToPortal: (value: boolean) => void;
  filterRiskScore: string[];
  filterCveIds: string[];
  filterCvssScore: number;
  filterAssetAuthor: string[];
  filterAssetSupplier: string[];
  filterAssetTypes: string[];
  filterLicenseTypes: string[];
  filterLabels: string[];
  setFilterOnlyHasVulns: (value: boolean) => void;
  setFilterOnlyHasCriticalVulns: (value: boolean) => void;
  setFilterOnlyHasLicenseIssues: (value: boolean) => void;
  setFilterOnlyFirstParty: (value: boolean) => void;
  setFilterOnlyThirdParty: (value: boolean) => void;
  setFilterIsActive: (value: boolean) => void;
  setFilterRiskScore: (value: string[]) => void;
  setFilterCveIds: (value: string[]) => void;
  setFilterCvssScore: (value: number) => void;
  setFilterAssetAuthor: (value: string[]) => void;
  setFilterAssetSupplier: (value: string[]) => void;
  setFilterAssetTypes: (value: string[]) => void;
  setFilterLicenseTypes: (value: string[]) => void;
  setFilterLabels: (value: string[]) => void;
}

export const AssetsTableFilters = ({
  filterOnlyHasVulns,
  filterOnlyHasCriticalVulns,
  filterOnlyHasLicenseIssues,
  filterOnlyFirstParty,
  filterOnlyThirdParty,
  filterIsActive,
  filterSharedToPortal,
  setFilterSharedToPortal,
  filterRiskScore,
  filterCveIds,
  filterCvssScore,
  filterAssetAuthor,
  filterAssetSupplier,
  filterAssetTypes,
  filterLicenseTypes,
  filterLabels,
  setFilterOnlyHasVulns,
  setFilterOnlyHasCriticalVulns,
  setFilterOnlyHasLicenseIssues,
  setFilterOnlyFirstParty,
  setFilterOnlyThirdParty,
  setFilterIsActive,
  setFilterRiskScore,
  setFilterCveIds,
  setFilterCvssScore,
  setFilterAssetAuthor,
  setFilterAssetSupplier,
  setFilterAssetTypes,
  setFilterLicenseTypes,
  setFilterLabels,
}: Props) => {
  const { t } = useTranslation();

  const { data: assetAuthors = [] } = useFetchAssetAuthors();
  const { data: assetSuppliers = [] } = useFetchAssetSuppliers();
  const { data: cveIds = [] } = useFetchAssetCVEIDs();
  const { data: labels = [] } = useFetchLabels({});
  const { data: sharingPortal } = useFetchSharingPortal();
  const isEnabledSharingPortal = Boolean(sharingPortal?.isActive);

  return (
    <>
      <Title
        order={3}
        className="cap-letter-spacing"
        color="cyan"
        size="lg"
        mt="0"
        mb="sm"
      >
        {t('tables.assets.filters.suggested')}
      </Title>
      <Divider my={0} />
      <Stack my="md">
        <Flex align="center" justify="space-between">
          <Checkbox
            label={t('tables.assets.filters.onlyHasVulns')}
            name="has-vulns"
            checked={filterOnlyHasVulns}
            classNames={{ label: styles.suggestedFilterLabel }}
            onChange={(event) => {
              setFilterOnlyHasVulns(event.target.checked);
            }}
          />
          <Tooltip label={t('tables.assets.filters.onlyHasVulns-tooltip')}>
            <span className="icon-tooltip-cont">
              <Icon icon="info-circle" />
            </span>
          </Tooltip>
        </Flex>
        <Flex align="center" justify="space-between">
          <Checkbox
            label={t('tables.assets.filters.onlyHasCriticalVulns')}
            name="has-critical-vulns"
            checked={filterOnlyHasCriticalVulns}
            classNames={{ label: styles.suggestedFilterLabel }}
            onChange={(event) => {
              setFilterOnlyHasCriticalVulns(event.target.checked);
            }}
          />
          <Tooltip label={t('tables.assets.filters.onlyHasCriticalVulns-tooltip')}>
            <span className="icon-tooltip-cont">
              <Icon icon="info-circle" />
            </span>
          </Tooltip>
        </Flex>
        <Flex align="center" justify="space-between">
          <Checkbox
            label={t('tables.assets.filters.onlyHasLicenseIssues')}
            name="has-license-issues"
            checked={filterOnlyHasLicenseIssues}
            classNames={{ label: styles.suggestedFilterLabel }}
            onChange={(event) => {
              setFilterOnlyHasLicenseIssues(event.target.checked);
            }}
          />
          <Tooltip label={t('tables.assets.filters.onlyHasLicenseIssues-tooltip')}>
            <span className="icon-tooltip-cont">
              <Icon icon="info-circle" />
            </span>
          </Tooltip>
        </Flex>
        <Flex align="center" justify="space-between">
          <Checkbox
            label={t('tables.assets.filters.onlyFirstParty')}
            name="only-first-party"
            checked={filterOnlyFirstParty}
            classNames={{ label: styles.suggestedFilterLabel }}
            onChange={(event) => {
              setFilterOnlyFirstParty(event.target.checked);
            }}
          />
          <Tooltip label={t('tables.assets.filters.onlyFirstParty-tooltip')}>
            <span className="icon-tooltip-cont">
              <Icon icon="info-circle" />
            </span>
          </Tooltip>
        </Flex>
        <Flex align="center" justify="space-between">
          <Checkbox
            label={t('tables.assets.filters.onlyThirdParty')}
            name="only-third-party"
            checked={filterOnlyThirdParty}
            classNames={{ label: styles.suggestedFilterLabel }}
            onChange={(event) => {
              setFilterOnlyThirdParty(event.target.checked);
            }}
          />
        </Flex>
        <Flex align="center" justify="space-between">
          <Checkbox
            label={t('tables.assets.filters.activeAssetsOnly')}
            name="onlyActiveAssets"
            checked={filterIsActive}
            classNames={{ label: styles.suggestedFilterLabel }}
            onChange={(event) => {
              setFilterIsActive(event.target.checked);
            }}
          />
          <Tooltip
            label={t('tables.assets.filters.activeAssetsOnlyTooltip')}
            classNames={{
              tooltip: styles.tooltip,
            }}
          >
            <span className="icon-tooltip-cont">
              <Icon icon="info-circle" />
            </span>
          </Tooltip>
        </Flex>
        {isEnabledSharingPortal && (
          <Flex align="center">
            <Checkbox
              label={t('tables.assets.filters.sharedToPortal')}
              name="sharedOnPortal"
              checked={filterSharedToPortal}
              classNames={{ label: styles.suggestedFilterLabel }}
              onChange={(event) => {
                setFilterSharedToPortal(event.target.checked);
              }}
            />
          </Flex>
        )}
      </Stack>
      <Title
        order={3}
        className="cap-letter-spacing"
        color="cyan"
        size="lg"
        mt="md"
        mb="sm"
      >
        {t('tables.assets.filters.vulnerabilities')}
      </Title>
      <Divider />
      <Stack my="md">
        <MultiSelect
          clearable
          searchable
          label={t('tables.assets.filters.cveIds')}
          placeholder={t('global.select')}
          data={cveIds}
          value={filterCveIds}
          onChange={(value) => setFilterCveIds(value)}
          rightSection={filterCveIds?.length ? null : undefined} //show clear button
        />
        <Stack className="range-slider" py="md">
          <Flex align="center" justify="space-between">
            <Text fw={500} size="sm">
              {t('tables.assets.filters.cvssScore')}
            </Text>
            <Tooltip label={t('tables.assets.filters.cvssScore-tooltip')}>
              <span className="icon-tooltip-cont">
                <Icon icon="info-circle" />
              </span>
            </Tooltip>
          </Flex>
          <Slider
            min={0}
            max={10}
            defaultValue={filterCvssScore}
            onChangeEnd={(value) => {
              setFilterCvssScore(value);
            }}
          />
          <Text color="gray" size="sm">
            {t('tables.assets.filters.cvssScore-value', {
              value: filterCvssScore,
              severity:
                filterCvssScore < 4
                  ? t('global.low')
                  : filterCvssScore < 7
                    ? t('global.medium')
                    : filterCvssScore < 9
                      ? t('global.high')
                      : t('global.critical'),
            })}
          </Text>
        </Stack>
      </Stack>
      <Title
        order={3}
        className="cap-letter-spacing"
        color="cyan"
        size="lg"
        mt="md"
        mb="sm"
      >
        {t('tables.assets.filters.assets')}
      </Title>
      <Divider />
      <Stack mt="md" mb="xl" pb="xl">
        <MultiSelect
          clearable
          data={[
            {
              value: 'low',
              label: t('tables.assets.filters.low'),
            },
            {
              value: 'medium',
              label: t('tables.assets.filters.medium'),
            },
            {
              value: 'high',
              label: t('tables.assets.filters.high'),
            },
          ]}
          label={
            <Flex align="center" justify="space-between">
              <Text fw={500} size="sm">
                {t('tables.assets.filters.riskScore')}
              </Text>
              <Tooltip label={t('tables.assets.filters.riskScore-tooltip')}>
                <span className="icon-tooltip-cont">
                  <Icon icon="info-circle" />
                </span>
              </Tooltip>
            </Flex>
          }
          placeholder={t('global.select')}
          onChange={(value) => setFilterRiskScore(value ?? '')}
          value={filterRiskScore}
          rightSection={filterRiskScore?.length ? null : undefined} //show clear button
        />
        <MultiSelect
          clearable
          searchable
          label={t('tables.assets.filters.labels')}
          placeholder={t('global.select')}
          data={labels.map((label) => ({
            color: label.color,
            label: label.name!,
            value: label._id?.toString()!,
          }))}
          itemComponent={forwardRef<HTMLDivElement, any>(
            ({ label, color, ...rest }, ref) => (
              <Flex ref={ref} {...rest}>
                <div className="label-color-container">
                  <div className="label-color" style={{ backgroundColor: color }} />
                </div>
                <div className="label-name">{label}</div>
              </Flex>
            ),
          )}
          valueComponent={forwardRef<HTMLDivElement, any>(
            ({ label, color, onRemove, ...rest }, ref) => (
              <Flex ref={ref} {...rest}>
                <div className="label-color-container">
                  <div className="label-color" style={{ backgroundColor: color }} />
                </div>
                <div className="label-name">{label}</div>
                <CloseButton
                  onMouseDown={onRemove}
                  variant="transparent"
                  size={22}
                  iconSize={14}
                  tabIndex={-1}
                />
              </Flex>
            ),
          )}
          value={filterLabels}
          onChange={(value) => setFilterLabels(value)}
          rightSection={filterLabels?.length ? null : undefined} //show clear button
        />
        <MultiSelect
          clearable
          searchable
          data={assetAuthors}
          label={t('tables.assets.filters.author')}
          placeholder={t('global.select')}
          onChange={(value) => setFilterAssetAuthor(value)}
          value={filterAssetAuthor}
          rightSection={filterAssetAuthor?.length ? null : undefined} //show clear button
        />
        <MultiSelect
          clearable
          searchable
          data={assetSuppliers}
          label={t('tables.assets.filters.supplier')}
          placeholder={t('global.select')}
          onChange={(value) => setFilterAssetSupplier(value)}
          value={filterAssetSupplier}
          rightSection={filterAssetSupplier?.length ? null : undefined} //show clear button
        />
        <MultiSelect
          clearable
          searchable
          label={t('tables.assets.filters.types')}
          placeholder={t('global.select')}
          data={[
            {
              label: t('tables.assets.filters.application'),
              value: 'application',
            },
            {
              label: t('tables.assets.filters.container'),
              value: 'container',
            },
            {
              label: t('tables.assets.filters.file'),
              value: 'file',
            },
            {
              label: t('tables.assets.filters.library'),
              value: 'library',
            },
            {
              label: t('tables.assets.filters.operating-system'),
              value: 'operating-system',
            },
            {
              label: t('tables.assets.filters.firmware'),
              value: 'firmware',
            },
            {
              label: t('tables.assets.filters.device'),
              value: 'device',
            },
            {
              label: t('tables.assets.filters.other'),
              value: 'other',
            },
          ]}
          onChange={(value) => setFilterAssetTypes(value)}
          value={filterAssetTypes}
          rightSection={filterAssetTypes?.length ? null : undefined} //show clear button
        />
        <MultiSelect
          clearable
          searchable
          label={t('tables.assets.filters.licenseTypes')}
          placeholder={t('global.select')}
          data={[
            {
              label: 'Copyleft',
              value: 'Copyleft',
            },
            {
              label: 'Custom',
              value: 'Custom',
            },
            {
              label: 'Permissive',
              value: 'Permissive',
            },
            {
              label: 'Proprietary',
              value: 'Proprietary',
            },
          ]}
          value={filterLicenseTypes}
          onChange={(value) => setFilterLicenseTypes(value)}
          rightSection={filterLicenseTypes?.length ? null : undefined} //show clear button
        />
      </Stack>
    </>
  );
};
