import type { InterfaceUnmatchedLicense } from '@manifest-cyber/types/interface/dbTables';
import { useQuery } from '@tanstack/react-query';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';

interface Options {
  refetchOnWindowFocus?: boolean;
}

export const useFetchUnmatchedLicenses = (options?: Options) => {
  let fetchUrl = `licenses/unmatched`;

  return useQuery({
    queryKey: ['unmatched-licenses', `/${fetchUrl}`],
    queryFn: () => AxiosProxy.get({ url: fetchUrl }),
    select: (data) => (data?.data ?? []) as InterfaceUnmatchedLicense[],
    refetchOnWindowFocus: options?.refetchOnWindowFocus,
  });
};
