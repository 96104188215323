import { useMutation } from '@tanstack/react-query';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';

interface CheckActiveResponse {
  success: boolean;
  errors: string[];
  data?: boolean | null;
}

interface FetchOptions {
  onSuccess?: (data: CheckActiveResponse) => void;
  onError?: (error: any) => void;
}

export const useCheckActiveAssets = (options?: FetchOptions) => {
  return useMutation<CheckActiveResponse, any, string[]>({
    mutationFn: async (assetIds: string[]) => {
      const fetchUrl = 'assets/check-active';
      const fetchBody = { assetIds };
      return await AxiosProxy.post({
        url: fetchUrl,
        withCredentials: true,
        body: fetchBody,
        options: {},
      });
    },
    onSuccess: options?.onSuccess,
    onError: options?.onError,
  });
};
