import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';
import { LABELS_QUERY_KEY } from '../queries/useFetchLabels';

interface UsePutLabelParams {
  name: string;
  color: string;
  id: string;
}

export const usePutLabel = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ id, name, color }: UsePutLabelParams) => {
      const response = await AxiosProxy.put({
        url: `/label/${id}`,
        withCredentials: true,
        body: { name, color },
      });

      if (!response.success) {
        return Promise.reject(response.errors);
      }

      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [LABELS_QUERY_KEY],
      });
    },
  });
};
