import { IconName } from '@fortawesome/fontawesome-svg-core';
import {
  InterfaceVulnerability,
  InterfaceVulnerabilityTriageStatus,
} from '@manifest-cyber/types/interface/dbTables';
import {
  Button,
  Flex,
  Group,
  Loader,
  Radio,
  Select,
  Text,
  Textarea,
  Tooltip,
} from '@mantine/core';
import { hasLength, useForm } from '@mantine/form';
import { DateTime } from 'luxon';
import {
  ComponentPropsWithoutRef,
  Dispatch,
  forwardRef,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { usePostVulnerabilityTriage } from '../../hooks/mutations/usePostVulnerabilityTriage';
import { useFetchOrganization } from '../../hooks/queries/useFetchOrganization';
import { useFetchTriageStatuses } from '../../hooks/queries/useFetchTriageStatuses';
import { useFetchUser } from '../../hooks/queries/useFetchUser';
import { useOrganizationId } from '../../hooks/utils/useOrganizationId';
import ClickableRegion from '../ClickableRegion';
import Icon from '../Icon';
import { RiskyTriageConfirm } from './RiskyTriageConfirm';
import styles from './TriageForm.module.scss';

interface VulnerabilityTriage {
  organizationId: string;
  userId: string;
  entityId: string;
  entityType: string;
  assetName: string;
  assetVersion: string;
  organizationAssetId: string;
  cveId: string;
  scope: string;
  justification: string;
  details: string;
  statusId: string;
  snooze: string;
  dateCreated: string;
  dateModified: string;
  statusName: string;
  status: { entityId: string; entityType: string; userId: string };
}

interface InterfaceVulnerabilityWithOptionalTriaged extends InterfaceVulnerability {
  vulnerabilityTriage?: VulnerabilityTriage;
}

interface SelectItem {
  value: string;
  label: string;
  group?: string;
}
interface RadioItem {
  value: string;
  label: string;
}
interface Props {
  cves: InterfaceVulnerabilityWithOptionalTriaged[];
  organizationAssetId: string;
  shouldResetForm: boolean;
  updateIsCloseable: Dispatch<SetStateAction<boolean>>;
  onSave: () => void;
}
interface ItemProps extends ComponentPropsWithoutRef<'div'> {
  icon: IconName;
  label: string;
  value: string;
  group: string;
}

function TriageForm({
  cves,
  organizationAssetId,
  shouldResetForm,
  updateIsCloseable,
  onSave,
}: Props) {
  const [currentOrgId] = useOrganizationId();
  const { data: fetchedCurrentOrganization, isLoading: isLoadingCurrentOrganization } =
    useFetchOrganization({ organizationId: currentOrgId });

  const acceptedVulnTriageFeatureFlag =
    !isLoadingCurrentOrganization &&
    fetchedCurrentOrganization?.enabledFeatures?.some(
      (feature) => feature === 'accepted-vuln-triage',
    );
  const isSingleCveAndTriaged =
    cves.length === 1 && cves[0]?.vulnerabilityTriage?.dateModified;

  const { t } = useTranslation();
  const scopes = [
    {
      value: 'thisAssetVersionOnly',
      label: t('triage.form.scopes.thisAssetVersionOnly'),
    },
    {
      value: 'allVersionsOfThisAssetCurrent',
      label: t('triage.form.scopes.allVersionsOfThisAssetCurrent'),
    },
  ];

  scopes.push({
    value: 'allVersionsOfThisAssetForever',
    label: t('triage.form.scopes.allVersionsOfThisAssetForever'),
  });

  const defaultStatuses: ItemProps[] = [
    {
      value: 'notAffected',
      label: t('triage.form.defaultStatuses.notAffected'),
      group: t('triage.form.cisaRecommendations'),
      icon: 'shield-check',
    },
    {
      value: 'affected',
      label: t('triage.form.defaultStatuses.affected'),
      group: t('triage.form.cisaRecommendations'),
      icon: 'triangle-exclamation',
    },
    {
      value: 'underInvestigation',
      label: t('triage.form.defaultStatuses.underInvestigation'),
      group: t('triage.form.cisaRecommendations'),
      icon: 'wrench',
    },
    {
      value: 'fixed',
      label: t('triage.form.defaultStatuses.fixed'),
      group: t('triage.form.cisaRecommendations'),
      icon: 'sparkles',
    },
  ];

  let otherStatuses: ItemProps[] = [
    {
      value: 'accepted',
      label: t('triage.form.otherStatuses.accepted'),
      group: t('triage.form.other'),
      icon: 'circle-check',
    },
  ];

  const justificationOptions: RadioItem[] = [
    {
      value: 'componentNotPresent',
      label: t('triage.form.justifications.componentNotPresent'),
    },
    {
      value: 'vulnerableCodeNotPresent',
      label: t('triage.form.justifications.vulnerableCodeNotPresent'),
    },
    {
      value: 'vulnerableCodeIsNotInExecutePath',
      label: t('triage.form.justifications.vulnerableCodeIsNotInExecutePath'),
    },
    {
      value: 'vulnerableCodeCannotBeControlledByAdvesary',
      label: t('triage.form.justifications.vulnerableCodeCannotBeControlledByAdvesary'),
    },
    {
      value: 'inlineMitigationsAlreadyExist',
      label: t('triage.form.justifications.inlineMitigationsAlreadyExist'),
    },
    {
      value: 'otherProvideImpactStatement',
      label: t('triage.form.justifications.otherProvideImpactStatement'),
    },
  ];

  /*
   * Get the author of the triage status, or triage
   */
  const author = useMemo(() => {
    let author = 'user';
    let authorId = '';
    if (isSingleCveAndTriaged && cves[0]?.vulnerabilityTriage) {
      const triage = cves[0].vulnerabilityTriage;
      // the author of the triage could be different to the user that update the triage status
      // so author priorities are:
      // 1. triage status author
      //  A. entity type and id
      // 2. triage author
      authorId = triage.status.userId || triage.userId || '';
      if (triage.status.entityType) {
        authorId = triage.status.entityId;
        author = triage.status.entityType;
      }
    }
    return { author, authorId };
  }, [cves, isSingleCveAndTriaged]);

  const { data: lastUpdatedActionUser, isLoading: fetchUserLoading } = useFetchUser({
    userId: isSingleCveAndTriaged ? author.authorId || '' : '',
  });

  /*
   * Check if the author is loading
   */
  const authorLoading = useMemo(
    () => author.authorId && fetchUserLoading,
    [fetchUserLoading, author],
  );

  /*
   * Get the last updated message depending on the author
   */
  const lastUpdatedMessage = useMemo(() => {
    if (!lastUpdatedActionUser?.decryptedEmails?.length) return '';
    let email: string = lastUpdatedActionUser.decryptedEmails[0] || '';
    let date: string = DateTime.fromISO(
      cves[0]?.vulnerabilityTriage?.dateModified || new Date().toISOString(),
    ).toFormat('MMM dd, yyyy');

    if (author.author === 'user')
      return t('triage.form.userText', {
        email,
        date,
      });

    if (author.author === 'vex')
      return t('triage.form.vexText', {
        email,
        date,
      });
  }, [lastUpdatedActionUser]);

  const { data: vulnTriageStatuses = [], isLoading: isLoadingTriageStatuses } =
    useFetchTriageStatuses();
  const { mutateAsync: postVulnTriage, isLoading: isPostingVulnTriage } =
    usePostVulnerabilityTriage(organizationAssetId);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [statuses, setStatuses] = useState<SelectItem[]>([]);
  const [someVulnsHaveTriage, setSomeVulnsHaveTriage] = useState<boolean>(false);
  const [showImpactStatement, setShowImpactStatement] = useState<boolean>(false);
  const [triageSaved, setTriageSaved] = useState<boolean>(false);
  const [safeToClose, setSafeToClose] = useState<boolean>(true);
  const [riskyConfirmationModalOpen, setRiskyConfirmationModalOpen] =
    useState<boolean>(false);

  const selectedCVEScopes = useMemo(() => {
    const scopes: string[] = [];
    cves.forEach((c) => {
      const scope = (c as any).vulnerabilityTriage?.scope;
      if (scope) scopes.push(scope);
    });
    return scopes;
  }, [cves]);

  const handleCloseRiskyConfirmModal = () => {
    if (!isPostingVulnTriage) {
      setRiskyConfirmationModalOpen(false);
    }
  };

  function updateStatuses(
    vulnTriageStatuses: InterfaceVulnerabilityTriageStatus[],
    otherStatuses: ItemProps[],
  ): ItemProps[] {
    return otherStatuses.map((status) => {
      const match = vulnTriageStatuses.find(
        (vulnStatus) => vulnStatus.name?.toLowerCase() === status.value.toLowerCase(),
      );

      if (match) {
        return {
          value: match._id?.toString() || '',
          label: match.name || '',
          group: status.group,
          icon: status.icon,
        };
      }

      return status;
    });
  }

  useEffect(() => {
    if (!isLoadingTriageStatuses && !isLoadingCurrentOrganization) {
      otherStatuses = updateStatuses(vulnTriageStatuses, otherStatuses);

      const additionalCustomStatuses = vulnTriageStatuses
        .filter((status) => !!status.name)
        .filter((status) => !defaultStatuses.some((s) => s.value === status.name))
        .filter(
          (status) =>
            !otherStatuses.some(
              (filter) => filter.label.toLowerCase() === status.name?.toLowerCase(),
            ),
        )
        .map(
          (status): ItemProps => ({
            value: status._id?.toString() || '',
            label: status.name || '',
            group: t('global.custom'),
            icon: 'pen-to-square',
          }),
        );

      if (acceptedVulnTriageFeatureFlag) {
        setStatuses([...defaultStatuses, ...otherStatuses, ...additionalCustomStatuses]);
      } else {
        setStatuses([...defaultStatuses, ...additionalCustomStatuses]);
      }
    }
  }, [
    vulnTriageStatuses,
    isLoadingTriageStatuses,
    isLoadingCurrentOrganization,
    acceptedVulnTriageFeatureFlag,
  ]);

  useEffect(() => {
    if (cves.length > 1) {
      const someVulnsHaveTriage = cves.some((cve) => !!cve.vulnerabilityTriage);
      setSomeVulnsHaveTriage(someVulnsHaveTriage);
    }
    if (cves.length === 1) {
      updateFormWithSavedValues();
    }
  }, [cves.length, statuses.length]);
  //loading info for single cve only
  const updateFormWithSavedValues = () => {
    if (form.values.status) {
      // we have already loaded saved values into the form
      // so we are probably creating a custom status here
      return;
    }
    const {
      status,
      justification,
      scope,
      justificationImpactStatement,
      actionStatement,
      statusNotes,
    } = getSavedValues();
    form.setValues({
      status,
      justification,
      scope,
      justificationImpactStatement,
      actionStatement,
      statusNotes,
    });
    if (justificationImpactStatement) {
      setShowImpactStatement(true);
    }
  };

  const getSavedValues = () => {
    const { vulnerabilityTriage } = cves[0] || {};
    if (!vulnerabilityTriage) {
      return {
        status: '',
        scope: 'thisAssetVersionOnly',
        justification: '',
        justificationImpactStatement: '',
        actionStatement: '',
        statusNotes: '',
      };
    }
    const {
      statusName: triageStatusName,
      statusId: triageStatusId,
      justification: triageJustification,
      scope: triageScope,
      details,
    } = vulnerabilityTriage;

    const status = statuses.find((status) => {
      if (status.group === t('global.custom') || status.group === 'Other') {
        return status.label.toLowerCase() === triageStatusName.toLowerCase();
      } else {
        return status.value.toLowerCase() === triageStatusName.toLowerCase();
      }
    });

    const scope = scopes.find((scope) => scope.value === triageScope);
    const justification = justificationOptions.find(
      (status) => status.value === triageJustification,
    );
    const impactStatement =
      details?.length > 0 ? (status?.value === 'notAffected' ? details : '') : '';
    const actionStatement =
      details?.length > 0 ? (status?.value === 'affected' ? details : '') : '';
    const statusNotes =
      details?.length > 0
        ? status?.value !== 'notAffected' && status?.value !== 'affected'
          ? details
          : ''
        : '';

    return {
      status: status?.value || '',
      justification: justification?.value || '',
      scope: scope?.value || '',
      justificationImpactStatement: impactStatement,
      actionStatement,
      statusNotes,
    };
  };

  const form = useForm({
    validateInputOnChange: [
      'justificationImpactStatement',
      'actionStatement',
      'statusNotes',
    ],
    initialValues: {
      status: '',
      scope: 'thisAssetVersionOnly',
      justification: '',
      justificationImpactStatement: '',
      actionStatement: '',
      statusNotes: '',
    },
    validate: {
      justificationImpactStatement: hasLength(
        { max: 999 },
        <div className="textarea-error">
          <Icon icon="exclamation-triangle" />
          <span>{t('triage.form.validationErrors.justificationImpactStatement')}</span>
        </div>,
      ),
      actionStatement: hasLength(
        { max: 999 },
        <div className="textarea-error">
          <Icon icon="exclamation-triangle" />
          <span>{t('triage.form.validationErrors.justificationImpactStatement')}</span>
        </div>,
      ),
      statusNotes: hasLength(
        { max: 999 },
        <div className="textarea-error">
          <Icon icon="exclamation-triangle" />
          <span>{t('triage.form.validationErrors.justificationImpactStatement')}</span>
        </div>,
      ),
    },
  });

  //control submit button disabled state
  useEffect(() => {
    const { status, justification, justificationImpactStatement, actionStatement } =
      form.values;
    setTriageSaved(false);
    //status is not set
    if (!status) {
      return setSubmitDisabled(true);
    }
    //not affected
    if (status === 'notAffected') {
      //status is set to notAffected and no justification is set
      if (!justification) {
        return setSubmitDisabled(true);
      }
      //status is set to notAffected and justification is set to otherProvideImpactStatement and no impact statement is provided
      if (
        justification &&
        justification === 'otherProvideImpactStatement' &&
        !justificationImpactStatement
      ) {
        return setSubmitDisabled(true);
      }
      //impact statement is provided and is longer than 1000 characters
      if (justificationImpactStatement.length >= 1000) {
        return setSubmitDisabled(true);
      }
    }
    //affected
    if (status === 'affected') {
      //status is set to affected and no action statement is provided
      if (!actionStatement) {
        return setSubmitDisabled(true);
      }
      //action statement is provided and is longer than 1000 characters
      if (actionStatement.length >= 1000) {
        return setSubmitDisabled(true);
      }
    }

    return setSubmitDisabled(false);
  }, [form.values]);

  //reset form dirty if user goes back to default or saved values
  useEffect(() => {
    const {
      status,
      justification,
      justificationImpactStatement,
      actionStatement,
      statusNotes,
      scope,
    } = getSavedValues();
    if (
      status === form.values.status &&
      scope === form.values.scope &&
      justification === form.values.justification &&
      justificationImpactStatement === form.values.justificationImpactStatement &&
      actionStatement === form.values.actionStatement &&
      statusNotes === form.values.statusNotes
    ) {
      form.resetDirty();
    }
  }, [form.values]);

  useEffect(() => {
    const { justification } = form.values;
    if (justification === 'otherProvideImpactStatement') {
      setShowImpactStatement(true);
    } else {
      setShowImpactStatement(false);
    }
  }, [form.values.justification]);

  useEffect(() => {
    updateIsCloseable(safeToClose);
  }, [safeToClose]);

  useEffect(() => {
    setSafeToClose(!form.isDirty());
  }, [form.isDirty()]);

  useEffect(() => {
    if (shouldResetForm) {
      form.reset();
      updateFormWithSavedValues();
    }
  }, [shouldResetForm && form.isDirty()]);

  const handleSubmitTriage = async () => {
    const { status, scope } = form.values;

    const statusItem = statuses.find((s) => s.value === status);

    const statusName =
      statusItem?.group === t('global.custom') || statusItem?.group === 'Other'
        ? statusItem.label
        : statusItem?.value || '';

    const triage = {
      triageStatusName: statusName,
      scope,
      justification: getJustificationForTriage(),
      details: getDetailFieldForTriage(),
      cveIds: cves.map((cve) => cve.cveId?.toString() || ''),
      organizationAssetId,
    };

    const triageStatus = await postVulnTriage({ triage });
    if (triageStatus.success) {
      setTriageSaved(true);
      setSafeToClose(true);
      onSave();
      setRiskyConfirmationModalOpen(false);
    }
  };

  const checkAndSubmitRiskyTriageAction = () => {
    const { scope } = form.values;
    // check if exist an scope change for selected cves
    const isAScopeChange =
      selectedCVEScopes.length && selectedCVEScopes.some((s) => s !== scope);
    // if there is a scope change, we shown the risky modal
    if (isAScopeChange) {
      return setRiskyConfirmationModalOpen(true);
    }
    return handleSubmitTriage();
  };

  const getDetailFieldForTriage = () => {
    const { status, justificationImpactStatement, actionStatement, statusNotes } =
      form.values;
    if (status === 'notAffected') {
      return justificationImpactStatement;
    }
    if (status === 'affected') {
      return actionStatement;
    }
    return statusNotes;
  };

  const getJustificationForTriage = () => {
    const { status, justification } = form.values;
    if (status === 'notAffected') {
      return justification;
    }
    return '';
  };

  const renderTriageInfoSingleVuln = (
    isSingleAssetScope: boolean,
    isSingleVuln: boolean,
  ) => (
    <div className="triage-info info">
      <Icon icon="lightbulb-on" />
      <span>
        {isSingleAssetScope ? (
          <>
            {isSingleVuln
              ? t('triage.form.triageInfo.singleAssetSingleVuln')
              : t('triage.form.triageInfo.singleAssetMultiVuln')}
          </>
        ) : (
          <>
            {isSingleVuln
              ? t('triage.form.triageInfo.multiAssetSingleVuln')
              : t('triage.form.triageInfo.multiAssetMultiVuln')}
          </>
        )}
      </span>
    </div>
  );

  const renderTriageInfoMultiVuln = () => (
    <div className="triage-info warning">
      <Icon icon="circle-exclamation" />
      <span>{t('triage.form.triageInfo.bulkAction')}</span>
    </div>
  );

  const renderInfoLabel = () => {
    const selectedCVEsCount = cves.length;
    const selectedScope = form.values.scope;
    const selectedStatus = form.values.status;
    const returnedComponents = [];

    const acceptedStatusId = vulnTriageStatuses.find(
      (status) => status.name?.toLowerCase() === 'accepted',
    )?._id;

    if (!selectedCVEsCount) return null;
    if (selectedCVEsCount > 1 && someVulnsHaveTriage) {
      returnedComponents.push(renderTriageInfoMultiVuln());
    }
    if (
      selectedStatus === 'notAffected' ||
      selectedStatus === 'fixed' ||
      selectedStatus.toLowerCase() === 'accepted' ||
      selectedStatus === acceptedStatusId?.toString()
    ) {
      if (selectedScope === 'thisAssetVersionOnly') {
        returnedComponents.push(
          renderTriageInfoSingleVuln(true, selectedCVEsCount === 1),
        );
      } else {
        returnedComponents.push(
          renderTriageInfoSingleVuln(false, selectedCVEsCount === 1),
        );
      }
    }

    return (
      <>
        {returnedComponents.map((c, i) => {
          return <div key={i}>{c}</div>;
        })}
      </>
    );
  };

  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ icon, label, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap spacing={8}>
          <Icon icon={icon} classNames={styles.selectItemIcon} />
          <div>
            <Text size="sm" className={styles.selectItemLabel}>
              {label}
            </Text>
          </div>
        </Group>
      </div>
    ),
  );

  return (
    <div className="triage-form-container">
      {renderInfoLabel()}
      <form id="triage" onSubmit={form.onSubmit(checkAndSubmitRiskyTriageAction)}>
        <Select
          classNames={{
            itemsWrapper: styles.itemsWrapper,
            separatorLabel: styles.separatorLabel,
            separator: styles.separator,
          }}
          itemComponent={SelectItem}
          mb={'lg'}
          size="md"
          label={
            <div className="status-label-container">
              <div className="label">
                {t('triage.form.status')} <span className="red">*</span>
              </div>
              <Tooltip label={t('triage.form.statusInfo')} multiline width={186}>
                <span>
                  <Icon icon="circle-info" />
                </span>
              </Tooltip>
            </div>
          }
          data={statuses}
          searchable
          getCreateLabel={(query) => (
            <div className="create-new-status-dropdown">
              {t('triage.form.newStatus', { value: query })}
            </div>
          )}
          shouldCreate={(query) => {
            const existingStatus = statuses.find(
              (status) =>
                status.label.toLocaleLowerCase().trim() ===
                query.toLocaleLowerCase().trim(),
            );
            if (existingStatus) {
              return false;
            }
            return !!query;
          }}
          onCreate={(query) => {
            const item = { value: query, label: query, group: t('global.custom') };
            setStatuses((current) => [...current, item]);
            return item;
          }}
          maxDropdownHeight={300}
          creatable
          placeholder={t('triage.form.statusPlaceholder')}
          {...form.getInputProps('status')}
        />
        {form.values.status === 'notAffected' && (
          <>
            <Radio.Group
              mb={'lg'}
              required
              size="md"
              withAsterisk
              label={t('triage.form.justification')}
              {...form.getInputProps('justification')}
            >
              <Group>
                {justificationOptions.map((option) => (
                  <Radio key={option.value} value={option.value} label={option.label} />
                ))}
              </Group>
            </Radio.Group>
            <>
              {showImpactStatement ? (
                <div className="impact-statement-container">
                  <Textarea
                    mb={'lg'}
                    label={t('triage.form.impactStatement')}
                    autosize
                    minRows={2}
                    maxRows={16}
                    maxLength={1000}
                    required={form.values.justification === 'otherProvideImpactStatement'}
                    withAsterisk={
                      form.values.justification === 'otherProvideImpactStatement'
                    }
                    {...form.getInputProps('justificationImpactStatement')}
                  />
                </div>
              ) : (
                <ClickableRegion
                  regionLabel={t('triage.form.addImpactStatement')}
                  className="add-impact-statement"
                  onClick={() => {
                    setShowImpactStatement(true);
                  }}
                >
                  <Icon icon="plus" />
                  {t('triage.form.addImpactStatement')}
                </ClickableRegion>
              )}
            </>
          </>
        )}
        {form.values.status === 'affected' && (
          <Textarea
            mb={'lg'}
            label={t('triage.form.actionStatement')}
            autosize
            minRows={2}
            maxRows={16}
            maxLength={1000}
            required={form.values.status === 'affected'}
            withAsterisk={form.values.status === 'affected'}
            {...form.getInputProps('actionStatement')}
          />
        )}
        {form.values.status &&
          form.values.status !== 'notAffected' &&
          form.values.status !== 'affected' && (
            <Textarea
              mb={'lg'}
              label={t('triage.form.statusNotes')}
              autosize
              minRows={2}
              maxRows={16}
              maxLength={1000}
              {...form.getInputProps('statusNotes')}
            />
          )}
        <Select
          mb={'lg'}
          size="md"
          label={t('triage.form.scope')}
          data={scopes}
          maxDropdownHeight={300}
          className="triage-scope-select"
          {...form.getInputProps('scope')}
        />
      </form>
      {authorLoading && (
        <Flex align={'center'} justify={'center'}>
          <Loader />
        </Flex>
      )}
      {!authorLoading && (
        <div>
          <Button
            loading={isPostingVulnTriage}
            type="submit"
            className="btn-save"
            form="triage"
            disabled={submitDisabled}
          >
            {triageSaved && !isPostingVulnTriage ? (
              <Icon icon="circle-check" />
            ) : (
              <>
                {lastUpdatedMessage && isSingleCveAndTriaged
                  ? t('global.update')
                  : t('global.save.buttonShort')}
              </>
            )}
          </Button>
          {lastUpdatedMessage && isSingleCveAndTriaged && (
            <Text className="triage-lastUpdated" mt={8}>
              {lastUpdatedMessage}
            </Text>
          )}
        </div>
      )}
      <RiskyTriageConfirm
        onClose={handleCloseRiskyConfirmModal}
        opened={riskyConfirmationModalOpen}
        onSubmit={handleSubmitTriage}
        isLoading={isPostingVulnTriage}
      />
    </div>
  );
}

export default TriageForm;
