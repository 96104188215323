import { Text } from '@mantine/core';
import { DateTime } from 'luxon';
import { MRT_Row } from 'mantine-react-table';
import { InterfaceFullAsset } from '../../../../types/FullInterfaces';
import styles from '../AssetsTable.module.scss';

export const DateCreatedColumn = ({ row }: { row: MRT_Row<InterfaceFullAsset> }) => {
  return (
    <Text
      color="gray"
      sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}
      className={`${row.original?.isActive === false ? styles.isInactiveAssetRow : ''}`}
    >
      {DateTime.fromISO(`${row.original.dateCreated}`).toRelative()}
    </Text>
  );
};
