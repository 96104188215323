import { DateTime } from 'luxon';
import capitalizeWords from '../../../../../lib/capitalizeWords';
import {
  SharedSbomsApiResponse,
  SharedSbomsMappedResponse,
} from '../../types/sharedSboms.interface';

export interface MapSharedSbomsTexts {
  unknownType: string;
}

export const mapSharedSboms = (
  { data, queryInfo }: SharedSbomsApiResponse,
  texts?: MapSharedSbomsTexts,
): SharedSbomsMappedResponse => {
  return {
    totalSboms: queryInfo.totalCount ?? 0,
    totalUnfilteredCount: queryInfo.totalUnfilteredCount ?? 0,
    latestSharedDate: data.latestSharedDate
      ? DateTime.fromISO(`${data.latestSharedDate}`).toFormat('MMM dd, yyyy')
      : '',
    sboms: data.sboms.map(
      ({
        sharedDate,
        assetName,
        assetVersion,
        assetType,
        sbomId,
        format,
        formatVersion,
      }) => ({
        id: sbomId,
        assetName: assetName,
        dateShared: sharedDate,
        dateSharedText:
          DateTime.fromISO(`${sharedDate}`).toFormat('MMM dd, yyyy HH:mm') || '',
        type: assetType ? capitalizeWords(assetType) : texts?.unknownType || '',
        assetVersion: assetVersion,
        format: `${format}${formatVersion ? ` ${formatVersion.replace('SPDX-', '')}` : ''}`,
      }),
    ),
  };
};
