import { AssetCountVulnerabilities } from '@manifest-cyber/types/interface/db';
import {
  InterfaceLabel,
  InterfaceOrganizationAsset,
  InterfaceVulnerability,
} from '@manifest-cyber/types/interface/dbTables';
import { useQuery } from '@tanstack/react-query';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';
import { captureExceptionWithMessage } from '../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';

export interface ProductQueryParams {
  enabled?: boolean;
  productId?: string;
}

export interface ProductOverviewVulnerability extends InterfaceVulnerability {
  occurrences: number;
}

export interface FetchProductOverviewResponse {
  success: boolean;
  errors: string[];
  data: {
    countHighRiskAssets: number;
    countVulnerabilities: AssetCountVulnerabilities;
    description: string;
    top5Assets: InterfaceOrganizationAsset[];
    top5Vulns: ProductOverviewVulnerability[];
    labels: InterfaceLabel[];
  };
}

type FetchError = Error;

export const useFetchProductOverview = ({
  enabled = true,
  productId,
}: ProductQueryParams) => {
  let fetchUrl = `product/${productId}/overview`;

  const queryKey = [`product`, `/${fetchUrl}`];

  return useQuery<FetchProductOverviewResponse, FetchError>({
    enabled: enabled,
    keepPreviousData: true,
    queryKey: queryKey,
    queryFn: () => AxiosProxy.get({ url: fetchUrl }).then((response) => response),
    onError: (error: FetchError) => {
      captureExceptionWithMessage(`Unable to fetch product:`, error);
    },
  });
};
