import { useMutation } from '@tanstack/react-query';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';

export interface PostAssetsParams {
  assets: {
    [assetId: string]: boolean;
  };
}

interface AssetStatus {
  isActive: boolean;
}

interface RequestBody {
  assets: Record<string, AssetStatus>;
}

export const usePostAssets = () => {
  return useMutation({
    mutationFn: ({ assets }: PostAssetsParams) => {
      const requestBody: RequestBody = {
        assets: Object.entries(assets).reduce<Record<string, AssetStatus>>(
          (acc, [assetId, isActive]) => {
            acc[assetId] = { isActive };
            return acc;
          },
          {},
        ),
      };
      return AxiosProxy.post({
        url: `/assets/update`,
        withCredentials: true,
        body: requestBody,
      });
    },
  });
};
