import { Badge, Button, Flex, Select, SelectItem } from '@mantine/core';
import classNames from 'classnames';
import {
  MantineReactTable,
  MRT_ColumnDef,
  useMantineReactTable,
} from 'mantine-react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import BasicSearch from '../../../../../components/BasicSearch';
import ClickableRegion from '../../../../../components/ClickableRegion';
import styles from '../../../../../components/DataTables/ComponentAssetsTable.module.scss';
import DataTableFooter from '../../../../../components/DataTables/DataTableFooter';
import Icon from '../../../../../components/Icon';
import Labels from '../../../../../components/Labels';
import { getDefaultTableOptions } from '../../../../../components/MRT/ManifestMRT';
import {
  useFetchVulnAssets,
  VulnImpactAsset,
} from '../../../../../hooks/queries/useFetchVulnAssets';
import { useAuth } from '../../../../../hooks/useAuth';
import capitalizeWords from '../../../../../lib/capitalizeWords';
import { OverviewTablesEmptyState } from '../OverviewTablesEmptyState';
import {
  AssetVendorFilter,
  useVulnAffectedAssetsFilters,
} from './useVulnAffectedAssetsFilters';

interface VulnerabilityAffectedAssetsProps {
  vulnId: string;
  setShowSimpleVexModal: (open: boolean) => void;
  tabsLoaded: boolean;
  isActiveAssetsOnly: boolean;
}

const defaultTableOptions = getDefaultTableOptions<VulnImpactAsset>();

export const VulnerabilityAffectedAssets = ({
  vulnId,
  setShowSimpleVexModal,
  tabsLoaded,
  isActiveAssetsOnly,
}: VulnerabilityAffectedAssetsProps) => {
  const { t } = useTranslation();
  const { checkUserAccess } = useAuth();
  const hasWriteAccess = checkUserAccess('write');
  const {
    assetSearchFilter,
    sorting,
    assetVendorFilter,
    pagination,
    handleSetAssetSearchFilter,
    setSorting,
    setAssetVendorFilter,
    setPagination,
  } = useVulnAffectedAssetsFilters();

  const { data, isFetching, isInitialLoading } = useFetchVulnAssets(
    vulnId,
    isActiveAssetsOnly,
    assetSearchFilter,
    assetVendorFilter,
    pagination,
    sorting,
  );

  const affectedAssets = data?.data;
  const totalCount = data?.count;

  const isInLoadingState = isFetching || !tabsLoaded;

  const AssetVendorFilterSelectOptions = useMemo<SelectItem[]>(() => {
    return [
      {
        value: AssetVendorFilter.all,
        label: t('page.vulnerability.details.overview-tab.assets-table.filter.all'),
      },
      {
        value: AssetVendorFilter.firstParty,
        label: t(
          'page.vulnerability.details.overview-tab.assets-table.filter.first-party',
        ),
      },
      {
        value: AssetVendorFilter.thirdParty,
        label: t(
          'page.vulnerability.details.overview-tab.assets-table.filter.third-party',
        ),
      },
    ];
  }, []);

  const isEmptyTable = Boolean(!affectedAssets?.length);

  const columns = useMemo<MRT_ColumnDef<VulnImpactAsset>[]>(() => {
    if (isEmptyTable && !isInLoadingState) {
      return [];
    }

    return [
      {
        accessorFn: (row) => row,
        id: 'asset_fullyQualifiedName',
        header: t('page.vulnerability.details.overview-tab.assets-table.asset'),
        Cell: ({ row }) => {
          const rowData = row.original;

          return (
            <Flex
              direction="column"
              gap={4}
              className={`asset-meta-column ${row.original?.isActive === false ? styles.isInactiveAssetRow : ''}`}
              pr={16}
            >
              <p className="asset-meta-title">{rowData.fullyQualifiedName}</p>
              <div className="asset-meta-content">
                <ClickableRegion
                  className="asset-version-link"
                  regionLabel={''}
                  href={`/asset/${rowData._id}`}
                >
                  {rowData.version}
                </ClickableRegion>
                {rowData.type && (
                  <p className="asset-meta-type">{capitalizeWords(rowData.type)}</p>
                )}
              </div>
            </Flex>
          );
        },
      },
      {
        accessorFn: (row) => row,
        id: 'assetVendor',
        header: t('page.vulnerability.details.overview-tab.assets-table.vendor'),
        Cell: ({ row }) => {
          const vendorNames = row.original.vendorName;
          const hasVendorNames = vendorNames && vendorNames.length > 0;

          return (
            <Flex
              gap={10}
              align="center"
              w="100%"
              className={`${row.original?.isActive === false ? styles.isInactiveAssetRow : ''}`}
            >
              <p>{hasVendorNames ? vendorNames.join(', ') : '--'}</p>
              {row.original?.relationshipToOrg === 'first' && (
                <Badge
                  mx={0}
                  sx={{
                    width: '35px',
                    '& span': {
                      textOverflow: 'none',
                      overflow: 'visible',
                    },
                  }}
                >
                  {t('global.first')}
                </Badge>
              )}
            </Flex>
          );
        },
        mantineTableBodyCellProps: {
          className: 'asset-vendor',
        },
        enableSorting: false,
        size: 100,
      },
      {
        accessorFn: (row) => row.name,
        id: 'assetLabels',
        header: t('page.vulnerability.details.overview-tab.assets-table.labels'),
        enableSorting: false,
        mantineTableBodyCellProps: {
          align: 'right',
        },
        Cell: ({ row }) => {
          return (
            <Labels
              entity={row.original}
              condensed
              justifyContent="flex-start"
              rounded
              inactive={row.original?.isActive === false}
            />
          );
        },
      },
    ];
  }, [isEmptyTable, isInLoadingState]);

  const table = useMantineReactTable<VulnImpactAsset>({
    ...defaultTableOptions,
    mantinePaperProps: {
      className: classNames(
        'manifest-data-table-no-footer table-card assets-table',
        isEmptyTable && 'empty-table',
      ),
      sx: {
        boxShadow: 'none',
      },
    },
    columns,
    data: affectedAssets || [],
    enableFilters: false,
    enablePagination: false,
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    rowCount: affectedAssets?.length,
    initialState: {
      ...defaultTableOptions.initialState,
      showGlobalFilter: false,
    },
    state: {
      pagination: {
        ...pagination,
        pageSize:
          isInitialLoading || (isInLoadingState && !affectedAssets?.length)
            ? 2
            : pagination.pageSize,
      },
      isLoading: isInLoadingState,
      sorting,
      showLoadingOverlay: isInLoadingState,
    },
    onPaginationChange: setPagination,
    mantineTableBodyRowProps: {
      className: 'table-row',
    },
    mantineTableHeadCellProps: {
      className: 'table-header',
    },
    renderTopToolbar: () => (
      <Flex bg="transparent" mb={8} gap={8}>
        <Select
          data={AssetVendorFilterSelectOptions}
          value={assetVendorFilter}
          onChange={(value) => {
            if (value) {
              setAssetVendorFilter(value);
            }
          }}
          clearable={false}
          allowDeselect={false}
          className="select-input"
        />
        <BasicSearch
          handleSearch={handleSetAssetSearchFilter}
          placeholder={t('global.search')}
          disabled={!affectedAssets}
          initialValue={assetSearchFilter}
        />
        {hasWriteAccess && (
          <Button
            variant="filled"
            leftIcon={<Icon icon="file-lines" />}
            ml="auto"
            onClick={() => setShowSimpleVexModal(true)}
          >
            {t('page.vulnerability.details.overview-tab.assets-table.create-vex')}
          </Button>
        )}
      </Flex>
    ),
    renderEmptyRowsFallback: () => (
      <OverviewTablesEmptyState
        context={t('page.vulnerability.details.overview-tab.assets').toLowerCase()}
        searchFilter={assetSearchFilter}
        vendorFilter={assetVendorFilter}
      />
    ),
    onSortingChange: setSorting,
  });

  return (
    <>
      <MantineReactTable table={table} />
      <DataTableFooter
        currentPage={pagination.pageIndex}
        limitPerPage={pagination.pageSize}
        totalResults={totalCount ?? 0}
        onChangePage={setPagination}
      />
    </>
  );
};
