import {
  InterfaceAssetComponent,
  InterfaceOrganizationAsset,
} from '@manifest-cyber/types/interface/dbTables';
import { useQuery } from '@tanstack/react-query';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';
import { captureExceptionWithMessage } from '../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';

export const sanitizeQueryParamProperty = (param: string) => param.split('_')?.[1];

export interface VulnImpactApiResponse {
  countImpactedAssets: number;
  countImpactedComponents: number;
  impactedAssets: InterfaceOrganizationAsset[];
  impactedComponents: InterfaceAssetComponent[];
}

export interface GetVulnImpactApiResponse<T> {
  data: T[];
  count: number;
  success: boolean;
  errors: string[];
}

export const useFetchVulnImpact = (vulnerabilityId?: string) => {
  const fetchUrl = `vulnerability/${vulnerabilityId}/impact`;

  return useQuery({
    queryKey: ['vulnImpact', { _id: vulnerabilityId }, `/${fetchUrl}`],
    queryFn: () => AxiosProxy.get({ url: fetchUrl }),
    select: (data) => data?.data?.[0] as VulnImpactApiResponse,
    onError: (error) => {
      captureExceptionWithMessage('Unable to fetch vulnerability impact: ', error);
    },
    enabled: Boolean(vulnerabilityId),
  });
};
