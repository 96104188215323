import { Box, Checkbox, Divider, Flex, Radio, Skeleton, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import { captureException, captureMessage } from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetchSharingPortal } from '../../../api/sharingPortal/hooks/useFetchSharingportal/useFetchSharingPortal';
import { ActionableCard } from '../../../components/ActionableCard/ActionableCard';
import { AvatarComponent } from '../../../components/AvatarComponent/AvatarComponent';
import Icon from '../../../components/Icon';
import ManifestModal from '../../../components/ManifestModal/ManifestModal';
import MessageWithBackground from '../../../components/MessageWithBackground/MessageWithBackground';
import { RemovableListItem } from '../../../components/RemovableListItem/RemovableListItem';
import appConfig from '../../../configs/appConfig';
import { ASSET_QUERY_CACHE_PREFIX } from '../../../hooks/queries/useFetchAsset';
import { ASSETS_QUERY_CACHE_PREFIX } from '../../../hooks/queries/useFetchAssets';
import { useNotifications } from '../../../hooks/utils/useNotifications';
import EmailInput from '../../UserSettings/SbomUploadAlerts/components/EmailInput/EmailInput';
import { SHARED_ELEMENTS_USERS_WITH_ACCESS_STUB } from '../__stubs__/shareResourceUsersWithAccess.stub';
import { MappedUsersWithAccessToSharedSbom } from '../api/shareResource/shareResource.api';
import { useDeleteSharedResourceUsers } from '../hooks/useDeleteSharedResourceUsers/useDeleteSharedResourceUsers';
import {
  SHARED_RESOURCE_USERS_CACHE_KEY,
  useFetchSharedResourceUsers,
} from '../hooks/useFetchSharedResourceUsers/useFetchSharedResourceUsers';
import { usePostSharedResourceUsers } from '../hooks/usePostSharedResourceUsers/usePostSharedResourceUsers';
import styles from './ShareSbomModal.module.scss';

interface ShareSbomModalProps {
  onClose: () => void;
  resources: { sbomId: string; isActiveAsset: boolean }[];
  onChangeAcceses: () => void;
}

type ShareSbomFormInterface = {
  emails: string[];
  resourceFormat: 'json';
  sendNotification: boolean;
};

const mockUsersForSkeleton: MappedUsersWithAccessToSharedSbom[] =
  SHARED_ELEMENTS_USERS_WITH_ACCESS_STUB;

export const ShareSbomModal = ({
  onClose,
  onChangeAcceses,
  resources,
}: ShareSbomModalProps) => {
  const someResouceIsInactive = resources.some((resource) => !resource.isActiveAsset);
  const firstResouceId: string = resources[0]?.sbomId || '';
  const isSingleResource = resources.length === 1;
  const { t } = useTranslation();
  const [step, setStep] = useState<'users' | 'sharingOptions'>(
    isSingleResource ? 'users' : 'sharingOptions',
  );
  const { mutateAsync: postSharedResourceUsers, isLoading } =
    usePostSharedResourceUsers();
  const {
    isLoading: isLoadingUsers,
    error: usersWithAccessError,
    data: usersWithAcces,
    refetch: refetchUsers,
  } = useFetchSharedResourceUsers(firstResouceId, {
    enabled: isSingleResource,
  });
  const { data: portal } = useFetchSharingPortal();
  const queryClient = useQueryClient();
  const { siteProtocol, siteURL } = appConfig as {
    siteProtocol: string;
    siteURL: string;
  };
  const portalPath = siteURL.startsWith(siteProtocol)
    ? `${siteURL}/o/${portal?.portalName}`
    : `${siteProtocol}${siteURL}/o/${portal?.portalName}`;

  const { mutateAsync: deleteAccess, isLoading: isDeletingUser } =
    useDeleteSharedResourceUsers();
  const showSharingOptions =
    step === 'sharingOptions' || (usersWithAcces && usersWithAcces.length === 0);
  const { error: showErrorNotification, success: showSuccessNotification } =
    useNotifications();
  const form = useForm<ShareSbomFormInterface>({
    initialValues: { emails: [], sendNotification: true, resourceFormat: 'json' },
    validateInputOnBlur: true,
  });

  const shareSboms = async () => {
    if (form.values.emails.length === 0) {
      onClose();
      return;
    }

    const sbomsQuantity = resources.length;
    try {
      await postSharedResourceUsers({
        notifyByEmail: form.values.sendNotification,
        emails: form.values.emails,
        sbomIds: resources.map((resource) => resource.sbomId),
      });

      showSuccessNotification({
        title: `${sbomsQuantity} ${t('global.sbom', { count: sbomsQuantity })} ${t('page.assets.shareSbomModal.sharedToPortal')}`,
        message: '',
      });

      setStep('users');
      queryClient.invalidateQueries({
        queryKey: [ASSETS_QUERY_CACHE_PREFIX, ASSET_QUERY_CACHE_PREFIX],
      });

      onChangeAcceses();
      onClose();
    } catch (error) {
      showErrorNotification({
        title: t('global.unexpectedError'),
        message: t('global.unexpectedErrorSubtitle'),
      });

      captureMessage(`Error sharing sboms: ${sbomsQuantity}`, 'error');
      captureException(error);
    }
  };

  const removeUserAccess = async (userEmail: string) => {
    try {
      const prevNumberOfUsers = usersWithAcces?.length || 0;

      await deleteAccess({
        email: userEmail,
        sbomId: firstResouceId,
      });

      queryClient.invalidateQueries({
        queryKey: [ASSETS_QUERY_CACHE_PREFIX, ASSET_QUERY_CACHE_PREFIX],
      });

      if (prevNumberOfUsers === 1) {
        onChangeAcceses();
        await queryClient.invalidateQueries({
          queryKey: [SHARED_RESOURCE_USERS_CACHE_KEY, firstResouceId],
        });
      }
    } catch (error) {
      showErrorNotification({
        title: t('global.unexpectedError'),
        message: t('global.unexpectedErrorSubtitle'),
      });

      captureMessage(`Error removing user access`, 'error');
      captureException(error);
    }
  };

  return (
    <ManifestModal
      size="lg"
      opened
      withCloseButton={true}
      onClose={() => {
        onClose();
      }}
      title={t('page.assets.shareSbomModal.title')}
      subtitle={`${t('page.assets.shareSbomModal.description')} ${portalPath}`}
      body={
        <>
          <Flex w="100%" direction="column" gap="12px">
            {someResouceIsInactive && (
              <MessageWithBackground
                message={t('page.assets.shareSbomModal.inactiveResourceMessage', {
                  count: resources.length,
                })}
                messageType={'warning'}
              />
            )}
            <EmailInput
              placeholder={t('page.assets.shareSbomModal.emailPlaceholder')}
              id="share-sbom-emails"
              {...{
                ...form.getInputProps('emails'),
                onChange: (value) => {
                  form.getInputProps('emails').onChange(value);

                  if (value.length > 0) {
                    setStep('sharingOptions');
                  }
                },
              }}
            />
            {showSharingOptions && (
              <div>
                <Box mb="32px">
                  <Checkbox
                    label={t('page.assets.shareSbomModal.notifyByEmail')}
                    {...form.getInputProps('sendNotification')}
                    checked={form.getInputProps('sendNotification').value}
                    classNames={{
                      label: styles.checkboxLabel,
                    }}
                  />
                </Box>
                <Radio.Group
                  name="sbomFormat"
                  label={
                    <span className={styles.subtitle}>
                      {t('page.assets.shareSbomModal.sbomFormat')}
                    </span>
                  }
                  withAsterisk
                  {...form.getInputProps('sbomFormat')}
                  value="json"
                >
                  <Divider mt="12px" mb="16px" />
                  <Stack>
                    <Radio
                      disabled
                      value="json"
                      label={t('page.assets.shareSbomModal.jsonFormat.label')}
                      classNames={{
                        description: styles.radioDescription,
                        label: styles.radioLabel,
                        radio: styles.radioInner,
                        icon: styles.radioIcon,
                      }}
                      description={t('page.assets.shareSbomModal.jsonFormat.description')}
                    />
                  </Stack>
                </Radio.Group>
              </div>
            )}
            {!showSharingOptions && (
              <div>
                <Skeleton
                  radius="md"
                  width="30%"
                  data-testid={isLoadingUsers ? 'users-skeleton' : ''}
                  visible={isLoadingUsers}
                  className={styles.skeleton}
                >
                  <span className={styles.subtitle}>
                    {t('page.assets.shareSbomModal.whoHasAccess')}
                  </span>
                </Skeleton>
                {!usersWithAccessError && (
                  <div className={styles.emailContainer}>
                    {(usersWithAcces || mockUsersForSkeleton).map((user) => (
                      <div key={user.email}>
                        <Divider mt="12px" mb="12px" />
                        <RemovableListItem
                          showLoadingInAction={false}
                          isLoading={isLoadingUsers}
                          title={user.email}
                          subtitle={`${t('page.assets.shareSbomModal.sharedOn')} ${user.dateSharedText}`}
                          removeLabel={t('global.remove')}
                          onRemove={() => {
                            removeUserAccess(user.email);
                          }}
                          rigthSection={
                            <Flex align="top" justify={'center'}>
                              <AvatarComponent
                                avatarLetter={user.avatarLetter}
                                isLoading={isLoadingUsers}
                              />
                            </Flex>
                          }
                        />
                      </div>
                    ))}
                    <Divider mt="12px" />
                  </div>
                )}
                {Boolean(usersWithAccessError) && (
                  <ActionableCard
                    title={t('error.general.errorLoadingData')}
                    subtitle={t('error.general.errorLoadingDataSubtitle')}
                    Icon={<Icon icon="circle-exclamation" size="lg" />}
                    primaryAction={{
                      label: t('global.retry'),
                      onClick: () => {
                        refetchUsers();
                      },
                      isLoading: isLoadingUsers,
                    }}
                  />
                )}
              </div>
            )}
          </Flex>
        </>
      }
      primaryAction={{
        label: t('global.done'),
        onClick: shareSboms,
        isLoading: isLoading,
      }}
    />
  );
};
