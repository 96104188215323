import React from 'react';
import { Modal, Text, Title, Loader } from '@mantine/core';
import Icon from '../Icon';
import styles from './NetworkErrorModal.module.scss';
import { useTranslation } from 'react-i18next';

interface NetworkErrorModalProps {
  isOpen: boolean;
}

export const NetworkErrorModal = ({ isOpen }: NetworkErrorModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      classNames={{
        body: styles.modalBody,
      }}
      opened={isOpen}
      onClose={() => {}}
      withCloseButton={false}
      centered
      size="lg"
    >
      <div style={{ textAlign: 'center' }}>
        <Icon icon={'wifi-slash'} size="lg" color={'#1EBBE2'} />
        <Title order={2} weight={700} mb="md">
          {t('global.oops')}
        </Title>
        <Text size="md" mb="md">
          {t('network.errorDescription')}
        </Text>
        <Text size="md" mb="md">
          {t('network.tryingToReconnect')}
        </Text>
        <Loader />
      </div>
    </Modal>
  );
};
