import { Flex, Skeleton } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Navigate, useNavigate, useOutlet } from 'react-router-dom';
import { UserApi } from '../../api/user/user.api';
import { ManifestLogo } from '../../components/Icons/ManifestLogoIcon/ManifestLogoIcon';
import TopBarWrapper from '../../components/Toolbar/Toolbar';
import { useAuth } from '../../hooks/useAuth';
import { captureExceptionWithMessage } from '../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';
import { useFetchSharingPortalReceiver } from '../pages/sharedSboms/api/sharingPortal/useFetchSharingPortalReceiver/useFetchSharingPortalReceiver';
import { UserSettings } from '../pages/UserSettings/UserSettings';
import { PortalFooter } from './PortalFooter/PortalFooter';
import styles from './PortalLayout.module.scss';
import { UserMenu } from './UserMenu/UserMenu';

const PortalLogo = ({ logoUrl, isLoading }: { logoUrl?: string; isLoading: boolean }) => {
  return logoUrl ? (
    <div>
      <div className={styles.logoImageContainer}>
        <img height={30} width={120} src={logoUrl} className={styles.logoImage} />
      </div>
    </div>
  ) : (
    <div>
      {isLoading && <Skeleton height={30} width={120} />}
      {!isLoading && <ManifestLogo height={30} width={120} />}
    </div>
  );
};

export const PortalLayout = () => {
  const outlet = useOutlet();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  const { portalName = '' } = useParams();
  const { user, logout } = useAuth();
  const { data: portal, isLoading: isLoadingPortal } = useFetchSharingPortalReceiver({
    portalName,
    enabled: Boolean(user?.id),
  });

  useEffect(() => {
    const fetchUser = async () => {
      try {
        await UserApi.getUser();
      } catch (error) {
        captureExceptionWithMessage('Error getting user profile in PortalLayout', error);
      }
    };

    fetchUser();
  }, []);

  const onClose = () => {
    setOpenSettingsModal(false);
  };

  if (!user?.id) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      {openSettingsModal && <UserSettings onClose={onClose} />}
      <Flex className={styles.pageContentArea} gap="20px" direction={'column'}>
        {!portal?.isActive && (
          <TopBarWrapper
            variant="simple"
            withSidebar={false}
            isSidebarOpen={false}
            logo={<PortalLogo logoUrl={portal?.logoUrl} isLoading={isLoadingPortal} />}
          />
        )}

        {portal?.isActive && (
          <TopBarWrapper
            withSidebar={false}
            isSidebarOpen={false}
            logo={<PortalLogo logoUrl={portal?.logoUrl} isLoading={isLoadingPortal} />}
            rightSide={
              <UserMenu
                onLogOut={() => {
                  navigate('/logout?reason=user-manual');
                }}
                texts={{
                  logOut: t('navigation.logout.label'),
                }}
              />
            }
            actions={{
              goBack: () => navigate(-1),
              goBackLabel: t('global.goBack'),
              goForward: () => navigate(1),
              goForwardLabel: t('global.goForward'),
            }}
          />
        )}
        <main>{outlet}</main>
        <PortalFooter description={t('portal.footer.poweredBy')} />
      </Flex>
    </>
  );
};
