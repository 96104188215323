import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';

interface Params {
  licenseId: string;
}

export const useDeleteLicense = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ licenseId }: Params) => {
      return AxiosProxy.delete({ url: `license/${licenseId}`, withCredentials: true });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['licenses']);
    },
  });
};
