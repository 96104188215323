import { InterfaceOrganizationIntegration } from '@manifest-cyber/types/interface/dbTables';
import {
  Box,
  Button,
  Flex,
  LoadingOverlay,
  Modal,
  Select,
  TextInput,
  Textarea,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { hasLength, useForm } from '@mantine/form';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CreateTicketFormValues,
  usePostTicket,
} from '../../hooks/mutations/usePostTicket';
import { useFetchTicketMetadata } from '../../hooks/queries/useFetchTicketMetadata';
import { useAuth } from '../../hooks/useAuth';
import Icon from '../Icon';
import { SelectItem, convertToSelectData } from './helpers';

const LINEAR_LOGO_SRC = '/linear_logo.png';

interface CreateLinearTicketProps {
  open: boolean;
  entityType: 'asset' | 'vulnerability';
  entityId: string;
  onSuccess: (
    integration: NonNullable<InterfaceOrganizationIntegration['integrationType']>,
    ticketData: any,
  ) => void;
  onError: (integration: Parameters<this['onSuccess']>[0]) => void;
  onCancel: () => void;
}

export const CreateLinearTicket = ({
  entityId,
  entityType,
  onCancel,
  onError,
  onSuccess,
  open,
}: CreateLinearTicketProps) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const user = auth?.user;
  const { mutateAsync: postTicket, isLoading: isCreatingTicket } = usePostTicket();
  const { isLoading, data: ticketMetadata } = useFetchTicketMetadata('linear', open);
  const [showProjectsSelector, setShowProjectsSelector] = useState(false);
  const [filteredProjects, setFilteredProjects] = useState<SelectItem[]>([]);
  const creators = convertToSelectData(ticketMetadata?.users || []);
  const assignees = convertToSelectData(ticketMetadata?.users || []);
  const priorities = ticketMetadata?.priorities || [];
  const statuses = ticketMetadata?.statuses || [];
  const teams = convertToSelectData(
    ticketMetadata?.teams?.map(({ id, name }) => ({
      id: id || '',
      name: name || '',
    })) || [],
  );
  const { onSubmit, getInputProps, setFieldValue, reset, values } = useForm({
    initialValues: {
      name: '',
      status: '',
      dueDate: new Date(),
      reporter: '',
      assignee: '',
      priority: '',
      description: '',
      teamId: '',
      projectId: '',
    },
    validate: {
      description: hasLength(
        { max: 32767 },
        'Description cannot be longer than 32,767 characters',
      ),
    },
    validateInputOnChange: true,
  });

  useEffect(() => {
    if (ticketMetadata && 'projects' in ticketMetadata) {
      setShowProjectsSelector(true);
    }
  }, [ticketMetadata]);

  useEffect(() => {
    if (ticketMetadata && values.teamId) {
      const team = ticketMetadata.teams.find((team) => team.id === values.teamId);
      const filteredElements =
        team && ticketMetadata.projects
          ? ticketMetadata.projects?.filter((project) =>
              project.teams.some(({ id: teamId }) => teamId === team.remote_id),
            )
          : [];

      setFilteredProjects(
        convertToSelectData(
          filteredElements?.map(({ id, name }) => ({
            id: id || '',
            name: name || '',
          })),
        ),
      );
      setFieldValue('projectId', '');
    }
  }, [values.teamId]);

  useEffect(() => {
    if (!user || !ticketMetadata?.users || !open) {
      return;
    }

    setFieldValue('teamId', teams[0]?.value || '');

    const [email] = user?.decryptedEmails;
    const currentUser = ticketMetadata.users.find((user) => user.email === email);
    if (currentUser) {
      setFieldValue('reporter', currentUser.id);
    }
  }, [user, ticketMetadata?.users, open]);

  const handleSubmit = onSubmit(
    async ({
      assignee,
      description,
      dueDate,
      name,
      priority,
      reporter,
      status,
      teamId,
      projectId,
    }) => {
      try {
        const submitValues: CreateTicketFormValues = {
          summary: name,
          status,
          creatorId: reporter,
          assigneeIds: assignee ? [assignee] : [],
          priority,
          description,
          type: entityType,
          id: entityId,
          integration: 'linear',
          issueType: '',
          dueDate: dueDate ? new Date(dueDate).toISOString() : undefined,
          teamId,
          projectId,
        };

        const { success, data } = await postTicket({ ticket: submitValues });
        if (!success) {
          throw new Error('error while trying to create linear ticket');
        }
        return onSuccess('linear', data);
      } catch (e) {
        return onError('linear');
      } finally {
        reset();
        onCancel();
      }
    },
  );

  const isSubmitBtnDisabled = !values.name.length || !values.reporter.length;

  const handleCloseModal = () => {
    if (isCreatingTicket) {
      return;
    }

    onCancel();
  };

  return (
    <Modal.Root
      centered
      opened={open}
      onClose={handleCloseModal}
      className="create-ticket-modal"
      size={552}
    >
      <Modal.Overlay />
      <Modal.Content className="linear-ticket">
        <LoadingOverlay
          data-testid="overlay"
          visible={isLoading || isCreatingTicket}
          overlayBlur={8}
        />
        <Modal.Header px={32} pt={24} pb={36}>
          <Modal.Title>
            <div className="header linear-ticket-header">
              <div className="logo">
                <img src={LINEAR_LOGO_SRC} alt="Linear logo" />
              </div>
              {t(`tickets.createLinearTicketModal.header`)}
            </div>
          </Modal.Title>
          <Modal.CloseButton className="close-btn" />
        </Modal.Header>
        <>
          <Modal.Body>
            <Box mx="auto" px={16}>
              <form id="create-linearticket" onSubmit={handleSubmit}>
                <Flex direction="column" gap={16}>
                  <TextInput
                    required
                    name="name"
                    label={t(`tickets.createLinearTicketModal.form.name`)}
                    {...getInputProps('name')}
                  />
                  <Flex gap={16}>
                    <Select
                      w="100%"
                      label={t(`tickets.createLinearTicketModal.form.status`)}
                      data={statuses}
                      nothingFound={t(`tickets.createLinearTicketModal.form.noResults`)}
                      {...getInputProps('status')}
                    />
                    <DatePickerInput
                      w="100%"
                      label={t(`tickets.createLinearTicketModal.form.dueDate`)}
                      dropdownType="modal"
                      clearable
                      rightSection={<Icon icon="angle-down" />}
                      {...getInputProps('dueDate')}
                    />
                  </Flex>
                  <Flex gap={16}>
                    <Select
                      label={t(`tickets.createLinearTicketModal.form.reporter`)}
                      data={creators}
                      searchable
                      required
                      w="100%"
                      nothingFound={t(`tickets.createLinearTicketModal.form.noResults`)}
                      {...getInputProps('reporter')}
                    />
                    <Select
                      label={t(`tickets.createLinearTicketModal.form.assignee`)}
                      data={assignees}
                      searchable
                      w="100%"
                      nothingFound={t(`tickets.createLinearTicketModal.form.noResults`)}
                      {...getInputProps('assignee')}
                    />
                  </Flex>
                  <Select
                    w="100%"
                    label={t(`tickets.createLinearTicketModal.form.team`)}
                    searchable
                    name="team"
                    data={teams}
                    nothingFound={t(`tickets.createLinearTicketModal.form.noResults`)}
                    required
                    {...getInputProps('teamId')}
                  />
                  {showProjectsSelector && (
                    <Select
                      w="100%"
                      label={t(`Project`)}
                      searchable
                      name={t(`tickets.createLinearTicketModal.form.project`)}
                      disabled={!values.teamId}
                      data={filteredProjects}
                      nothingFound={t(`tickets.createLinearTicketModal.form.noResults`)}
                      {...getInputProps('projectId')}
                    />
                  )}
                  <Select
                    label={t(`tickets.createLinearTicketModal.form.priority`)}
                    name="priority"
                    data={priorities}
                    placeholder={t('global.select')}
                    nothingFound={t(`tickets.createLinearTicketModal.form.noResults`)}
                    {...getInputProps('priority')}
                  />
                  <Textarea
                    name="description"
                    label={t(`tickets.createLinearTicketModal.form.description`)}
                    {...getInputProps('description')}
                  />
                  <Flex align="center" justify="end" gap={4} mb={8}>
                    <Button
                      variant="outline"
                      onClick={onCancel}
                      className="btn cancel-btn"
                      disabled={isCreatingTicket}
                    >
                      {t('global.cancel')}
                    </Button>
                    <Button
                      type="submit"
                      className="btn submit-btn"
                      disabled={isLoading || isCreatingTicket || isSubmitBtnDisabled}
                    >
                      {t('global.submit')}
                    </Button>
                  </Flex>
                </Flex>
              </form>
            </Box>
          </Modal.Body>
        </>
      </Modal.Content>
    </Modal.Root>
  );
};
