import * as Sentry from '@sentry/react';
import Cookies from 'js-cookie';

type CookiesKey = 'organizationid';

const getCookie = (key: CookiesKey): string | undefined => {
  try {
    return Cookies.get(key);
  } catch (err) {
    Sentry.captureMessage(`Error parsing cookie: ${key}`, 'error');
    Sentry.captureException(err);
  }
};

const setCookie = ({
  key,
  value,
  options,
}: {
  key: CookiesKey;
  value: string;
  options?: Cookies.CookieAttributes;
}) => {
  try {
    Cookies.set(key, value, options);
  } catch (err) {
    Sentry.captureMessage(`Error setting cookie: ${key}`, 'error');
    Sentry.captureException(err);
  }
};

const removeCookie = (key: CookiesKey, options?: Cookies.CookieAttributes) => {
  try {
    Cookies.remove(key, options);
  } catch (err) {
    Sentry.captureMessage(`Error removing cookie: ${key}`, 'error');
    Sentry.captureException(err);
  }
};

export const CookieUtils = {
  removeCookie,
  setCookie,
  getCookie,
};
