import { ManifestApiResponse } from '@manifest-cyber/types/interface/apiResponse';
import {
  InterfaceOrganizationCustomField,
  OrganizationCustomField,
} from '@manifest-cyber/types/interface/dbTables';
import { rejectFailedRequestOrEmptyData } from '../../lib/request/rejectFailedRequest/rejectFailedRequest';
import { AxiosProxy } from '../axiosProxy/axiosProxy';
import { CustomField } from './pageConfig.interface';

const createCustomField = async (params: { body: CustomField }) => {
  const response: ManifestApiResponse<
    ManifestApiResponse<InterfaceOrganizationCustomField>
  > = await AxiosProxy.post({
    url: `organization/customfields`,
    withCredentials: true,
    body: params.body,
  });

  const successfulResponse = await rejectFailedRequestOrEmptyData(response);

  return successfulResponse.data;
};

const getCustomFields = async (params: {
  fieldType: OrganizationCustomField;
}): Promise<string[]> => {
  const response: ManifestApiResponse<string[]> = await AxiosProxy.get({
    url: `organization/customfields/${params.fieldType}`,
  });

  const successfulResponse = await rejectFailedRequestOrEmptyData(response);

  return successfulResponse.data;
};

export const CustomFieldsApi = {
  createCustomField,
  getCustomFields,
};
