import { useQuery } from '@tanstack/react-query';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';
import { InterfaceFullGithubIntegration } from '../../types/FullInterfaces';

export const useFetchOneGithubIntegration = (orgId: string, installationId: string) => {
  const fetchUrl = `integration/github/${orgId}/${installationId}`;

  return useQuery({
    queryKey: ['integration-github', `/${fetchUrl}`],
    queryFn: () => AxiosProxy.get({ url: fetchUrl }),
    select: (data) => data?.data as InterfaceFullGithubIntegration,
  });
};
