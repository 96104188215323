import { InterfaceOrganizationIntegration } from '@manifest-cyber/types/interface/dbTables';
import { Button, Flex, Menu, Popover, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { CSSProperties, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useFetchIntegrations } from '../../hooks/queries/useFetchIntegrations';
import { useNotifications } from '../../hooks/utils/useNotifications';
import Icon from '../Icon/Icon';
import CreateJiraTicket from './CreateJiraTicket';
import { CreateLinearTicket } from './CreateLinearTicket';
import CreateSevicenowTicket from './CreateServicenowTicket';
import { getPlatformLogo } from './helpers';
import './ticket.scss';

interface Props {
  entityType: 'asset' | 'vulnerability';
  entityId: string;
  variant?: string;
  showIcon?: boolean;
  shortText?: boolean;
  width?: CSSProperties['width'];
  buttonClassname?: string;
  menuWidth?: CSSProperties['width'];
}
function CreateTicketButton({
  entityType,
  entityId,
  variant,
  showIcon = true,
  shortText = false,
  width,
  buttonClassname,
  menuWidth,
}: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { custom: showNotificationWithCustomIcon, error: showErrorNotification } =
    useNotifications();
  const buttonText = shortText
    ? t('global.create')
    : t('page.vulnerability.createTicket');

  const [popoverOpened, { close: popoverClose, open: popoverOpen }] =
    useDisclosure(false);

  const processTicketNotification = (
    ticketData: Array<{ ticket_url: string }>,
    isSuccess: boolean,
    platformName: NonNullable<InterfaceOrganizationIntegration['integrationType']>,
  ) => {
    const [ticket] = ticketData;
    const ticketUrl = ticket?.ticket_url;
    const ticketUrlDisplay =
      (ticketUrl?.length ?? 0) > 40 ? `${ticketUrl?.slice(0, 40)}...` : ticketUrl;
    if (isSuccess) {
      showNotificationWithCustomIcon({
        title: t(`tickets.notifications.${platformName}.created`),
        message: (
          <a href={ticketUrl} target="_blank">
            {ticketUrlDisplay}
          </a>
        ),
        icon: (
          <img
            style={{ width: '40px', height: '40px' }}
            src={getPlatformLogo(platformName)}
          />
        ),
      });
    } else {
      showErrorNotification({
        title: t(`tickets.notifications.${platformName}.error`),
        message: t(`tickets.notifications.${platformName}.errorDescription`),
      });
    }
  };
  const [openedIntegrationModal, setOpenedIntegrationModal] = useState<
    InterfaceOrganizationIntegration['integrationType'] | null
  >(null);

  const closeModal = () => {
    setOpenedIntegrationModal(null);
  };

  const onTicketModalFeedback = (
    integration: NonNullable<InterfaceOrganizationIntegration['integrationType']>,
    ticketData = [],
  ) => {
    const isSuccess = Boolean(ticketData.length);

    processTicketNotification(ticketData, isSuccess, integration);
  };

  const { data: integrations } = useFetchIntegrations({
    includeGithub: false,
  });

  const jiraIntegration = integrations?.find(
    ({ integrationType }) => integrationType === 'jira',
  );
  const jiraStatus =
    jiraIntegration &&
    (jiraIntegration.status === null
      ? 'pending'
      : jiraIntegration.status
        ? jiraIntegration.status
        : '');

  const servicenowIntegration = integrations?.find(
    ({ integrationType }) => integrationType === 'servicenow',
  );
  const servicenowStatus =
    servicenowIntegration &&
    (servicenowIntegration.status === null
      ? 'pending'
      : servicenowIntegration.status
        ? servicenowIntegration.status
        : '');

  const linearIntegration = integrations?.find(
    ({ integrationType }) => integrationType === 'linear',
  );
  const linearStatus =
    linearIntegration &&
    (linearIntegration.status === null
      ? 'pending'
      : linearIntegration.status
        ? linearIntegration.status
        : '');

  const hasNoIntegrations = !integrations || integrations?.length === 0;

  const presentIntegrations = useMemo(() => {
    return [jiraIntegration, servicenowIntegration, linearIntegration];
  }, [jiraIntegration, servicenowIntegration, linearIntegration]);

  const presentFilteredIntegrations = presentIntegrations.filter(
    Boolean,
  ) as InterfaceOrganizationIntegration[];
  const hasAtLeastMultipleIntegrations = presentFilteredIntegrations.length > 1;
  const areAllIntegrationsInactive = presentFilteredIntegrations.every(
    (integration) => integration?.status !== 'active',
  );
  const hasSingleIntegrationAndIsInactive =
    presentFilteredIntegrations.length === 1 &&
    presentFilteredIntegrations[0]?.status === null;

  const handleCreateTicketClickIfSingleIntegration = () => {
    if (hasNoIntegrations) {
      navigate('/settings/integrations', { replace: true });
    } else {
      const [presentIntegration] = presentFilteredIntegrations;

      if (presentIntegration) {
        setOpenedIntegrationModal(presentIntegration.integrationType);
      }
    }
  };

  return (
    <>
      {hasAtLeastMultipleIntegrations ? (
        <Menu width={menuWidth} trigger="hover" position="top" closeDelay={400}>
          <Menu.Target>
            <Button
              variant={variant || 'filled'}
              leftIcon={showIcon === true ? <Icon icon="plus" /> : undefined}
              disabled={areAllIntegrationsInactive}
              className={buttonClassname}
              w={width}
            >
              {!areAllIntegrationsInactive
                ? buttonText
                : t('page.vulnerability.syncingData')}
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            {jiraIntegration && (
              <Menu.Item
                onClick={() => setOpenedIntegrationModal('jira')}
                disabled={!jiraStatus || jiraStatus !== 'active'}
              >
                {t(
                  `page.vulnerability.tickets.${
                    jiraStatus === 'active' ? 'create' : 'syncing'
                  }-jira`,
                )}
              </Menu.Item>
            )}
            {servicenowIntegration && (
              <Menu.Item
                onClick={() => setOpenedIntegrationModal('servicenow')}
                disabled={!servicenowStatus || servicenowStatus !== 'active'}
              >
                {t(
                  `page.vulnerability.tickets.${
                    servicenowStatus === 'active' ? 'create' : 'syncing'
                  }-servicenow`,
                )}
              </Menu.Item>
            )}
            {linearIntegration && (
              <Menu.Item
                onClick={() => setOpenedIntegrationModal('linear')}
                disabled={!linearStatus || linearStatus !== 'active'}
              >
                {t(
                  `page.vulnerability.tickets.${
                    linearStatus === 'active' ? 'create' : 'syncing'
                  }-linear`,
                )}
              </Menu.Item>
            )}
          </Menu.Dropdown>
        </Menu>
      ) : (
        <Popover
          width={200}
          position="top"
          withArrow
          shadow="md"
          opened={popoverOpened}
          disabled={!(hasNoIntegrations || hasSingleIntegrationAndIsInactive)}
        >
          <Popover.Target>
            <Flex w={width} onMouseEnter={popoverOpen} onMouseLeave={popoverClose}>
              <Button
                variant={variant || 'filled'}
                onClick={handleCreateTicketClickIfSingleIntegration}
                leftIcon={showIcon === true ? <Icon icon="plus" /> : undefined}
                disabled={hasNoIntegrations || hasSingleIntegrationAndIsInactive}
                className={buttonClassname}
                w={width}
              >
                {buttonText}
              </Button>
            </Flex>
          </Popover.Target>
          <Popover.Dropdown
            sx={{
              pointerEvents: 'none',
              backgroundColor: 'var(--color-color-text-base)',
              color: 'var(--color-forceBlack)',
            }}
          >
            <Text size="sm">
              {hasNoIntegrations
                ? t('tickets.setupIntegrationText')
                : t('tickets.createTicketSyncingData')}
            </Text>
          </Popover.Dropdown>
        </Popover>
      )}
      <CreateJiraTicket
        open={openedIntegrationModal === 'jira'}
        entityId={entityId}
        entityType={entityType}
        onCancel={closeModal}
        onSuccess={onTicketModalFeedback}
        onError={onTicketModalFeedback}
      />
      <CreateSevicenowTicket
        open={openedIntegrationModal === 'servicenow'}
        entityId={entityId}
        entityType={entityType}
        onCancel={closeModal}
        onSuccess={onTicketModalFeedback}
        onError={onTicketModalFeedback}
      />
      <CreateLinearTicket
        open={openedIntegrationModal === 'linear'}
        entityId={entityId}
        entityType={entityType}
        onCancel={closeModal}
        onSuccess={onTicketModalFeedback}
        onError={onTicketModalFeedback}
      />
    </>
  );
}

export default CreateTicketButton;
