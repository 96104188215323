import {
  InterfaceOssIngest,
  InterfaceSbom,
} from '@manifest-cyber/types/interface/dbTables';
import { Loader, Tooltip } from '@mantine/core';
import { DateTime } from 'luxon';
import { MRT_PaginationState } from 'mantine-react-table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Table } from 'rsuite';
import { ValueType } from 'rsuite/esm/Checkbox';
import { shallow } from 'zustand/shallow';
import ClickableRegion from '../../components/ClickableRegion';
import Icon from '../../components/Icon';
import Loading from '../../components/Loading';
import appConfig from '../../configs/appConfig';
import { useFetchUser } from '../../hooks/queries/useFetchUser';
import { useFetchVendor } from '../../hooks/queries/useFetchVendor';
import { useSbomsStore } from '../../hooks/stores/sboms.store';
import { useOrganizationId } from '../../hooks/utils/useOrganizationId';
import { getSBOMName } from '../../lib/getSBOMName';
import { getSBOMVersion } from '../../lib/getSBOMVersion';
import { captureExceptionWithMessage } from '../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';
import {
  SBOMSelectionData,
  fromSBOMToSelectionData,
} from '../../pages/Sboms/models/SBOMSelectionData.model';
import DataTableFooter from './DataTableFooter';

const { Column, HeaderCell, Cell } = Table;

const UploadedByCell = ({ rowData }: { rowData: any }) => {
  const { t } = useTranslation();

  if (rowData?.uploadByVendor) {
    const { data: uploadVendor } = useFetchVendor(
      { vendorId: rowData?.uploadByVendor },
      { staleTime: Infinity },
    );

    if (uploadVendor) {
      const { name } = uploadVendor;
      return (
        <>
          <Icon icon="building" />
          {name}
        </>
      );
    }
    return rowData?.uploadUserId;
  }
  if (rowData?.uploadUserId) {
    const { data: uploadUser } = useFetchUser(
      { userId: rowData?.uploadUserId },
      { staleTime: Infinity },
    );

    if (uploadUser) {
      const { decryptedEmails, firstName, lastName } = uploadUser;
      let userDisplay = `${firstName} ${lastName}`.trim();
      if (userDisplay.length < 1) {
        userDisplay = decryptedEmails?.[0] || rowData?.uploadUserId;
      }
      return (
        <>
          <Icon icon="user" />
          {userDisplay}
        </>
      );
    }
    return rowData?.uploadUserId;
  }
  const sbomInternalSources: string[] = [
    'circle-ci',
    'circle-ci-orb',
    'github-action',
    'manifest-cli',
  ];
  if (rowData?.source && sbomInternalSources.includes(rowData?.source)) {
    return t(`jargon.source.${rowData?.source}`);
  }
  if (rowData?.source && rowData?.source?.length > 0) {
    return `${rowData?.source}`;
  }
  return 'Unavailable';
};

interface Props {
  countTotalSboms: number;
  isLoadingSboms: boolean;
  sboms: InterfaceSbom[] | InterfaceOssIngest[];
  hasWriteAccess: boolean;
}

export const SbomTable = ({
  countTotalSboms,
  sboms,
  isLoadingSboms,
  hasWriteAccess,
}: Props) => {
  const { t } = useTranslation();
  const [currentOrgId] = useOrganizationId();
  const pendingTabs = ['pending', 'ingests'];

  //state
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });

  //store
  const {
    actions: {
      setCantMergeDownloadReason,
      setCantMergeShareReason,
      setDeleteModalOpen,
      setErrors,
      setFetchLimit,
      setFetchPage,
      setSbomIdsToDelete,
      setSelectedSBOMsData,
      setSortColumn,
      setSortType,
    },
    activeTab,
    errors,
    selectedSBOMsData,
    sortColumn,
    sortType,
  } = useSbomsStore(
    (state) => ({
      actions: state.actions,
      activeTab: state.activeTab,
      errors: state.errors,
      fetchLimit: state.fetchLimit,
      fetchPage: state.fetchPage,
      selectedSBOMsData: state.selectedSBOMsData,
      sortColumn: state.sortColumn,
      sortType: state.sortType,
    }),
    shallow,
  );

  //memos
  const selectedSBOMFormat = useMemo(() => {
    return new Set(selectedSBOMsData.map((sd) => sd.format));
  }, [selectedSBOMsData]);

  const selectedSBOMType = useMemo(() => {
    return new Set(selectedSBOMsData.map((sd) => sd.fileType));
  }, [selectedSBOMsData]);

  const areAllSbomsSelected = useMemo(
    () =>
      !!selectedSBOMsData.length &&
      activeTab !== 'pending' &&
      selectedSBOMsData.length === sboms.length,
    [selectedSBOMsData, sboms, activeTab],
  );

  const allSbomsSelectedIndeterminate = useMemo(
    () => !areAllSbomsSelected && selectedSBOMsData.length > 0,
    [areAllSbomsSelected, selectedSBOMsData],
  );

  //handlers & callbacks
  const getSBOMByID = useCallback(
    (id: string) => {
      return (sboms as InterfaceSbom[]).find((s) => `${s._id}` === id);
    },
    [sboms],
  );

  const handleOpenDeleteModal = (sbomId?: string) => {
    if (sbomId !== undefined) {
      setSbomIdsToDelete([sbomId]);
    } else {
      setSbomIdsToDelete(selectedSBOMsData.map((sd) => sd.id));
    }
    setDeleteModalOpen(true);
  };

  const handleSortColumn = async (sortColumn: any, sortType: any) => {
    setSortColumn(sortColumn);
    setSortType(sortType);
  };

  const handleDownloadRequest = async (assets: InterfaceSbom[]) => {
    if (!assets.length) return;

    try {
      const sbomIds = assets
        .map((asset) => asset._id?.toString())
        .filter((id): id is string => !!id); // Filter out any null or undefined IDs

      if (!sbomIds.length) return;

      if (sbomIds.length === 1) {
        const asset = assets[0];
        if (asset) {
          const isEnriched = asset.enrichments && asset.enrichments.length > 0;
          const enrichedParam = isEnriched ? '&enriched=true' : '';
          window.open(
            `//${appConfig?.apiUrl}/v${appConfig?.apiVersion}/sbom/download/${sbomIds[0]}?redirect=1${enrichedParam}`,
            '_blank',
          );
        }
      } else {
        window.open(
          `//${appConfig?.apiUrl}/v${appConfig?.apiVersion}/download/bundle?fileIds=${sbomIds.join(
            ',',
          )}`,
          '_blank',
        );
      }
    } catch (e) {
      captureExceptionWithMessage('error handleDownloadRequest', e);
      setErrors([...errors, 'Unable to download SBOM(s)']);
    }
  };

  const handleCheckAll = (value: ValueType | undefined, checked: boolean) => {
    const allSBOMData = (sboms as InterfaceSbom[]).map(fromSBOMToSelectionData);
    const SBOMsData: SBOMSelectionData[] = checked ? allSBOMData : [];
    setSelectedSBOMsData(SBOMsData);
  };

  const handleCheck = (value: ValueType | undefined, checked: boolean) => {
    const SBOMId = value as string;

    const selectedSBOM = getSBOMByID(SBOMId);
    if (!selectedSBOM) return;
    const SBOMData = fromSBOMToSelectionData(selectedSBOM);

    const SBOMsData: SBOMSelectionData[] = checked
      ? [...selectedSBOMsData, SBOMData]
      : selectedSBOMsData.filter((sd) => sd.id !== SBOMId);
    setSelectedSBOMsData(SBOMsData);
  };

  //effects
  useEffect(() => {
    if (pagination) {
      setFetchPage(pagination.pageIndex + 1);
      setFetchLimit(pagination.pageSize);
    }
  }, [pagination]);

  useEffect(() => {
    if (selectedSBOMsData.length <= 1) {
      setCantMergeDownloadReason(t('page.uploads.please-select-multiple-sboms'));
      setCantMergeShareReason(t('page.uploads.please-select-multiple-sboms'));
      if (selectedSBOMType.has('VEX')) {
        setCantMergeDownloadReason(t('page.uploads.vex-cannot-be-merge'));
      }
      return;
    }

    if (activeTab === 'ingests') {
      return;
    }

    if (selectedSBOMType.has('VEX')) {
      setCantMergeDownloadReason(t('page.uploads.vex-cannot-be-merge'));
      if (selectedSBOMType.has('SBOM')) {
        setCantMergeDownloadReason(t('page.uploads.vex-sbom-cannot-be-merge'));
      }
      return;
    }

    if (selectedSBOMFormat.size > 1) {
      setCantMergeDownloadReason(t(`page.uploads.sboms-must-be-same-type`));
      setCantMergeShareReason(t(`page.uploads.sboms-must-be-same-type`));
      return;
    }

    setCantMergeDownloadReason('');
    setCantMergeShareReason('');
  }, [selectedSBOMsData, selectedSBOMType, sboms, selectedSBOMFormat]);

  //table
  const columns = new Set(
    (
      [
        !pendingTabs.includes(activeTab) && 'select',
        pendingTabs.includes(activeTab) && 'status',
        'sbomAssetName',
        !pendingTabs.includes(activeTab) && 'version',
        activeTab !== 'ingests' && 'fileType',
        activeTab !== 'ingests' && 'format',
        activeTab !== 'ingests' && 'type',
        activeTab !== 'ingests' && 'uploadedBy',
        'uploadedDate',
        (activeTab === 'failed' || activeTab === 'ingests') && 'error',
        activeTab === 'ingests' &&
          (sboms as InterfaceOssIngest[])?.filter(
            (s) => s?.ingestErrors && s?.ingestErrors?.length > 0,
          ) &&
          'error',
        !pendingTabs.includes(activeTab) && 'actions',
        // Ingest-Specific Columns
        activeTab === 'ingests' && 'gitUrl',
        activeTab === 'ingests' && 'version',
        activeTab === 'ingests' && 'commitHash',
        activeTab === 'ingests' && 'license',
      ] as const
    ).filter(Boolean),
  );

  return (
    <>
      <Table
        // @ts-ignore
        data={sboms}
        sortColumn={sortColumn}
        headerHeight={60}
        sortType={sortType}
        autoHeight
        virtualized
        rowKey="_id"
        onSortColumn={handleSortColumn}
        loading={isLoadingSboms}
        renderEmpty={() => <div className="table-empty">{t('global.no-data')}</div>}
        renderLoading={() => (
          <div className="table-loading">
            <Loading />
          </div>
        )}
        className="anim-slideInUpShort"
        rowClassName={(rowData) => {
          if (rowData) {
            return selectedSBOMsData.find((sd) => sd.id === `${rowData._id}`)
              ? 'checked-row'
              : '';
          }
          return '';
        }}
      >
        {columns.has('select') && (
          <Column width={30} align="left">
            <HeaderCell style={{ padding: 0 }}>
              <div style={{ lineHeight: '40px' }}>
                <Checkbox
                  inline
                  style={{ marginLeft: 0 }}
                  checked={areAllSbomsSelected}
                  indeterminate={allSbomsSelectedIndeterminate}
                  onChange={handleCheckAll}
                />
              </div>
            </HeaderCell>
            <Cell
              style={{ padding: 0, display: 'flex', alignItems: 'center' }}
              align="left"
            >
              {(rowData, rowIndex) => (
                <Checkbox
                  value={rowData._id}
                  style={{ marginLeft: 0 }}
                  inline
                  onChange={handleCheck}
                  checked={selectedSBOMsData.some((sd) => sd.id === `${rowData._id}`)}
                />
              )}
            </Cell>
          </Column>
        )}

        {columns.has('status') && (
          <Column sortable minWidth={230} flexGrow={2}>
            <HeaderCell>{t('page.uploads.statusHeader')}</HeaderCell>
            <Cell>
              {(rowData, rowIndex) => {
                let status = t('page.uploads.status.staging');
                if (rowData?.whenProcessed) {
                  return (
                    <ClickableRegion
                      regionLabel="View Asset"
                      href={`/asset/${rowData?.assetId}/${currentOrgId || ''}`}
                    >
                      <span>${t('page.uploads.status.processed')}</span>
                    </ClickableRegion>
                  );
                }

                if (rowData?.whenProcessing) {
                  return (
                    <div className="page-sboms__loader">
                      <Loader className="status-loader" speed="slow" size={18} />
                      <span>{t('page.uploads.status.processing')}</span>
                    </div>
                  );
                }

                if (rowData?.whenConverted) {
                  return (
                    <div className="page-sboms__loader">
                      <Loader className="status-loader" speed="slow" size={18} />
                      <span>{t('page.uploads.status.converted')}</span>
                    </div>
                  );
                }

                if (rowData?.whenConverting) {
                  return (
                    <div className="page-sboms__loader">
                      <Loader className="status-loader" speed="slow" size={18} />
                      <span>{t('page.uploads.status.converting')}</span>
                    </div>
                  );
                }

                if (rowData?.whenUploaded) {
                  return (
                    <div className="page-sboms__loader">
                      <Loader className="status-loader" speed="slow" size={18} />
                      <span>{t('page.uploads.status.uploaded')}</span>
                    </div>
                  );
                }

                if (rowData?.whenUploading) {
                  return (
                    <div className="page-sboms__loader">
                      <Loader className="status-loader" speed="slow" size={18} />
                      <span>{t('page.uploads.status.uploading')}</span>
                    </div>
                  );
                }

                // Handle Ingest Statuses
                if (rowData?.status) {
                  if (rowData?.ingestErrors?.length > 0) {
                    return (
                      <>
                        <Icon
                          icon="times"
                          style={{
                            position: 'relative',
                            top: '3px',
                            color: '#CC3663',
                          }}
                        />
                        <span>{t(`page.uploads.status.ingests.errored`)}</span>
                      </>
                    );
                  }

                  // If scan itself has completed, check for for asset/sbom data
                  if (rowData?.status === 'completed') {
                    if (
                      !rowData?.assetData?.[0]?._id ||
                      !rowData?.sbomData?.[0]?.whenUploaded
                    ) {
                      return (
                        <div className="page-sboms__loader">
                          <Loader className="status-loader" speed="slow" size={18} />
                          <span>{t(`page.uploads.status.ingests.processing-sbom`)}</span>
                        </div>
                      );
                    } else if (
                      !rowData?.assetData?.[0]?._id ||
                      !rowData?.sbomData?.[0]?.whenProcessed
                    ) {
                      return (
                        <div className="page-sboms__loader">
                          <Loader className="status-loader" speed="slow" size={18} />
                          <span>{t(`page.uploads.status.ingests.processing-sbom`)}</span>
                        </div>
                      );
                    }

                    return (
                      <>
                        <Icon
                          icon="check"
                          style={{
                            position: 'relative',
                            top: '2px',
                          }}
                        />
                        <span>{t(`page.uploads.status.ingests.completed`)}</span>
                      </>
                    );
                  }

                  return (
                    <div className="page-sboms__loader">
                      <Loader className="status-loader" speed="slow" size={18} />
                      <span>
                        {t(`page.uploads.status.ingests.${rowData?.status || 'queued'}`)}
                      </span>
                    </div>
                  );
                }

                return status;
              }}
            </Cell>
          </Column>
        )}

        {columns.has('sbomAssetName') && (
          <Column flexGrow={2.5}>
            <HeaderCell>
              {t(
                `tables.uploads.headers.${
                  activeTab === 'ingests' ? 'assetName' : 'fileName'
                }`,
              )}
            </HeaderCell>
            <Cell fullText key="assetName">
              {(rowData, rowIndex) => {
                if (rowData?.fileType === 'VEX') {
                  return (
                    // <ClickableRegion data-sbomid={rowData?._id} href={`#`}>
                    <span>{rowData?.fileName}</span>
                    // </ClickableRegion>
                  );
                }
                if (rowData?.organizationAssetId) {
                  return (
                    <ClickableRegion
                      data-sbomid={rowData?._id}
                      regionLabel={t('tables.uploads.cells.fileName.viewAssetDetails')}
                      href={`/asset/${rowData?.organizationAssetId}/${
                        currentOrgId || ''
                      }`}
                    >
                      <span>{getSBOMName(rowData as InterfaceSbom)}</span>
                    </ClickableRegion>
                  );
                }

                return (
                  <span data-sbomid={rowData?._id}>
                    {t('tables.uploads.cells.fileName.no-name')}
                  </span>
                );
              }}
            </Cell>
          </Column>
        )}

        {columns.has('gitUrl') && (
          <Column sortable minWidth={230} flexGrow={2}>
            <HeaderCell>{t('page.uploads.ingests.gitUrlHeader')}</HeaderCell>
            <Cell>
              {(rowData, rowIndex) => {
                let status = t('page.uploads.status.staging');

                if (
                  (rowData?.gitUrl && rowData?.gitUrl?.includes('https://github.com')) ||
                  rowData?.gitUrl?.includes('https://www.github.com')
                ) {
                  const gitUrlDisplay = rowData?.gitUrl
                    ?.replace('https://', '')
                    .replace('http://', '')
                    .replace('www', '')
                    .replace('github.com/', '');
                  return (
                    <ClickableRegion
                      regionLabel={t('page.uploads.ingests.gitUrlLink')}
                      href={`${rowData?.gitUrl}?rel=manifest-cyber`}
                      target="_blank"
                      className="giturl-link"
                    >
                      <Icon icon="github" iconStyle="fab" />
                      <span>{gitUrlDisplay || rowData?.gitUrl}</span>
                      <Icon icon="arrow-up-right-from-square" />
                    </ClickableRegion>
                  );
                }

                return status;
              }}
            </Cell>
          </Column>
        )}

        {columns.has('version') && (
          <Column sortable minWidth={120} flexGrow={1}>
            <HeaderCell>
              {t(
                `tables.uploads.headers.${
                  activeTab === 'ingests' ? 'ingest-version' : 'version'
                }`,
              )}
            </HeaderCell>
            {activeTab === 'ingests' ? (
              <Cell>
                {(rowData, rowIndex) => {
                  let display = '';

                  if (rowData?.assetData?.[0]?.version) {
                    display = rowData?.assetData?.[0]?.version;
                  } else if (rowData?.metadata?.version) {
                    display = rowData.metadata.version;
                  } else {
                    display = getSBOMVersion(rowData as InterfaceSbom);
                  }

                  if (!rowData?.commitHash) {
                    // No hash, so no link yet
                    return <span>{display}</span>;
                  }

                  let getGitLink = rowData?.gitUrl?.replace('.git', '');

                  if (rowData?.metadata?.releaseData) {
                    // Return release
                    getGitLink = `${getGitLink}/releases/tag/v${rowData?.metadata?.version}`;
                  } else {
                    // No release, so will be a SHA. Clean up.
                    const splitGitLink = getGitLink?.split(
                      `${rowData?.groupName}/${rowData?.repoName}`,
                    );
                    if (splitGitLink[1] && splitGitLink[1]?.includes('/')) {
                      // branch was included, so remove it
                      const lastIndex = getGitLink.lastIndexOf('/');
                      getGitLink = getGitLink.substring(0, lastIndex);
                    }

                    getGitLink = `${getGitLink}/commit/${rowData?.commitHash}`;
                  }

                  return (
                    <ClickableRegion
                      regionLabel={t('page.uploads.ingests.gitUrlLink')}
                      href={`${getGitLink}`}
                      target="_blank"
                      className="giturl-link"
                    >
                      <span>{display}</span>
                      <Icon icon="arrow-up-right-from-square" />
                    </ClickableRegion>
                  );
                }}
              </Cell>
            ) : (
              <Cell dataKey="assetVersion">
                {(rowData) => getSBOMVersion(rowData as InterfaceSbom)}
              </Cell>
            )}
          </Column>
        )}

        {columns.has('fileType') && (
          <Column sortable minWidth={100} flexGrow={1}>
            <HeaderCell>{t('tables.uploads.headers.docType')}</HeaderCell>
            <Cell dataKey="fileType">
              {(rowData, rowIndex) => {
                if (rowData?.fileType) {
                  return rowData.fileType;
                }
                return 'SBOM';
              }}
            </Cell>
          </Column>
        )}

        {columns.has('format') && (
          <Column sortable minWidth={130} flexGrow={1}>
            <HeaderCell>{t('tables.uploads.headers.format')}</HeaderCell>
            <Cell dataKey="format">
              {(rowData, rowIndex) => {
                const format = rowData?.format || '';
                let formatVersion = rowData?.formatVersion || '';
                formatVersion = formatVersion?.replace?.('SPDX-', '');
                if (!formatVersion) return format;
                return `${format} ${formatVersion}`;
              }}
            </Cell>
          </Column>
        )}

        {columns.has('type') && (
          <Column sortable flexGrow={1} minWidth={130}>
            <HeaderCell>{t('tables.uploads.headers.type')}</HeaderCell>
            <Cell dataKey="relationshipToOrg">
              {(rowData, rowIndex) => {
                if (rowData?.relationshipToOrg && rowData.relationshipToOrg === 'first') {
                  return (
                    <>
                      <Icon icon="circle" />
                      {t(`jargon.first-party`)}
                    </>
                  );
                } else if (
                  rowData?.relationshipToOrg &&
                  rowData.relationshipToOrg === 'third'
                ) {
                  return (
                    <>
                      <Icon icon="diamond" />
                      {t(`jargon.third-party`)}
                    </>
                  );
                }
                return t('global.unavailable');
              }}
            </Cell>
          </Column>
        )}

        {columns.has('uploadedBy') && (
          <Column flexGrow={2}>
            <HeaderCell>{t('tables.uploads.headers.uploadedBy')}</HeaderCell>
            <Cell fullText>{(rowData) => <UploadedByCell rowData={rowData} />}</Cell>
          </Column>
        )}

        {columns.has('uploadedDate') && (
          <Column sortable flexGrow={1.5} minWidth={200}>
            <HeaderCell>{t('tables.uploads.headers.uploadDate')}</HeaderCell>
            <Cell fullText dataKey="dateCreated">
              {(rowData, rowIndex) => {
                const curr = DateTime.fromISO(rowData?.dateCreated).toLocaleString(
                  DateTime.DATETIME_MED,
                );
                return `${curr}`;
              }}
            </Cell>
          </Column>
        )}

        {columns.has('error') && (
          <>
            <Column flexGrow={3}>
              <HeaderCell>
                {t(
                  `tables.uploads.headers.${
                    activeTab === 'ingests' ? 'ingest-errors' : 'errors'
                  }`,
                )}
              </HeaderCell>
              <Cell>
                {(rowData, rowIndex) => {
                  if (activeTab === 'ingests' && rowData?.ingestErrors?.length > 0) {
                    return (
                      <Tooltip
                        label={rowData?.ingestErrors.join(' ')}
                        multiline
                        width={300}
                      >
                        <span>
                          <Icon
                            icon="triangle-exclamation"
                            classNames="failed-error-reason"
                            style={{
                              position: 'relative',
                              top: '2px',
                              color: '#CC3663',
                            }}
                          />
                          {rowData?.ingestErrors.join('. ')}
                        </span>
                      </Tooltip>
                    );
                  } else if (rowData?.uploadErrors?.length > 0) {
                    return (
                      <Tooltip
                        label={rowData.uploadErrors.join(' ')}
                        multiline
                        width={300}
                      >
                        <span>
                          <Icon
                            icon="triangle-exclamation"
                            classNames="failed-error-reason"
                          />
                          {rowData.uploadErrors.join('. ')}
                        </span>
                      </Tooltip>
                    );
                  } else if (rowData?.processErrors?.length > 0) {
                    return (
                      <Tooltip
                        label={rowData.processErrors.join(' ')}
                        multiline
                        width={300}
                      >
                        <span>
                          <Icon
                            icon="triangle-exclamation"
                            classNames="failed-error-reason"
                          />
                          {rowData.processErrors.join('. ')}
                        </span>
                      </Tooltip>
                    );
                  } else if (
                    rowData.status?.toLowerCase() === 'failed' &&
                    rowData.enrichments[0]?.processingErrors?.length
                  ) {
                    return (
                      <span className="failed-error-reason">
                        <Icon icon="triangle-exclamation" />
                        {t('tables.uploads.cells.errors.enrichmentError')}
                      </span>
                    );
                  } else if (rowData.uploadErrors || rowData.processErrors) {
                    return (
                      <span className="failed-error-reason">
                        <Icon icon="triangle-exclamation" />
                        {t('tables.uploads.cells.errors.unknown')}
                      </span>
                    );
                  }
                }}
              </Cell>
            </Column>
          </>
        )}
        {columns.has('actions') && (
          <Column flexGrow={1}>
            <HeaderCell>Actions</HeaderCell>
            <Cell>
              {(rowData, _rowIndex) => (
                <div className="actions-container">
                  {activeTab !== 'pending' ? (
                    <ClickableRegion
                      regionLabel={t('global.download')}
                      onClick={() => handleDownloadRequest([rowData as InterfaceSbom])}
                    >
                      <Icon icon="arrow-down-to-bracket" />
                    </ClickableRegion>
                  ) : null}
                  {activeTab !== 'pending' && hasWriteAccess ? (
                    <ClickableRegion
                      regionLabel={t('global.remove')}
                      href={`#`}
                      onClick={() => handleOpenDeleteModal(rowData?._id)}
                    >
                      <Icon icon="trash-can" />
                    </ClickableRegion>
                  ) : null}
                </div>
              )}
            </Cell>
          </Column>
        )}
      </Table>
      <div className="list-pagination">
        <DataTableFooter
          currentPage={pagination.pageIndex}
          limitPerPage={pagination.pageSize}
          totalResults={countTotalSboms}
          onChangePage={setPagination}
        />
      </div>
    </>
  );
};
