import { useTranslation } from 'react-i18next';
import { getMergedSBOMs } from '../../api/sbom.api';
import appConfig from '../../configs/appConfig';
import { captureExceptionWithMessage } from '../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';
import { useNotifications } from './useNotifications';

export const useSBOMsUtils = () => {
  const { error } = useNotifications();
  const { t } = useTranslation();
  const downloadSBOMs = (sbomIDs: string[]) => {
    if (!sbomIDs.length) return;
    try {
      const downloadString = sbomIDs.join(',');
      let url;
      if (sbomIDs.length === 1) {
        url = `//${appConfig?.apiUrl}/v${appConfig?.apiVersion}/sbom/download/${downloadString}?redirect=1`;
      } else {
        url = `//${appConfig?.apiUrl}/v${appConfig?.apiVersion}/download/bundle?fileIds=${downloadString}`;
      }

      const downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = '';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (e) {
      error({
        title: t('notifications.sbom-download-error.title'),
        message: t('notifications.sbom-download-error.message'),
      });
      captureExceptionWithMessage('downloadSBOMs', e);
    }
  };

  const mergeSBOMs = async (sbomIDs: string[]) => {
    if (sbomIDs.length <= 1) return;
    try {
      const response = await getMergedSBOMs(sbomIDs);
      if (response.success) return response.data?.[0];
      throw new Error(`error merging SBOMs: ${JSON.stringify(response.errors)}`);
    } catch (e) {
      captureExceptionWithMessage('mergeSBOMs', e);

      error({
        title: t('notifications.asset-pdf-export-error.title'),
        message: t('notifications.asset-pdf-export-error.message'),
      });
    }
  };

  return { downloadSBOMs, mergeSBOMs };
};
