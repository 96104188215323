import { useMutation } from '@tanstack/react-query';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';

interface Params {
  triggerType: string;
  force: boolean;
}

export const useTriggerMessage = () => {
  return useMutation({
    mutationFn: ({ triggerType, force }: Params) => {
      return AxiosProxy.post({
        url: `/admin/trigger/${triggerType}?force=${force}`,
        withCredentials: true,
        body: alert,
      });
    },
  });
};
