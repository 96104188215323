import clsx from 'clsx';
import { ReactNode } from 'react';
import ClickableRegion from '../../ClickableRegion';
import Icon from '../../Icon';
import styles from './EditableRow.module.scss';

export const EditableRow = ({
  children,
  onClick,
  tooltipText,
  useMutedText,
}: {
  children: ReactNode;
  onClick: () => void;
  tooltipText?: string;
  useMutedText?: boolean;
}) => {
  return (
    <div className={clsx(styles.container, useMutedText ? styles.mutedText : '')}>
      {children}
      <ClickableRegion
        regionLabel={tooltipText}
        onClick={onClick}
        className={styles.editIcon}
      >
        <>
          <Icon icon="pencil" />
        </>
      </ClickableRegion>
    </div>
  );
};
