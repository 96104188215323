import { faHandHoldingHeart } from '@fortawesome/pro-light-svg-icons';
import {
  faArrowUpRightFromSquare,
  faBoltLightning,
  faCalendar,
  faCheckCircle,
  faCodeBranch,
  faDesktop,
  faExclamationCircle,
  faExclamationTriangle,
  faKey,
  faMapPin,
  faStar,
} from '@fortawesome/pro-regular-svg-icons';
import { AssetReference } from '@manifest-cyber/types/interface/db';
import {
  InterfaceOssIngest,
  InterfaceVulnerability,
} from '@manifest-cyber/types/interface/dbTables';
import { Loader, Modal } from '@mantine/core';
import {
  Document,
  Image,
  PDFDownloadLink,
  PDFViewer,
  Page,
  Link as PdfLink,
  Text,
  View,
} from '@react-pdf/renderer';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import Icon from '../../../components/Icon';
import IconForPdf from '../../../components/IconForPdf';
import Loading from '../../../components/Loading';
import appConfig from '../../../configs/appConfig';
import { useFetchAsset } from '../../../hooks/queries/useFetchAsset';
import { useFetchComponents } from '../../../hooks/queries/useFetchComponents';
import { useFetchOssIngest } from '../../../hooks/queries/useFetchOssIngest';
import { useFetchRiskiestAssetComponents } from '../../../hooks/queries/useFetchRiskiestAssetComponents';
import { useFetchVulns } from '../../../hooks/queries/useFetchVulns';
import replaceKeysWithStringValues, {
  checkForScaryLocations,
} from '../../../lib/abbreviateCommonStrings';
import formatBigCount from '../../../lib/formatBigCount';
import normalizeAuthor from '../../../lib/normalizeAuthor';
import timeSince from '../../../lib/timeSince';
import {
  getChartOptions,
  getChartValues,
} from '../tabs/asset-risk-overview-tab-components/RiskOverviewCard/RiskOverviewCard';
import styles from './AssetPdf.module.scss';
import { pdfColors, pdfFontSizes, pdfStyles } from './AssetPdfStyles';

interface Props {
  viewExportDocument: boolean;
  setViewExportDocument: React.Dispatch<React.SetStateAction<boolean>>;
  assetId: string;
  organizationId: string;
}

export const AssetPdf = (props: Props) => {
  const { viewExportDocument, setViewExportDocument, assetId, organizationId } = props;
  const [chartURI, setChartURI] = useState('');

  // hooks
  const { t } = useTranslation();
  // fetch assets
  const { data: fetchedAsset, isLoading: loadingA } = useFetchAsset({
    assetId,
    organizationId,
  });
  // fetch ingest data
  const { data: fetchedIngest = {} as InterfaceOssIngest, isLoading: loadingB } =
    useFetchOssIngest({
      ossIngestId: `${fetchedAsset?.ossIngestId}`,
    });
  // fetch riskiest components
  const { data: riskiestComponents, isLoading: loadingC } =
    useFetchRiskiestAssetComponents(fetchedAsset?._id?.toString() || '', 10);
  // fetch components
  const {
    data: { queryInfo: { totalCount: countComponents = 0 } = {} } = {},
    isLoading: loadingD,
  } = useFetchComponents({
    queryParams: { assetId },
  });
  // fetch vulnerabilities
  const {
    data: { queryInfo: { countVulnerabilities = 0 } = {} } = {},
    isLoading: loadingE,
  } = useFetchVulns({
    assetId,
    organizationId,
    sortColumn: 'epssScore',
    sortType: 'desc',
  });

  // memo
  const isLoading = useMemo(
    () => [loadingA, loadingB, loadingC, loadingD, loadingE].some((l) => l),
    [loadingA, loadingB, loadingC, loadingD, loadingE],
  );

  // callbacks
  const searchChartURI = useCallback(async () => {
    const apexWindow = window as any;

    if (apexWindow.Apex._chartInstances) {
      // @ts-ignore
      const chartInstance = apexWindow.Apex?._chartInstances.find(
        (chart: ApexChart) => chart.id === 'risk-overview-chart-pdf',
      );

      const base64Image = await chartInstance?.chart?.dataURI();
      setChartURI(`${base64Image?.imgURI || base64Image}`);
    }
  }, []);

  // effects
  useEffect(() => {
    searchChartURI();
  }, [fetchedAsset, countVulnerabilities]);

  // Create Document Component
  const refinedLinks: AssetReference[] = [];
  const numInKev = fetchedAsset?.countVulnerabilities?.inKEV || 0;
  const numMitigate = fetchedAsset?.countVulnerabilities?.mitigate || 0;
  const numMonitor = fetchedAsset?.countVulnerabilities?.monitor || 0;
  const numLicenseIssues = fetchedAsset?.countLicenseIssues?.total || 0;

  const signature =
    fetchedAsset?.latestSbom?.attestation?.signatures &&
    fetchedAsset?.latestSbom?.attestation?.signatures?.[0]?.sig
      ? fetchedAsset?.latestSbom?.attestation?.signatures?.[0]?.sig
      : '';

  let githubMeta: any = null;
  if (
    fetchedIngest &&
    fetchedIngest?._id &&
    fetchedIngest?.gitUrl &&
    fetchedIngest?.metadata &&
    fetchedIngest?.metadata?.github
  ) {
    githubMeta = fetchedIngest?.metadata?.github;
  }

  const truncateText = (text: string | undefined, maxLength: number) => {
    if (text && text.length <= maxLength) {
      return text;
    }
    return `${text && text.substring(0, maxLength - 3)}...`;
  };

  const cleanupContributorLocation = (location: any) => {
    const forceLowercase = (str: string) => str.toLowerCase();
    const emptyStrings = ['null', 'unknown', 'anonymous', 'undefined', 'empty', ''];

    if (
      !location ||
      typeof location !== 'string' ||
      emptyStrings.includes(forceLowercase(`${location}`)?.trim())
    ) {
      return t('global.na');
    }

    let locationClasses = checkForScaryLocations(location, {});
    location = replaceKeysWithStringValues(location);

    // Add spaces after any trailing commas
    return location.replace(/,(?=[^\s])/g, ', ');
  };

  const PdfExportDocument = () => {
    return (
      <Document title={`${fetchedAsset?.name}`}>
        <Page size="LETTER" style={pdfStyles.page}>
          {/* Header */}
          <View style={pdfStyles.header}>
            <View style={pdfStyles.headerInner}>
              <Text style={pdfStyles.headerLeft}>
                <Image style={pdfStyles.headerImage} src="/pdf-logo.png" />
              </Text>
              <View style={pdfStyles.headerRight}>
                <Text style={pdfStyles.headerRightTitle}>
                  {t('pdf-export.asset-details.title')}
                </Text>
                <Text style={pdfStyles.headerRightSubtitle}>
                  {t('pdf-export.asset-details.subtitle')}&nbsp;
                  {DateTime.fromISO(`${fetchedAsset?.dateModified}`).toLocaleString(
                    DateTime.DATETIME_FULL,
                  )}
                </Text>
              </View>
            </View>
            <View style={pdfStyles.headerBackground}>
              <Image
                style={pdfStyles.headerBackgroundImage}
                src="/pdf-header-background.png"
              />
            </View>
          </View>
          <View style={pdfStyles.headerLeftOverlay}>
            <Image style={pdfStyles.headerImage2} src="/pdf-logo.png" />
          </View>
          <View style={pdfStyles.headerRightOverlay}>
            <Text style={pdfStyles.headerRightTitle}>
              {t('pdf-export.asset-details.title')}
            </Text>
            <Text style={pdfStyles.headerRightSubtitle}>
              {t('pdf-export.asset-details.subtitle')}&nbsp;
              {DateTime.fromISO(`${fetchedAsset?.dateModified}`).toLocaleString(
                DateTime.DATETIME_FULL,
              )}
            </Text>
          </View>

          {/* Main Content */}
          <View style={pdfStyles.pageContent}>
            <View style={pdfStyles.splitView}>
              <View style={pdfStyles.splitViewLeft}>
                <Text style={pdfStyles.overviewSectionTitle}>{fetchedAsset?.name}</Text>
                <Text style={pdfStyles.overviewSectionVersion}>
                  {t('pdf-export.asset-details.version', {
                    version: fetchedAsset?.version,
                  })}
                </Text>

                {/* Summary Divider */}
                <View style={pdfStyles.sectionDivider}>
                  <Text style={pdfStyles.sectionDividerText}>
                    {t('pdf-export.asset-details.sections.summary')}
                  </Text>
                  <View style={pdfStyles.sectionDividerLine} />
                </View>

                {/* Summary Content */}
                <View style={pdfStyles.sectionContent}>
                  <View style={pdfStyles.sectionContentSummary}>
                    <View style={pdfStyles.sectionContentSummaryListItem}>
                      {numInKev === 0 ? (
                        <IconForPdf
                          faIcon={faCheckCircle}
                          style={pdfStyles.iconSummarySuccess}
                        />
                      ) : (
                        <IconForPdf
                          faIcon={faBoltLightning}
                          style={pdfStyles.iconSummaryIssue}
                        />
                      )}
                      <Text>
                        {t('page.asset.bullets.numKEV', {
                          count: numInKev,
                        })}
                      </Text>
                    </View>
                    <View style={pdfStyles.sectionContentSummaryListItem}>
                      {numMitigate === 0 ? (
                        <IconForPdf
                          faIcon={faCheckCircle}
                          style={pdfStyles.iconSummarySuccess}
                        />
                      ) : (
                        <IconForPdf
                          faIcon={faExclamationTriangle}
                          style={pdfStyles.iconSummaryIssue}
                        />
                      )}
                      <Text>
                        {t('page.asset.bullets.numMitigate', {
                          count: numMitigate,
                        })}
                      </Text>
                    </View>
                    <View style={pdfStyles.sectionContentSummaryListItem}>
                      {numMonitor === 0 ? (
                        <IconForPdf
                          faIcon={faCheckCircle}
                          style={pdfStyles.iconSummarySuccess}
                        />
                      ) : (
                        <IconForPdf
                          faIcon={faExclamationCircle}
                          style={pdfStyles.iconSummaryIssue}
                        />
                      )}
                      <Text>
                        {t('page.asset.bullets.numMonitor', {
                          count: numMonitor,
                        })}
                      </Text>
                    </View>
                    <View style={pdfStyles.sectionContentSummaryListItem}>
                      {numLicenseIssues === 0 ? (
                        <IconForPdf
                          faIcon={faCheckCircle}
                          style={pdfStyles.iconSummarySuccess}
                        />
                      ) : (
                        <IconForPdf faIcon={faKey} style={pdfStyles.iconSummaryIssue} />
                      )}
                      <Text>
                        {t('page.asset.bullets.numLicenseIssue', {
                          count: numLicenseIssues,
                        })}
                      </Text>
                    </View>
                  </View>
                </View>

                {/* Vuln Analysis Divider */}
                <View style={pdfStyles.sectionDivider}>
                  <View style={pdfStyles.sectionDividerText}>
                    <Text>{t('pdf-export.asset-details.sections.analysis')}</Text>
                  </View>
                  <View style={pdfStyles.sectionDividerLine2} />
                </View>

                {/* Vuln Analysis Content */}
                <View style={pdfStyles.sectionContentGraphContainer}>
                  <View style={pdfStyles.sectionContentGraph}>
                    {chartURI && chartURI?.length > 0 && <Image src={chartURI} />}
                  </View>
                  <View style={pdfStyles.sectionContentLegend}>
                    {/* Critical */}
                    <View style={pdfStyles.sectionContentLegendRow}>
                      <View
                        style={{
                          ...pdfStyles.sectionContentLegendRowColor,
                          backgroundColor: pdfColors.red,
                        }}
                      />
                      <Text style={pdfStyles.sectionContentLegendRowLabel}>
                        {t('global.critical')}
                      </Text>
                      <Text style={pdfStyles.sectionContentLegendRowValue}>
                        {fetchedAsset?.countVulnerabilities?.critical || 0}
                      </Text>
                    </View>
                    {/* High */}
                    <View style={pdfStyles.sectionContentLegendRow}>
                      <View
                        style={{
                          ...pdfStyles.sectionContentLegendRowColor,
                          backgroundColor: '#ED765D',
                        }}
                      />
                      <Text style={pdfStyles.sectionContentLegendRowLabel}>
                        {t('global.high')}
                      </Text>
                      <Text style={pdfStyles.sectionContentLegendRowValue}>
                        {fetchedAsset?.countVulnerabilities?.high || 0}
                      </Text>
                    </View>
                    {/* Medium */}
                    <View style={pdfStyles.sectionContentLegendRow}>
                      <View
                        style={{
                          ...pdfStyles.sectionContentLegendRowColor,
                          backgroundColor: pdfColors.medium,
                        }}
                      />
                      <Text style={pdfStyles.sectionContentLegendRowLabel}>
                        {t('global.medium')}
                      </Text>
                      <Text style={pdfStyles.sectionContentLegendRowValue}>
                        {fetchedAsset?.countVulnerabilities?.medium || 0}
                      </Text>
                    </View>
                    {/* Low */}
                    <View style={pdfStyles.sectionContentLegendRow}>
                      <View
                        style={{
                          ...pdfStyles.sectionContentLegendRowColor,
                          backgroundColor: '#B8BFCB',
                        }}
                      />
                      <Text style={pdfStyles.sectionContentLegendRowLabel}>
                        {t('global.low')}
                      </Text>
                      <Text style={pdfStyles.sectionContentLegendRowValue}>
                        {fetchedAsset?.countVulnerabilities?.low || 0}
                      </Text>
                    </View>
                    {/* Info */}
                    <View style={pdfStyles.sectionContentLegendRow}>
                      <View
                        style={{
                          ...pdfStyles.sectionContentLegendRowColor,
                          backgroundColor: '#B8BFCBCC',
                        }}
                      />
                      <Text style={pdfStyles.sectionContentLegendRowLabel}>
                        {t('global.info')}
                      </Text>
                      <Text style={pdfStyles.sectionContentLegendRowValue}>
                        {fetchedAsset?.countVulnerabilities?.info || 0}
                      </Text>
                    </View>
                  </View>
                </View>

                {/* Top 10 Riskiest Divider */}
                <View style={pdfStyles.sectionDivider}>
                  <View style={pdfStyles.sectionDividerText}>
                    <Text>
                      {t('pdf-export.asset-details.sections.riskiest-components')}
                    </Text>
                  </View>
                  <View style={pdfStyles.sectionDividerLine3} />
                </View>

                {/* Top 10 Riskiest Content */}
                <View style={pdfStyles.sectionContent}>
                  <View style={pdfStyles.impactedAssetsList}>
                    <View style={pdfStyles.impactedAssetsListItemHeader}>
                      <Text style={pdfStyles.impactedAssetsListItemColumnAHeader}>
                        {t('pdf-export.asset-details.name')}
                      </Text>
                      <Text style={pdfStyles.impactedAssetsListItemColumnBHeader}>
                        {t('pdf-export.asset-details.vulnerabilities')}
                      </Text>
                      <Text style={pdfStyles.impactedAssetsListItemColumnCHeader}>
                        {t('pdf-export.asset-details.license-issues')}
                      </Text>
                    </View>
                    {riskiestComponents?.map((component: any, index) => (
                      <View style={pdfStyles.impactedAssetsListItem} key={index}>
                        <View style={pdfStyles.impactedAssetsListItemColumnA}>
                          <Text>
                            {component?.fullyQualifiedName || component?.name}@
                            {component?.version}
                          </Text>
                        </View>
                        <View style={pdfStyles.impactedAssetsListItemColumnB}>
                          {component?.vulnerabilities
                            .slice(0, 3)
                            ?.map((vuln: InterfaceVulnerability, index: number) => {
                              const countInKev = parseInt(
                                component?.countVulnerabilities?.inKEV +
                                  component?.countVulnerabilities?.inKEVPreviously,
                              );
                              return (
                                <View
                                  style={pdfStyles.impactedAssetsListVulns}
                                  key={vuln?._id?.toString() || index}
                                >
                                  <Text style={pdfStyles.impactedAssetsListVulnsCve}>
                                    {vuln?.cveId}
                                  </Text>
                                  {(vuln?.kevData?.inKEV ||
                                    vuln?.kevData?.inKEVPreviously ||
                                    index < countInKev) && (
                                    <View style={pdfStyles.impactedAssetsListVulnsKev}>
                                      <IconForPdf
                                        faIcon={faBoltLightning}
                                        style={pdfStyles.iconKev}
                                      />
                                      <Text>{t('pdf-export.asset-details.kev')}</Text>
                                    </View>
                                  )}
                                  {vuln?.recommendedAction === 'mitigate' ? (
                                    <View
                                      style={{
                                        ...pdfStyles.impactedAssetsListVulnsAction,
                                        color: pdfColors.red,
                                      }}
                                    >
                                      <IconForPdf
                                        faIcon={faExclamationTriangle}
                                        style={pdfStyles.iconVulnMitigate}
                                      />
                                      <Text>
                                        {t(
                                          'page.vulnerability.overview.recommendationActions.mitigate',
                                        )}
                                      </Text>
                                    </View>
                                  ) : (
                                    <View
                                      style={{
                                        ...pdfStyles.impactedAssetsListVulnsAction,
                                        color: pdfColors.medium,
                                      }}
                                    >
                                      <IconForPdf
                                        faIcon={faExclamationCircle}
                                        style={pdfStyles.iconVulnMonitor}
                                      />
                                      <Text>
                                        {t(
                                          'page.vulnerability.overview.recommendationActions.monitor',
                                        )}
                                      </Text>
                                    </View>
                                  )}
                                </View>
                              );
                            })}
                          {component?.vulnerabilities?.length > 3 && (
                            <PdfLink
                              src={`${appConfig?.siteURL}/component/${component?._id}/vulnerabilities`}
                              style={pdfStyles.pdfLinkAlt}
                            >
                              {t(
                                'page.vulnerability.overview.view-all-n-component-vulns',
                                {
                                  countVulns: component?.vulnerabilities?.length,
                                },
                              )}
                            </PdfLink>
                          )}
                        </View>
                        <View style={pdfStyles.impactedAssetsListItemColumnC}>
                          {component?.countLicenseIssues?.total > 0 ? (
                            <Text>{component?.license?.[0]?.name}</Text>
                          ) : (
                            <Text
                              style={{
                                width: '100%',
                                paddingLeft: '10px',
                              }}
                            >
                              --
                            </Text>
                          )}
                        </View>
                      </View>
                    ))}
                    {(!riskiestComponents || riskiestComponents?.length < 1) && (
                      <Text
                        style={{
                          ...pdfStyles.notProvidedText,
                          paddingLeft: '10px',
                        }}
                      >
                        {t('pdf-export.asset-details.no-riskiest-components')}
                      </Text>
                    )}
                  </View>
                </View>
              </View>
              {/* End Left Side */}

              {/* Right side - Quick Stats, Link, etc */}
              <View style={pdfStyles.splitViewRight}>
                <View>
                  <PdfLink
                    src={`${appConfig?.siteURL}/asset/${fetchedAsset?._id}`}
                    style={pdfStyles.pdfLink}
                  >
                    <Text style={pdfStyles.pdfLinkText}>
                      {t('pdf-export.asset-details.view-on-site')}
                    </Text>
                    <IconForPdf
                      faIcon={faArrowUpRightFromSquare}
                      style={pdfStyles.pdfLinkIcon}
                    />
                  </PdfLink>
                </View>
                <View style={pdfStyles.section}>
                  <View style={pdfStyles.sidebarBlock}>
                    <Text style={pdfStyles.sidebarBlockTitle}>
                      {t('page.asset.risk')}
                    </Text>
                    <View style={pdfStyles.sidebarBlockFlex}>
                      <Text
                        style={{
                          ...pdfStyles.sidebarBlockValue,
                          color:
                            fetchedAsset?.riskScore === 'high'
                              ? pdfColors.red
                              : pdfColors.text,
                        }}
                      >
                        {t(`page.asset.risk-score.${fetchedAsset?.riskScore}`)}
                      </Text>
                    </View>
                  </View>
                  <View style={pdfStyles.sidebarBlock}>
                    <Text style={pdfStyles.sidebarBlockTitle}>
                      {t('page.asset.tabs.components')}
                    </Text>
                    <View style={pdfStyles.sidebarBlockFlex}>
                      <IconForPdf faIcon={faDesktop} style={pdfStyles.sidebarBlockIcon} />
                      <Text style={pdfStyles.sidebarBlockValuePadded}>
                        {countComponents?.toLocaleString()}
                      </Text>
                    </View>
                  </View>
                  <View style={pdfStyles.sidebarBlock}>
                    <Text style={pdfStyles.sidebarBlockTitle}>
                      {t('page.asset.tabs.vulnerabilities')}
                    </Text>
                    <View style={pdfStyles.sidebarBlockFlex}>
                      <IconForPdf
                        faIcon={faExclamationTriangle}
                        style={pdfStyles.sidebarBlockIcon}
                      />
                      <Text style={pdfStyles.sidebarBlockValuePadded}>
                        {countVulnerabilities?.toLocaleString()}
                      </Text>
                    </View>
                  </View>
                  <View style={pdfStyles.sidebarBlock}>
                    <Text style={pdfStyles.sidebarBlockTitle}>
                      {t('pdf-export.asset-details.license-issues')}
                    </Text>
                    <View style={pdfStyles.sidebarBlockFlex}>
                      <IconForPdf faIcon={faKey} style={pdfStyles.sidebarBlockIcon} />
                      <Text style={pdfStyles.sidebarBlockValuePadded}>
                        {(fetchedAsset?.countLicenseIssues?.total || 0)?.toLocaleString()}
                      </Text>
                    </View>
                  </View>
                  <View style={pdfStyles.sidebarBlock}>
                    <Text style={pdfStyles.sidebarBlockTitle}>
                      {t('pdf-export.asset-details.links')}
                    </Text>
                    <View>
                      {fetchedAsset?.references?.map((thisAssetLink: AssetReference) => {
                        if (
                          thisAssetLink?.url &&
                          thisAssetLink?.url?.length > 0 &&
                          (thisAssetLink?.url?.includes('http://') ||
                            thisAssetLink?.url?.includes('https://'))
                        ) {
                          const thisLink = refinedLinks.find(
                            (link) => link.url === thisAssetLink?.url,
                          );
                          if (!thisLink) {
                            refinedLinks.push(thisAssetLink);
                            return (
                              <PdfLink
                                src={thisAssetLink?.url}
                                style={pdfStyles.pdfLinkList}
                              >
                                <Text style={pdfStyles.pdfLinkText}>
                                  {thisAssetLink?.url}
                                </Text>
                              </PdfLink>
                            );
                          }
                        }

                        return null;
                      })}
                      {refinedLinks?.length < 1 && (
                        <Text style={pdfStyles.notProvidedText}>
                          {t('pdf-export.asset-details.no-links')}
                        </Text>
                      )}
                    </View>
                  </View>
                </View>
              </View>
            </View>
            {/** End splitView */}

            {/* About Divider */}
            <View
              style={{
                ...pdfStyles.sectionDivider,
                marginTop: '14px',
              }}
            >
              <View style={pdfStyles.sectionDividerText}>
                <Text>{t('pdf-export.asset-details.sections.about')}</Text>
              </View>
              <View style={pdfStyles.sectionDividerLine} />
            </View>

            {/* About Content */}
            <View
              style={{
                ...pdfStyles.section,
                marginTop: '12px',
              }}
            >
              <Text
                style={{
                  color: pdfColors.text,
                  fontSize: pdfFontSizes.p,
                  fontWeight: 'bold',
                  marginBottom: '8px',
                }}
              >
                {t('pdf-export.asset-details.about.general.title')}
              </Text>

              {/* About List */}
              <View style={pdfStyles.aboutRow}>
                <Text style={pdfStyles.aboutRowLeft}>
                  {t('pdf-export.asset-details.about.general.name')}
                </Text>
                <View style={pdfStyles.aboutRowRight}>
                  <Text>{fetchedAsset?.fullyQualifiedName || fetchedAsset?.name}</Text>
                </View>
              </View>
              <View style={pdfStyles.aboutRow}>
                <Text style={pdfStyles.aboutRowLeft}>
                  {t('pdf-export.asset-details.about.general.version')}
                </Text>
                <View style={pdfStyles.aboutRowRight}>
                  <Text>{fetchedAsset?.version}</Text>
                </View>
              </View>
              <View style={pdfStyles.aboutRow}>
                <Text style={pdfStyles.aboutRowLeft}>
                  {t('pdf-export.asset-details.about.general.license')}
                </Text>
                <View style={pdfStyles.aboutRowRight}>
                  {fetchedIngest && fetchedIngest?.license ? (
                    <Text>
                      {
                        // @ts-ignore
                        fetchedIngest?.license?.name
                      }
                    </Text>
                  ) : (!fetchedIngest || !fetchedIngest?.license) &&
                    fetchedAsset?.licensesData &&
                    fetchedAsset?.licensesData?.length > 0 &&
                    fetchedAsset?.licensesData?.[0]?.shortName ? (
                    <Text>{fetchedAsset?.licensesData?.[0]?.shortName}</Text>
                  ) : (
                    <Text>{t('global.notProvided')}</Text>
                  )}
                </View>
              </View>
              <View style={pdfStyles.aboutRow}>
                <Text style={pdfStyles.aboutRowLeft}>
                  {t('pdf-export.asset-details.about.general.developedBy')}
                </Text>
                <View style={pdfStyles.aboutRowRight}>
                  {fetchedIngest && fetchedIngest?.metadata?.groupName ? (
                    <Text>{fetchedIngest?.metadata?.groupName}</Text>
                  ) : (
                    fetchedAsset && <Text>{normalizeAuthor(fetchedAsset, t)}</Text>
                  )}
                </View>
              </View>
              <View style={pdfStyles.aboutRow}>
                <Text style={pdfStyles.aboutRowLeft}>
                  {t('pdf-export.asset-details.about.general.uploaded')}
                </Text>
                <View style={pdfStyles.aboutRowRight}>
                  <Text>
                    {fetchedAsset?.latestSbom?.dateCreated
                      ? DateTime.fromISO(
                          fetchedAsset?.latestSbom?.dateCreated.toString(),
                        ).toLocaleString(DateTime.DATETIME_MED)
                      : t('global.notProvided')}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.aboutRow}>
                <Text style={pdfStyles.aboutRowLeft}>
                  {t('pdf-export.asset-details.about.general.updated')}
                </Text>
                <View style={pdfStyles.aboutRowRight}>
                  <Text>
                    {fetchedAsset?.latestSbom?.dateModified
                      ? DateTime.fromISO(
                          fetchedAsset?.latestSbom?.dateModified.toString(),
                        ).toLocaleString(DateTime.DATETIME_MED)
                      : t('global.notProvided')}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.aboutRow}>
                <Text style={pdfStyles.aboutRowLeft}>
                  {t('pdf-export.asset-details.about.general.attestation')}
                </Text>
                <View style={pdfStyles.aboutRowRight}>
                  {signature ? (
                    <Text>
                      {signature.length > 12
                        ? `${signature.slice(0, 6).toLowerCase()}...${signature
                            .slice(-6)
                            .toLowerCase()}  `
                        : `${signature}  `}
                    </Text>
                  ) : (
                    <Text>{t(`page.asset.attestation.missing-full`)}</Text>
                  )}
                </View>
              </View>
            </View>
            {/** End About List */}

            {fetchedIngest &&
              fetchedIngest?._id &&
              fetchedIngest?.gitUrl &&
              fetchedIngest?.gitUrl?.length > 0 && (
                <>
                  {/* Repository Content */}
                  <View
                    style={{
                      ...pdfStyles.section,
                      marginTop: '12px',
                    }}
                  >
                    <Text
                      style={{
                        color: pdfColors.text,
                        fontSize: pdfFontSizes.p,
                        fontWeight: 'bold',
                        marginBottom: '4px',
                      }}
                    >
                      {t('pdf-export.asset-details.about.repo.title')}
                    </Text>
                    <PdfLink
                      src={fetchedIngest?.gitUrl}
                      style={{
                        ...pdfStyles.linkOverride,
                        color: pdfColors.primary,
                        fontSize: '10px',
                        marginBottom: '4px',
                      }}
                    >
                      {fetchedIngest?.gitUrl}
                    </PdfLink>
                    <View style={pdfStyles.repoFlex}>
                      <View style={pdfStyles.repoFlexItem}>
                        <IconForPdf faIcon={faStar} style={pdfStyles.repoFlexIcon} />
                        <Text>
                          {t('page.asset.about-content.analysis.stars', {
                            value: githubMeta?.stargazers_count
                              ? formatBigCount(githubMeta?.stargazers_count)
                              : t('global.na'),
                          })}
                        </Text>
                      </View>
                      <View style={pdfStyles.repoFlexItemBranch}>
                        <IconForPdf
                          faIcon={faCodeBranch}
                          style={pdfStyles.repoFlexIcon}
                        />
                        <Text>
                          {t('page.asset.about-content.analysis.stars', {
                            value: githubMeta?.forks_count
                              ? formatBigCount(githubMeta?.forks_count)
                              : t('global.na'),
                          })}
                        </Text>
                      </View>
                      <View style={pdfStyles.repoFlexItem}>
                        <IconForPdf faIcon={faCalendar} style={pdfStyles.repoFlexIcon} />
                        <Text>
                          {t('page.asset.about-content.analysis.repo-created', {
                            date: githubMeta?.created_at
                              ? DateTime.fromISO(githubMeta?.created_at).toLocaleString(
                                  DateTime.DATE_MED,
                                )
                              : t('global.na'),
                            timeAgo: githubMeta?.created_at
                              ? DateTime.fromISO(githubMeta?.created_at).toRelative()
                              : t('global.na'),
                          })}
                        </Text>
                      </View>
                    </View>

                    <View style={pdfStyles.fullDivider}></View>

                    {/* Repository List */}
                    <View style={pdfStyles.aboutRow}>
                      <Text style={pdfStyles.aboutRowLeft}>
                        {t('pdf-export.asset-details.about.repo.owner')}
                      </Text>
                      <View style={pdfStyles.aboutRowRight}>
                        {githubMeta?.owner?.login ? (
                          <Text>
                            @
                            {t('page.asset.about-content.analysis.owner-value', {
                              owner: githubMeta?.owner?.login,
                              ownerType: githubMeta?.owner?.type,
                            })}
                          </Text>
                        ) : (
                          <Text>{t('global.na')}</Text>
                        )}
                      </View>
                    </View>
                    <View style={pdfStyles.aboutRow}>
                      <Text style={pdfStyles.aboutRowLeft}>
                        {t('pdf-export.asset-details.about.repo.lastCommitDateLeft')}
                      </Text>
                      <View style={pdfStyles.aboutRowRight}>
                        {githubMeta?.pushed_at ? (
                          <Text>
                            {t('page.asset.about-content.analysis.lastCommitDate-value', {
                              date: githubMeta?.pushed_at
                                ? DateTime.fromISO(githubMeta?.pushed_at).toLocaleString(
                                    DateTime.DATE_MED,
                                  )
                                : t('global.na'),
                              timeAgo: githubMeta?.pushed_at
                                ? DateTime.fromISO(githubMeta?.pushed_at).toRelative()
                                : t('global.na'),
                            })}
                          </Text>
                        ) : (
                          <Text>{t('global.na')}</Text>
                        )}
                      </View>
                    </View>
                    <View style={pdfStyles.aboutRow}>
                      <Text style={pdfStyles.aboutRowLeft}>
                        {t('pdf-export.asset-details.about.repo.contributors')}
                      </Text>
                      <View style={pdfStyles.aboutRowRight}>
                        {fetchedIngest?.countUniqueContributors ? (
                          <Text>
                            {(fetchedIngest?.countUniqueContributors).toLocaleString()}
                          </Text>
                        ) : (
                          <Text>{t('global.na')}</Text>
                        )}
                      </View>
                    </View>
                    <View
                      style={{
                        ...pdfStyles.aboutRow,
                        alignItems: 'flex-start',
                      }}
                    >
                      <Text style={pdfStyles.aboutRowLeft}>
                        {t('pdf-export.asset-details.about.repo.topTen')}
                      </Text>
                      <View style={pdfStyles.aboutRowRightFlex}>
                        <View style={pdfStyles.sectionDividerLine4} />

                        {fetchedIngest?.mostActiveContributors?.map(
                          (contributor, index) => {
                            return (
                              <>
                                <View style={pdfStyles.aboutRowRightFlexRow}>
                                  {/* First column for Name and Created date */}
                                  <View
                                    style={{
                                      ...pdfStyles.aboutRowRightFlexItem1,
                                    }}
                                  >
                                    <Text style={pdfStyles.aboutRowItemName}>
                                      {truncateText(contributor?.name, 25)}
                                    </Text>
                                    <Text style={pdfStyles.aboutRowItemCreatedDate}>
                                      {t('page.asset.about-content.analysis.created-at', {
                                        date: timeSince(contributor.accountCreated, t),
                                      })}
                                    </Text>
                                  </View>
                                  {/* Second column for Location */}
                                  <View
                                    style={{
                                      ...pdfStyles.repoFlexItem,
                                      ...pdfStyles.aboutRowRightFlexItem2,
                                    }}
                                  >
                                    <IconForPdf
                                      faIcon={faMapPin}
                                      style={pdfStyles.repoFlexIcon}
                                    />

                                    <Text style={pdfStyles.aboutRowItemLocation}>
                                      {cleanupContributorLocation(
                                        `${contributor?.location}` || '',
                                      )}
                                    </Text>
                                  </View>
                                  {/* Third column for Contributions */}
                                  <View
                                    style={{
                                      ...pdfStyles.repoFlexItem,
                                      ...pdfStyles.aboutRowRightFlexItem2,
                                    }}
                                  >
                                    <IconForPdf
                                      faIcon={faHandHoldingHeart}
                                      style={pdfStyles.repoFlexIcon}
                                    />

                                    <Text style={pdfStyles.aboutRowItemContributions}>
                                      {t(
                                        'page.asset.about-content.analysis.contributions',
                                        {
                                          contributionsAmount:
                                            contributor?.commits?.toLocaleString(),
                                        },
                                      )}
                                    </Text>
                                  </View>
                                </View>
                                <View style={pdfStyles.sectionDividerLine4} />
                              </>
                            );
                          },
                        )}
                      </View>
                    </View>

                    <View style={pdfStyles.aboutRow}>
                      <Text style={pdfStyles.aboutRowLeft}>
                        {t('pdf-export.asset-details.about.repo.hash')}
                      </Text>
                      <View style={pdfStyles.aboutRowRight}>
                        {fetchedIngest?.commitHash ? (
                          <Text>{fetchedIngest?.commitHash}</Text>
                        ) : (
                          <Text>{t('global.na')}</Text>
                        )}
                      </View>
                    </View>

                    <View style={pdfStyles.fullDivider}></View>

                    <View style={pdfStyles.aboutRow}>
                      <Text style={pdfStyles.aboutRowLeft}>
                        {t('pdf-export.asset-details.about.repo.clamav')}
                      </Text>
                      <View style={pdfStyles.aboutRowRight}>
                        {fetchedIngest?.metadata?.clamScanResults ? (
                          <Text>
                            {fetchedIngest?.metadata?.clamScanResults?.infectedFiles === 0
                              ? t('pdf-export.asset-details.about.repo.clamav-clean')
                              : t('pdf-export.asset-details.about.repo.clamav-infected', {
                                  value: fetchedIngest?.metadata?.clamScanResults
                                    ?.infectedFiles as number,
                                })}
                          </Text>
                        ) : (
                          <Text>
                            {t('pdf-export.asset-details.about.repo.clamav-not-run')}
                          </Text>
                        )}
                      </View>
                    </View>

                    <View style={pdfStyles.aboutRow}>
                      <Text style={pdfStyles.aboutRowLeft}>
                        {t('pdf-export.asset-details.about.repo.openssf-score')}
                      </Text>
                      <View style={pdfStyles.aboutRowRight}>
                        {fetchedIngest?.metadata?.openSsfScorecardUrl ? (
                          <View
                            style={{
                              ...pdfStyles.aboutRowRightFlexRow,
                              alignItems: 'center',
                              top: '3px',
                            }}
                          >
                            <Text>
                              {fetchedIngest?.metadata?.openSsfScorecardUrl?.[
                                'no-scorecard'
                              ]
                                ? t(
                                    'pdf-export.asset-details.about.repo.openssf-not-available',
                                  )
                                : t(
                                    'pdf-export.asset-details.about.repo.openssf-score-value',
                                    {
                                      value: fetchedIngest?.metadata?.openSsfScorecardUrl
                                        ?.score as number,
                                      countChecks: fetchedIngest?.metadata
                                        ?.openSsfScorecardUrl?.checks?.length as number,
                                    },
                                  )}
                            </Text>
                            {fetchedIngest?.metadata?.openSsfScorecardUrl
                              ?.scorecardUrl && (
                              <PdfLink
                                src={
                                  fetchedIngest?.metadata?.openSsfScorecardUrl
                                    ?.scorecardUrl
                                }
                                style={{
                                  ...pdfStyles.linkOverride,
                                  color: pdfColors.primary,
                                  fontSize: '10px',
                                  marginBottom: '0px',
                                }}
                              >
                                {t(
                                  'pdf-export.asset-details.about.repo.openssf-score-link',
                                )}
                              </PdfLink>
                            )}
                          </View>
                        ) : (
                          <Text>
                            {t(
                              'pdf-export.asset-details.about.repo.openssf-not-available',
                            )}
                          </Text>
                        )}
                      </View>
                    </View>
                  </View>
                </>
              )}
          </View>
          {/* Page Number, Fixed to All Pages */}
          <Text
            style={pdfStyles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${t('pdf-export.page-number', {
                current: pageNumber,
                total: totalPages,
              })}`
            }
            fixed
          />
        </Page>
      </Document>
    );
  };

  return (
    <>
      {fetchedAsset && fetchedAsset.countVulnerabilities && (
        <div className={styles.pdf_view_only}>
          <Chart
            options={getChartOptions(fetchedAsset.countVulnerabilities, true)}
            series={getChartValues(fetchedAsset.countVulnerabilities)}
            height="340"
            type="donut"
          />
        </div>
      )}
      <Modal
        opened={viewExportDocument}
        onClose={() => {
          setViewExportDocument(false);
        }}
        size={'xl'}
        title={
          <>
            <PDFDownloadLink
              document={<PdfExportDocument />}
              fileName={`${fetchedAsset?.name}-${fetchedAsset?.version}-export.pdf`}
            >
              {({ loading }) =>
                loading || isLoading ? (
                  <div className="pdf-modal-download-loading">
                    <Loader
                      variant="dots"
                      size={'xs'}
                      style={{
                        position: 'relative',
                        top: '-2px',
                        marginRight: '5px',
                      }}
                    />
                    <span>{t('pdf-export.loading-preview')}</span>
                  </div>
                ) : (
                  <div className="pdf-modal-download">
                    <Icon icon="arrow-down-to-bracket" />
                    <span>{t('pdf-export.download')}</span>
                  </div>
                )
              }
            </PDFDownloadLink>
          </>
        }
      >
        <div className="pdf-preview">
          {isLoading && <Loading />}
          {!isLoading && (
            <PDFViewer showToolbar={false} style={{ width: '100%', height: '80vh' }}>
              <PdfExportDocument />
            </PDFViewer>
          )}
        </div>
      </Modal>
    </>
  );
};
