import { Button } from '@mantine/core';
import { useEffect, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';
import ClickableRegion from '../../components/ClickableRegion';
import Loading from '../../components/Loading';
import Logo from '../../components/Logo';
import '../../scss/pages/download.scss';

const Download = () => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [link, setLink] = useState('');
  let requestSent = false;
  const token = searchParams.get('token');

  async function tryDownload() {
    if (!requestSent) {
      requestSent = true;
      try {
        const { success, data } = await AxiosProxy.get({
          url: `/download?token=${token}`,
          withCredentials: false,
        });
        setSuccess(success);
        if (!data || !data.link)
          throw new Error('Server returned an unexpected response.');
        setLink(data.link);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setSuccess(false);
      }
    }
  }

  useEffect(() => {
    if (!token) {
      <Navigate to="/" />;
    }
    tryDownload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const visitDownloadLink = () => {
    window.open(link, '_blank');
  };

  return (
    <>
      <Logo wordmark={true} />
      <div className="download-container">
        {loading ? (
          <>
            <h4> Please wait while we fetch your data...</h4>
            <Loading />
          </>
        ) : (
          <>
            {success ? (
              <div className="success-container">
                <h4> Click the button below to download your file.</h4>
                <Button
                  onClick={() => {
                    visitDownloadLink();
                  }}
                >
                  Download
                </Button>
              </div>
            ) : (
              <div className="failure-container">
                <h4> We could not process your request. Your link may have expired.</h4>
                <p>
                  You may close this tab or{' '}
                  <ClickableRegion regionLabel="login" href={'/'}>
                    click here to return home
                  </ClickableRegion>
                  .
                </p>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Download;
