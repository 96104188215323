import { AxiosError } from 'axios';
import { AxiosProxy } from '../../../../../api/axiosProxy/axiosProxy';
import { PORTAL_ERROR_CODES } from '../../../../../api/sharingPortal/sharingPortalErrorCode.contant';
import { rejectFailedRequestOrEmptyData } from '../../../../../lib/request/rejectFailedRequest/rejectFailedRequest';
import { APIResponse } from '../../../../../types/api_response.model';

export interface ApiPortalReceiverResponse {
  portal: {
    _id: string;
    isActive: boolean;
    logoUrl?: string;
    portalName?: string;
    organizationId: string;
  };
}

const getPortal = async (
  portalName: string,
): Promise<ApiPortalReceiverResponse['portal'] | null> => {
  try {
    const response: APIResponse<ApiPortalReceiverResponse> = await AxiosProxy.get({
      url: `sharing-portal/portal/${portalName}`,
    });

    if (
      response.success === false &&
      response.errors &&
      response.errors.includes(PORTAL_ERROR_CODES.NOT_FOUND)
    ) {
      return null;
    }

    return (await rejectFailedRequestOrEmptyData(response)).data.portal;
  } catch (error) {
    if ((error as AxiosError).request.status === 404) {
      return null;
    }
    return Promise.reject(error);
  }
};

export const SharingPortalReceiverApi = {
  getPortal,
};
