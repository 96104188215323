import { MRT_Row } from 'mantine-react-table';
import { InterfaceFullAsset } from '../../../../types/FullInterfaces';
import styles from '../AssetsTable.module.scss';

export const VersionColumn = ({ row }: { row: MRT_Row<InterfaceFullAsset> }) => {
  return (
    <span
      className={`${row.original?.isActive === false ? styles.isInactiveAssetRow : ''}`}
    >
      {row.original?.version}
    </span>
  );
};
