import { InterfaceOrganizationProduct } from '@manifest-cyber/types/interface/dbTables';
import { useQuery } from '@tanstack/react-query';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';
import { captureExceptionWithMessage } from '../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';

export interface ProductQueryParams {
  enabled?: boolean;
  productId?: string;
}

type FetchError = Error;

type Response = {
  success: boolean;
  data: InterfaceOrganizationProduct;
  errors: string[];
};

export const useFetchProduct = ({ enabled = true, productId }: ProductQueryParams) => {
  let fetchUrl = `product/${productId}`;

  const queryKey = [`product`, `/${fetchUrl}`];

  return useQuery<Response, FetchError>({
    enabled: enabled,
    keepPreviousData: true,
    queryKey: queryKey,
    queryFn: () =>
      AxiosProxy.get({ url: fetchUrl }).then((response) => ({
        success: response.success,
        data: response.data[0],
        errors: response.errors,
      })),
    onError: (error: FetchError) => {
      captureExceptionWithMessage(`Unable to fetch product:`, error);
    },
  });
};
