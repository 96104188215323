import '../../i18n'; // i18n
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { HelmetProvider } from 'react-helmet-async';
import { AuthProvider } from '../../hooks/useAuth';
import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { CustomProvider } from 'rsuite';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { theme } from '../../scss/theme';
import { Notifications } from '@mantine/notifications';
import { ThemeProvider, IntlProvider } from '@ory/elements';
import { SBOMDownloadProvider } from '../../contexts/SBOMDownload.context';
import { NetworkErrorProvider } from '../../components/NetworkErrorProvider/NetworkErrorProvider';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 20, // 20 minutes
      staleTime: 10000, // 10 seconds
      retry: 1, // Retry once on network errors
    },
  },
});

export const AppProviders = () => {
  return (
    <MantineProvider withCSSVariables theme={theme('dark')}>
      <ModalsProvider modalProps={{ withCloseButton: false }}>
        <CustomProvider theme="dark">
          <ThemeProvider>
            <IntlProvider>
              <BrowserRouter>
                <QueryParamProvider adapter={ReactRouter6Adapter}>
                  <AuthProvider>
                    <HelmetProvider>
                      <QueryClientProvider client={queryClient}>
                        <NetworkErrorProvider>
                          <SBOMDownloadProvider>
                            <Notifications
                              position="bottom-right"
                              zIndex={2077}
                              limit={5}
                            />
                            <App />
                          </SBOMDownloadProvider>
                          <ReactQueryDevtools position="bottom-right" />
                        </NetworkErrorProvider>
                      </QueryClientProvider>
                    </HelmetProvider>
                  </AuthProvider>
                </QueryParamProvider>
              </BrowserRouter>
            </IntlProvider>
          </ThemeProvider>
        </CustomProvider>
      </ModalsProvider>
    </MantineProvider>
  );
};
