import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';

interface Params {
  organizationId: string;
}

export const useDeleteOrganization = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ organizationId }: Params) => {
      return AxiosProxy.delete({
        url: `organization/${organizationId}`,
        withCredentials: true,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['childOrganizations']);
    },
  });
};
