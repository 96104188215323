import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  ShareResourceApi,
  ShareResourcePayload,
} from '../../api/shareResource/shareResource.api';
import { SHARED_RESOURCE_USERS_CACHE_KEY } from '../useFetchSharedResourceUsers/useFetchSharedResourceUsers';

export const usePostSharedResourceUsers = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: ShareResourcePayload): Promise<void> =>
      ShareResourceApi.shareResource(payload),
    onSuccess: (_, variables) => {
      variables.sbomIds.forEach((id) => {
        queryClient.invalidateQueries({
          queryKey: [SHARED_RESOURCE_USERS_CACHE_KEY, id],
        });
      });
    },
  });
};
