import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CustomFieldsApi } from './customFields.api';
import { CUSTOM_FIELDS_CACHE_KEY } from './useGetCustomFields';

export const useCreateCustomField = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: Parameters<typeof CustomFieldsApi.createCustomField>[0]) =>
      CustomFieldsApi.createCustomField(params),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: [CUSTOM_FIELDS_CACHE_KEY, variables.body.field],
      });
    },
    onMutate: async (variables) => {
      await queryClient.cancelQueries({
        queryKey: [CUSTOM_FIELDS_CACHE_KEY, variables.body.field],
      });

      const previousFields = queryClient.getQueryData<string[]>([
        CUSTOM_FIELDS_CACHE_KEY,
        variables.body.field,
      ]);

      const newFields = previousFields
        ? [...previousFields, variables.body.value]
        : [variables.body.value];

      queryClient.setQueryData(
        [CUSTOM_FIELDS_CACHE_KEY, variables.body.field],
        newFields,
      );

      return { previousFields, newFields };
    },
    onError: (_, payload, context) => {
      queryClient.setQueryData(
        [CUSTOM_FIELDS_CACHE_KEY, payload.body.field],
        context?.previousFields ? context?.previousFields : [],
      );
    },
  });
};
