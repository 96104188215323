import { ManifestApiResponse } from '@manifest-cyber/types/interface/apiResponse';
import { AxiosProxy } from '../../../../../api/axiosProxy/axiosProxy';
import { buildUrlWithParams } from '../../../../../lib/url/buildWithParams/buildWithParams';
import {
  SharedSbomsApiResponse,
  SharedSbomsMappedResponse,
} from '../../types/sharedSboms.interface';

import { rejectFailedRequestOrEmptyData } from '../../../../../lib/request/rejectFailedRequest/rejectFailedRequest';
import { ManifestApiFilterParam } from '../../../../../types/manifestApiFilterParam.interface';
import { mapSharedSboms, MapSharedSbomsTexts } from './sharedSboms.mapper';

interface GetUrlParams {
  limit?: number;
  page?: number;
  sort?: {
    column: string;
    type: 1 | -1;
  };
  portalName: string;
  filters?: ManifestApiFilterParam[];
}

const getUrl = ({ limit, page, sort, portalName, filters }: GetUrlParams): string => {
  const sortParam = sort
    ? {
        [sort.column]: sort.type,
        _id: sort.type,
      }
    : undefined;

  return buildUrlWithParams(`sharing-portal/${portalName}/sboms`, {
    limit,
    page,
    sort: sortParam,
    filters,
  });
};

export interface GetSharedSbomsParams {
  requestOptions: GetUrlParams;
  texts?: MapSharedSbomsTexts;
}

const getElements = async ({
  texts,
  requestOptions,
}: GetSharedSbomsParams): Promise<SharedSbomsMappedResponse> => {
  const response: SharedSbomsApiResponse = await AxiosProxy.get({
    url: SharedSbomsApi.getUrl(requestOptions),
  });

  if (!response.success) {
    return Promise.reject(response);
  }

  return mapSharedSboms(response, texts);
};

export interface DownloadSharedSbomResponse {
  fileName: string;
  sbomId: string;
  url: string;
}

const downloadSbom = async (
  sbomId: string,
  organizationId: string,
): Promise<DownloadSharedSbomResponse> => {
  const response: ManifestApiResponse<DownloadSharedSbomResponse[]> =
    await AxiosProxy.get({
      url: `sharing-portal/${organizationId}/${sbomId}/download`,
      withCredentials: true,
      params: {},
      headers: {},
      signal: undefined,
      options: {
        responseType: 'json',
      },
    });

  const { data } = await rejectFailedRequestOrEmptyData(response);
  const parsedResponse = data[0];
  if (!parsedResponse) {
    throw new Error('No data found');
  }

  return parsedResponse;
};

const downloadSboms = async (
  sbomIds: string[],
  organizationId: string,
): Promise<BlobPart> => {
  const response = (await AxiosProxy.get({
    url: `sharing-portal/${organizationId}/download/bundle?sbomIds=${sbomIds.join(',')}`,
    withCredentials: true,
    params: {},
    headers: {},
    signal: undefined,
    options: { responseType: 'blob' },
  })) as BlobPart | ManifestApiResponse<[]>;

  /* When the endpoint fails, returns a json not  blob  */
  if ((response as ManifestApiResponse<[]>)?.success === false) {
    throw response;
  }

  return response as BlobPart;
};

export const SharedSbomsApi = {
  getElements,
  getUrl,
  downloadSbom,
  downloadSboms,
};
