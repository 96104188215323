import { InterfaceLicense } from '@manifest-cyber/types/interface/dbTables';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';

interface Params {
  license: InterfaceLicense;
}

export const usePutLicense = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ license }: Params) => {
      return AxiosProxy.put({
        url: `license/${license._id}`,
        withCredentials: true,
        body: {
          ...license,
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['licenses']);
    },
  });
};
