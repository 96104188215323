import { AxiosProxy } from '../../../../../api/axiosProxy/axiosProxy';
import { sortByLasUpdated } from '../../../../../lib/list/sortByLastUpdated';
import {
  MappedSbomAlert,
  SbomAlertResponse,
  SbomAlertsResponse,
} from '../../types/SBOMAlerts.interface';
import { mapSbomAlert } from './sbomAlert.mapper';

export interface GetSbomAlertReturnValue {
  mappedAlert: MappedSbomAlert;
  rawAlert: SbomAlertResponse['data'];
}

const getSbomAlert = async (alertId: string): Promise<GetSbomAlertReturnValue> => {
  const data = await AxiosProxy.get({
    url: `organization/notificationSettings/${alertId}`,
  });

  if (!data.success) {
    return Promise.reject(data.errors);
  }

  return { mappedAlert: mapSbomAlert(data.data), rawAlert: data.data };
};

export interface GetSbomAlertsReturnValue {
  rawAlerts: SbomAlertsResponse['data'];
  mappedAlerts: MappedSbomAlert[];
}

const getSbomAlerts = async (
  notificationEventId: string,
): Promise<GetSbomAlertsReturnValue> => {
  const data: SbomAlertsResponse = await AxiosProxy.get({
    url: `organization/notificationSettings?notificationEventId=${notificationEventId}`,
  });

  if (data.errors.length > 0 || !data.data) {
    return Promise.reject(data.errors);
  }

  return {
    mappedAlerts: sortByLasUpdated(data.data.map((alert) => mapSbomAlert(alert))),
    rawAlerts: data.data,
  };
};

export const SbomUploadAlertsApi = {
  getSbomAlert,
  getSbomAlerts,
};
