import { ManifestApiResponse } from '@manifest-cyber/types/interface/apiResponse';
import { TFunction } from 'i18next';
import { AxiosProxy } from '../../../../api/axiosProxy/axiosProxy';
import {
  CustomField,
  MappedPageConfig,
  PageConfig,
} from '../../../../api/customFields/pageConfig.interface';
import { rejectFailedRequestOrEmptyData } from '../../../../lib/request/rejectFailedRequest/rejectFailedRequest';
import {
  ComponentsColumnId,
  mapComponentsPageConfig,
} from './componentsPageConfig.mapper';

const getComponentsPageConfig = async ({
  t,
}: {
  t: TFunction;
}): Promise<MappedPageConfig<ComponentsColumnId>> => {
  try {
    const response: ManifestApiResponse<string[]> = await AxiosProxy.get({
      url: `/user/customfields/component-page`,
    });

    const successfulResponse = await rejectFailedRequestOrEmptyData(response);
    const data = successfulResponse.data[0];
    const config = data ? JSON.parse(data) : undefined;

    return mapComponentsPageConfig({
      data: config,
      t,
    });
  } catch (error) {
    return mapComponentsPageConfig({
      t,
    });
  }
};

const updateComponentsPageConfig = async (
  body: PageConfig<ComponentsColumnId>,
): Promise<PageConfig<ComponentsColumnId>> => {
  const response: ManifestApiResponse<CustomField> = await AxiosProxy.put({
    url: `/organization/customfields`,
    withCredentials: true,
    body: {
      field: 'component-page',
      value: JSON.stringify(body),
    },
  });

  const successfulResponse = await rejectFailedRequestOrEmptyData(response);

  return JSON.parse(successfulResponse.data.value) as PageConfig<ComponentsColumnId>;
};

export const ComponentsPageConfigApi = {
  getComponentsPageConfig,
  updateComponentsPageConfig,
};
