const browserNavigate = (url: string) => {
  window.location.href = url;
};

const getLocation = (): Location => window.location;

export const LocationUtils = {
  browserNavigate,
  getLocation,
};
