import { Logger } from '../sentry/captureExceptionWithMessage/captureExceptionWithMessage';

export type LocalStorageKey =
  | 'manifest-user'
  | 'lastPath'
  | 'previousorgid'
  | 'login-email'
  | `${string}-sortCol`
  | `${string}-sortDesc`
  | `${string}-pageSize`
  | 'manifest-theme';

const getItem = <T,>(key: LocalStorageKey): T | undefined => {
  try {
    const value = window.localStorage.getItem(key);
    return value ? (JSON.parse(value) as T) : undefined;
  } catch (err) {
    Logger.captureExceptionWithMessage(
      `Error getting item from localStorage: ${key}`,
      err,
    );
  }
};

const setItem = <T,>(key: LocalStorageKey, value: T): void => {
  try {
    window.localStorage.setItem(key, JSON.stringify(value || ''));
  } catch (err) {
    Logger.captureExceptionWithMessage(
      `Error setting item to localStorage: ${key}. value: ${JSON.stringify(value)}`,
      err,
    );
  }
};

const removeItem = <T,>(key: LocalStorageKey): void => {
  try {
    window.localStorage.removeItem(key);
  } catch (err) {
    Logger.captureExceptionWithMessage(
      `Error removing item from localStorage: ${key}`,
      err,
    );
  }
};

export const LocalStorageUtils = {
  getItem,
  setItem,
  removeItem,
};
