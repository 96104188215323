import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';

export const usePostChildOrganization = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (name: string) => {
      return AxiosProxy.post({
        url: `organization/child`,
        withCredentials: true,
        body: {
          name,
        },
      });
    },
    onSuccess: () => {
      // Invalidate user.
      queryClient.invalidateQueries(['childOrganizations']);
      queryClient.invalidateQueries(['currentOrganization']);
      queryClient.invalidateQueries(['user']);
    },
  });
};
