import { ActionIcon, Flex, Switch, Title, Tooltip } from '@mantine/core';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, Navigate } from 'react-router-dom';
import ClickableRegion from '../../components/ClickableRegion';
import { OrganizationUsersTable } from '../../components/DataTables/OrganizationUsersTable';
import Icon from '../../components/Icon/Icon';
import { usePostOrganization } from '../../hooks/mutations/usePostOrganization';
import { useFetchOrganization } from '../../hooks/queries/useFetchOrganization';
import { useAuth } from '../../hooks/useAuth';
import { useOrganizationId } from '../../hooks/utils/useOrganizationId';
import representOrganization from '../../lib/representOrganization';
import '../../scss/pages/people-orgs.scss';

export const OrganizationUsers = () => {
  const { t } = useTranslation();
  const { user, checkUserAccess } = useAuth();
  const [currentOrgId] = useOrganizationId(null);
  const { data: fetchedCurrentOrganization, isLoading: isLoadingCurrentOrganization } =
    useFetchOrganization({ organizationId: currentOrgId });

  const hasWriteAccess = checkUserAccess('write');

  const hasEditPermissions = useMemo(
    () =>
      Boolean(
        (!!user?.internalRoles?.length &&
          (user?.internalRoles?.includes('staff') ||
            user?.internalRoles?.includes('admin'))) ||
          (!!user?.isAdminOfOrganizations?.length &&
            currentOrgId &&
            user?.isAdminOfOrganizations?.includes(currentOrgId)),
      ),
    [user],
  );

  const { mutateAsync: postOrganization, isLoading: isUpdatingOrg } =
    usePostOrganization();

  const handleChangeInviteOnly = async (value: boolean) => {
    // @ts-ignore
    await postOrganization({ organization: { enableInviteOnly: !value } });
  };

  const isParentOrg =
    !fetchedCurrentOrganization?.parent || fetchedCurrentOrganization?.parent?.length < 1;
  const parentOrgId = user?.organizationData?.[`${currentOrgId}`]?.parent;

  return (
    <div className="page-people anim-slideInDownShort">
      {!hasEditPermissions && <Navigate to="/settings" />}
      {/* If we have a parent and we are able to fetch parent org data, show breadcrumbs */}
      {/* TODO: check why parentOrgId array is used as organizationData key */}
      {!isParentOrg &&
        parentOrgId &&
        user?.organizationData?.[parentOrgId as unknown as string] && (
          <div className="breadcrumbs">
            <ClickableRegion
              regionLabel={t('page.userSettings.organization.people.breadcrumbs.parent', {
                parentOrgName:
                  user?.organizationData?.[parentOrgId as unknown as string]?.name,
              })}
              className="breadcrumbs-parent"
              onClick={() =>
                representOrganization(
                  user?.organizationData?.[`${currentOrgId}`]?.parent?.[0] ?? '',
                )
              }
            >
              {parentOrgId &&
                user?.organizationData?.[parentOrgId as unknown as string]?.name}
            </ClickableRegion>
            <span className="breadcrumbs-divider">/</span>
            <Tooltip
              label={t('page.userSettings.organization.people.breadcrumbs.child', {
                currentOrgName: user?.organizationData?.[currentOrgId || '']?.name,
              })}
            >
              <span>{user?.organizationData?.[currentOrgId || '']?.name}</span>
            </Tooltip>
          </div>
        )}
      <Flex justify="space-between">
        <div className="header-flex">
          <Title order={2}>{t('page.userSettings.organization.people.header')}</Title>
        </div>
      </Flex>
      <p>
        {user &&
          (user.organizationData?.[`${currentOrgId}`]?.parent?.length ?? 0) > 0 &&
          t('page.userSettings.organization.people.description-child')}
        {user &&
          (user.organizationData?.[`${currentOrgId}`]?.parent?.length ?? 0) < 1 && (
            <Trans i18nKey={'page.userSettings.organization.people.description'}>
              Manage details and permissions of people in your organization. Parent
              organization admins can create sub-organizations on the{' '}
              <Link to={'../suborganizations'}>Sub-Orgs</Link> tab.
            </Trans>
          )}
      </p>

      {hasEditPermissions && isParentOrg && (
        <div className="invite-only-switch">
          <Switch
            aria-label={t('page.userSettings.organization.people.invite-only-toggle')}
            label={
              <Trans
                i18nKey={`page.userSettings.organization.people.invite-only-${
                  user &&
                  (user.organizationData?.[currentOrgId || '']?.domains?.length ?? 0) > 0
                    ? 'toggle'
                    : 'no-domains'
                }`}
                values={{
                  domain:
                    user?.organizationData?.[currentOrgId || '']?.domains?.join(', '),
                }}
              >
                Allow anyone from
                <strong className="color-emphasis">
                  {user?.organizationData?.[currentOrgId || '']?.domains?.[0]}
                </strong>{' '}
                to join
              </Trans>
            }
            disabled={Boolean(
              isUpdatingOrg ||
                isLoadingCurrentOrganization ||
                !hasWriteAccess ||
                (user &&
                  (user.organizationData?.[currentOrgId || '']?.domains?.length ?? 0) <
                    1),
            )}
            style={{ marginRight: 10 }}
            checked={fetchedCurrentOrganization?.enableInviteOnly ? false : true}
            onChange={(e) => handleChangeInviteOnly(e.target.checked)}
          />
          <Tooltip
            multiline
            width={300}
            label={t('page.userSettings.organization.people.invite-tooltip')}
          >
            <ActionIcon color="gray.5">
              <Icon icon="circle-info" />
            </ActionIcon>
          </Tooltip>
        </div>
      )}

      <OrganizationUsersTable
        hasEditPermissions={hasEditPermissions && hasWriteAccess}
        isParentOrg={isParentOrg}
        org={fetchedCurrentOrganization}
      />
    </div>
  );
};

export default OrganizationUsers;
