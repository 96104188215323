import { InterfaceOrganizationProduct } from '@manifest-cyber/types/interface/dbTables';
import { Badge, Flex, Select, SelectItem, Text } from '@mantine/core';
import classNames from 'classnames';
import {
  MRT_ColumnDef,
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import BasicSearch from '../../../../../components/BasicSearch';
import ClickableRegion from '../../../../../components/ClickableRegion';
import DataTableFooter from '../../../../../components/DataTables/DataTableFooter';
import { getDefaultTableOptions } from '../../../../../components/MRT/ManifestMRT';
import { useFetchVulnProducts } from '../../../../../hooks/queries/useFetchVulnProducts';
import { renderText } from '../../../../Components/ComponentsTable';
import { OverviewTablesEmptyState } from '../OverviewTablesEmptyState';
import {
  ProductRelationshipFilter,
  useVulnAffectedProductsFilters,
} from './useVulnAffectedProductsFilters';

const MAX_PRODUCT_VERSION_LINK_LENGTH = 30;

interface VulnerabilityAffectedProductsProps {
  vulnId: string;
}

const defaultTableOptions = getDefaultTableOptions<InterfaceOrganizationProduct>();

export const VulnerabilityAffectedProducts = ({
  vulnId,
}: VulnerabilityAffectedProductsProps) => {
  const { t } = useTranslation();

  const {
    handleSetProductSearchFilter,
    pagination,
    productRelationshipFilter,
    productSearchFilter,
    setPagination,
    setProductRelationshipFilter,
    setSorting,
    sorting,
  } = useVulnAffectedProductsFilters();

  const { data, isFetching, isInitialLoading } = useFetchVulnProducts(
    vulnId,
    productSearchFilter,
    productRelationshipFilter,
    pagination,
    sorting,
  );

  const affectedProducts = data?.data;
  const totalCount = data?.count;

  const ProductRelationshipFilterSelectOptions = useMemo<SelectItem[]>(() => {
    return [
      {
        value: ProductRelationshipFilter.all,
        label: t('page.vulnerability.details.overview-tab.products-table.filter.all'),
      },
      {
        value: ProductRelationshipFilter.firstParty,
        label: t(
          'page.vulnerability.details.overview-tab.products-table.filter.first-party',
        ),
      },
      {
        value: ProductRelationshipFilter.thirdParty,
        label: t(
          'page.vulnerability.details.overview-tab.products-table.filter.third-party',
        ),
      },
    ];
  }, []);

  const isEmptyTable = Boolean(!affectedProducts?.length);

  const columns = useMemo<MRT_ColumnDef<InterfaceOrganizationProduct>[]>(() => {
    if (isEmptyTable && !isFetching) {
      return [];
    }

    return [
      {
        accessorFn: (row) => row.name,
        id: 'product_name',
        header: t('page.vulnerability.details.overview-tab.products-table.product-name'),
        Cell: ({ row }) => {
          const { _id, name } = row.original;

          let normalizedName = name || '';

          if (normalizedName?.length > MAX_PRODUCT_VERSION_LINK_LENGTH) {
            normalizedName = `${normalizedName?.slice(
              0,
              MAX_PRODUCT_VERSION_LINK_LENGTH,
            )}...`;
          }

          return (
            <ClickableRegion
              href={`/product/${_id}/assets`}
              className="table-link"
              regionLabel={name}
              tooltipMultiline
            >
              {normalizedName}
            </ClickableRegion>
          );
        },
      },
      {
        accessorFn: (row) => row.version,
        id: 'product_version',
        header: t(
          'page.vulnerability.details.overview-tab.products-table.product-version',
        ),
        Cell: ({ row }) => {
          return renderText(row.original?.version || '');
        },
      },
      {
        accessorFn: (row) => row.manufacturer,
        id: 'product_manufacturer',
        header: t(
          'page.vulnerability.details.overview-tab.products-table.product-manufacturer',
        ),
        Cell: ({ row }) => {
          return (
            <Flex gap={8} align="center">
              <Text>{row.original.manufacturer}</Text>
              {row.original.relationship && (
                <Badge
                  mx={0}
                  sx={{
                    width: '35px',
                    '& span': {
                      textOverflow: 'none',
                      overflow: 'visible',
                    },
                  }}
                  className={classNames(
                    row.original.relationship === 'third' && 'third-badge',
                  )}
                >
                  {t(`global.${row.original.relationship}`)}
                </Badge>
              )}
            </Flex>
          );
        },
      },
    ];
  }, [isEmptyTable, isFetching]);

  const table = useMantineReactTable<InterfaceOrganizationProduct>({
    ...defaultTableOptions,
    mantinePaperProps: {
      className: classNames(
        'manifest-data-table-no-footer table-card products-table',
        isEmptyTable && 'empty-table',
      ),
      sx: {
        boxShadow: 'none',
      },
    },
    columns,
    data: affectedProducts || [],
    enableFilters: false,
    enablePagination: false,
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    rowCount: affectedProducts?.length,
    initialState: {
      ...defaultTableOptions.initialState,
      showGlobalFilter: false,
    },
    state: {
      pagination: {
        ...pagination,
        pageSize:
          isInitialLoading || (isFetching && !affectedProducts?.length)
            ? 2
            : pagination.pageSize,
      },
      isLoading: isFetching,
      sorting,
      showLoadingOverlay: isFetching,
    },
    onPaginationChange: setPagination,
    mantineTableBodyRowProps: {
      className: 'table-row',
    },
    mantineTableHeadCellProps: {
      className: 'table-header',
    },
    renderTopToolbar: () => (
      <Flex bg="transparent" mb={8} gap={8}>
        <Select
          data={ProductRelationshipFilterSelectOptions}
          value={productRelationshipFilter}
          onChange={(value) => {
            if (value) {
              setProductRelationshipFilter(value);
            }
          }}
          clearable={false}
          allowDeselect={false}
          className="select-input"
        />
        <BasicSearch
          handleSearch={handleSetProductSearchFilter}
          placeholder={t('global.search')}
          disabled={!affectedProducts}
          initialValue={productSearchFilter}
        />
      </Flex>
    ),
    renderEmptyRowsFallback: () => (
      <OverviewTablesEmptyState
        context={t('page.vulnerability.details.overview-tab.products').toLowerCase()}
        searchFilter={productSearchFilter}
        vendorFilter={productRelationshipFilter}
      />
    ),
    onSortingChange: setSorting,
  });

  return (
    <>
      <MantineReactTable table={table} />
      <DataTableFooter
        currentPage={pagination.pageIndex}
        limitPerPage={pagination.pageSize}
        totalResults={totalCount || 0}
        onChangePage={setPagination}
      />
    </>
  );
};
