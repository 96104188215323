import { rejectFailedRequestOrEmptyData } from '../../lib/request/rejectFailedRequest/rejectFailedRequest';
import { UserApiResponse } from '../../types/FullInterfaces';
import { AxiosProxy } from '../axiosProxy/axiosProxy';
import { MappedUser, mapUser } from './user.mapper';

const getUser = async (): Promise<MappedUser> => {
  const response: UserApiResponse = await AxiosProxy.get({ url: 'user' });

  const successfulResponse = await rejectFailedRequestOrEmptyData(response);
  const user = successfulResponse.data?.[0];

  if (!user) {
    throw new Error('No user found');
  }

  return mapUser(user);
};

export const UserApi = {
  getUser,
};
