import { MRT_PaginationState, MRT_SortingState } from 'mantine-react-table';
import { useEffect, useState } from 'react';
import {
  ArrayParam,
  BooleanParam,
  NumberParam,
  StringParam,
  UrlUpdateType,
  useQueryParam,
} from 'use-query-params';
import { useLocalStorage } from './useLocalStorage';

export const useURLFilterBooleanState = (paramId: string, defaultState: boolean) => {
  const [filterParam, setFilterParam] = useQueryParam(paramId, BooleanParam);

  const [filter, setFilter] = useState<boolean>(filterParam ?? defaultState);

  useEffect(() => {
    setFilterParam(filter === defaultState ? undefined : filter ?? undefined);
  }, [filter]);

  return [filter, setFilter] as const;
};

export const useURLFilterNumberState = (paramId: string, defaultState: number) => {
  const [filterParam, setFilterParam] = useQueryParam(paramId, NumberParam);

  const [filter, setFilter] = useState<number>(filterParam ?? defaultState);

  useEffect(() => {
    setFilterParam(filter === defaultState ? undefined : filter ?? undefined);
  }, [filter]);

  return [filter, setFilter] as const;
};

export const useURLFilterStringState = (
  paramId: string,
  defaultState: string,
  urlUpdateType: UrlUpdateType = 'pushIn',
) => {
  const [filterParam, setFilterParam] = useQueryParam(paramId, StringParam);

  const [filter, setFilter] = useState<string>(filterParam ?? defaultState);

  useEffect(() => {
    setFilterParam(
      filter === defaultState ? undefined : filter?.length ? filter : undefined,
      urlUpdateType,
    );
  }, [filter]);

  return [filter, setFilter] as const;
};

export const useURLFilterArrayState = (paramId: string, defaultState: string[]) => {
  const [filterParam, setFilterParam] = useQueryParam(paramId, ArrayParam);

  const [filter, setFilter] = useState<string[]>(
    (filterParam as string[]) ?? defaultState,
  );

  useEffect(() => {
    setFilterParam(
      filter?.length ? filter : filter === defaultState ? undefined : filter,
    );
  }, [filter]);

  return [filter, setFilter] as const;
};

export const useLocalStoragePaginationState = (
  tableId: string,
  defaultState: MRT_PaginationState,
) => {
  const [storedPageSize, setStoredPageSize] = useLocalStorage<number>(
    `${tableId}-pageSize`,
    defaultState.pageIndex,
  );

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: defaultState.pageIndex,
    pageSize: +(storedPageSize || 0) || defaultState.pageSize,
  });

  useEffect(() => {
    setStoredPageSize(pagination.pageSize);
  }, [pagination]);

  return [pagination, setPagination] as const;
};

export const useURLandLocalStorageSortingState = (
  tableId: string,
  defaultState: MRT_SortingState,
  urlUpdateType: UrlUpdateType = 'pushIn',
) => {
  const [sortColumnParam, setSortColumnParam] = useQueryParam('sortCol', StringParam);
  const [sortDescParam, setSortDescParam] = useQueryParam('sortDesc', BooleanParam);
  const [storedSortColumn, setStoredSortColumn] = useLocalStorage<string>(
    `${tableId}-sortCol`,
    defaultState[0]?.id,
  );
  const [storedSortDesc, setStoredSortDesc] = useLocalStorage<boolean>(
    `${tableId}-sortDesc`,
    defaultState[0]?.desc,
  );
  const columnId = sortColumnParam ?? storedSortColumn ?? defaultState[0]?.id;

  const [sorting, setSorting] = useState<MRT_SortingState>(
    columnId
      ? [
          {
            id: columnId,
            desc: sortDescParam ?? storedSortDesc ?? defaultState[0]?.desc ?? false,
          },
        ]
      : defaultState,
  );

  useEffect(() => {
    setSortColumnParam(
      sorting?.[0]?.id === defaultState[0]?.id ? undefined : sorting?.[0]?.id,
      urlUpdateType,
    );
    setSortDescParam(
      sorting?.[0]?.desc === defaultState[0]?.desc ? undefined : sorting?.[0]?.desc,
      urlUpdateType,
    );
    setStoredSortColumn(sorting?.[0]?.id);
    setStoredSortDesc(sorting?.[0]?.desc);
  }, [sorting]);

  return [sorting, setSorting] as const;
};
