import * as Sentry from '@sentry/react';
import { Replay } from '@sentry/replay';
import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import { createRoot } from 'react-dom/client';
import appConfig from './configs/appConfig';
import { captureExceptionWithMessage } from './lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';
import { AppProviders } from './pages/App/AppProviders';

if (appConfig?.sentryDSN) {
  /* To make sure sentry never is enabled even when running locally pointing to development api (yarn run development) */
  const isEnabled =
    !window.location.origin.startsWith('http://localhost') &&
    !window.location.origin.startsWith('http://local.manifestcyber.com') &&
    appConfig.isSentryEnabled;

  Sentry.init({
    enabled: isEnabled,
    dsn: appConfig?.sentryDSN,
    environment: appConfig?.appEnv,
    release: appConfig?.currentVersion || 'unknown',
    integrations: [
      new BrowserTracing(),
      new Replay({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    //@ts-ignore
    replaysSessionSampleRate: appConfig?.env === 'production' ? 0.1 : 1.0,

    // Send all error replays to Sentry
    replaysOnErrorSampleRate: 1.0,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    //@ts-ignore
    tracesSampleRate: appConfig?.env === 'production' ? 0.2 : 1.0,
  });
} else {
  captureExceptionWithMessage('No Sentry DSN, sentry not initialized!', null);
}

// Create & Hook App
const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <AppProviders />
      <div className="mantine-tooltips" />
    </React.StrictMode>,
  );
} else {
  captureExceptionWithMessage('Unable to find root to render document!', null);
}
