import { AxiosProxy } from '../api/axiosProxy/axiosProxy';
import { HistoryUtils } from './history/history';
import { LocationUtils } from './location/location';
import { captureExceptionWithMessage } from './sentry/captureExceptionWithMessage/captureExceptionWithMessage';

/**
 * Request to represent a new organization
 * @param orgId
 */
export const representOrganization = async (orgId: string) => {
  if (!orgId || orgId.length < 1) {
    captureExceptionWithMessage('Unable to find orgId to represent');
    return; // Early return if orgId is invalid
  }

  let changedOrg = false;
  const currentOrgId = window.localStorage.getItem('organizationid');

  if (currentOrgId !== orgId) {
    try {
      const attempt = await AxiosProxy.get({ url: `organization/choose/${orgId}` });
      if (attempt && attempt.success) {
        window.localStorage.setItem('organizationid', JSON.stringify(orgId));
        changedOrg = true;
      } else {
        // Handle unsuccessful attempt to switch organizations
        captureExceptionWithMessage('Failed to switch organizations', attempt);
      }
    } catch (e) {
      captureExceptionWithMessage('Error during organization switch', e);
    }
  }

  // Remove the organizationId from the URL search params if present to prevent infinite loop
  const browserLocation = LocationUtils.getLocation();
  const url = new URL(browserLocation.href);
  const params = new URLSearchParams(url.search);
  params.delete('organizationId');

  if (changedOrg) {
    // Redirect to root '/' after successful organization switch
    LocationUtils.browserNavigate(`${browserLocation.origin}/`);
  } else {
    HistoryUtils.replaceState({
      data: {},
      unused: '',
      url: `${browserLocation.origin}${browserLocation.pathname}?${params.toString()}`,
    });
  }
};

export default representOrganization;
