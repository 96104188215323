import {
  IRCategoryObject,
  IRCategoryObjectForKev,
} from '@manifest-cyber/types/interface/db';
import { InterfaceOrganizationSlaSetting } from '@manifest-cyber/types/interface/dbTables';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePostSLAs } from '../../../../hooks/mutations/usePostSLAs';
import { useNotifications } from '../../../../hooks/utils/useNotifications';
import { captureExceptionWithMessage } from '../../../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';
import { checkOverlap, generateMarks, initializeFormData } from './methods';

interface Props {
  onClose: VoidFunction;
  rowData?: IRCategoryObject | IRCategoryObjectForKev | null;
  vulnerabilityRisk: string;
  organizationSlaSetting?: InterfaceOrganizationSlaSetting;
}

interface FormState {
  formData: IRCategoryObject | IRCategoryObjectForKev | undefined;
  remediationTime: { number: number; unit: string };
  criteriaRange: [number, number];
  overlapError: string | null;
}

export const useEditSla = ({
  onClose,
  rowData,
  vulnerabilityRisk,
  organizationSlaSetting,
}: Props) => {
  const { t } = useTranslation();
  const { mutate } = usePostSLAs();
  const { error } = useNotifications();

  const [formState, setFormState] = useState<FormState>({
    formData: rowData || {},
    remediationTime: { number: 0, unit: 'd' },
    criteriaRange: [0, 0],
    overlapError: null,
  });

  useEffect(() => {
    initializeFormData(
      rowData,
      (formData) => setFormState((prevState) => ({ ...prevState, formData })),
      (remediationTime) =>
        setFormState((prevState) => ({ ...prevState, remediationTime })),
      (criteriaRange) => setFormState((prevState) => ({ ...prevState, criteriaRange })),
    );
  }, [rowData]);

  useEffect(() => {
    if (
      organizationSlaSetting &&
      organizationSlaSetting.riskCategory &&
      formState.criteriaRange.length
    ) {
      const isOverlap = checkOverlap(
        formState.criteriaRange,
        vulnerabilityRisk,
        organizationSlaSetting.riskCategory,
      );
      setFormState((prevState) => ({
        ...prevState,
        overlapError: isOverlap ? t('page.sla.overlapError') : null,
      }));
    }
  }, [formState.criteriaRange, organizationSlaSetting, vulnerabilityRisk, t]);

  const handleChange = (
    field: keyof IRCategoryObject | keyof IRCategoryObjectForKev,
    value: any,
  ) => {
    setFormState((prevState) => ({
      ...prevState,
      formData: { ...prevState.formData, [field]: value },
    }));
  };

  const handleSave = () => {
    const { formData, overlapError } = formState;
    if (formData) {
      const isCriteriaBoolean = typeof formData.criteria === 'boolean';

      if (!isCriteriaBoolean && overlapError) {
        return;
      }

      const updatedFormData = {
        ...formData,
        remediationTimeFrame: `${formState.remediationTime.number}${formState.remediationTime.unit}`,
        criteria: vulnerabilityRisk === 'onKev' ? true : formState.criteriaRange,
      };

      if (organizationSlaSetting && organizationSlaSetting.riskCategory) {
        const updatedOrganizationSlaSetting = {
          ...organizationSlaSetting,
          riskCategory: {
            ...organizationSlaSetting.riskCategory,
            [vulnerabilityRisk]: updatedFormData,
          },
          dateModified: new Date(),
        };

        mutate(updatedOrganizationSlaSetting, {
          onSuccess: (response) => {
            if (response.errors.length) {
              error({
                title: t('page.sla.name'),
                message: t('page.sla.requestFail'),
              });
            }
          },
          onError: () => {
            error({
              title: t('page.sla.name'),
              message: t('page.sla.requestFail'),
            });
          },
        });
      } else {
        captureExceptionWithMessage(
          'Organization SLA Setting or riskCategory is undefined',
        );
      }
    }
    onClose();
  };

  const isBooleanCriteria = useMemo(
    () => typeof formState.formData?.criteria === 'boolean',
    [formState.formData?.criteria],
  );

  const criteria = useMemo(
    () => t(`page.sla.${vulnerabilityRisk}`),
    [t, vulnerabilityRisk],
  );

  const marks = useMemo(() => generateMarks(1, 9, 1), []);

  const isSaveDisabled = useMemo(() => {
    const { formData, remediationTime, overlapError } = formState;
    const isCriteriaBoolean = typeof formData?.criteria === 'boolean';
    if (isCriteriaBoolean) {
      return !remediationTime.number || !remediationTime.unit;
    } else {
      return (
        !formData?.criteria ||
        !remediationTime.number ||
        !remediationTime.unit ||
        !!overlapError
      );
    }
  }, [formState]);

  return {
    formState,
    setFormState,
    isBooleanCriteria,
    criteria,
    marks,
    isSaveDisabled,
    handleChange,
    handleSave,
  };
};
