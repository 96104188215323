import { useQuery } from '@tanstack/react-query';
import { captureExceptionWithMessage } from '../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';
import { getAssetVulnerabilitiesTriage } from './vulnerabilities.api';

export const useFetchVulnerabilitiesTriages = (assetID: string) => {
  const { isError, isLoading, data } = useQuery({
    queryKey: [`vulnerabilities_triages_${assetID}`],
    queryFn: () => getAssetVulnerabilitiesTriage(assetID),
    onError: (error) => {
      captureExceptionWithMessage(`Unable to fetch vulnerability:`, error);
    },
    select: (data) => {
      if (data.success) {
        return data.data;
      }
      if (data.errors && data.errors.length > 0) {
        captureExceptionWithMessage(`error useFetchVulnerabilitiesTriages:`, data.errors);
      }
    },
    staleTime: 30 * 1000,
  });
  return { isError, isLoading, data };
};
