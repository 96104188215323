import { Loader } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';
import { useAuth } from '../../hooks/useAuth';
import { sdk } from '../../lib/orySDK';
import { captureExceptionWithMessage } from '../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';

const Logout = () => {
  const { logout } = useAuth();
  const location = useLocation();
  const [logoutUrl, setLogoutUrl] = useState<string>();
  const [ignoreSSO, setIgnoreSSO] = useState<boolean>();

  const { t } = useTranslation();

  const logoutUser = async () => {
    let logoutReason: string | undefined =
      location?.search?.split('reason=')[1] || undefined;
    if (logoutReason) {
      logoutReason = logoutReason?.split('&')[0] ?? undefined;
    }

    const hitProfile = await AxiosProxy.get({ url: 'auth/logout' });

    if (hitProfile && hitProfile.success && hitProfile.data.length > 0) {
      // Do nothing
    } else {
      captureExceptionWithMessage('Unable to log the out on remote...');
    }

    logout?.(logoutReason, { redirectUrl: logoutUrl });
  };

  const createLogoutFlow = () => {
    sdk
      .createBrowserLogoutFlow(undefined, {})
      .then(({ data }) => {
        setLogoutUrl(data.logout_url);
        setIgnoreSSO(false);
      })
      .catch((error) => {
        setIgnoreSSO(true);
      });
  };

  useEffect(() => {
    createLogoutFlow();
  }, []);

  useEffect(() => {
    async function logout() {
      if (ignoreSSO !== undefined) {
        await logoutUser();
      }
    }

    logout();
  }, [ignoreSSO]);

  return (
    <>
      <Loader color="green" />
      <span>{t('page.logout.waiting')}</span>
    </>
  );
};

export default Logout;
