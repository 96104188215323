import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  DeleteAccesToResourceParams,
  MappedUsersWithAccessToSharedSbom,
  ShareResourceApi,
} from '../../api/shareResource/shareResource.api';
import { SHARED_RESOURCE_USERS_CACHE_KEY } from '../useFetchSharedResourceUsers/useFetchSharedResourceUsers';

export const useDeleteSharedResourceUsers = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: DeleteAccesToResourceParams): Promise<void> =>
      ShareResourceApi.deleteAccesToResource(payload),
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries({
        queryKey: [SHARED_RESOURCE_USERS_CACHE_KEY, variables.sbomId],
      });
    },
    onMutate: async (variables) => {
      await queryClient.cancelQueries({
        queryKey: [SHARED_RESOURCE_USERS_CACHE_KEY, variables.sbomId],
      });

      const previousUsers = queryClient.getQueryData<MappedUsersWithAccessToSharedSbom[]>(
        [SHARED_RESOURCE_USERS_CACHE_KEY, variables.sbomId],
      );

      const newUsers = previousUsers?.filter((user) => user.email !== variables.email);

      queryClient.setQueryData(
        [SHARED_RESOURCE_USERS_CACHE_KEY, variables.sbomId],
        newUsers,
      );

      return { previousUsers, newUsers };
    },
    onError: (_, payload, context) => {
      queryClient.setQueryData(
        [SHARED_RESOURCE_USERS_CACHE_KEY, payload.sbomId],
        context?.previousUsers,
      );
    },
  });
};
