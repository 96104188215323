import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';
import { captureExceptionWithMessage } from '../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';

type FetchError = Error;

type Response = {
  success: boolean;
  data: boolean | null;
  errors: string[];
};

export const useCheckProductAssociation = () => {
  const queryClient = useQueryClient();

  return useMutation<Response, FetchError, string[]>(
    (assetIds) =>
      AxiosProxy.post({
        url: 'assets/check-product-associations',
        withCredentials: true,
        body: { assetIds },
      }).then((response) => ({
        success: response.success,
        data: response.data,
        errors: response.errors,
      })),
    {
      onSuccess: (data, assetIds) => {
        queryClient.setQueryData(['checkProductAssociations', assetIds.join(',')], data);
      },
      onError: (error) => {
        captureExceptionWithMessage('Unable to check product associations:', error);
      },
    },
  );
};
