import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';

interface UseDeleteProductProps {
  closeModalCallback: VoidFunction;
}

export const useDeleteProduct = ({ closeModalCallback }: UseDeleteProductProps) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (productId: string) => {
      return AxiosProxy.delete({ url: `product/${productId}`, withCredentials: true });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['products'],
      });
      closeModalCallback();
    },
  });
};
