import { TextComponent } from '@manifest-cyber/design-system/lib/components/TextComponent';
import { Box, Divider, Flex } from '@mantine/core';
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_GlobalFilterTextInput,
  MRT_Row,
  MRT_RowSelectionState,
  useMantineReactTable,
} from 'mantine-react-table';
import { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ActionableCard } from '../../../components/ActionableCard/ActionableCard';
import DataTableFooter from '../../../components/DataTables/DataTableFooter';
import Icon from '../../../components/Icon/Icon';
import Loading from '../../../components/Loading';
import { getDefaultTableOptions } from '../../../components/MRT/ManifestMRT';
import { useNotifications } from '../../../hooks/utils/useNotifications';
import {
  useLocalStoragePaginationState,
  useURLandLocalStorageSortingState,
  useURLFilterStringState,
} from '../../../hooks/utils/usePersistentStates';
import { captureExceptionWithMessage } from '../../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';
import { createAndClickLink } from '../../../lib/url/clickLink';
import { ManifestApiFilterParam } from '../../../types/manifestApiFilterParam.interface';
import { GetSharedSbomsParams, SharedSbomsApi } from './api/sharedSboms/sharedSboms.api';
import { useFetchSharingPortalReceiver } from './api/sharingPortal/useFetchSharingPortalReceiver/useFetchSharingPortalReceiver';
import { DownloadCell } from './components/DowloadCell/DownloadCell';
import { NameCell } from './components/NameCell/NameCell';
import { TableToolbar } from './components/TableToolbar/TableToolbar';
import { useSharedSboms } from './hooks/useSharedSboms/useSharedSboms';
import styles from './SharedSbomsPage.module.scss';
import { MappedSharedAsset } from './types/sharedSboms.interface';
const pageName = 'shared-sboms';

const getCurrentFilters = (params: {
  assetNameToFilter: string;
}): ManifestApiFilterParam[] | undefined => {
  const currentFilters = [];

  if (params.assetNameToFilter) {
    currentFilters.push({ field: 'assetName', value: [params.assetNameToFilter] });
  }

  return currentFilters.length > 0 ? currentFilters : undefined;
};

const SharedSboms = () => {
  const { t } = useTranslation();
  const { error, success } = useNotifications();
  const [pagination, setPagination] = useLocalStoragePaginationState(pageName, {
    pageIndex: 0,
    pageSize: 20,
  });
  const [sorting, setSorting] = useURLandLocalStorageSortingState(pageName, [
    { id: 'dateShared', desc: true },
  ]);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const selectedElementsIds = Object.keys(rowSelection);
  const sortOption = sorting[0];
  const sort: GetSharedSbomsParams['requestOptions']['sort'] = sortOption
    ? { column: sortOption.id, type: sortOption.desc ? -1 : 1 }
    : undefined;

  const texts: GetSharedSbomsParams['texts'] = {
    unknownType: t('global.unknown'),
  };
  const { portalName = '' } = useParams();
  const [isDownloadingMultipleSboms, setIsDownloadingMultipleSboms] = useState(false);
  const navigate = useNavigate();
  const {
    data: portal,
    isError: isErrorLoadingPortal,
    refetch: refetchPortal,
    isLoading: isLoadingPortal,
  } = useFetchSharingPortalReceiver({ portalName });
  const isPortalEnabled = Boolean(portal?.isActive);
  const [assetNameToFilter, setAssetNameToFilter] = useURLFilterStringState(
    'assetName',
    '',
  );
  const filters = getCurrentFilters({ assetNameToFilter });

  const {
    data: {
      sboms: fetchedAssets = [],
      totalSboms = 0,
      totalUnfilteredCount = 0,
      latestSharedDate,
    } = {},
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useSharedSboms(
    {
      requestOptions: {
        sort,
        page: pagination.pageIndex + 1,
        limit: pagination.pageSize,
        portalName,
        filters,
      },
      texts,
    },
    { enabled: isPortalEnabled },
  );

  // Prefetch the next page for faster navigation
  useSharedSboms(
    {
      requestOptions: {
        sort,
        page: pagination.pageIndex + 2,
        limit: pagination.pageSize,
        portalName,
        filters,
      },
      texts,
    },
    {
      enabled: isPortalEnabled,
    },
  );

  const downloadSbom = async ({
    sbomId,
    organizationId,
    assetName,
    assetVersion,
  }: {
    sbomId: string;
    assetName: string;
    assetVersion: string;
    organizationId?: string;
  }) => {
    if (!organizationId || !sbomId) return;

    try {
      const response = await SharedSbomsApi.downloadSbom(sbomId, organizationId);

      createAndClickLink({
        url: response.url,
        fileName: `${assetName}-${assetVersion}.json`,
      });

      success({
        title: t('portal.sboms.downwloadSuccessTitle', { count: 1 }),
        message: t('portal.sboms.downwloadSuccessMessage'),
      });
    } catch (downloadError) {
      error({
        title: t('notifications.sbom-download-error.title'),
        message: t('notifications.sbom-download-error.message'),
      });
      captureExceptionWithMessage('Error downloading SBOMs:', downloadError);
    }
  };

  const downloadSboms = async (sbomIds: string[], organizationId?: string) => {
    if (!organizationId || sbomIds.length === 0 || isDownloadingMultipleSboms) {
      return;
    }

    try {
      setIsDownloadingMultipleSboms(true);

      const response = await SharedSbomsApi.downloadSboms(sbomIds, organizationId);
      const blob = new Blob([response], { type: 'application/zip' });
      const blobUrl = URL.createObjectURL(blob);

      createAndClickLink({
        url: blobUrl,
        fileName: `sbom-bundle-${new Date().toISOString()}.zip`,
      });

      URL.revokeObjectURL(blobUrl);
      success({
        title: t('portal.sboms.downwloadSuccessTitle', { count: sbomIds.length }),
        message: t('portal.sboms.downwloadSuccessMessage'),
      });

      setIsDownloadingMultipleSboms(false);
    } catch (downloadError) {
      setIsDownloadingMultipleSboms(false);

      error({
        title: t('notifications.sbom-download-error.title'),
        message: t('notifications.sbom-download-error.message'),
      });
      captureExceptionWithMessage('Error downloading SBOMs:', downloadError);
    }
  };

  const columns = useMemo<MRT_ColumnDef<MappedSharedAsset>[]>(
    () => [
      {
        enableSorting: false,
        accessorKey: 'assetName',
        header: t('global.name'),
        size: 300,
        Cell: ({ cell, row }) => (
          <Flex gap="8px" align={'center'}>
            <Box>
              <TextComponent color="primary" component="div" inline p="8px">
                <Icon
                  icon="file"
                  fixedWidth={false}
                  iconStyle="fal"
                  className={styles.icon}
                />
              </TextComponent>
            </Box>
            <NameCell title={cell.getValue<string>()} subtitle={row.original.type} />
          </Flex>
        ),
      },
      {
        enableSorting: false,
        accessorFn: (row) => row.assetVersion,
        id: 'assetVersion',
        header: t('global.version'),
        size: 250,
        Cell: ({ row }: { row: MRT_Row<MappedSharedAsset> }) => (
          <span className={styles.text}>{row.original?.assetVersion}</span>
        ),
      },
      {
        enableSorting: false,
        accessorFn: (row) => row.assetVersion,
        id: 'format',
        header: t('global.format'),
        size: 150,
        Cell: ({ row }: { row: MRT_Row<MappedSharedAsset> }) => (
          <span className={styles.text}>{row.original.format}</span>
        ),
      },

      {
        enableSorting: false,
        accessorKey: 'dateShared',
        header: t('portal.sboms.table.headers.dateShared'),
        Cell: ({ row }: { row: MRT_Row<MappedSharedAsset> }) => {
          return <span className={styles.text}>{row.original.dateSharedText}</span>;
        },
      },
      {
        accessorKey: 'id',
        header: '',
        enableSorting: false,
        size: 50,
        Cell: ({ row }) => (
          <DownloadCell
            onClick={() => {
              downloadSbom({
                sbomId: row.original.id,
                assetName: row.original.assetName,
                assetVersion: row.original.assetVersion ?? '',
                organizationId: portal?.organizationId,
              });
            }}
            label={`${t('global.download')} ${t('portal.sboms.table.json')}`}
          />
        ),
      },
    ],
    [t, portal],
  );
  const defaultTableOptions = getDefaultTableOptions<MappedSharedAsset>();

  const table = useMantineReactTable<MappedSharedAsset>({
    ...defaultTableOptions,
    renderTopToolbar: () => (
      <TableToolbar
        SearchBar={<MRT_GlobalFilterTextInput table={table} />}
        onClick={() => {
          downloadSboms(selectedElementsIds, portal?.organizationId);
        }}
        label={t('global.download')}
        showButton={selectedElementsIds.length > 0}
        isLoadingButton={isDownloadingMultipleSboms}
      />
    ),
    mantinePaperProps: {
      className: styles.noFooter,
    },
    layoutMode: 'grid',
    displayColumnDefOptions: {
      'mrt-row-select': {
        size: 50,
        mantineTableHeadCellProps: {
          align: 'center',
          sx: {
            flexGrow: 0,
          },
        },
        mantineTableBodyCellProps: {
          align: 'center',
          sx: {
            flexGrow: 0,
          },
        },
      },
    },
    columns,
    data: fetchedAssets,
    columnFilterDisplayMode: 'custom',
    getRowId: (originalRow) => originalRow.id,
    enableRowActions: false,
    enableRowSelection: true,
    enableFilters: false,
    enablePagination: false,
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    rowCount: totalSboms,
    state: {
      pagination,
      sorting,
      rowSelection,
      isLoading,
      showLoadingOverlay: false,
      globalFilter: assetNameToFilter,
    },
    initialState: {
      showGlobalFilter: true,
      pagination,
      globalFilter: assetNameToFilter,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    onGlobalFilterChange: setAssetNameToFilter,
    mantineSearchTextInputProps: {
      placeholder: t('portal.sboms.searchSboms'),
      sx: {
        minWidth: '260px',
      },
      classNames: {
        input: styles.searchBarInput,
      },
    },
  });

  return (
    <section>
      <Helmet title={t('global.sbom', { count: 2 })}>
        <meta name="description" content={t('app.oneliner')} />
      </Helmet>
      {isLoadingPortal && <Loading />}
      {isErrorLoadingPortal && (
        <Flex justify={'center'}>
          <ActionableCard
            title={t('error.general.errorLoadingData')}
            subtitle={t('error.general.errorLoadingDataSubtitle')}
            Icon={<Icon icon="circle-exclamation" size="lg" />}
            primaryAction={{
              label: t('global.retry'),
              onClick: () => {
                refetchPortal();
              },
              isLoading: isLoadingPortal,
            }}
          />
        </Flex>
      )}
      {!isErrorLoadingPortal && !isPortalEnabled && !isLoadingPortal && (
        <Flex justify={'center'}>
          <ActionableCard
            title={t('portal.disabledPortal.title')}
            subtitle={t('portal.disabledPortal.description')}
            Icon={<Icon icon="lock" size="lg" />}
            primaryAction={{
              label: t('navigation.logout.label'),
              onClick: () => {
                navigate('/logout?reason=user-manual');
              },
              variant: 'default',
            }}
          />
        </Flex>
      )}
      {isPortalEnabled && !isErrorLoadingPortal && (
        <>
          {isLoading && !isFetching && <Loading />}
          {isError && (
            <Flex justify={'center'}>
              <ActionableCard
                title={t('error.general.errorLoadingData')}
                subtitle={t('error.general.errorLoadingDataSubtitle')}
                Icon={<Icon icon="circle-exclamation" size="lg" />}
                primaryAction={{
                  label: t('global.retry'),
                  onClick: () => {
                    refetch();
                  },
                  isLoading: isLoading,
                }}
              />
            </Flex>
          )}
          {!isLoading &&
            !isFetching &&
            !isError &&
            fetchedAssets.length === 0 &&
            !assetNameToFilter && (
              <Flex justify={'center'}>
                <ActionableCard
                  title={t('portal.sboms.empty.title')}
                  subtitle={t('portal.sboms.empty.description')}
                  Icon={<Icon icon="share-nodes" size="lg" />}
                />
              </Flex>
            )}

          {!isError &&
            (fetchedAssets.length > 0 ||
              (fetchedAssets.length === 0 && (assetNameToFilter || isFetching))) && (
              <div>
                <Flex className={styles.card} justify="space-between">
                  <Flex align={'center'}>
                    <TextComponent variant="headerXLarge" color="title">
                      {t('portal.header')}
                    </TextComponent>
                  </Flex>
                  <Flex gap="16px">
                    <Flex gap="8px" direction="column">
                      <TextComponent variant="headerAllCaps" color="muted">
                        {t('portal.totalSbomsShared')}
                      </TextComponent>
                      <TextComponent
                        variant="bodyLarge"
                        color="title"
                        component="div"
                        pb="16px"
                      >
                        {totalUnfilteredCount}
                      </TextComponent>
                    </Flex>

                    <>
                      <Divider orientation="vertical" />
                      <Flex gap="8px" direction="column">
                        <TextComponent variant="headerAllCaps" color="muted">
                          {t('portal.latestShared')}
                        </TextComponent>
                        <TextComponent
                          variant="bodyLarge"
                          color={latestSharedDate ? 'title' : 'faint'}
                          component="div"
                          pb="16px"
                        >
                          {latestSharedDate ?? '---'}
                        </TextComponent>
                      </Flex>
                    </>
                  </Flex>
                </Flex>
                <MantineReactTable table={table} />
                <DataTableFooter
                  currentPage={pagination.pageIndex}
                  limitPerPage={pagination.pageSize}
                  totalResults={totalSboms}
                  onChangePage={setPagination}
                />
              </div>
            )}
        </>
      )}
    </section>
  );
};

export default SharedSboms;
