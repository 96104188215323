import { useQuery } from '@tanstack/react-query';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';
import { InterfaceFullAsset } from '../../types/FullInterfaces';

interface Params {
  productId?: string;
  enabled?: boolean;
  limit?: number;
  page?: number;
  sortColumn?: string;
  sortType?: string;
  filters?: any;
}

interface Options {
  enabled?: boolean;
}

export const ASSETS_QUERY_CACHE_PREFIX = 'assets';

export const useFetchAssets = (
  { productId, limit, page, sortColumn, sortType, filters }: Params,
  options?: Options,
) => {
  let fetchUrl = productId ? `product/${productId}/assets?` : 'assets?';

  if (page) {
    fetchUrl += `&page=${page}`;
  }
  if (limit) {
    fetchUrl += `&limit=${limit}`;
  }
  if (sortColumn && sortType) {
    if (sortColumn === 'riskScore') sortColumn = 'riskScoreNum';
    fetchUrl += `&sort={"${sortColumn}":"${sortType === 'asc' ? 1 : -1}","_id":"${
      sortType === 'asc' ? 1 : -1
    }"}`;
  }
  if (filters) {
    fetchUrl += `&filters=${JSON.stringify(filters)}`;
  }
  fetchUrl = fetchUrl.replace('?&', '?');

  return useQuery({
    enabled: options?.enabled,
    keepPreviousData: true,
    queryKey: [ASSETS_QUERY_CACHE_PREFIX, `/${fetchUrl}`],
    queryFn: () => AxiosProxy.get({ url: fetchUrl }),
    select: (data) =>
      data as {
        data: InterfaceFullAsset[];
        queryInfo: { totalCount: number };
      },
  });
};
