import { useMutation, useQueryClient } from '@tanstack/react-query';
import { MappedPageConfig } from '../../../../api/customFields/pageConfig.interface';
import { ComponentsPageConfigApi } from './componentsPageConfig.api';
import { ComponentsColumnId } from './componentsPageConfig.mapper';
import { COMPONENT_PAGE_CONFIG_CACHE_KEY } from './useGetComponentsPageConfig';

export const useUpdateComponentsPageConfig = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (
      params: Parameters<typeof ComponentsPageConfigApi.updateComponentsPageConfig>[0],
    ) => ComponentsPageConfigApi.updateComponentsPageConfig(params),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [COMPONENT_PAGE_CONFIG_CACHE_KEY],
      });
    },
    onMutate: async (variables) => {
      await queryClient.cancelQueries({
        queryKey: [COMPONENT_PAGE_CONFIG_CACHE_KEY],
      });

      const previousConfig = queryClient.getQueryData<
        MappedPageConfig<ComponentsColumnId>
      >([COMPONENT_PAGE_CONFIG_CACHE_KEY]);

      const newConfig = variables;
      queryClient.setQueryData([COMPONENT_PAGE_CONFIG_CACHE_KEY], newConfig);

      return { previousConfig, newConfig };
    },
    onError: (_, _payload, context) => {
      queryClient.setQueryData(
        [COMPONENT_PAGE_CONFIG_CACHE_KEY],
        context?.previousConfig,
      );
    },
  });
};
