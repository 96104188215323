import { Text, Title } from '@mantine/core';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Loading from '../../components/Loading';
import { useFetchComponents } from '../../hooks/queries/useFetchComponents';
import '../../scss/pages/components.scss';
import { ComponentsTable } from './ComponentsTable';

const Components = () => {
  const { t } = useTranslation();

  const {
    isLoading,
    data: { queryInfo: { totalCount: countComponents = 0 } = {} } = {},
  } = useFetchComponents({
    queryParams: { page: 1, limit: 20 },
  });

  return (
    <section className="page-components anim-slideInUpShort">
      <Helmet title={t('page.components.page-title')}>
        <meta name="description" content={t('app.oneliner')} />
      </Helmet>
      <Title mb="sm" order={1} className="anim-slideInDownShort">
        {t('page.components.header')}
      </Title>
      {isLoading && <Loading />}
      {countComponents === 0 && !isLoading && <>No components</>}
      {countComponents > 0 && !isLoading && (
        <>
          <Text className="anim-slideInUpShort">
            {t('page.components.total', {
              count: countComponents?.toLocaleString() as any,
            })}
          </Text>
          <ComponentsTable />
        </>
      )}
    </section>
  );
};

export default Components;
