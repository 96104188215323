import { useQuery } from '@tanstack/react-query';
import { CustomFieldsApi } from './customFields.api';

export const CUSTOM_FIELDS_CACHE_KEY = 'customFields';

export const useGetCustomFields = (
  params: Parameters<typeof CustomFieldsApi.getCustomFields>[0],
) =>
  useQuery({
    queryFn: () => CustomFieldsApi.getCustomFields(params),
    queryKey: [CUSTOM_FIELDS_CACHE_KEY, params.fieldType],
    keepPreviousData: true,
  });
