import { InterfaceOrganizationSlaSetting } from '@manifest-cyber/types/interface/dbTables';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';

interface ApiResponseWithErrors<T> extends AxiosResponse<T> {
  errors: string[];
}

export const usePostSLAs = () => {
  const queryClient = useQueryClient();
  return useMutation<
    ApiResponseWithErrors<InterfaceOrganizationSlaSetting>,
    unknown,
    InterfaceOrganizationSlaSetting
  >({
    mutationFn: (body: InterfaceOrganizationSlaSetting) => {
      return AxiosProxy.post({ url: `sla/setting`, withCredentials: true, body });
    },
    onSuccess: () => {
      queryClient.invalidateQueries([`slas`]);
    },
  });
};
