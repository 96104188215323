import { DefaultRepositorySettings } from '@manifest-cyber/types/interface/dbIntegration';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';

interface Params {
  manifestOrganizationId: string; //organizationId
  githubOrganizationId: string;
  githubIntegrationId: string;
  defaultRepositoryOptions: DefaultRepositorySettings;
}

export const usePostGitHubDefaultSettings = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      manifestOrganizationId,
      githubOrganizationId,
      defaultRepositoryOptions,
      githubIntegrationId,
    }: Params) => {
      return AxiosProxy.post({
        url: `integration/github/default-settings`,
        withCredentials: true,
        body: {
          manifestOrganizationId,
          githubOrganizationId,
          defaultRepositoryOptions,
          githubIntegrationId,
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['integration-github']);
    },
  });
};
