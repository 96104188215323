import { DatePickerInput } from '@mantine/dates';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../../../components/Icon';
import ManifestModal from '../../../../components/ManifestModal/ManifestModal';

export const EndOfSupportModal = ({
  onClose,
  onUpdate,
  initialValue,
  showLoadingButton,
  componentName,
}: {
  onClose: () => void;
  onUpdate: (endOfSupport: string) => void;
  showLoadingButton?: boolean;
  componentName: string;
  initialValue?: string;
}) => {
  const [selectedValue, setSelectedValue] = useState<Date | null>(
    initialValue ? new Date(initialValue) : null,
  );
  const { t } = useTranslation();

  return (
    <ManifestModal
      opened
      withCloseButton
      onClose={onClose}
      title={`${t('global.update')} ${t('tables.components.headers.endOfSupport')}`}
      subtitle={`${t('tables.components.forComponent')}: ${componentName}`}
      body={
        <div>
          <DatePickerInput
            icon={<Icon icon="calendar" />}
            label={t('tables.components.date')}
            value={selectedValue}
            onChange={(value) => {
              setSelectedValue(value);
            }}
            valueFormat="MM/DD/YYYY"
            mx="auto"
            maw={400}
            {...{ placeholder: t('tables.components.dateSelectorPlaceholder') }}
            popoverProps={{ withinPortal: true }}
          />
        </div>
      }
      primaryAction={{
        label: t('global.update'),
        isDisabled: !selectedValue,
        onClick: () => {
          if (!selectedValue) {
            return;
          }

          onUpdate(selectedValue.toISOString());
        },
        isLoading: showLoadingButton,
        color: 'cyan',
      }}
      secondaryAction={{
        label: t('global.cancel'),
        onClick: onClose,
      }}
    />
  );
};
