import { DateTime } from 'luxon';
import { AxiosProxy } from '../../../../api/axiosProxy/axiosProxy';
import {
  rejectFailedRequestAcceptEmptyData,
  rejectFailedRequestOrEmptyData,
} from '../../../../lib/request/rejectFailedRequest/rejectFailedRequest';
import { APIResponse } from '../../../../types/api_response.model';

export interface UsersWithAccessToSharedSbom {
  sbomId: string;
  emails: {
    email: string;
    sharedDate: string;
  }[];
}

export interface MappedUsersWithAccessToSharedSbom {
  email: string;
  dateSharedText: string;
  avatarLetter: string;
}

const getSharedWithUsers = async (
  id: string,
): Promise<MappedUsersWithAccessToSharedSbom[]> => {
  const response = (await AxiosProxy.get({
    url: `portal/sbom/${id}/users-with-access`,
  })) as APIResponse<UsersWithAccessToSharedSbom>;

  const { data } = await rejectFailedRequestOrEmptyData(response);

  return data.emails.map((user) => ({
    email: user.email,
    avatarLetter: user.email[0]?.toUpperCase() || '?',
    dateSharedText: DateTime.fromISO(user.sharedDate).toFormat('MMMM dd, yyyy'),
  }));
};

export interface ShareResourcePayload {
  emails: string[];
  sbomIds: string[];
  notifyByEmail?: boolean;
}

const shareResource = async (payload: ShareResourcePayload) => {
  const response = (await AxiosProxy.post({
    url: 'portal/sbomshare',
    withCredentials: true,
    body: payload,
  })) as APIResponse<undefined>;
  await rejectFailedRequestAcceptEmptyData(response);

  return;
};

export interface DeleteAccesToResourceParams {
  email: string;
  sbomId: string;
}

const deleteAccesToResource = async ({ email, sbomId }: DeleteAccesToResourceParams) => {
  const response = (await AxiosProxy.delete({
    url: `portal/sbomshare/${sbomId}/${email}`,
    withCredentials: true,
  })) as APIResponse<undefined>;
  await rejectFailedRequestAcceptEmptyData(response);

  return;
};

export const ShareResourceApi = {
  getSharedWithUsers,
  shareResource,
  deleteAccesToResource,
};
