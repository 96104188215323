import { Text } from '@mantine/core';
import { MRT_Cell, MRT_Row } from 'mantine-react-table';
import { InterfaceFullAsset } from '../../../../types/FullInterfaces';
import styles from '../AssetsTable.module.scss';

export const VulnerabilitiesColumn = ({
  cell,
  row,
}: {
  cell: MRT_Cell<InterfaceFullAsset>;
  row: MRT_Row<InterfaceFullAsset>;
}) => {
  return (
    <Text
      size="lg"
      className={`${row.original?.isActive === false ? styles.isInactiveAssetRow : ''}`}
    >
      {cell.getValue<number>()}
    </Text>
  );
};
