import {
  IRCategoryObject,
  IRCategoryObjectForKev,
} from '@manifest-cyber/types/interface/db';
import { InterfaceOrganizationSlaSetting } from '@manifest-cyber/types/interface/dbTables';
import {
  Anchor,
  Button,
  Flex,
  Grid,
  Modal,
  NumberInput,
  Popover,
  RangeSlider,
  Select,
  Text,
  Textarea,
} from '@mantine/core';
import { IconSelector } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import Icon from '../../../../components/Icon';
import styles from './EditSla.module.scss';
import { adjustGradientForInterval } from './methods';
import { useEditSla } from './useEditSla.hook';

interface Props {
  opened: boolean;
  onClose: VoidFunction;
  rowData?: IRCategoryObject | IRCategoryObjectForKev | null;
  vulnerabilityRisk: string;
  organizationSlaSetting?: InterfaceOrganizationSlaSetting;
}

export const EditSlaModal = ({
  opened,
  onClose,
  rowData,
  vulnerabilityRisk,
  organizationSlaSetting,
}: Props) => {
  const { t } = useTranslation();
  const {
    formState,
    setFormState,
    isBooleanCriteria,
    criteria,
    marks,
    isSaveDisabled,
    handleChange,
    handleSave,
  } = useEditSla({
    onClose,
    rowData,
    vulnerabilityRisk,
    organizationSlaSetting,
  });

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={
        vulnerabilityRisk
          ? t('page.sla.modalTitle', {
              vulnerabilityRisk: `${criteria[0]?.toUpperCase() + criteria.substring(1)} ${
                vulnerabilityRisk !== 'onKev' ? 'Risk ' : ''
              }`,
            })
          : ''
      }
    >
      <Grid gutter="24px">
        <Grid.Col span={12}>
          <Textarea
            minRows={3}
            label={t('page.sla.description')}
            classNames={{
              label: styles.fieldLabel,
            }}
            value={formState?.formData?.description ?? ''}
            onChange={(e) => handleChange('description', e.target.value)}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          {isBooleanCriteria ? (
            <>
              <Text className={styles.fieldLabel}>
                {t('page.sla.criteria')}
                <span className={styles.requiredStar}>*</span>{' '}
                <Popover
                  position="top"
                  withArrow
                  shadow="md"
                  classNames={{
                    dropdown: styles.popoverDropdown,
                    arrow: styles.popoverArrow,
                  }}
                >
                  <Popover.Target>
                    <span>
                      <Icon icon="circle-info" classNames={styles.circleInfo} />
                    </span>
                  </Popover.Target>
                  <Popover.Dropdown>{t('page.sla.overlapPopover')}</Popover.Dropdown>
                </Popover>
              </Text>
              <Text className={styles.fieldReadOnly}>
                {t('page.sla.on')}{' '}
                <Anchor
                  href="https://www.cisa.gov/known-exploited-vulnerabilities-catalog"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.anchor}
                >
                  {t('page.sla.cisaKevList')}
                </Anchor>
              </Text>
            </>
          ) : (
            <>
              <Text className={styles.fieldLabel}>
                {t('page.sla.criteria')}
                <span className={styles.requiredStar}>*</span>{' '}
                <Popover
                  position="top"
                  withArrow
                  shadow="md"
                  classNames={{
                    dropdown: styles.popoverDropdown,
                    arrow: styles.popoverArrow,
                  }}
                >
                  <Popover.Target>
                    <span>
                      <Icon icon="circle-info" classNames={styles.circleInfo} />
                    </span>
                  </Popover.Target>
                  <Popover.Dropdown>{t('page.sla.overlapPopover')}</Popover.Dropdown>
                </Popover>
              </Text>
              <Text className={styles.description}>
                {formState.criteriaRange == null ||
                typeof formState.criteriaRange[0] !== 'number' ||
                typeof formState.criteriaRange[1] !== 'number'
                  ? t('page.sla.rangeSliderHelper')
                  : t('page.sla.cvssRange', {
                      range: `${formState.criteriaRange?.[0].toFixed(1)} - ${formState.criteriaRange?.[1].toFixed(1)}`,
                    })}
              </Text>

              <RangeSlider
                value={formState.criteriaRange}
                onChange={(range) =>
                  setFormState((prevState) => ({
                    ...prevState,
                    criteriaRange: range,
                  }))
                }
                min={0}
                max={10}
                maxRange={10}
                minRange={0.1}
                step={0.1}
                marks={marks}
                label={null}
                color="red"
                classNames={{
                  track: styles.sliderTrack,
                  mark: styles.sliderMark,
                  markFilled: styles.sliderMarkFilled,
                  markLabel: styles.sliderMarkLabel,
                  thumb: styles.sliderThumb,
                }}
                styles={(theme) => ({
                  bar: {
                    backgroundImage: `linear-gradient(90deg, ${adjustGradientForInterval(
                      formState.criteriaRange[0],
                      formState.criteriaRange[1],
                    )})`,
                  },
                })}
              />
              {formState.overlapError && (
                <Text color="red" mt={10}>
                  <Icon icon="exclamation-triangle" classNames={styles.exclamationIcon} />{' '}
                  {formState.overlapError}
                </Text>
              )}
            </>
          )}
        </Grid.Col>
        <Grid.Col>
          <Grid gutter="xs">
            <Grid.Col>
              <Text className={styles.fieldLabel}>
                {t('page.sla.timeFrame')}
                <span className={styles.requiredStar}>*</span>
              </Text>
              <Text className={styles.description}>
                {t('page.sla.timeFrameDescription')}
              </Text>
            </Grid.Col>
            <Flex align="center">
              <Grid.Col span={2}>
                <NumberInput
                  value={formState.remediationTime.number}
                  onChange={(value) =>
                    setFormState((prevState) => ({
                      ...prevState,
                      remediationTime: {
                        ...prevState.remediationTime,
                        number: value || 0,
                      },
                    }))
                  }
                  hideControls
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <Select
                  rightSection={<IconSelector size="1rem" />}
                  data={[
                    {
                      value: 'd',
                      label: t('page.sla.day', {
                        count: formState.remediationTime.number,
                      }),
                    },
                    {
                      value: 'h',
                      label: t('page.sla.hour', {
                        count: formState.remediationTime.number,
                      }),
                    },
                  ]}
                  value={formState.remediationTime.unit}
                  onChange={(value) =>
                    setFormState((prevState) => ({
                      ...prevState,
                      remediationTime: {
                        ...prevState.remediationTime,
                        unit: value || 'd',
                      },
                    }))
                  }
                />
              </Grid.Col>
            </Flex>
          </Grid>
        </Grid.Col>

        <Grid.Col span={12}>
          <Flex justify="flex-end" gap={8}>
            <Button onClick={onClose} variant="default">
              {t('page.sla.cancel')}
            </Button>
            <Button onClick={handleSave} disabled={isSaveDisabled}>
              {t('page.sla.save')}
            </Button>
          </Flex>
        </Grid.Col>
      </Grid>
    </Modal>
  );
};
