import { IconName } from '@fortawesome/fontawesome-svg-core';
import { Group, Select, SelectItem, SelectProps, Text } from '@mantine/core';
import { ComponentPropsWithoutRef, forwardRef, ReactNode } from 'react';
import Icon from '../Icon';
import styles from './CreationalSelect.module.scss';

export type CreationalSelectElement = SelectItem & { iconName?: IconName };

type ItemProps = ComponentPropsWithoutRef<'div'> & CreationalSelectElement;

const SelectItemComponent = forwardRef<HTMLDivElement, ItemProps>(
  ({ iconName, label, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap spacing={8}>
        {iconName && <Icon icon={iconName} classNames={styles.selectItemIcon} />}
        <div>
          <Text className={styles.selectItemLabel}>{label}</Text>
        </div>
      </Group>
    </div>
  ),
);

type FilteredSelectProps = Omit<
  SelectProps,
  | 'classNames'
  | 'itemComponent'
  | 'size'
  | 'data'
  | 'getCreateLabel'
  | 'shouldCreate'
  | 'onCreate'
  | 'maxDropdownHeight'
>;

export interface CreationalSelectProps extends FilteredSelectProps {
  addItem: (value: string) => SelectItem;
  data: CreationalSelectElement[];
  getCreateItemText: (query: string) => ReactNode;
}

export const CreationalSelect = ({
  data,
  addItem,
  label,
  placeholder,
  getCreateItemText,
  value,
  onChange,
  onBlur,
  ...props
}: CreationalSelectProps) => {
  return (
    <Select
      classNames={{
        itemsWrapper: styles.itemsWrapper,
        separatorLabel: styles.separatorLabel,
        separator: styles.separator,
        item: styles.selectItem,
      }}
      itemComponent={SelectItemComponent}
      value={value}
      label={label}
      data={data}
      creatable
      placeholder={placeholder}
      onBlur={onBlur}
      onChange={onChange}
      maxDropdownHeight={350}
      getCreateLabel={(query) => (
        <div className={styles.createNewLosDropdown}>
          <Icon icon={'plus'} classNames={styles.plusIcon} />
          {getCreateItemText(query)}
        </div>
      )}
      onCreate={(query) => addItem(query)}
      {...props}
    />
  );
};
