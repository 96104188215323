import { ManifestApiResponse } from '@manifest-cyber/types/interface/apiResponse';
import {
  ActionIcon,
  Box,
  Button,
  Divider,
  Flex,
  Stack,
  Switch,
  Tooltip,
} from '@mantine/core';
import { useForm, UseFormReturnType } from '@mantine/form';
import { useQueryClient } from '@tanstack/react-query';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useFetchSharingPortal } from '../../../api/sharingPortal/hooks/useFetchSharingportal/useFetchSharingPortal';
import { usePostSharingPortal } from '../../../api/sharingPortal/hooks/usePostSharingPortal/usePostSharingPortal';
import { ActionableCard } from '../../../components/ActionableCard/ActionableCard';
import ClickableRegion from '../../../components/ClickableRegion';
import Icon from '../../../components/Icon';
import ManifestModal from '../../../components/ManifestModal/ManifestModal';
import { ManifestTextInput } from '../../../components/ManifestTextInput/ManifestTextInput';
import MessageWithBackground from '../../../components/MessageWithBackground/MessageWithBackground';
import appConfig from '../../../configs/appConfig';
import { ASSET_QUERY_CACHE_PREFIX } from '../../../hooks/queries/useFetchAsset';
import { ASSETS_QUERY_CACHE_PREFIX } from '../../../hooks/queries/useFetchAssets';
import { useAuth } from '../../../hooks/useAuth';
import { useNotifications } from '../../../hooks/utils/useNotifications';
import { Logger } from '../../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';
import { isValidURL } from '../../../lib/url/isValidUrl/isValidUrl';
import { ALPHANUMERIC_AND_DASHES_REGEX } from '../../../regExp.constant';
import { ApiPortalResponse } from '../../../types/apiPortalResponse.interface';
import styles from './SharingPortalSettings.module.scss';

interface SharingPortalSettingsForm {
  isEnabled: boolean;
  name: string;
  logoUrl: string;
}

export const PORTAL_NAME_ALREADY_TAKEN_ERROR = 'Portal name is already taken';

const thereAreChangesInSettings = (
  form: UseFormReturnType<SharingPortalSettingsForm>,
  portal?: ApiPortalResponse['portal'] | null,
) => {
  if (!portal) {
    return Boolean(form.values.isEnabled || form.values.name || form.values.logoUrl);
  }

  return (
    form.values.isEnabled !== portal.isActive ||
    form.values.name !== portal.portalName ||
    form.values.logoUrl !== portal.logoUrl
  );
};

export const SharingPortalSettings = () => {
  const [showCopiedToClipboardMessage, setShowCopiedToClipboardMessage] = useState(false);
  const { t } = useTranslation();
  const { checkUserAccess } = useAuth();
  const hasWriteAccess = checkUserAccess('write');
  const { success: successNotification } = useNotifications();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { data: portal, isLoading, isError, refetch } = useFetchSharingPortal();
  const {
    mutateAsync: postPortal,
    isError: isErrorUpdating,
    error: errorUpdatingPortal,
    isLoading: isUpdatingPortal,
  } = usePostSharingPortal();
  const errorUpdating = (errorUpdatingPortal as ManifestApiResponse<null> | undefined)
    ?.errors?.[0];
  const isDuplicatedNameError =
    isErrorUpdating && errorUpdating === PORTAL_NAME_ALREADY_TAKEN_ERROR;
  const form = useForm<SharingPortalSettingsForm>({
    initialValues: {
      isEnabled: false,
      name: '',
      logoUrl: '',
    },
    validate: ({ name, logoUrl }: SharingPortalSettingsForm) => {
      const errors: Partial<Record<keyof SharingPortalSettingsForm, string>> = {};
      const requiredFieldMessage = t('global.form.errors.fieldCannotBeBlank');
      if (!name) {
        errors['name'] = requiredFieldMessage;
      }

      const validationUrlResult = isValidURL(logoUrl, {
        validateIsHttps: true,
        validateIsImage: true,
      });

      if (logoUrl) {
        if (!validationUrlResult.isValid || !validationUrlResult.isValidHttps) {
          errors['logoUrl'] = t('global.form.errors.invalidUrl');
        }
        if (validationUrlResult.isValid && !validationUrlResult.isValidForImage) {
          errors['logoUrl'] = t('global.form.errors.invalidUrlForImage');
        }
      }

      return errors;
    },
    validateInputOnBlur: true,
  });

  const { siteProtocol, siteURL } = appConfig as {
    siteProtocol: string;
    siteURL: string;
  };
  const portalPath = siteURL.startsWith(siteProtocol)
    ? `${siteURL}/o/${form.values.name}`
    : `${siteProtocol}${siteURL}/o/${form.values.name}`;

  useEffect(() => {
    if (portal) {
      form.setValues({
        isEnabled: portal.isActive,
        name: portal.portalName,
        logoUrl: portal.logoUrl,
      });
    }
  }, [portal]);

  useEffect(() => {
    if (errorUpdating && isDuplicatedNameError) {
      form.setFieldError('name', t('global.form.errors.nameIsDuplicated'));
    }
  }, [errorUpdating, isDuplicatedNameError]);

  const onUpdatePortalSettings = async () => {
    if (!form.isValid()) {
      form.validate();
      return;
    }

    if (isLoading) {
      return;
    }

    const payload = {
      isActive: form.values.isEnabled,
      portalName: form.values.name,
      logoUrl: form.values.logoUrl,
    };

    try {
      await postPortal(payload);
      successNotification({
        title: t('portal.notification.portalSettingsUpdated'),
        message: '',
      });
      await queryClient.invalidateQueries({
        queryKey: [ASSETS_QUERY_CACHE_PREFIX, ASSET_QUERY_CACHE_PREFIX],
      });
    } catch (error) {
      Logger.captureExceptionWithMessage('Error updating sharing portal', error);
    }
  };

  const closeModal = () => {
    setIsOpenModal(false);
  };
  return (
    <div className={styles.container}>
      {isError && (
        <Flex justify={'center'}>
          <ActionableCard
            title={t('error.general.errorLoadingData')}
            subtitle={t('error.general.errorLoadingDataSubtitle')}
            Icon={<Icon icon="circle-exclamation" size="lg" />}
            primaryAction={{
              label: t('global.retry'),
              onClick: () => {
                refetch();
              },
              isLoading: isLoading,
            }}
          />
        </Flex>
      )}
      {!isError && (
        <div>
          <div>
            {isErrorUpdating && !isDuplicatedNameError && (
              <Box mb="12px">
                <MessageWithBackground
                  message={'An unexpected error occurred while saving. Please try again.'}
                  messageType={'critical'}
                />
              </Box>
            )}
            <Flex justify={'space-between'} gap="40px">
              <div>
                <h1 className={styles.title}>
                  {t('page.userSettings.sharingPortal.title')}
                </h1>
                <span className={styles.subtitle}>
                  {t('page.userSettings.sharingPortal.subtitle')}
                </span>
              </div>
              <Button
                variant="filled"
                onClick={onUpdatePortalSettings}
                loading={isLoading || isUpdatingPortal}
                disabled={
                  !thereAreChangesInSettings(form, portal) ||
                  isUpdatingPortal ||
                  !hasWriteAccess
                }
              >
                {t('global.save.buttonShort')}
              </Button>
            </Flex>
          </div>
          <Divider my="24px" />

          <Stack spacing={'32px'}>
            <Switch
              labelPosition="left"
              size="md"
              label={t('page.userSettings.sharingPortal.enableLabel')}
              description={t('page.userSettings.sharingPortal.enableDescription')}
              classNames={{
                description: styles.switchDescription,
                label: styles.switchLabel,
              }}
              {...form.getInputProps('isEnabled')}
              checked={form.getInputProps('isEnabled').value}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (e.target.checked === false) {
                  setIsOpenModal(true);
                } else {
                  form.setFieldValue('isEnabled', e.target.checked);
                }
              }}
              disabled={!hasWriteAccess}
            />

            {form.getInputProps('isEnabled').value && (
              <>
                <div>
                  <ManifestTextInput
                    inputClasName={styles.nameInput}
                    size="md"
                    label={t('page.userSettings.sharingPortal.urlLabel')}
                    description={t('page.userSettings.sharingPortal.urlDescription')}
                    inputProps={{
                      ...form.getInputProps('name'),
                      onChange: (e: ChangeEvent<HTMLInputElement>) => {
                        const value = e.target.value;
                        form.setFieldValue(
                          'name',
                          value.replace(ALPHANUMERIC_AND_DASHES_REGEX, ''),
                        );
                      },
                    }}
                    disabled={!hasWriteAccess}
                  />
                  <Flex gap="2px" align={'center'}>
                    <div>
                      <span>URL: </span>
                      <ClickableRegion
                        target="_blank"
                        href={portalPath}
                        onClick={(event) => {
                          event.preventDefault();
                          navigate(`/o/${form.values.name}`);
                        }}
                      >
                        {portalPath}
                      </ClickableRegion>
                    </div>
                    <Tooltip
                      label={t('page.userSettings.sharingPortal.copiedPortalUrl')}
                      opened={showCopiedToClipboardMessage}
                    >
                      <ActionIcon
                        aria-label={t('page.userSettings.sharingPortal.copyPortalUrl')}
                        onClick={() => {
                          navigator.clipboard?.writeText(portalPath);
                          setShowCopiedToClipboardMessage(true);

                          setTimeout(() => {
                            setShowCopiedToClipboardMessage(false);
                          }, 1000);
                        }}
                        className={styles.mutedText}
                      >
                        <Icon icon={'link'} />
                      </ActionIcon>
                    </Tooltip>
                  </Flex>
                </div>
                <ManifestTextInput
                  inputClasName={styles.logoUrlInput}
                  size="md"
                  label={t('page.userSettings.sharingPortal.logoLabel')}
                  description={t('page.userSettings.sharingPortal.logoDescription')}
                  inputProps={form.getInputProps('logoUrl')}
                  disabled={!hasWriteAccess}
                />
              </>
            )}
          </Stack>
          {isOpenModal && (
            <ManifestModal
              size="md"
              opened
              withCloseButton={true}
              onClose={closeModal}
              title={t('page.userSettings.sharingPortal.disableModalTitle')}
              body={t('page.userSettings.sharingPortal.disableModalSubtitle')}
              primaryAction={{
                label: t('global.disable'),
                onClick: () => {
                  form.setFieldValue('isEnabled', false);
                  closeModal();
                },
                color: 'red',
              }}
              secondaryAction={{
                onClick: closeModal,
                label: t('page.userSettings.sharingPortal.disableModalCancelLabel'),
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};
