import { useQuery } from '@tanstack/react-query';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';
import { InterfaceFullAssetLicenseIssue } from '../../types/FullInterfaces';

interface Params {
  assetId: string;
}

interface Options {
  enabled?: boolean;
}

export const useFetchAssetLicenseIssues = ({ assetId }: Params, option?: Options) => {
  let fetchUrl = `license-issues/asset/${assetId}`;

  return useQuery({
    enabled: option?.enabled,
    queryKey: ['asset-license-issues', { assetId }, `/${fetchUrl}`],
    queryFn: () => AxiosProxy.get({ url: fetchUrl }),
    select: (data) => (data?.data ?? []) as InterfaceFullAssetLicenseIssue[],
  });
};
