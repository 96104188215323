import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';
import { rejectFailedRequestAcceptEmptyData } from '../../lib/request/rejectFailedRequest/rejectFailedRequest';

interface Params {
  memberId: string;
}

export const useDeleteOrganizationUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ memberId }: Params) => {
      // FYI Target with the memberId, not the userId
      const response = await AxiosProxy.delete({
        url: `member/${memberId}`,
        withCredentials: true,
      });
      const successfulResponse = await rejectFailedRequestAcceptEmptyData(response);

      return successfulResponse;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['organizationUsers']);
      queryClient.invalidateQueries(['currentOrganization']);
    },
  });
};
