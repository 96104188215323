import { MRT_Row } from 'mantine-react-table';
import { Dispatch, SetStateAction } from 'react';
import { InterfaceFullAsset } from '../../../../types/FullInterfaces';
import Labels from '../../../Labels';
import styles from '../AssetsTable.module.scss';

export const LabelsColumn = ({
  row,
  setFilterLabels,
  hasWriteAccess,
}: {
  row: MRT_Row<InterfaceFullAsset>;
  setFilterLabels: Dispatch<SetStateAction<string[]>>;
  hasWriteAccess: boolean;
}) => {
  return (
    <div
      className={`${row.original?.isActive === false ? styles.isInactiveAssetRow : ''}`}
    >
      <Labels
        entity={row.original}
        condensed
        justifyContent="flex-end"
        {...(hasWriteAccess && { onLabelClick: (label) => setFilterLabels([label._id]) })}
        rounded
      />
    </div>
  );
};
