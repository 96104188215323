import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';

export interface TriageValues {
  triageStatusName: string;
  organizationAssetId: string;
  scope: string;
  cveIds: string[];
  justification: string;
  details: string;
}
interface Params {
  triage: TriageValues;
}

export const usePostVulnerabilityTriage = (organizationAssetId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ triage }: Params) => {
      return AxiosProxy.post({
        url: `/triage`,
        withCredentials: true,
        body: {
          ...triage,
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['vulnerabilities_triages']);
      queryClient.invalidateQueries(['assetVulns']);
      queryClient.invalidateQueries(['triageStatuses']);
      queryClient.invalidateQueries([`vulnerabilities_triages_${organizationAssetId}`]);
      queryClient.invalidateQueries(['countStats']);
    },
  });
};
