import { Button } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactMultiEmail } from 'react-multi-email';
import { Input, Modal } from 'rsuite';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';
import styles from './requestSbomModal.module.scss';

interface RequestSbomModalProps {
  open: boolean;
  onClose: () => void;
}

function RequestSbomModal({ open, onClose }: RequestSbomModalProps) {
  const { t } = useTranslation();
  const [requestSbomModalOpen, setRequestSbomModalOpen] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [emails, setEmails] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [messageToVendor, setMessageToVendor] = useState('');
  const [messageToVendorError, setMessageToVendorError] = useState('');

  const MAX_MESSAGE_LENGTH = 1000;

  useEffect(() => {
    if (open) {
      setRequestSbomModalOpen(true);
    } else {
      setRequestSbomModalOpen(false);
    }
  }, [open]);

  useEffect(() => {
    setErrors([]);
  }, [emails, messageToVendor]);

  const handleSendEmails = async () => {
    if (messageToVendor.length > MAX_MESSAGE_LENGTH) {
      setMessageToVendorError(`Message cannot exceed ${MAX_MESSAGE_LENGTH} characters`);
      return;
    }
    setLoading(true);
    try {
      const result = await AxiosProxy.post({
        url: '/vendor/requestSboms',
        withCredentials: true,
        body: {
          emails,
          messageToVendor,
        },
      });
      if (!result.success) throw new Error('unable to send request');
      setLoading(false);
      setEmails([]);
      setMessageToVendor('');
      onClose();
    } catch (e) {
      setErrors([...errors, 'unable to send request']);
    } finally {
      setLoading(false);
    }
  };

  const handleMessageToVendorUpdate = (value: string) => {
    setMessageToVendor(value);
    if (value.length > MAX_MESSAGE_LENGTH) {
      setMessageToVendorError(
        t('page.uploads.requestSbomModal.messageCannotExceedCharacters', {
          maxLength: MAX_MESSAGE_LENGTH,
        }),
      );
    } else {
      setMessageToVendorError('');
    }
  };

  return (
    <Modal open={requestSbomModalOpen} onClose={onClose} className="request-sbom-modal">
      <Modal.Header>
        <Modal.Title>{t(`page.uploads.requestSbomModal.header`)}</Modal.Title>
        <span className="subHeader">{t(`page.uploads.requestSbomModal.subHeader`)}</span>
      </Modal.Header>
      <Modal.Body>
        {errors && errors.length > 0 && (
          <ul className="page-errors anim-slideInUpShort">
            {errors.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        )}
        <div className="emails-container manifest-react-multi-email">
          <span className="field-label">
            {t(`page.uploads.requestSbomModal.enterEmails`)}
          </span>
          <div className="multi-email">
            <ReactMultiEmail
              placeholder={t('page.uploads.requestSbomModal.enterEmailsPlaceholder')}
              emails={emails}
              onChange={(_emails: string[]) => {
                setEmails(_emails);
              }}
              autoFocus={true}
              getLabel={(email, index, removeEmail) => {
                return (
                  <div data-tag key={index}>
                    <div data-tag-item>{email}</div>
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                      ×
                    </span>
                  </div>
                );
              }}
            />
          </div>
        </div>
        <div className="message-container">
          <span className="field-label full-width">
            {t(`page.uploads.requestSbomModal.message`)}
          </span>
          <div className={styles.inputWithCounter}>
            <Input
              as="textarea"
              rows={3}
              className="manifest-textarea"
              value={messageToVendor}
              onChange={handleMessageToVendorUpdate}
              placeholder={t('page.uploads.requestSbomModal.message-placeholder')}
            />
            <span
              className={`${styles.characterCount} ${
                messageToVendor.length > MAX_MESSAGE_LENGTH ? styles.textError : ''
              }`}
            >
              {messageToVendor.length}/{MAX_MESSAGE_LENGTH}
            </span>
          </div>
          {messageToVendorError && (
            <div className={styles.errorMessage}>{messageToVendorError}</div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer">
        <Button
          loading={loading}
          fullWidth
          disabled={emails.length === 0 || messageToVendor.length > MAX_MESSAGE_LENGTH}
          onClick={handleSendEmails}
        >
          {t(`page.uploads.requestSbomModal.btnSendEmails`)}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default RequestSbomModal;
