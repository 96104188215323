import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SHARING_PORTAL_RECEIVER_QUERY_KEY } from '../../../../shared-portal/pages/sharedSboms/api/sharingPortal/useFetchSharingPortalReceiver/useFetchSharingPortalReceiver';
import { ApiPortalResponse } from '../../../../types/apiPortalResponse.interface';
import { SharingPortalApi, UpdatePortalPayload } from '../../sharingPortal.api';
import { SHARING_PORTAL_QUERY_KEY } from '../useFetchSharingportal/useFetchSharingPortal';

export const usePostSharingPortal = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (payload: UpdatePortalPayload) =>
      SharingPortalApi.updatePortal(payload),
    onMutate: async (updatedPortal) => {
      await queryClient.cancelQueries({
        queryKey: [SHARING_PORTAL_QUERY_KEY, SHARING_PORTAL_RECEIVER_QUERY_KEY],
      });

      const previousPortal = queryClient.getQueryData<ApiPortalResponse>([
        SHARING_PORTAL_QUERY_KEY,
      ]);

      const newPortal = {
        ...(previousPortal || {}),
        ...updatedPortal,
      };

      queryClient.setQueryData([SHARING_PORTAL_QUERY_KEY], newPortal);
      queryClient.setQueryData([SHARING_PORTAL_RECEIVER_QUERY_KEY], newPortal);

      return { previousPortal, newPortal };
    },
    onError: (_, _payload, context) => {
      queryClient.setQueryData([SHARING_PORTAL_QUERY_KEY], context?.previousPortal);
      queryClient.setQueryData(
        [SHARING_PORTAL_RECEIVER_QUERY_KEY],
        context?.previousPortal,
      );
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: [SHARING_PORTAL_QUERY_KEY, SHARING_PORTAL_RECEIVER_QUERY_KEY],
      });
    },
  });
};
