import { InterfaceOrganization } from '@manifest-cyber/types/interface/dbTables';
import { Button, Menu } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { Form, Table } from 'rsuite';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';
import ClickableRegion from '../../components/ClickableRegion';
import Icon from '../../components/Icon';
import TriggerMessageModal from '../../components/TriggerMessageModal';
import { useAuth } from '../../hooks/useAuth';
import { useOrganizationId } from '../../hooks/utils/useOrganizationId';
import representOrganization from '../../lib/representOrganization';
import { captureExceptionWithMessage } from '../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';
import '../../scss/pages/administration.scss';
const { Column, HeaderCell, Cell } = Table;

const Administration = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [currentOrgId, setCurrentOrgId] = useOrganizationId();
  const [newOrgForm, setNewOrgForm] = useState<any | null>(null);
  const [isCreatingNewOrg, setIsCreatingNewOrg] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [isFetchingOrgs, setIsFetchingOrgs] = useState(false);
  const [fetchedOrgs, setFetchedOrgs] = useState<InterfaceOrganization[]>([]);

  const [sortColumn, setSortColumn] = useState();
  const [sortType, setSortType] = useState();

  const getOrgData = () => {
    if (sortColumn && sortType) {
      return fetchedOrgs.sort((a, b) => {
        let x: any =
          a[sortColumn] === undefined ? Number.NEGATIVE_INFINITY : a[sortColumn];
        let y: any =
          b[sortColumn] === undefined ? Number.NEGATIVE_INFINITY : b[sortColumn];

        if (sortType === 'asc') {
          if (x < y) return -1;
          if (x > y) return 1;
          return 0;
        } else {
          if (x < y) return 1;
          if (x > y) return -1;
          return 0;
        }
      });
    }
    return fetchedOrgs;
  };

  const handleSortColumn = (sortColumn: any, sortType: any) => {
    setIsFetchingOrgs(true);
    setTimeout(() => {
      setIsFetchingOrgs(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };

  const createNewOrg = async () => {
    setIsCreatingNewOrg(true);

    if (newOrgForm?.name && newOrgForm?.name.length > 0) {
      const createdOrg = await AxiosProxy.post({
        url: 'organization/create',
        withCredentials: true,
        body: {
          name: newOrgForm?.name,
          domains: newOrgForm?.domains || null,
          isPaid: true,
          enableInviteOnly: false,
        },
      });

      if (createdOrg?.errors) {
        setErrors(createdOrg?.errors);
      }
    } else {
      setErrors([...errors, "Can't create an organization with no name!"]);
    }

    setIsCreatingNewOrg(false);
    await fetchOrgs();
  };

  useEffect(() => {
    setErrors([]);
  }, [newOrgForm]);

  const attemptImpersonation = async (orgId: string) => {
    window.localStorage.setItem('previousorgid', currentOrgId || '');
    await representOrganization(orgId);
    setCurrentOrgId(orgId);
  };

  if (
    !user?.internalRoles ||
    user?.internalRoles?.length < 1 ||
    (!user?.internalRoles?.includes('staff') && !user?.internalRoles?.includes('admin'))
  ) {
    return <Navigate to="/?oof=1" />;
  }

  const fetchOrgs = async () => {
    setIsFetchingOrgs(true);
    try {
      const orgs: {
        success: boolean;
        errors: string[];
        data: InterfaceOrganization[];
      } = await AxiosProxy.get({ url: 'organizations' });

      if (orgs?.success) {
        if (Array.isArray(orgs?.data)) {
          setFetchedOrgs(orgs?.data);
        } else {
          captureExceptionWithMessage('Administration: Fetched orgs is not an array!');
        }
      } else {
        setErrors(['Unable to fetch orgs (success false)']);
        captureExceptionWithMessage('Unable to fetch orgs (success false)');
      }

      setIsFetchingOrgs(false);
    } catch (err) {
      captureExceptionWithMessage('Unable to fetch orgs!', err);
    }
  };

  const [isModalOpen, setModalOpen] = useState(false);
  const [triggerType, setTriggerType] = useState('');

  return (
    <section className="page-administration">
      <div className="header">
        <div className="header-grouper">
          <h1>{t('page.administration.header')}</h1>
          <span className="label-internal">{t('page.administration.internalOnly')}</span>
        </div>
        <TriggerMessageModal
          open={isModalOpen}
          onClose={() => setModalOpen(false)}
          triggerType={triggerType}
        />
        <Menu position="bottom-end">
          <Menu.Target>
            <Button
              className="manifest-menu__target"
              variant="default"
              rightIcon={<Icon icon="chevron-down" />}
            >
              {t('page.administration.trigger')}
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              onClick={() => {
                setTriggerType('daily-vuln-match');
                setModalOpen(true);
              }}
              className="manifest-menu__item"
            >
              <span>{t('page.administration.daily-vuln-match')}</span>
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                setTriggerType('nvd-ingest');
                setModalOpen(true);
              }}
              className="manifest-menu__item"
            >
              <span>{t('page.administration.seed-nvd')}</span>
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                setTriggerType('osv-ingest');
                setModalOpen(true);
              }}
              className="manifest-menu__item"
            >
              <span>{t('page.administration.seed-osv')}</span>
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                setTriggerType('epss-ingest');
                setModalOpen(true);
              }}
              className="manifest-menu__item"
            >
              <span>{t('page.administration.seed-epss')}</span>
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                setTriggerType('kev-ingest');
                setModalOpen(true);
              }}
              className="manifest-menu__item"
            >
              <span>{t('page.administration.seed-kev')}</span>
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </div>
      <div className="muted">{t('page.administration.subHeader')}</div>
      {errors.length > 0 && (
        <ul className="page-errors anim-slideInUpShort">
          {errors.map((error) => (
            <li>{error}</li>
          ))}
        </ul>
      )}

      <div className="create-org">
        <h4>{t('page.administration.createNewOrganization')}</h4>
        <div className="create-org-inner">
          <Form
            layout="inline"
            className="flex"
            onChange={(val: any) => setNewOrgForm(val)}
          >
            <Form.Group className="flex flex-col" controlId="username-7">
              <Form.ControlLabel className="muted">{t('global.name')}</Form.ControlLabel>
              <Form.Control name="name" placeholder={t('global.organizationName')} />
            </Form.Group>

            <Form.Group className="flex flex-col" controlId="password-7">
              <Form.ControlLabel className="muted">
                {t('global.domain')}
              </Form.ControlLabel>
              <Form.Control name="domains" autoComplete="off" placeholder="****.com" />
            </Form.Group>

            <Button
              className="btn-create-org"
              disabled={isCreatingNewOrg}
              onClick={() => createNewOrg()}
            >
              {t('global.create')}
            </Button>
          </Form>
        </div>
      </div>

      <div className="list-orgs">
        <h4>{t('global.allOrganizations')}</h4>
        <div className="display-fetched-orgs">
          <Table
            // @ts-ignore
            data={getOrgData()}
            autoHeight
            sortColumn={sortColumn}
            sortType={sortType}
            onSortColumn={handleSortColumn}
            loading={isFetchingOrgs}
            renderEmpty={() => (
              <div className="table-empty">
                <ClickableRegion
                  regionLabel="Click to load organizations"
                  onClick={() => fetchOrgs()}
                  className="fetch-table-data"
                >
                  <span>Click to Fetch Data. Requests are audited.</span>
                </ClickableRegion>
              </div>
            )}
          >
            <Column sortable flexGrow={2}>
              <HeaderCell>Name</HeaderCell>
              <Cell dataKey="name" />
            </Column>

            <Column sortable flexGrow={2}>
              <HeaderCell>Organization ID</HeaderCell>
              <Cell dataKey="_id" />
            </Column>

            <Column sortable flexGrow={1}>
              <HeaderCell>Status</HeaderCell>
              <Cell dataKey="status" />
            </Column>

            <Column sortable flexGrow={2}>
              <HeaderCell>Domains</HeaderCell>
              <Cell dataKey="domains">
                {(rowData, rowIndex) => {
                  return `${
                    rowData.domains
                      .sort((a: string, b: string) => {
                        const nameA = a.toLowerCase(),
                          nameB = b.toLowerCase();
                        if (nameA < nameB) return -1;
                        if (nameA > nameB) return 1;
                        return 0;
                      })
                      .join(',') || 'None'
                  }`;
                }}
              </Cell>
            </Column>

            <Column sortable flexGrow={2}>
              <HeaderCell>Users</HeaderCell>
              <Cell dataKey="countUsers">
                {(rowData, rowIndex) => {
                  return `${rowData.countUsers || '-'}`;
                }}
              </Cell>
            </Column>

            <Column flexGrow={2}>
              <HeaderCell> </HeaderCell>
              <Cell>
                {(rowData, rowIndex) => {
                  if (rowData?._id === currentOrgId) {
                    return (
                      <>
                        <strong>Current Organization</strong>
                      </>
                    );
                  }

                  return (
                    <ClickableRegion
                      regionLabel="Impersonate"
                      className="impersonate-btn"
                      onClick={() =>
                        rowData?._id ? attemptImpersonation(rowData._id) : null
                      }
                    >
                      <span>Impersonate</span>
                    </ClickableRegion>
                  );
                }}
              </Cell>
            </Column>
          </Table>
        </div>
      </div>
    </section>
  );
};

export default Administration;
