import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useAuth } from '../hooks/useAuth';
import representOrganization from '../lib/representOrganization';

interface IImpersonationBarProps {
  currentOrgData: any;
}
function ImpersonationBar({ currentOrgData }: IImpersonationBarProps) {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [orgName, setOrgName] = useState('');

  useEffect(() => {
    if (currentOrgData?.name) {
      setOrgName(currentOrgData.name);
    }
  }, [currentOrgData]);

  const handleEndImpersonation = () => {
    const orgId = window.localStorage.getItem('previousorgid') || user?.organizations[0];
    if (orgId) {
      representOrganization(orgId);
    }
  };
  return (
    <div className="impersonation-bar">
      <span>
        <Trans i18nKey="impersonation.notice" values={{ orgName }} />
      </span>

      <button className="btn-stop-impersonation" onClick={() => handleEndImpersonation()}>
        {t('impersonation.exit')}
      </button>
    </div>
  );
}

export default ImpersonationBar;
