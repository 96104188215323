/**
 * capitalizeWords()
 * Attempts to capitalize words in string. Useful for generated auth codes, etc
 * @param {string} string Contains one or more words to be capitalized
 * @returns {string}
 */
export const capitalizeWords = (
  string: string,
  ignoreBlacklist: boolean = false,
): string => {
  if (typeof string !== 'string' || string === '') {
    return '';
  }
  const words = string.split(' ');
  const capBlacklist = ['the', 'to', 'with', 'and', 'for']; // words to avoid capitalizing. Should localize at some point

  return Array.from(words)
    .map((currentWord) => {
      if (ignoreBlacklist && capBlacklist.includes(currentWord)) {
        return '';
      }

      return currentWord.charAt(0).toUpperCase() + currentWord.slice(1);
    })
    .join(' ');
};

export default capitalizeWords;
