import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';
import { Organization } from '../../types/organization.interface';

export const usePostOrganization = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      organization,
      isChild = false,
    }: {
      organization: Partial<Organization>;
      isChild?: boolean;
    }) => {
      if (isChild) {
        return AxiosProxy.post({
          url: `organization/${organization?._id}`,
          withCredentials: true,
          body: {
            name: organization?.name,
          },
        });
      }

      return AxiosProxy.post({
        url: `organization/`,
        withCredentials: true,
        body: organization,
      });
    },
    onSuccess: () => {
      // Invalidate orgs
      queryClient.invalidateQueries(['currentOrganization']);
      queryClient.invalidateQueries(['childOrganizations']);
    },
  });
};
