const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|webp|svg)$/i;
interface isValidURLReturnValue {
  isValid: boolean;
  isValidForImage?: boolean;
  isValidHttps?: boolean;
}
interface isValidURLOptions {
  validateIsImage?: boolean;
  validateIsHttps?: boolean;
}

export const isValidURL = (
  url: string,
  { validateIsImage, validateIsHttps }: isValidURLOptions = {},
): isValidURLReturnValue => {
  try {
    const parsedUrl = new URL(url);
    const result: isValidURLReturnValue = { isValid: true };

    if (validateIsImage) {
      result.isValidForImage = imageExtensions.test(parsedUrl.pathname);
    }

    if (validateIsHttps) {
      result.isValidHttps = parsedUrl.protocol === 'https:';
    }

    return result;
  } catch {
    return { isValid: false };
  }
};
