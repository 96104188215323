import { BrowserUserSessionUtils } from '../../lib/storage/sesion.utils';
import {
  InterfaceFullUser,
  OrganizationData,
  OrganizationMembershipResponse,
} from '../../types/FullInterfaces';

export interface MappedUser extends InterfaceFullUser {
  hasOrganizations: boolean;
  id: string;
  isPaidOrgs: boolean;
  hasWriteAccess: boolean;
  isOrganizationAdmin: boolean;
  isManifestInternalAdminOrStaff: boolean;
  organizationId?: string;
  organization?: {
    parentOrg?: OrganizationData;
    parentOrgId?: string;
    domains: string[];
    isParentOrg: boolean;
    hasDomains: boolean;
    formattedDomains?: string;
    enableInviteOnly: boolean;
    name: string;
    childrenId?: string[];
  };
}

export const mapUser = (user: InterfaceFullUser): MappedUser => {
  const organizationId =
    BrowserUserSessionUtils.getOrganizationId() || user.organizations[0];
  const organizationMembership: OrganizationMembershipResponse['_doc'] | undefined =
    organizationId ? user.membershipData[organizationId]?._doc : undefined;
  const isManifestInternalAdminOrStaff =
    user.internalRoles.includes('admin') || user.internalRoles.includes('staff');

  const isOrganizationMemberNotReadOnly = Boolean(
    organizationMembership && !organizationMembership.isReadOnly,
  );

  const isOrganizationAdmin = Boolean(
    organizationId && user.isAdminOfOrganizations.includes(organizationId),
  );

  const curentOrganizationData = organizationId
    ? user.organizationData[organizationId]
    : undefined;
  const parentOrgId = curentOrganizationData?.parent[0];
  const parentOrg = parentOrgId ? user.organizationData[parentOrgId] : undefined;
  const isParentOrg = !Boolean(parentOrgId);

  const hasDomains = (curentOrganizationData?.domains?.length ?? 0) > 0;
  const formattedDomains = curentOrganizationData?.domains?.join(', ');
  const organization: MappedUser['organization'] = {
    parentOrg,
    parentOrgId,
    isParentOrg,
    hasDomains,
    domains: curentOrganizationData?.domains || [],
    formattedDomains,
    name: curentOrganizationData?.name || '',
    enableInviteOnly: Boolean(curentOrganizationData?.enableInviteOnly),
    childrenId: curentOrganizationData?.children || [],
  };

  return {
    ...user,
    hasOrganizations: user.organizations.length > 0,
    organizationId,
    hasWriteAccess: isManifestInternalAdminOrStaff || isOrganizationMemberNotReadOnly,
    isManifestInternalAdminOrStaff,
    organization: curentOrganizationData ? organization : undefined,
    isOrganizationAdmin,
    id: user._id,
  };
};
