import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';

/**
 * useDeleteSBOMs mutation (react-query)
 * delete api request to delete sboms, assets, and other related data
 */
export const useDeleteSBOMs = (
  options?: UseMutationOptions<unknown, unknown, string[], unknown>,
) =>
  useMutation({
    mutationFn: (sbomIds) => {
      return AxiosProxy.delete({
        url: `sboms/delete`,
        withCredentials: true,
        body: {
          sbomIds,
        },
      });
    },
    ...options,
  });
