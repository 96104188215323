import { InterfaceOrganizationProduct } from '@manifest-cyber/types/interface/dbTables';
import { useMutation } from '@tanstack/react-query';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { postGenerateSBOM } from '../api/product.api';
import { useNotifications } from '../hooks/utils/useNotifications';

interface SBOMDownloadContextType {
  downloadQueue: InterfaceOrganizationProduct[];
  addToDownloadQueue: (product: InterfaceOrganizationProduct) => void;
  isLoading: boolean;
  isQueueMode: boolean;
  toggleQueueMode: () => void;
}

const SBOMDownloadContext = createContext<SBOMDownloadContextType | undefined>(undefined);

export const SBOMDownloadProvider = ({ children }: { children: ReactNode }) => {
  const [downloadQueue, setDownloadQueue] = useState<InterfaceOrganizationProduct[]>([]);
  const [isProcessingQueue, setIsProcessingQueue] = useState<boolean>(false);
  const [isQueueMode, setIsQueueMode] = useState<boolean>(false);
  const { error: errorNotification, info, success } = useNotifications();
  const { t } = useTranslation();

  const toggleQueueMode = () => setIsQueueMode(!isQueueMode);

  const downloadLink = (link: string, productName: string) => {
    try {
      const downloadLink = document.createElement('a');
      downloadLink.href = link;
      downloadLink.download = '';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch {
      showError(productName);
    }
  };

  const generateSbom = useCallback(
    async (productToDownload: InterfaceOrganizationProduct) => {
      info({
        title: t('product.preparingYourDownload'),
        message: t('product.downloadSbomNotification'),
      });
      if (
        !productToDownload._id ||
        !productToDownload.name ||
        !productToDownload.version
      ) {
        return null;
      }
      const response = await postGenerateSBOM(
        productToDownload._id.toString(),
        productToDownload.name,
        productToDownload.version,
      );
      if (!response.success) return null;
      return response.data;
    },
    [info, t],
  );

  const showError = (productName?: string) =>
    errorNotification({
      title: t('product.errorWithYourSbom'),
      message: t('product.downloadSbomError', {
        productName: productName || '-',
      }),
    });

  const dequeue = (productToDownload: InterfaceOrganizationProduct) =>
    setDownloadQueue((currentQueue) =>
      currentQueue.filter((p) => p._id !== productToDownload._id),
    );

  const { mutate, isLoading } = useMutation(generateSbom, {
    onSuccess: (generateResponse, productToDownload) => {
      if (!generateResponse) {
        showError(productToDownload.name);
        dequeue(productToDownload);
        return;
      }
      downloadLink(generateResponse.link, productToDownload.name || '');
      dequeue(productToDownload);
      success({
        title: t('product.sbomIsReady'),
        message: t('product.sbomIsReadyDescription', {
          productName: productToDownload.name,
        }),
      });
    },
    onError: (error, productToDownload) => {
      showError(productToDownload.name);
      dequeue(productToDownload);
    },
    onSettled: () => {
      setIsProcessingQueue(false);
    },
  });

  useEffect(() => {
    const processQueue = () => {
      if (
        isQueueMode &&
        downloadQueue.length > 0 &&
        downloadQueue[0] &&
        !isProcessingQueue
      ) {
        setIsProcessingQueue(true);
        mutate(downloadQueue[0]);
      } else if (!isQueueMode && downloadQueue.length > 0) {
        const productsToDownload = [...downloadQueue];
        setDownloadQueue([]);
        productsToDownload.forEach((product) => {
          mutate(product);
        });
      }
    };

    processQueue();
  }, [downloadQueue, isQueueMode, isProcessingQueue, mutate]);

  const addToDownloadQueue = (product: InterfaceOrganizationProduct) => {
    setDownloadQueue((currentQueue) => [...currentQueue, product]);
    if (isQueueMode && isProcessingQueue) {
      info({
        title: t('product.downloadSbom'),
        message: t('product.downloadQueued', {
          productName: product.name,
        }),
      });
    }
  };

  return (
    <SBOMDownloadContext.Provider
      value={{
        downloadQueue,
        addToDownloadQueue,
        isLoading,
        isQueueMode,
        toggleQueueMode,
      }}
    >
      {children}
    </SBOMDownloadContext.Provider>
  );
};

export const useSBOMDownload = () => {
  const context = useContext(SBOMDownloadContext);
  if (context === undefined) {
    throw new Error('useSBOMDownload must be used within a SBOMDownloadProvider');
  }
  return context;
};
