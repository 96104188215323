import { useQuery } from '@tanstack/react-query';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';

interface Filter {
  field: string;
  value: any[];
}

interface Params {
  componentId: string;
  isActive?: boolean;
}

interface Options {
  enabled?: boolean;
}

const buildFilters = (params: Params): Filter[] => {
  const filters: Filter[] = [];
  /*
   * Expected behavior: if true, show active assets only. Otherwise, show both active and inactive
   * */
  if (params.isActive === true) {
    filters.push({ field: 'assetActive', value: [params.isActive] });
  }
  return filters;
};

const buildQueryParams = (params: Params): string => {
  const queryParams = new URLSearchParams();

  if (params.componentId) {
    queryParams.append('organizationComponentId', params.componentId);
  }

  const filters = buildFilters(params);
  if (filters.length > 0) {
    queryParams.append('filters', JSON.stringify(filters));
  }

  return queryParams.toString();
};

export const useFetchComponentAssets = (params: Params, options?: Options) => {
  const queryParams = buildQueryParams(params);
  const fetchUrl = `component/assetList?${queryParams}`;

  return useQuery({
    enabled: options?.enabled,
    keepPreviousData: true,
    queryKey: ['component-assets', { componentId: params.componentId }, `/${fetchUrl}`],
    queryFn: () => AxiosProxy.get({ url: fetchUrl }),
    select: (data) => {
      return {
        data: data?.data?.[0]?.assetLists,
        queryInfo: { totalCount: data?.data?.[0]?.totalCount },
      };
    },
  });
};
