import { useQuery } from '@tanstack/react-query';
import { PaginationState, SortingState } from '@tanstack/react-table';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';
import { captureExceptionWithMessage } from '../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';
import {
  GetVulnImpactApiResponse,
  sanitizeQueryParamProperty,
} from './useFetchVulnImpact';
export interface VulnImpactComponent {
  _id: string;
  components: {
    version?: string;
    _id?: string;
    packageUrlNoVersion?: string;
  }[];
}

export const useFetchVulnComponents = (
  vulnerabilityId: string,
  searchFilter?: string,
  isActiveComponentsOnly?: boolean,
  { pageIndex, pageSize }: Partial<PaginationState> = {},
  sorting?: SortingState,
  noPaginate?: boolean,
) => {
  const params = new URLSearchParams();

  const filters: { field: string; value: any[] }[] = [];

  if (sorting && sorting[0]?.id) {
    params.set(
      'sort',
      `{${sorting
        .map((sortObj) => {
          return `"${sanitizeQueryParamProperty(sortObj.id)}":"${sortObj.desc ? 1 : -1}"`;
        })
        .join('')}}`,
    );
  }

  if (typeof pageIndex !== 'undefined' && typeof pageSize !== 'undefined') {
    params.set('page', (pageIndex + 1).toString());
    params.set('limit', pageSize.toString());
  }

  if (searchFilter) {
    filters.push({ field: 'componentSearch', value: [searchFilter] });
  }

  if (isActiveComponentsOnly) {
    filters.push({ field: 'assetActive', value: [true] });
  }

  if (filters.length) {
    params.set('filters', JSON.stringify(filters));
  }

  if (noPaginate) {
    params.set('noPaginate', 'true');
  }

  params.set('slim', 'true');

  let fetchUrl = `vulnerability/${vulnerabilityId}/components?${params.toString()}`;

  return useQuery({
    queryKey: [
      'vulnImpactComponents',
      vulnerabilityId,
      searchFilter,
      isActiveComponentsOnly,
      pageIndex,
      pageSize,
      sorting,
      noPaginate,
    ],
    queryFn: ({ signal }) =>
      AxiosProxy.get({
        url: fetchUrl,
        withCredentials: true,
        params: undefined,
        headers: undefined,
        signal,
      }),
    select: (data) => data as GetVulnImpactApiResponse<VulnImpactComponent>,
    onError: (error) => {
      captureExceptionWithMessage('Unable to fetch vulnerability components: ', error);
    },
    keepPreviousData: true,
  });
};
