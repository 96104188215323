import './AssetVulnerabilitiesTable.scss';

import { InterfaceVulnerability } from '@manifest-cyber/types/interface/dbTables';
import { ActionIcon, Affix, Button, Popover, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { DateTime } from 'luxon';
import {
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_SortingState,
  MRT_Updater,
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FilterConfigQuery,
  useFetchAssetVulns,
} from '../../../hooks/queries/useFetchAssetVulns';
import { useFetchOrganization } from '../../../hooks/queries/useFetchOrganization';
import { useAuth } from '../../../hooks/useAuth';
import { useOrganizationId } from '../../../hooks/utils/useOrganizationId';
import capitalizeWords from '../../../lib/capitalizeWords';
import { TriageCount } from '../../../vulnerabilities/TriageCount';
import BasicSearch from '../../BasicSearch';
import ClickableRegion from '../../ClickableRegion';
import {
  ColumnConfig,
  ColumnsConfigPopover,
} from '../../ColumnsConfigPopover/ColumnsConfigPopover';
import Icon from '../../Icon';
import { getDefaultTableOptions } from '../../MRT/ManifestMRT';
import { ManifestTag } from '../../ManifestTag/ManifestTag';
import Triage from '../../Triage/Triage';
import DataTableFooter from '../DataTableFooter';
import ColumnFilters, { FilterCategory } from './ColumnFilters';

const defaultTableOptions = getDefaultTableOptions<InterfaceVulnerability>();

interface Props {
  assetId?: string;
  organizationId?: string;
  activeVulnerabilities: number;
}

interface TriageStatus {
  value: string;
  label: string;
  styleClass: string;
  justificationHeader?: string;
  detailHeader?: string;
  statusHeader?: string;
}

export const AssetVulnerabilitiesTable = (props: Props) => {
  const { assetId, organizationId, activeVulnerabilities } = props;
  const { t } = useTranslation();
  const { checkUserAccess } = useAuth();
  const hasWriteAccess = checkUserAccess('write');
  const [currentOrgId] = useOrganizationId();
  const { data: fetchedCurrentOrganization, isLoading: isLoadingCurrentOrganization } =
    useFetchOrganization({ organizationId: currentOrgId });
  const acceptVulnTriageFeatureFlag =
    !isLoadingCurrentOrganization &&
    fetchedCurrentOrganization?.enabledFeatures?.some(
      (feature) => feature === 'accepted-vuln-triage',
    );

  const availablerecommendations = [
    { id: 'mitigate', label: t('jargon.recommendation.mitigate') },
    { id: 'monitor', label: t('jargon.recommendation.monitor') },
    { id: 'accept', label: t('jargon.recommendation.acceptRisk') },
  ];
  const defaultColumnsConfig = [
    {
      id: 'cveId',
      label: t('vulnerabilities.table.columns.cveId'),
      visible: true,
      locked: true,
    },
    {
      id: 'recommendedAction',
      label: t('vulnerabilities.table.columns.recommendedAction'),
      visible: true,
      locked: false,
    },
    {
      id: 'cvssScore',
      label: t('vulnerabilities.table.columns.severity'),
      visible: true,
      locked: false,
    },
    {
      id: 'epssScore',
      label: t('vulnerabilities.table.columns.epssExploitability'),
      visible: true,
      locked: false,
    },
    {
      id: 'epssPercentile',
      label: t('vulnerabilities.table.columns.epssPercentile'),
      visible: false,
      locked: false,
    },
    {
      id: 'affectedComponents',
      label: t('vulnerabilities.table.columns.affectedComponents'),
      visible: true,
      locked: false,
    },
    {
      id: 'publishDate',
      label: t('vulnerabilities.table.columns.publishDate'),
      visible: false,
      locked: false,
    },
    {
      id: 'dateModified',
      label: t('vulnerabilities.table.columns.lastUpdated'),
      visible: false,
      locked: false,
    },
    {
      id: 'triage',
      label: t('vulnerabilities.table.columns.triage'),
      visible: true,
      locked: false,
    },
  ];
  const filters: FilterCategory[] = [
    {
      id: 'recommendedAction',
      label: t('jargon.recommendation.label'),
      options: [
        {
          id: 'mitigate',
          label: t('jargon.recommendation.mitigate'),
          active: false,
        },
        {
          id: 'monitor',
          label: t('jargon.recommendation.monitor'),
          active: false,
        },
        {
          id: 'accept',
          label: t('jargon.recommendation.acceptRisk'),
          active: false,
        },
      ],
    },
    {
      id: 'severity',
      label: t('jargon.severity.label'),
      options: [
        {
          id: 'CRITICAL',
          label: t('jargon.severity.critical'),
          active: false,
        },
        {
          id: 'HIGH',
          label: t('jargon.severity.high'),
          active: false,
        },
        {
          id: 'MEDIUM',
          label: t('jargon.severity.medium'),
          active: false,
        },
      ],
    },
  ];
  const defaultTableConfig = {
    columnsConfig: defaultColumnsConfig,
    filtersConfig: filters,
  };
  //TODO: figure out a way to reset the localstorage settings when new columns / filters are added
  if (
    !localStorage.getItem('vulnerabilityTableSettings') ||
    Object.keys(localStorage.getItem('vulnerabilityTableSettings') || {}).length === 0
  )
    localStorage.setItem(
      'vulnerabilityTableSettings',
      JSON.stringify(defaultTableConfig),
    );
  const vulnerabilityTableSettings =
    localStorage.getItem('vulnerabilityTableSettings') &&
    Object.keys(JSON.parse(localStorage.getItem('vulnerabilityTableSettings') || ''))
      .length > 0
      ? JSON.parse(localStorage.getItem('vulnerabilityTableSettings') || '')
      : defaultTableConfig;
  const { columnsConfig, filtersConfig } = vulnerabilityTableSettings;
  const [currentColumnsConfig, setCurrentColumnsConfig] =
    useState<ColumnConfig[]>(columnsConfig);
  const [currentFiltersConfig, setCurrentFiltersConfig] =
    useState<FilterCategory[]>(filtersConfig);

  const getCurrentFilters = () => {
    const activeFiltersString = currentFiltersConfig
      .filter((filter) => {
        return filter.options.some((option) => option.active);
      })
      .map((filter) => {
        return {
          field: filter.id,
          values: filter.options
            .filter((option) => option.active)
            .map((option) => option.id),
        };
      });
    if (currentSearchTerm)
      activeFiltersString.push({ field: 'search', values: [currentSearchTerm] });
    if (activeFiltersString.length > 0) return activeFiltersString as FilterConfigQuery[];
    return null;
  };
  useEffect(() => {
    localStorage.setItem(
      'vulnerabilityTableSettings',
      JSON.stringify({
        columnsConfig: currentColumnsConfig,
        filtersConfig: currentFiltersConfig,
      }),
    );
  }, [currentColumnsConfig, currentFiltersConfig]);

  const [currentSearchTerm, setCurrentSearchTerm] = useState<string | null>(null);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [sorting, setSorting] = useState<MRT_SortingState>([
    {
      id: 'cvssScore',
      desc: true,
    },
  ]);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectedCVEs, setSelectedCVEs] = useState<InterfaceVulnerability[]>([]);
  const [triageOpened, { open: openTriageDrawer, close: closeTriageDrawer }] =
    useDisclosure(false);
  const [triageCloseable, setTriageCloseable] = useState<boolean>(true);
  const [openCloseModal, setOpenCloseModal] = useState<boolean>(false);

  const handleRowSelectionChange = (updater: MRT_Updater<MRT_RowSelectionState>) => {
    const value = updater instanceof Function ? updater(rowSelection) : updater;
    if (Object.keys(value).length === 0) {
      if (!triageCloseable) {
        setOpenCloseModal(true);
        return;
      } else {
        closeTriageDrawer();
      }
    }
    setRowSelection((prevSelection) =>
      updater instanceof Function ? updater(prevSelection) : updater,
    );
  };
  useEffect(() => {
    if (!triageOpened) {
      setTriageCloseable(true);
      table.resetRowSelection(true);
    }
  }, [triageOpened]);
  const {
    data: {
      data: fetchedVulnerabilities = [],
      queryInfo: { countVulnerabilities = 0 } = {},
    } = {},
    isError: isErrorLoadingVulnerabilities,
    isLoading: isLoadingVulnerabilities,
    isFetching: isFetchingVulnerabilities,
  } = useFetchAssetVulns({
    assetId,
    organizationId,
    sortColumn: sorting[0]?.id,
    sortType:
      sorting[0]?.desc === true ? 'desc' : sorting[0]?.desc === false ? 'asc' : undefined,
    page: pagination.pageIndex + 1,
    limit: pagination.pageSize,
    filters: getCurrentFilters(),
  });
  useEffect(() => {
    const currentRowSelection = table
      .getSelectedRowModel()
      .rows.map((row) => row.original || null);
    setSelectedCVEs(currentRowSelection);
  }, [rowSelection, fetchedVulnerabilities]);
  //prefetch the next page for faster navigation
  const {} = useFetchAssetVulns({
    assetId,
    organizationId,
    sortColumn: sorting[0]?.id,
    sortType:
      sorting[0]?.desc === true ? 'desc' : sorting[0]?.desc === false ? 'asc' : undefined,
    page: pagination.pageIndex + 2,
    limit: pagination.pageSize,
  });

  const fixedNotAffectedVulnerabilities = useMemo(
    () => Math.max(0, countVulnerabilities - activeVulnerabilities),
    [countVulnerabilities, activeVulnerabilities],
  );

  const handleSearch = (searchTerm: string) => {
    setCurrentSearchTerm(searchTerm);
  };

  const handleColumnChange = (columns: ColumnConfig[]) => {
    setCurrentColumnsConfig(columns);
  };

  const handleColumnFilterChange = (filterId: string, selectedOptions: string[]) => {
    setCurrentFiltersConfig((prev) => {
      const newFilters = prev.map((filter) => {
        if (filter.id === filterId) {
          return {
            ...filter,
            options: filter.options.map((option) => {
              if (selectedOptions.includes(option.id)) {
                return {
                  ...option,
                  active: true,
                };
              }
              return {
                ...option,
                active: false,
              };
            }),
          };
        }
        return filter;
      });
      return newFilters;
    });
  };

  const columns = useMemo<MRT_ColumnDef<InterfaceVulnerability>[]>(() => {
    const availableColumns: MRT_ColumnDef<InterfaceVulnerability>[] = [
      {
        accessorFn: (row) => row.cveId,
        id: 'cveId',
        size: 180,
        header: t('vulnerabilities.table.columnHeaders.cveId'),
        Cell: ({ cell, row }) => {
          // @ts-ignore
          const { vulnerabilityTriage = {} } = row?.original;
          const statusName = vulnerabilityTriage?.statusName;

          const { kevData } = row.original;
          const kevDateAddedFormatted = DateTime.fromISO(
            kevData?.kevDateAdded || '',
          ).toLocaleString(DateTime.DATE_MED);
          const kevStatus = kevData?.inKEV
            ? 'kev'
            : kevData?.inKEVPreviously
              ? 'prev-kev'
              : null;
          if (cell.getValue()) {
            return (
              <div className="cve-id-cell">
                <ClickableRegion
                  className="table-link"
                  regionLabel={t(
                    'page.vulnerabilities.table.body.viewVulnerabilityDetails',
                  )}
                  href={`/vulnerability/${`${cell.getValue()}`.toUpperCase()}`}
                >
                  <span
                    className={`cve-id ${
                      statusName === 'notAffected' ||
                      (acceptVulnTriageFeatureFlag && statusName === 'fixed') ||
                      (acceptVulnTriageFeatureFlag && statusName === 'Accepted')
                        ? 'dimmed'
                        : ''
                    }`}
                  >
                    {`${cell.getValue()}`.toUpperCase()}
                  </span>
                </ClickableRegion>
                {kevStatus && (
                  <Tooltip
                    label={
                      <div className="kev-tooltip">
                        <strong className="kev-tooltip-title">
                          {kevData?.kevVulnerabilityName}
                        </strong>
                        <p>
                          {t('page.vulnerabilities.table.body.reported', {
                            date: kevDateAddedFormatted,
                          })}
                        </p>
                      </div>
                    }
                    width={300}
                  >
                    <span className="kev-badge-wrapper">
                      <ManifestTag variant={kevStatus} showIcon={false} />
                    </span>
                  </Tooltip>
                )}
              </div>
            );
          }
        },
      },
      {
        accessorFn: (row) => row.recommendedAction,
        id: 'recommendedAction',
        size: 180,
        header: t('vulnerabilities.table.columnHeaders.recommendedAction'),
        Cell: ({ row }) => {
          // @ts-ignore
          const { recommendedAction, vulnerabilityTriage } = row?.original;
          const statusName = vulnerabilityTriage?.statusName;
          if (recommendedAction) {
            const action = recommendedAction.toLowerCase().trim();

            const rec = availablerecommendations.find((rec) => rec.id === action);
            if (!rec) return 'N/A';
            return (
              <div
                className={`recommended-action-cell tag ${rec.id} ${
                  statusName === 'notAffected' ||
                  (acceptVulnTriageFeatureFlag && statusName === 'fixed') ||
                  (acceptVulnTriageFeatureFlag && statusName === 'Accepted')
                    ? 'dimmed'
                    : ''
                }`}
              >
                {rec.label}
              </div>
            );
          }
        },
      },
      {
        accessorFn: (row) => row.cvss3BaseScore,
        id: 'cvssScore',
        size: 180,
        header: t('vulnerabilities.table.columnHeaders.severity'),
        Cell: ({ row }) => {
          // @ts-ignore
          const { vulnerabilityTriage } = row?.original;
          const statusName = vulnerabilityTriage?.statusName;
          let color = '';
          switch (row.original.cvss3BaseSeverity || row.original.cvss2BaseSeverity) {
            case 'CRITICAL':
              color = 'severity-critical';
              break;
            case 'HIGH':
              color = 'severity-high';
              break;
            case 'MEDIUM':
              color = 'severity-medium';
              break;
            case 'LOW':
              color = 'severity-low';
              break;
            case 'INFO':
              color = 'severity-info';
              break;
            default:
              color = 'severity-info';
              break;
          }

          if (row.original.cvss3BaseScore || row.original.cvss2BaseScore) {
            return (
              <div className="severity-cell">
                <div
                  className={`table-severity ${color} ${
                    statusName === 'notAffected' ||
                    (acceptVulnTriageFeatureFlag && statusName === 'fixed') ||
                    (acceptVulnTriageFeatureFlag && statusName === 'Accepted')
                      ? 'dimmed'
                      : ''
                  }`}
                >
                  {`${capitalizeWords(
                    (
                      row.original.cvss3BaseSeverity ||
                      row.original.cvss2BaseSeverity ||
                      ''
                    ).toLowerCase(),
                  )} `}
                </div>
                &nbsp;
                <span
                  className={`${
                    statusName === 'notAffected' ||
                    (acceptVulnTriageFeatureFlag && statusName === 'fixed') ||
                    (acceptVulnTriageFeatureFlag && statusName === 'Accepted')
                      ? 'dimmed'
                      : ''
                  }`}
                >{`(${
                  row.original.cvss3BaseScore
                    ? row.original.cvss3BaseScore.toFixed(1)
                    : row.original.cvss2BaseScore
                      ? row.original.cvss2BaseScore.toFixed(1)
                      : null
                })`}</span>
              </div>
            );
          }

          return <span className="table-na">{t('global.notProvided')}</span>;
        },
      },
      {
        accessorFn: (row) => row.epssScore,
        id: 'epssScore',
        size: 180,
        header: t('vulnerabilities.table.columnHeaders.epssExploitability'),
        Cell: ({ row }) => {
          // @ts-ignore
          const { vulnerabilityTriage, epssScore } = row?.original;
          const statusName = vulnerabilityTriage?.statusName;
          let displayValue = null;

          if (epssScore && epssScore < 1) {
            displayValue = `${Math.round(epssScore * 10000) / 100}%`;
          } else if (epssScore) {
            displayValue = `${epssScore}%`;
          }

          if (displayValue && displayValue.length > 1) {
            return (
              <Tooltip
                label={t('page.vulnerabilities.table.body.rawValueFromEPSS', {
                  value: `${epssScore}`,
                })}
              >
                <span
                  className={`${
                    statusName === 'notAffected' ||
                    (acceptVulnTriageFeatureFlag && statusName === 'fixed') ||
                    (acceptVulnTriageFeatureFlag && statusName === 'Accepted')
                      ? 'dimmed'
                      : ''
                  }`}
                >
                  {displayValue}
                </span>
              </Tooltip>
            );
          }

          return (
            <span
              className={`table-na ${
                statusName === 'notAffected' ||
                (acceptVulnTriageFeatureFlag && statusName === 'fixed') ||
                (acceptVulnTriageFeatureFlag && statusName === 'Accepted')
                  ? 'dimmed'
                  : ''
              }`}
            >
              {t('global.notProvided')}
            </span>
          );
        },
      },
      {
        accessorFn: (row) => row.epssPercentile,
        id: 'epssPercentile',
        size: 180,
        header: t('vulnerabilities.table.columnHeaders.epssPercentile'),
        Cell: ({ row }) => {
          // @ts-ignore
          const { vulnerabilityTriage, epssPercentile } = row?.original;
          const statusName = vulnerabilityTriage?.statusName;
          let displayValue = null;

          if (epssPercentile && epssPercentile < 1) {
            displayValue = `${Math.round(epssPercentile * 10000) / 100}%`;
          } else if (epssPercentile) {
            displayValue = `${epssPercentile}%`;
          }

          if (displayValue && displayValue.length > 1) {
            return (
              <Tooltip
                label={t('page.vulnerabilities.table.body.rawValueFromEPSS', {
                  value: `${epssPercentile}`,
                })}
              >
                <span
                  className={`${
                    statusName === 'notAffected' ||
                    (acceptVulnTriageFeatureFlag && statusName === 'fixed') ||
                    (acceptVulnTriageFeatureFlag && statusName === 'Accepted')
                      ? 'dimmed'
                      : ''
                  }`}
                >
                  {displayValue}
                </span>
              </Tooltip>
            );
          }

          return (
            <span
              className={`table-na ${
                statusName === 'notAffected' ||
                (acceptVulnTriageFeatureFlag && statusName === 'fixed') ||
                (acceptVulnTriageFeatureFlag && statusName === 'Accepted')
                  ? 'dimmed'
                  : ''
              }`}
            >
              {t('global.notProvided')}
            </span>
          );
        },
      },
      {
        accessorFn: (row) => row.cveId,
        id: 'affectedComponents',
        size: 340,
        header: t('vulnerabilities.table.columnHeaders.affectedComponents'),
        enableSorting: false,
        mantineTableBodyCellProps: ({ cell }) => ({
          sx: {
            zIndex: 'auto',
          },
        }),
        Cell: ({ row }) => {
          //@ts-ignore
          const { affectedComponents, vulnerabilityTriage } = row?.original;
          const statusName = vulnerabilityTriage?.statusName;
          if (affectedComponents?.length > 0) {
            const { affectedComponentName: firstComponentName, id: firstComponentId } =
              affectedComponents[0];
            return (
              <div className="affected-components-cell">
                <ClickableRegion
                  className="table-link"
                  regionLabel={firstComponentName}
                  href={`/component/${firstComponentId}/overview`}
                >
                  <span
                    className={`affected-component ${
                      statusName === 'notAffected' ||
                      (acceptVulnTriageFeatureFlag && statusName === 'fixed') ||
                      (acceptVulnTriageFeatureFlag && statusName === 'Accepted')
                        ? 'dimmed'
                        : ''
                    }`}
                  >
                    {firstComponentName.length > 40
                      ? firstComponentName.substring(0, 40) + '...'
                      : firstComponentName}
                    &nbsp;
                  </span>
                </ClickableRegion>
                {affectedComponents?.length > 1 && (
                  <Popover position="top">
                    <Popover.Target>
                      <span className="extra-components">{`(+${
                        affectedComponents?.length - 1
                      })`}</span>
                    </Popover.Target>
                    <Popover.Dropdown className="light">
                      <div>
                        {affectedComponents?.slice(1)?.map((component: any) => {
                          const { affectedComponentName: name, id } = component;
                          return (
                            <ClickableRegion
                              key={id}
                              className="table-link column light"
                              regionLabel={name}
                              href={`/component/${id}/overview`}
                            >
                              <span className="affected-component">
                                {name.length > 40 ? name.substring(0, 40) + '...' : name}
                              </span>
                            </ClickableRegion>
                          );
                        })}
                      </div>
                    </Popover.Dropdown>
                  </Popover>
                )}
              </div>
            );
          }
        },
      },
      {
        accessorFn: (row) => row.publishDate,
        id: 'publishDate',
        size: 190,
        header: t('vulnerabilities.table.columnHeaders.publishDate'),
        Cell: ({ cell, row }) => {
          //@ts-ignore
          const { vulnerabilityTriage = {} } = row?.original;
          const statusName = vulnerabilityTriage?.statusName;
          const curr = DateTime.fromISO(cell.getValue<any>()).toLocaleString(
            DateTime.DATETIME_MED,
          );
          return (
            <span
              className={`${
                statusName === 'notAffected' ||
                (acceptVulnTriageFeatureFlag && statusName === 'fixed') ||
                (acceptVulnTriageFeatureFlag && statusName === 'Accepted')
                  ? 'dimmed'
                  : ''
              }`}
            >
              {`${curr}`}
            </span>
          );
        },
      },
      {
        accessorFn: (row) => row.dateModified,
        id: 'dateModified',
        size: 190,
        header: t('vulnerabilities.table.columnHeaders.lastUpdated'),
        Cell: ({ cell, row }) => {
          //@ts-ignore
          const { vulnerabilityTriage = {} } = row?.original;
          const statusName = vulnerabilityTriage?.statusName;
          const curr = DateTime.fromISO(cell.getValue<any>()).toLocaleString(
            DateTime.DATETIME_MED,
          );
          return (
            <span
              className={`${
                statusName === 'notAffected' ||
                (acceptVulnTriageFeatureFlag && statusName === 'fixed') ||
                (acceptVulnTriageFeatureFlag && statusName === 'Accepted')
                  ? 'dimmed'
                  : ''
              }`}
            >
              {`${curr}`}
            </span>
          );
        },
      },
      {
        accessorFn: (row) => row.cveId,
        id: 'triage',
        size: 180,
        header: t('vulnerabilities.table.columnHeaders.triage'),
        Cell: ({ cell, row }) => {
          // figure out how to handle triage closaeable when nothing changed.
          //@ts-ignore
          const { vulnerabilityTriage = {} } = row?.original;
          const statusName = vulnerabilityTriage?.statusName;

          const defaultStatuses: TriageStatus[] = [
            {
              value: 'notAffected',
              label: t('triage.form.defaultStatuses.notAffected'),
              styleClass: 'notAffected',
              justificationHeader: t('triage.form.justification'),
              detailHeader: t('triage.form.impactStatement'),
            },
            {
              value: 'affected',
              label: t('triage.form.defaultStatuses.affected'),
              styleClass: 'affected',
              detailHeader: t('triage.form.actionStatement'),
            },
            {
              value: 'underInvestigation',
              label: t('triage.form.defaultStatuses.underInvestigation'),
              styleClass: 'underInvestigation',
              detailHeader: t('triage.form.notes'),
            },
            {
              value: 'fixed',
              label: t('triage.form.defaultStatuses.fixed'),
              styleClass: 'fixed',
              detailHeader: t('triage.form.notes'),
            },
          ];
          const isCustomStatus = !defaultStatuses.some(
            (status) => status.value === statusName,
          );
          const status = !statusName
            ? null
            : defaultStatuses.find((status) => status.value === statusName) || {
                value: 'custom',
                label: t('global.custom'),
                styleClass: 'custom',
                statusHeader: t('triage.form.status'),
                detailHeader: t('triage.form.notes'),
              };

          if (!!status && acceptVulnTriageFeatureFlag && statusName === 'Accepted') {
            status.styleClass = 'fixed';
            status.label = t('triage.form.otherStatuses.accepted');
          }
          return (
            <>
              {status && (
                <Tooltip
                  multiline
                  disabled={
                    (status.value === 'affected' && !vulnerabilityTriage?.details) ||
                    (status.value === 'notAffected' &&
                      !vulnerabilityTriage?.justification &&
                      !vulnerabilityTriage?.details) ||
                    (status.value === 'underInvestigation' &&
                      !vulnerabilityTriage?.details) ||
                    (status.value === 'fixed' && !vulnerabilityTriage?.details) ||
                    (status.value === 'custom' &&
                      !vulnerabilityTriage?.statusName &&
                      !vulnerabilityTriage?.details)
                  }
                  label={
                    <div className="triage-tooltip">
                      {isCustomStatus && (
                        <p>
                          <strong className="triage-tooltip-title">
                            {status?.statusHeader}:{' '}
                          </strong>
                          <span>{vulnerabilityTriage?.statusName}</span>
                        </p>
                      )}
                      {vulnerabilityTriage?.justification && (
                        <p>
                          <strong className="triage-tooltip-title">
                            {status?.justificationHeader}:{' '}
                          </strong>
                          <span>
                            {t(
                              `triage.form.justifications.${vulnerabilityTriage.justification}`,
                            )}
                          </span>
                        </p>
                      )}
                      {vulnerabilityTriage?.details && (
                        <p>
                          <strong className="triage-tooltip-title">
                            {status.detailHeader}:{' '}
                          </strong>
                          <span>
                            {vulnerabilityTriage.details.length > 150
                              ? vulnerabilityTriage.details.slice(0, 150) + '...'
                              : vulnerabilityTriage.details}
                          </span>
                        </p>
                      )}
                    </div>
                  }
                  width={300}
                >
                  <div className={`triage-cell tag ${status.styleClass}`}>
                    {status.label}
                  </div>
                </Tooltip>
              )}
            </>
          );
        },
      },
    ];
    // handle column visibility based on currentColumnsConfig
    return availableColumns.filter((col) => {
      const columnConfig = currentColumnsConfig.find((c) => c.id === col.id);
      return columnConfig?.visible;
    });
  }, [currentColumnsConfig, acceptVulnTriageFeatureFlag]);

  const table = useMantineReactTable<InterfaceVulnerability>({
    ...defaultTableOptions,
    mantinePaperProps: {
      className: 'manifest-data-table-no-footer asset-vulnerabilities-table',
    },
    layoutMode: 'grid',
    displayColumnDefOptions: {
      'mrt-row-select': {
        size: 50,
        mantineTableHeadCellProps: {
          align: 'center',
          sx: {
            flexGrow: 0,
          },
        },
        mantineTableBodyCellProps: {
          align: 'center',
          sx: {
            flexGrow: 0,
          },
        },
      },
    },
    columns,
    data: fetchedVulnerabilities,
    enableFilters: false,
    enablePagination: false,
    enableRowSelection: true,
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    enablePinning: true,
    rowCount: countVulnerabilities,
    initialState: {
      ...defaultTableOptions.initialState,
      showGlobalFilter: false,
      columnPinning: {
        left: [
          'mrt-row-select',
          ...currentColumnsConfig.filter((col) => col.locked).map((col) => col.id),
        ],
      },
      columnOrder: ['mrt-row-select', ...currentColumnsConfig.map((col) => col.id)],
    },
    state: {
      pagination,
      sorting,
      rowSelection,
      columnPinning: {
        left: [
          'mrt-row-select',
          ...currentColumnsConfig.filter((col) => col.locked).map((col) => col.id),
        ],
      },
      //update the column order based on currentColumnsConfig
      columnOrder: ['mrt-row-select', ...currentColumnsConfig.map((col) => col.id)],
      isLoading: isLoadingVulnerabilities,
      showLoadingOverlay: isFetchingVulnerabilities,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onRowSelectionChange: handleRowSelectionChange,
    mantineTopToolbarProps: {}, //temp
    mantineTableBodyRowProps: ({ row }) => ({
      sx: {
        td: {
          backgroundColor: row.getIsSelected() ? 'rgb(25, 36, 52)' : 'rgb(19, 27, 38)', //dark.7
          whiteSpace: 'normal',
        },
        '&:hover': {
          td: {
            backgroundColor: 'rgb(25, 36, 52)', //dark.5
            whiteSpace: 'normal',
          },
        },
      },
    }),
  });
  return (
    <>
      {isErrorLoadingVulnerabilities && (
        <ul className="page-errors anim-slideInUpShort">
          <li>{t('tables.vulnerabilities.unable-to-fetch-vulnerabilities')}</li>
        </ul>
      )}
      <div className="vulns-container">
        <div className={`left ${triageOpened && 'drawer-open'}`}>
          <div className={`asset-vulnerabilities-table-container `}>
            <div className="list-vulnerabilities">
              <TriageCount assetId={assetId!} />
              <div className="table-controls">
                <div className="table-controls-left">
                  <BasicSearch
                    handleSearch={handleSearch}
                    minCharCount={2}
                    placeholder={t('global.search')}
                  />
                  <Popover position="bottom-end" zIndex={1000}>
                    <Popover.Target>
                      <ActionIcon className="table-control-actionicon">
                        <Icon icon="filter" />
                      </ActionIcon>
                    </Popover.Target>
                    <Popover.Dropdown className="column-filters-container">
                      <ColumnFilters
                        filters={currentFiltersConfig}
                        onFiltersChange={handleColumnFilterChange}
                      />
                    </Popover.Dropdown>
                  </Popover>
                  <ColumnsConfigPopover
                    columns={currentColumnsConfig}
                    onChange={handleColumnChange}
                    title={t('vulnerabilities.table.controls.customizeColumns')}
                  />
                </div>
                <div className={`table-controls-right ${triageOpened && 'drawer-open'}`}>
                  <Tooltip
                    disabled={selectedCVEs.length > 0}
                    label={t('triage.buttonDisabledTooltip')}
                  >
                    <span>
                      {hasWriteAccess && (
                        <Button
                          disabled={selectedCVEs.length === 0 || triageOpened}
                          onClick={openTriageDrawer}
                        >
                          {t('triage.buttonLabel')}
                        </Button>
                      )}
                    </span>
                  </Tooltip>
                </div>
              </div>
              <div className="current-filters">
                {currentFiltersConfig.map((filter) => {
                  const activeFilters = filter.options.filter((option) => option.active);
                  if (activeFilters.length > 0) {
                    return (
                      <div className="active-filter" key={filter.id}>
                        <div className="title">{filter.label}:</div>
                        <div className="filters">
                          {activeFilters
                            .map((activeFilter) => activeFilter.label)
                            .join(', ')}
                        </div>
                        <Icon
                          icon="close"
                          onClick={() => {
                            handleColumnFilterChange(filter.id, []);
                          }}
                        />
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
              <div className="table-wrapper">
                <MantineReactTable table={table} />
              </div>
              <div className={`table-footer-wrapper ${triageOpened && 'drawer-open'}`}>
                <DataTableFooter
                  currentPage={pagination.pageIndex}
                  limitPerPage={pagination.pageSize}
                  totalResults={countVulnerabilities}
                  onChangePage={setPagination}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="right">
          <Affix
            position={{ bottom: 0, right: 0 }}
            className={`right vuln-triage-tool-container ${
              triageOpened && 'drawer-open'
            }`}
          >
            {triageOpened && (
              <Triage
                selectedCVEs={selectedCVEs}
                onClose={closeTriageDrawer}
                updateIsCloseable={setTriageCloseable}
                closeable={triageCloseable}
                openCloseModal={openCloseModal}
                setOpenCloseModal={setOpenCloseModal}
                organizationAssetId={assetId || ''}
              />
            )}
          </Affix>
        </div>
      </div>
    </>
  );
};
