import { Button } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';
import Icon from '../../components/Icon';
import Loading from '../../components/Loading';
import Logo from '../../components/Logo';
import { useNotifications } from '../../hooks/utils/useNotifications';
import '../../scss/pages/vendor_portal.scss';

function VendorPortalThankyou() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { success: showSuccessNotification, error: showErrorNotification } =
    useNotifications();
  const [isLoading] = useState(false);
  const [requestAccountButtonDisabled, setRequestAccountButtonDisabled] = useState(false);
  // const [recipientEmail, setRecipientEmail] = useState('');
  const handleUploadMore = () => {
    navigate('/vendor-portal/upload-center');
  };
  useEffect(() => {
    const requestInfo = sessionStorage.getItem('requestInfo');
    if (!requestInfo) {
      navigate('/');
    }
  });

  const handleRequestAccount = async () => {
    const errors = [];
    try {
      setRequestAccountButtonDisabled(true);
      const requestInfo = sessionStorage.getItem('requestInfo');
      const vendorInfo = sessionStorage.getItem('vendorInfo');

      const {
        vendorEmail = null, // vendor email (person)
        vendorName = null, // vendor name (person)
        vendorOrganizationName = null, // vendor org name
        requestingOrgName = null, // requester org name
        requestingOrgId = null, // requester org id
        vendorHash = null, // token
        vendorInvitedEmail = null, // vendores email
      } = {
        ...JSON.parse((vendorInfo as string) || '{}'),
        ...JSON.parse((requestInfo as string) || '{}'),
      };

      if (!vendorHash || !vendorInvitedEmail || !requestingOrgId) {
        errors.push(t('page.vendorPortal.thankyou.requestAccountMessageSuccessful'));
      }

      if (errors.length < 1) {
        const body = {
          vendorEmail,
          vendorName,
          vendorOrganizationName,
          requestingOrgName,
          requestingOrgId,
          vendorHash,
          vendorInvitedEmail,
        };
        const createResponse = await AxiosProxy.post({
          url: 'vendor/requestAccount',
          withCredentials: true,
          body,
        });

        if (!createResponse.success) {
          errors.push(t('page.vendorPortal.thankyou.requestAccountMessageError'));
        }
      }
    } catch (error) {
      errors.push(t('page.vendorPortal.thankyou.requestAccountMessageError'));
    }
    if (errors.length < 1) {
      showSuccessNotification({
        title: t('page.vendorPortal.thankyou.requestSentHeader'),
        message: t('page.vendorPortal.thankyou.requestAccountMessageSuccessful'),
      });
    } else {
      showErrorNotification({
        title: t('page.vendorPortal.thankyou.requestFailedHeader'),
        message: t('page.vendorPortal.thankyou.requestAccountMessageError'),
      });
      setRequestAccountButtonDisabled(false);
    }
  };
  return (
    <div className="vendor-portal-container">
      {isLoading ? (
        <div className="loader-container">
          <Loading />
        </div>
      ) : (
        <>
          <div className="banner">
            {' '}
            <Logo wordmark={true} />
          </div>
          <div className="thankyou-content portal-text">
            <div className="pane">
              <div className="header-icon">
                <Icon icon="lock-keyhole" iconStyle="fal" />
              </div>
              <div className="header">{t('page.vendorPortal.thankyou.header')}</div>
              {/* <div className="subHeader thankyou-subHeader">
                {t('page.vendorPortal.thankyou.subHeader', { recipientEmail })}
              </div> */}
              <Button variant="default" mt="lg" onClick={() => handleUploadMore()}>
                {t('page.vendorPortal.thankyou.uploadMore')}
              </Button>
              <div className="manifest-pitch">
                <div className="header-alt centered">
                  {t('page.vendorPortal.thankyou.manifestPitch.header')}
                </div>
                <div className="centered pitch-subHeader">
                  {t('page.vendorPortal.thankyou.manifestPitch.subHeader')}
                </div>
                <div className="features-container">
                  <div className="feature">
                    <Icon icon="database" iconStyle="far" />
                    <div className="centered subHeader">
                      {t('page.vendorPortal.thankyou.manifestPitch.featureOne')}
                    </div>
                  </div>
                  <div className="feature">
                    <Icon icon="shield-exclamation" iconStyle="far" />
                    <div className="centered subHeader">
                      {t('page.vendorPortal.thankyou.manifestPitch.featureTwo')}
                    </div>
                  </div>
                  <div className="feature">
                    <Icon icon="share-nodes" iconStyle="far" />
                    <div className="centered subHeader">
                      {t('page.vendorPortal.thankyou.manifestPitch.featureThree')}
                    </div>
                  </div>
                </div>
                <Button
                  disabled={requestAccountButtonDisabled}
                  onClick={() => handleRequestAccount()}
                >
                  {t('page.vendorPortal.thankyou.manifestPitch.requestAccount')}
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default VendorPortalThankyou;
