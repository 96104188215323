import { rejectFailedRequestOrEmptyData } from '../../lib/request/rejectFailedRequest/rejectFailedRequest';
import { APIResponse } from '../../types/api_response.model';
import { ApiPortalResponse } from '../../types/apiPortalResponse.interface';
import { AxiosProxy } from '../axiosProxy/axiosProxy';
import { PORTAL_ERROR_CODES } from './sharingPortalErrorCode.contant';

const getPortal = async (): Promise<ApiPortalResponse['portal'] | null> => {
  const response: APIResponse<ApiPortalResponse> = await AxiosProxy.get({
    url: 'portal',
  });

  if (
    response.success === false &&
    response.errors &&
    response.errors.includes(PORTAL_ERROR_CODES.NOT_FOUND)
  ) {
    return null;
  }

  return (await rejectFailedRequestOrEmptyData(response)).data.portal;
};

export interface UpdatePortalPayload {
  isActive: boolean;
  portalName: string;
  logoUrl: string;
}

const updatePortal = async (payload: UpdatePortalPayload): Promise<ApiPortalResponse> => {
  const response: APIResponse<ApiPortalResponse> = await AxiosProxy.post({
    url: `portal`,
    withCredentials: true,
    body: payload,
  });

  await rejectFailedRequestOrEmptyData(response);

  return response.data!;
};

export interface UpdatePortalResponse {
  data: {
    _id: string;
    logoUrl: string;
    isActive: boolean;
    portalName: string;
  };
  success: boolean;
  errors?: string[];
}

export const SharingPortalApi = {
  getPortal,
  updatePortal,
};
