import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';
import { SBOM_ALERTS_QUERY_KEY } from '../../pages/UserSettings/SbomUploadAlerts/hooks/useFetchSbomAlerts';
import { SbomAlert } from '../../pages/UserSettings/SbomUploadAlerts/types/SBOMAlerts.interface';

interface Params {
  alert: SbomAlert;
}

export const usePutAlert = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ alert }: Params) => {
      return AxiosProxy.put({
        url: `/organization/notificationSettings/${alert?._id?.toString()}`,
        withCredentials: true,
        body: alert,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [SBOM_ALERTS_QUERY_KEY], //re-fetch the alerts list
      });
      queryClient.invalidateQueries({
        queryKey: ['sbomUploadAlert'], //re-fetch the alert
      });
    },
  });
};
