import { InterfaceOrganizationAsset } from '@manifest-cyber/types/interface/dbTables';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';

interface UseDeleteAssetsProps {
  hasErrorCallback: (errors: string[]) => void;
}

interface ApiResponseWithErrors<T> extends AxiosResponse<T> {
  errors: string[];
}

export const useDeleteAssets = ({ hasErrorCallback }: UseDeleteAssetsProps) => {
  const queryClient = useQueryClient();

  return useMutation<
    ApiResponseWithErrors<InterfaceOrganizationAsset[]>,
    unknown,
    [string, string[]]
  >({
    mutationFn: (variables: [string, string[]]) => {
      const [productId, newAssets] = variables;
      return AxiosProxy.delete({
        url: `product/${productId}/assets`,
        withCredentials: true,
        body: { assetIds: newAssets },
      });
    },
    onSuccess: (response: ApiResponseWithErrors<InterfaceOrganizationAsset[]>) => {
      if (response.errors.length) {
        hasErrorCallback(response.errors);
      } else {
        hasErrorCallback([]);
        queryClient.invalidateQueries({
          queryKey: [`assets`],
        });
      }
    },
  });
};
