import {
  InterfaceRiskCategory,
  IRCategoryObject,
  IRCategoryObjectForKev,
} from '@manifest-cyber/types/interface/db';

export interface GradientColor {
  color: string;
  percentage: number;
}

export const gradientColors: [
  GradientColor,
  GradientColor,
  GradientColor,
  GradientColor,
] = [
  { color: '#E4E6EA', percentage: 18.06 },
  { color: '#EAD077', percentage: 37.34 },
  { color: '#E49937', percentage: 65.66 },
  { color: '#EB5545', percentage: 95.18 },
];

/**
 * Interpolates between two colors based on a fraction t.
 * @param {string} startColor - The starting color in hex format.
 * @param {string} endColor - The ending color in hex format.
 * @param {number} t - The interpolation factor, between 0 and 1.
 * @returns {string} - The interpolated color in hex format.
 */
export function interpolateColor(
  startColor: string,
  endColor: string,
  t: number,
): string {
  const start = parseInt(startColor.slice(1), 16);
  const end = parseInt(endColor.slice(1), 16);

  const sr = (start >> 16) & 0xff,
    sg = (start >> 8) & 0xff,
    sb = start & 0xff;

  const er = (end >> 16) & 0xff,
    eg = (end >> 8) & 0xff,
    eb = end & 0xff;

  const r = Math.round(sr + (er - sr) * t);
  const g = Math.round(sg + (eg - sg) * t);
  const b = Math.round(sb + (eb - sb) * t);

  return `#${((r << 16) | (g << 8) | b).toString(16).padStart(6, '0')}`;
}

/**
 * Adjusts the gradient colors for a specified interval [x, y] within the range [0, 10].
 * @param {number} x - The start of the interval.
 * @param {number} y - The end of the interval.
 * @returns {string} - The adjusted gradient as a CSS gradient string.
 */
export function adjustGradientForInterval(x: number, y: number): string {
  if (x < 0 || y > 10 || x >= y) {
    throw new Error('Invalid interval. Ensure 0 <= x < y <= 10.');
  }

  const intervalLength = y - x;
  const adjustedGradient: GradientColor[] = [];

  for (let i = 0; i < gradientColors.length - 1; i++) {
    const startColor = gradientColors[i]!;
    const endColor = gradientColors[i + 1]!;

    const startPos = (startColor.percentage / 100) * 10;
    const endPos = (endColor.percentage / 100) * 10;

    // Skip colors that are completely outside the interval
    if (endPos < x || startPos > y) {
      continue;
    }

    // Handle the color at the start of the interval
    if (startPos <= x && endPos >= x) {
      const interpolatedColor = interpolateColor(
        startColor.color,
        endColor.color,
        (x - startPos) / (endPos - startPos),
      );
      adjustedGradient.push({ color: interpolatedColor, percentage: 0 });
    }

    // Add colors that fall within the interval
    if (startPos >= x && startPos <= y) {
      adjustedGradient.push({
        color: startColor.color,
        percentage: ((startPos - x) / intervalLength) * 100,
      });
    }

    // Handle the color at the end of the interval
    if (endPos >= x && endPos <= y) {
      adjustedGradient.push({
        color: endColor.color,
        percentage: ((endPos - x) / intervalLength) * 100,
      });
    }

    // Handle the case where the interval ends within a gradient segment
    if (startPos <= y && endPos >= y) {
      const interpolatedColor = interpolateColor(
        startColor.color,
        endColor.color,
        (y - startPos) / (endPos - startPos),
      );
      adjustedGradient.push({ color: interpolatedColor, percentage: 100 });
    }
  }

  // Ensure the gradient starts at 0% and ends at 100%
  if (
    adjustedGradient.length === 0 ||
    (adjustedGradient[0] && adjustedGradient[0].percentage > 0)
  ) {
    const interpolatedColor = interpolateColor(
      adjustedGradient[0]?.color ?? '',
      gradientColors[1].color,
      x / ((gradientColors[1].percentage / 100) * 10),
    );
    adjustedGradient.unshift({ color: interpolatedColor, percentage: 0 });
  }

  if (adjustedGradient[adjustedGradient.length - 1]?.percentage ?? 0 < 100) {
    const lastColor = adjustedGradient[adjustedGradient.length - 1];
    const interpolatedColor = interpolateColor(
      lastColor?.color ?? '',
      gradientColors[gradientColors.length - 1]?.color ?? '',
      (y - ((lastColor?.percentage ?? 0 / 100) * intervalLength + x)) /
        (((gradientColors?.[(gradientColors?.length ?? 0) - 1]?.percentage ?? 0) / 100) *
          10 -
          y),
    );
    adjustedGradient.push({ color: interpolatedColor, percentage: 100 });
  }

  // Remove duplicate colors to avoid redundancy
  const uniqueGradient = adjustedGradient.filter((item, index) => {
    return index === 0 || item.color !== adjustedGradient[index - 1]?.color;
  });

  return uniqueGradient
    .map(({ color, percentage }) => `${color} ${percentage.toFixed(2)}%`)
    .join(', ');
}

export const generateMarks = (min: number, max: number, step: number) => {
  const marks = [];
  for (let i = min; i <= max; i = Math.round((i + step) * 100) / 100) {
    marks.push({ value: i });
  }
  return marks;
};

export const checkOverlap = (
  currentRange: [number, number],
  currentRisk: string,
  riskCategory: InterfaceRiskCategory,
) => {
  const ranges = Object.entries(riskCategory)
    .filter(
      ([risk, data]) =>
        risk !== currentRisk && data?.criteria && Array.isArray(data.criteria),
    )
    .map(([, data]) => data.criteria as [number, number]);

  return ranges.some(
    ([start, end]) =>
      !(currentRange[1] < start || currentRange[0] > end) ||
      currentRange[1] === start ||
      currentRange[0] === end,
  );
};

export const parseRemediationTime = (remediationTimeFrame: string) => {
  const number = parseInt(remediationTimeFrame.slice(0, -1));
  const unit = remediationTimeFrame.slice(-1);
  return { number, unit };
};

export const initializeFormData = (
  rowData: IRCategoryObject | IRCategoryObjectForKev | null | undefined,
  setFormData: (data: IRCategoryObject | IRCategoryObjectForKev | undefined) => void,
  setRemediationTime: (time: { number: number; unit: string }) => void,
  setCriteriaRange: (range: [number, number]) => void,
) => {
  setFormData(rowData || {});
  if (rowData?.remediationTimeFrame) {
    setRemediationTime(parseRemediationTime(rowData.remediationTimeFrame));
  }
  if (Array.isArray(rowData?.criteria)) {
    setCriteriaRange(rowData.criteria as [number, number]);
  }
};
