import type { InterfaceVulnerabilityTriageStatus } from '@manifest-cyber/types/interface/dbTables';
import { useQuery } from '@tanstack/react-query';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';

interface Options {
  enabled?: boolean;
  refetchOnWindowFocus?: boolean;
  stateTime?: number;
}

export const useFetchTriageStatuses = (options?: Options) => {
  let fetchUrl = `/triage/statuses`;
  fetchUrl = fetchUrl.replace('?&', '?');

  return useQuery({
    enabled: options?.enabled,
    staleTime: options?.stateTime ?? 1000 * 60 * 60, // 1 hour (or until invalidated)
    queryKey: ['triageStatuses', `/${fetchUrl}`],
    queryFn: () => AxiosProxy.get({ url: fetchUrl }),
    select: (data) => {
      let customTriageStatuses = (data?.data ??
        []) as InterfaceVulnerabilityTriageStatus[];
      return customTriageStatuses;
    },
    refetchOnWindowFocus: options?.refetchOnWindowFocus,
  });
};
