import { useQuery } from '@tanstack/react-query';
import {
  GetSharedSbomsParams,
  SharedSbomsApi,
} from '../../api/sharedSboms/sharedSboms.api';

interface Options {
  enabled?: boolean;
}

export const getSharedSbomsCacheKey = (
  params: Parameters<typeof SharedSbomsApi.getUrl>[0],
) => ['shared-elements', SharedSbomsApi.getUrl(params)];

export const useSharedSboms = (
  { texts, requestOptions }: GetSharedSbomsParams,
  options?: Options,
) => {
  return useQuery({
    refetchInterval: 1000 * 15,
    enabled: options?.enabled,
    keepPreviousData: true,
    queryKey: getSharedSbomsCacheKey(requestOptions),
    queryFn: () => SharedSbomsApi.getElements({ texts, requestOptions }),
  });
};
