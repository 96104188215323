import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { CSSProperties } from 'react';
import { captureExceptionWithMessage } from '../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';
import './Icon.scss';

// Default FontAwesome6 icon style to use
const defaultIconStyle: IconPrefix = 'far';
const sharpStyles: IconPrefix = 'fass';

const iconSprite = '';

// Available custom icons
// e.g. for specific icons we designed instead of using FA
const customIcons: string[] = ['okta', 'auth0'];

export interface IconProps extends FontAwesomeIconProps {
  icon: IconName;
  iconSharp?: boolean;
  fixedWidth?: boolean;
  iconStyle?: IconPrefix | 'manifest';
  classNames?: string;
  className?: string;
  label?: string;
  style?: CSSProperties;
  onClick?: () => void;
  spin?: boolean;
}

/**
 * Wraps FontAwesomeIcon and custom icons so that we can specify app-wide defaults (e.g. iconStyle, etc)
 * If adding a custom icon, make sure to run `generate-custom-icons` in package.json to generate a sprite and add to `customIcons` array above
 */
export const Icon = ({
  classNames = '',
  fixedWidth = true,
  iconStyle = defaultIconStyle,
  iconSharp = true,
  label = '',
  icon,
  className,
  ...rest
}: IconProps) => {
  let _iconStyle: IconProps['iconStyle'] = iconStyle || defaultIconStyle;
  if (iconSharp && sharpStyles === iconStyle) {
    _iconStyle = iconStyle;
  }

  if (!icon || icon.length < 1) return null;

  if (_iconStyle === 'manifest') {
    if (!customIcons.includes(icon)) {
      captureExceptionWithMessage(`Custom Dash "${icon}" does not exist.`);
      return null;
    }

    if (customIcons.length > 0) {
      return (
        <svg
          className={`manifest-icon ${classNames || className}`}
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
        >
          <use xlinkHref={`${iconSprite}#${icon}`} />
        </svg>
      );
    }

    return null;
  }

  // Account for add'l classes, etc
  const concatIconStyles: string[] = [];
  concatIconStyles.push(iconStyle);
  concatIconStyles.push(icon);

  if (label) {
    return (
      <div className="icon-w-label">
        <FontAwesomeIcon
          className={`fa-icon manifest-icon ${classNames || className}`}
          fixedWidth={fixedWidth}
          icon={[_iconStyle, icon]}
          {...rest}
        />
        <div className="label">{label}</div>
      </div>
    );
  }

  return (
    <FontAwesomeIcon
      className={`fa-icon manifest-icon ${classNames || className}`}
      fixedWidth={fixedWidth}
      icon={[_iconStyle, icon]}
      {...rest}
    />
  );
};

export default Icon;
