import { Badge, Tooltip } from '@mantine/core';
import copy from 'copy-to-clipboard';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'rsuite';
import featureFlagDisciminators from '../../configs/featureFlags';
import { useAuth } from '../../hooks/useAuth';
import useFeatureFlag from '../../hooks/useFeatureFlag';
import { getOrgColorCode } from '../../lib/orgIconColor';
import '../../scss/userDropdown.scss';
import { Organization } from '../../types/organization.interface';
import Icon from '../Icon/Icon';
import SwitchOrgsModal from './SwitchOrgsModal';

interface IUserDropdownProps {
  currentOrgData?: Organization;
}
function UserDropdown({ currentOrgData }: IUserDropdownProps) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [userEmail, setUserEmail] = useState('');
  const [multipleOrgs, setMultipleOrgs] = useState(false);
  const [isAdminUser, setIsAdminUser] = useState(false);
  const [isSwitschOrgsModalOpen, setIsSwitchOrgsModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      const { internalRoles, organizations } = user;
      const [email = ''] = user?.decryptedEmails;
      setIsAdminUser(internalRoles?.includes('staff'));
      setMultipleOrgs(organizations?.length > 1);
      setUserEmail(email);
    }
  }, [user]);

  const renderDropdownToggle = (props: any, ref: any) => {
    const expanded = props['aria-expanded'];
    return (
      <button
        className={`flex btn-manifest-dropdown-toggle ${expanded && 'active'}`}
        {...props}
        ref={ref}
      >
        <Icon icon="user" />
        <span>{currentOrgData?.name || ''}</span>
      </button>
    );
  };

  const handleSwitchOrgs = () => {
    setIsSwitchOrgsModalOpen(true);
  };

  const isEngineerElementsEnabled = useFeatureFlag(
    featureFlagDisciminators.engineerElements,
  );
  return (
    <>
      <Dropdown
        renderToggle={renderDropdownToggle}
        className="userDropdown dropdown-header anim-slideInDownShort"
        trigger={['click']}
        placement="bottomEnd"
      >
        <Dropdown.Item panel className="dropdown-item">
          <div className={`org-header`}>
            <Badge
              style={{
                margin: 0,
                color: 'var(--color-forceWhite)',
                borderRadius: '5px',
                backgroundColor: getOrgColorCode(currentOrgData?.name || ''),
              }}
            >
              {currentOrgData?.name}
            </Badge>
            {isEngineerElementsEnabled && (
              <Tooltip label="Copy organization ID to clipboard">
                <span
                  className="copy-org-id"
                  onClick={() => copy(`${currentOrgData?._id}`)}
                >
                  <Icon icon="copy" />
                </span>
              </Tooltip>
            )}
          </div>
          <span className="muted">{userEmail}</span>
        </Dropdown.Item>
        <Dropdown.Separator className="separator" />
        {multipleOrgs && (
          <>
            <Dropdown.Item className="flex dropdown-item" onClick={handleSwitchOrgs}>
              <Icon icon="arrow-right-arrow-left" />
              <span>{t('navigation.switchOrganizations')}</span>
            </Dropdown.Item>
            <Dropdown.Separator className="separator" />
          </>
        )}
        <Dropdown.Item
          className="flex dropdown-item"
          onClick={() => {
            navigate('/settings');
          }}
        >
          <Icon icon="circle-user" />
          <span>{t('navigation.settings')}</span>
        </Dropdown.Item>
        {isAdminUser && (
          <Dropdown.Item
            className="flex dropdown-item"
            onClick={() => {
              navigate('/administration');
            }}
          >
            <Icon icon="users" />
            <span>{t('navigation.admin.label')}</span>
            <span className="label-internal">{t('navigation.admin.internal')}</span>
          </Dropdown.Item>
        )}
        <Dropdown.Item
          className="flex dropdown-item"
          onClick={() => {
            navigate('/logout?reason=user-manual');
          }}
        >
          <Icon icon="sign-out-alt" />
          <span>{t('navigation.logout.label')}</span>
        </Dropdown.Item>
      </Dropdown>
      <SwitchOrgsModal
        open={isSwitschOrgsModalOpen}
        onClose={() => {
          setIsSwitchOrgsModalOpen(false);
        }}
      />
    </>
  );
}

export default UserDropdown;
