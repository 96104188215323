import { InterfaceOrganizationTicket } from '@manifest-cyber/types/interface/dbTables';
import { useQuery } from '@tanstack/react-query';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';

export const useFetchTickets = (entityId: string, entityType: string) => {
  const fetchUrl = `/integration/merge/tickets?entityId=${entityId}&entityType=${entityType}`;

  return useQuery({
    queryKey: ['tickets', `/${fetchUrl}`],
    enabled: Boolean(entityId),
    queryFn: () => AxiosProxy.get({ url: fetchUrl }),
    select: (data) => data?.data as InterfaceOrganizationTicket[],
  });
};
