import { Box, Tooltip } from '@mantine/core';
import Icon from '../../Icon';

export const HeaderWithTooltip = ({
  text,
  tooltip,
}: {
  text: string;
  tooltip: string;
}) => (
  <span>
    {text}
    <Tooltip label={<Box maw={200}>{tooltip}</Box>} multiline>
      <Box component="span" style={{ marginLeft: '5px' }}>
        <Icon icon="question-circle" style={{ position: 'relative', top: '1px' }} />
      </Box>
    </Tooltip>
  </span>
);
