import '../../scss/pages/integrations.scss';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IntegrationCard } from './integrations/components/IntegrationCard';
import { IIntegration, getAllIntegrations } from './integrations/integrations-data';
import { useFetchIntegrations } from '../../hooks/queries/useFetchIntegrations';
import { Loader } from 'rsuite';
import { useAuth } from '../../hooks/useAuth';

function Integrations() {
  const { t } = useTranslation();
  const { checkUserAccess } = useAuth();

  const allIntegrationInfos = getAllIntegrations(t);
  const hasWriteAccess = checkUserAccess('write');

  const { data: fetchedIntegrations, isLoading: isLoadingIntegrations } =
    useFetchIntegrations();

  const activeIntegrationTypes = useMemo(() => {
    const configuredIntegrations = new Set<string>();
    fetchedIntegrations?.forEach((fetchedIntegration) => {
      if (
        (fetchedIntegration.status === 'active' || fetchedIntegration.status === null) &&
        fetchedIntegration.integrationType
      )
        configuredIntegrations.add(fetchedIntegration.integrationType);
    });
    return configuredIntegrations;
  }, [fetchedIntegrations]);

  const configuredIntegrationInfos = useMemo(() => {
    const configuredIntegrationInfos: IIntegration[] = [];
    allIntegrationInfos.forEach((integration) => {
      const integrationStatus = fetchedIntegrations?.find((fetchedIntegration) => {
        return fetchedIntegration.integrationType === integration.integrationType;
      })?.status;
      const configurationStatus =
        integrationStatus === undefined
          ? null
          : integrationStatus === null
            ? 'pending'
            : integrationStatus;
      if (activeIntegrationTypes.has(integration.integrationType)) {
        configuredIntegrationInfos.push(
          integrationStatus !== undefined
            ? { ...integration, integrationStatus: configurationStatus }
            : integration,
        );
      }
    });
    return configuredIntegrationInfos;
  }, [allIntegrationInfos, activeIntegrationTypes, fetchedIntegrations]);

  return (
    <div className="page-integrations anim-slideInDownShort">
      <h3 className="header">{t('page.userSettings.integrations.header')}</h3>
      <h4>{t('page.userSettings.integrations.sub-header')}</h4>
      <h5>{t('page.userSettings.integrations.configured')}</h5>
      <hr />
      {isLoadingIntegrations ? (
        <Loader />
      ) : !!configuredIntegrationInfos.length ? (
        <>
          <div className="integration-cards anim-slideInUp">
            {configuredIntegrationInfos.map((integration, index) => {
              return (
                <IntegrationCard
                  action={integration.integrationStatus === 'active' ? 'edit' : 'pending'}
                  key={index}
                  hasWriteAccess={hasWriteAccess}
                  {...integration}
                />
              );
            })}
          </div>
        </>
      ) : (
        <p className="no-configured">
          {t('page.userSettings.integrations.no-configured')}
        </p>
      )}
      <h5 className="all-integrations">
        {t('page.userSettings.integrations.all-integrations')}
      </h5>
      <hr />
      <div className="integration-cards anim-slideInUp">
        {allIntegrationInfos.map((integration, index) => {
          // if (integration?.integrationType === 'servicenow') return null;
          return (
            <IntegrationCard
              action={
                activeIntegrationTypes.has(integration.integrationType)
                  ? 'installed'
                  : 'add'
              }
              key={index}
              hasWriteAccess={hasWriteAccess}
              {...integration}
            />
          );
        })}
      </div>
    </div>
  );
}

export default Integrations;
