import { CloseButton, Flex, Skeleton } from '@mantine/core';
import styles from './RemovableListItem.module.scss';

interface RemovableListItemProps {
  title: string;
  subtitle: string;
  onRemove: () => void;
  removeLabel: string;
  rigthSection?: React.ReactNode;
  isLoading?: boolean;
  showLoadingInAction?: boolean;
}

export const RemovableListItem = ({
  title,
  subtitle,
  onRemove,
  removeLabel,
  rigthSection,
  isLoading,
  showLoadingInAction,
}: RemovableListItemProps) => {
  return (
    <Flex w="100%" justify={'space-between'}>
      <Flex gap="8px">
        {rigthSection}
        <Flex direction="column" gap="4px">
          <Skeleton radius="md" visible={isLoading} className={styles.skeleton}>
            <div className={styles.title}>{title}</div>
          </Skeleton>
          <Skeleton radius="md" visible={isLoading} className={styles.skeleton}>
            <div className={styles.subtitle}>{subtitle}</div>
          </Skeleton>
        </Flex>
      </Flex>
      <Skeleton
        radius="xs"
        visible={isLoading || showLoadingInAction}
        className={styles.removeButtonSkeleton}
        height={'20px'}
       
      >
        <CloseButton onClick={onRemove} size="md" aria-label={removeLabel} />
      </Skeleton>
    </Flex>
  );
};
