import { useQuery } from '@tanstack/react-query';
import { TFunction } from 'i18next';
import { MappedPageConfig } from '../../../../api/customFields/pageConfig.interface';
import { ComponentsPageConfigApi } from './componentsPageConfig.api';
import { ComponentsColumnId } from './componentsPageConfig.mapper';

export const COMPONENT_PAGE_CONFIG_CACHE_KEY = 'componentsPageConfig';

export const useGetComponentsPageConfig = ({
  t,
  options,
}: {
  t: TFunction;
  options?: { placeholderData?: MappedPageConfig<ComponentsColumnId> };
}) =>
  useQuery({
    queryFn: () => ComponentsPageConfigApi.getComponentsPageConfig({ t }),
    queryKey: [COMPONENT_PAGE_CONFIG_CACHE_KEY],
    keepPreviousData: true,
    placeholderData: options?.placeholderData,
    refetchOnMount: true,
  });
