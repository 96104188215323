import { APIResponse, APIResponseWithQueryInfo } from '../types/api_response.model';
import { SbomInterface } from '../types/sboms.interface';
import { AxiosProxy } from './axiosProxy/axiosProxy';

export type MergeSBOMsResponse = {
  mergedSbom: string;
  sbomId: string;
};

export type SBOMTargetStatus = 'successful' | 'pending' | 'failed';

export type FetchSBOMsParams = {
  assetId?: string;
  limit?: number;
  page?: number;
  searchText?: string;
  sortColumn?: string;
  sortType?: string;
  targetStatus?: SBOMTargetStatus;
};

export const getSBOMs = async (
  params: FetchSBOMsParams,
): Promise<APIResponseWithQueryInfo<SbomInterface[]>> => {
  const { assetId, limit, sortColumn, sortType, page, searchText, targetStatus } = params;
  let fetchUrl = `/sboms?`;
  if (assetId) {
    fetchUrl += `&assetId=${assetId}`;
  }
  if (limit) {
    fetchUrl += `&limit=${limit}`;
  }
  if (sortColumn && sortType) {
    fetchUrl += `&sort={"${sortColumn}":${sortType === 'asc' ? 1 : -1}}`;
  }
  if (page) {
    fetchUrl += `&page=${page}`;
  }
  if (searchText) {
    fetchUrl += `&searchText=${searchText}`;
  }
  if (targetStatus) {
    fetchUrl += `&targetStatus=${targetStatus}`;
  }
  fetchUrl.replace('?&', '?');

  const response = (await AxiosProxy.get({ url: fetchUrl })) as APIResponseWithQueryInfo<
    SbomInterface[]
  >;
  return response;
};

export const getMergedSBOMs = async (sbomIDs: string[]) => {
  const response = await AxiosProxy.get({
    url: 'sboms/multiple-sbom-return',
    withCredentials: true,
    params: {
      merge: true,
      sbomIds: sbomIDs.join(',') || '',
    },
  });
  return response as APIResponse<MergeSBOMsResponse[]>;
};
