import { Flex } from '@mantine/core';
import { useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import MessageWithBackground from '../../../../../components/MessageWithBackground/MessageWithBackground';
import { usePostAlert } from '../../../../../hooks/mutations/usePostAlert';
import { isUniqueString } from '../../../../../lib/string/isUniqueString';
import SbomAlertHeader from '../../components/AlertHeader/AlertHeader';
import { AlertNameInput } from '../../components/AlertNameInput/AlertNameInput';
import DeliveryMethod from '../../components/DeliveryMethodSection/DeliveryMethodSection';
import RiskCriteriaSection from '../../components/RiskCriteriaSection/RiskCriteriaSection';
import Source from '../../components/SourceSection/SourceSection';
import { useFetchSbomAlerts } from '../../hooks/useFetchSbomAlerts';
import { useSbomAlertForms } from '../../hooks/useSbomAlertsForms';
import { alertCreateReducer, getInitialState } from '../../reducer/alertCreateReducer';
import { RiskCriteriaSelector } from '../../reducer/alertCreateReducer.interface';
import { mapFormsToAlertPayload } from '../../utils/mapFormsToAlertPayload';
import { removeRiskCriteria } from '../../utils/removeRiskCriteria';

function AlertCreate() {
  const [{ source, riskCriteria, deliveryMethod }, dispatch] = useReducer(
    alertCreateReducer,
    getInitialState('create'),
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [hasCreationError, setHasCreationError] = useState(false);
  const { data: alertsResponse, isLoading: isLoadingAlerts } = useFetchSbomAlerts();
  const { mutateAsync: createAlert, isLoading: isCreatingAlert } = usePostAlert();

  const {
    deliveryMethodsForm,
    riskCriteriasForm,
    sourceForm,
    sectionsConfig,
    riskCriteriasInitialValues,
  } = useSbomAlertForms({
    riskCriteriaState: riskCriteria,
  });

  const handleCancelClick = async () => {
    navigate(`/settings/sbom-upload-alerts`);
  };

  const handleSaveClick = async () => {
    if (!alertsResponse?.notificationEventId || !alertsResponse?.alerts) {
      return;
    }
    setHasCreationError(false);

    // Validating here in case the user filled the name field before alerts request has finishes and name validation in on blur did not run
    const isUniqueName = isUniqueString({
      list: alertsResponse.alerts.mappedAlerts.map((alert) => alert.name),
      query: sourceForm.values.name,
      isCaseSensitive: false,
    });

    if (!isUniqueName) {
      sourceForm.setErrors({
        ...sourceForm.errors,
        name: t('page.userSettings.sbomUploadAlerts.nameIsDuplicatedErrorMessage'),
      });
      return;
    }

    if (!(sourceForm.isValid() && deliveryMethodsForm.isValid())) {
      sourceForm.validate();
      deliveryMethodsForm.validate();
      return;
    }
    const alertToCreate = mapFormsToAlertPayload({
      notificationEventId: alertsResponse.notificationEventId,
      riskCriteriasForm,
      sourceForm,
      deliveryMethodsForm,
      isActive: true,
    });
    try {
      const response = await createAlert({ alert: alertToCreate });
      if (response.success) {
        navigate('/settings/sbom-upload-alerts');
      } else {
        setHasCreationError(true);
      }
    } catch {
      setHasCreationError(true);
    }
  };

  const getActiveSectionHasErrors = () => {
    if (source.state === 'edit') {
      const { hasErrors } = sourceForm.validate();
      return hasErrors;
    }

    if (riskCriteria.state === 'edit') {
      const { hasErrors } = riskCriteriasForm.validate();
      return hasErrors;
    }

    if (deliveryMethod.state === 'edit') {
      const { hasErrors } = deliveryMethodsForm.validate();
      return hasErrors;
    }
  };

  const enableSourceEdition = () => {
    if (getActiveSectionHasErrors()) {
      return;
    }

    dispatch({ type: 'enableEditionInSourceSection' });
  };

  const enableRiskCriteriaEdition = () => {
    if (getActiveSectionHasErrors()) {
      return;
    }

    dispatch({ type: 'enableEditionInRiskSection' });
  };

  const enableDeliveryMethodEdition = () => {
    if (getActiveSectionHasErrors()) {
      return;
    }

    dispatch({ type: 'enableEditionInDeliveryMethodSection' });
  };

  return (
    <Flex gap={20} direction={'column'}>
      <div>
        <SbomAlertHeader
          badge={{ isVisible: false }}
          title={t('page.userSettings.sbomUploadAlerts.createSbomAlert')}
          subtitle={t('page.userSettings.sbomUploadAlerts.createAlertSubheader')}
          goBack={handleCancelClick}
        />
        {hasCreationError && (
          <MessageWithBackground
            message={t('error.general.unexpectedErrorTryAgain')}
            messageType="critical"
          />
        )}
      </div>
      <AlertNameInput
        inputProps={{
          ...sourceForm.getInputProps('name'),
          onBlur: (event: React.FocusEvent<HTMLInputElement, Element>) => {
            const currentName = event.currentTarget.value;
            const isUnique = isUniqueString({
              list: alertsResponse?.alerts.mappedAlerts.map((alert) => alert.name) || [],
              query: currentName,
              isCaseSensitive: false,
            });

            if (!isUnique && currentName.length > 0) {
              sourceForm.setErrors({
                name: t(
                  'page.userSettings.sbomUploadAlerts.nameIsDuplicatedErrorMessage',
                ),
              });
            } else {
              sourceForm.getInputProps('name').onBlur(event);
            }
          },
        }}
        showLoader={isLoadingAlerts && sourceForm.getInputProps('name').value}
        label={t('page.userSettings.sbomUploadAlerts.alertName')}
      />

      <Source
        form={sourceForm}
        sectionConfig={sectionsConfig.sourceSection}
        state={source.state}
        nextButton={{
          isVisible: source.state === 'edit',
          onClick: () => {
            const { hasErrors } = sourceForm.validate();
            if (!hasErrors) {
              dispatch({ type: 'activateRiskSection' });
            }
          },
        }}
        editButton={{ isVisible: true, onClick: enableSourceEdition }}
      />
      <RiskCriteriaSection
        onClickRemoveCriteria={(criteria: RiskCriteriaSelector) => {
          removeRiskCriteria({
            criteria,
            form: riskCriteriasForm,
            dispatch,
            initialValues: riskCriteriasInitialValues,
          });
        }}
        onClickAddCriteria={() => {
          dispatch({ type: 'addRiskCriteria' });
        }}
        nextButton={{
          isVisible: riskCriteria.state === 'edit',
          onClick: () => {
            const { hasErrors } = riskCriteriasForm.validate();
            if (!hasErrors) {
              dispatch({ type: 'activateDeliveryMethodSection' });
            }
          },
        }}
        editButton={{ isVisible: true, onClick: enableRiskCriteriaEdition }}
        riskCriterias={riskCriteria.criterias}
        form={riskCriteriasForm}
        state={riskCriteria.state}
        sectionConfig={sectionsConfig.riskCriteriaSection}
      />
      <DeliveryMethod
        form={deliveryMethodsForm}
        state={deliveryMethod.state}
        nextButton={{
          onClick: handleSaveClick,
          isDisabled: isLoadingAlerts,
          isVisible: deliveryMethod.state === 'edit',
          isLoading: isCreatingAlert,
        }}
        editButton={{ onClick: enableDeliveryMethodEdition, isVisible: true }}
      />
    </Flex>
  );
}

export default AlertCreate;
