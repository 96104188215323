import * as Sentry from '@sentry/react';
import { MappedUser, mapUser } from '../../api/user/user.mapper';
import appConfig from '../../configs/appConfig';
import { CookieUtils } from './cookies.util';
import { LocalStorageUtils } from './localStorage.util';

const orgIdOptions: Cookies.CookieAttributes = {
  domain: `${appConfig.cookieDomain}`,
  path: '/',
};

const getUser = (): MappedUser | undefined => {
  const rawUser = LocalStorageUtils.getItem<MappedUser>('manifest-user');
  return rawUser ? mapUser(rawUser) : rawUser;
};

const getLastPath = (): string | undefined =>
  LocalStorageUtils.getItem<string>('lastPath');

const setLastPath = (path: string): void => LocalStorageUtils.setItem('lastPath', path);

const getPreviousOrgId = (): string | undefined =>
  LocalStorageUtils.getItem<string | undefined>('previousorgid');

const setPreviousOrgId = (id: string): void =>
  LocalStorageUtils.setItem('previousorgid', id);

const getLoginEmail = (): string | undefined =>
  LocalStorageUtils.getItem<string | undefined>('login-email');

const setLoginEmail = (id: string): void => LocalStorageUtils.setItem('login-email', id);

const removeLoginEmail = (): void => LocalStorageUtils.removeItem('login-email');

const getOrganizationId = () => CookieUtils.getCookie('organizationid');

const clearUserSession = () => {
  const lastPath = BrowserUserSessionUtils.getLastPath();
  Sentry.setUser(null);

  CookieUtils.removeCookie('organizationid', orgIdOptions);
  window.localStorage.clear();
  window.sessionStorage.clear();
  /* 
  When users without being loged in enter to some path that require authentication we:
  - Save that path in the local storage (eg. \o\some-portal)
  - Redirect them to /login
  - After a successful login we use the local storage value of previos path to redirect them to the path they were trying to access before loging in
  */

  if (lastPath) {
    BrowserUserSessionUtils.setLastPath(lastPath);
  }
};

const saveUserSession = (user: MappedUser) => {
  Sentry.setUser({
    id: user._id,
    ip_address: '{{auto}}',
    locale: user.locale || 'en-us',
    access: user.internalRoles,
  });

  LocalStorageUtils.setItem('manifest-user', user);
  CookieUtils.setCookie({
    key: 'organizationid',
    value: user.organizationId || '',
    options: orgIdOptions,
  });
};

const updateUserOrganization = (id: string) => {
  // we override the orgid cookie sent by BE since that one in some endpoints has the htttp: true blocking the frontend of reading it
  CookieUtils.setCookie({ key: 'organizationid', value: id, options: orgIdOptions });

  const prevUser = BrowserUserSessionUtils.getUser();
  if (prevUser) {
    const updatedUser: MappedUser = { ...prevUser, organizationId: id };
    LocalStorageUtils.setItem('manifest-user', updatedUser);
  }
};

export const BrowserUserSessionUtils = {
  clearUserSession,
  saveUserSession,
  getUser,
  getOrganizationId,
  getLastPath,
  getLoginEmail,
  getPreviousOrgId,
  removeLoginEmail,
  setLastPath,
  setLoginEmail,
  setPreviousOrgId,
  updateUserOrganization,
};
