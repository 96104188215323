import { useForm } from '@mantine/form';
import { useEffect, useState } from 'react';
import { ManufacturerErrors } from '../../components/Product/FormValidation/ManufacturerErrors';
import { NameErrors } from '../../components/Product/FormValidation/NameErrors';
import { VersionErrors } from '../../components/Product/FormValidation/VersionErrors';
import { ProductFormData, usePostProduct } from './usePostProduct';

interface UseProductModalProps {
  isEditMode: boolean;
  editProductData: ProductFormData | undefined;
  closeModal: () => void;
}

export const useProductModal = ({
  isEditMode,
  editProductData,
  closeModal,
}: UseProductModalProps) => {
  const form = useForm<Omit<ProductFormData, 'id'>>({
    initialValues:
      isEditMode && editProductData
        ? editProductData
        : {
            name: '',
            version: '',
            manufacturer: '',
            // types: [],
            description: '',
            labels: [],
          },
  });

  /*const fetchTypes = async () => {
    const response = await hitApi.get(`products/types`);
    return response.data;
  };*/

  const [typesOptions, setTypesOptions] = useState<any[]>([]);
  // const typesQuery = useQuery(['fetchTypes'], fetchTypes);

  const [errors, setErrors] = useState<string[]>([]);

  const {
    mutate: mutateProduct,
    isError,
    isLoading,
  } = usePostProduct({
    closeModal: closeModal,
    hasErrorCallback: (errors: string[]) => setErrors(errors),
    isEdit: isEditMode,
  });

  /*useEffect(() => {
    if (typesQuery.data) {
      const formattedTypes = typesQuery.data.map((type: any) => ({
        value: type._id,
        label: type.name,
      }));
      setTypesOptions(formattedTypes);
    }
  }, [typesQuery.data]);*/

  useEffect(() => {
    if (isEditMode && editProductData) {
      form.setValues(editProductData);
    } else {
      form.reset();
    }
  }, [isEditMode]);

  // Manufacturer Validation
  useEffect(() => {
    if (form?.values?.manufacturer && form?.values?.manufacturer.length > 100) {
      form.setFieldError('manufacturer', <ManufacturerErrors />);
    }
  }, [form?.values?.manufacturer]);

  // Name Validation
  useEffect(() => {
    if (form?.values?.name && form.values.name.length > 100) {
      form.setFieldError('name', <NameErrors />);
    }
  }, [form?.values?.name]);

  // Version Validation
  useEffect(() => {
    if (form?.values?.version && form.values.version.length > 100) {
      form.setFieldError('version', <VersionErrors errorType="length" />);
    }
  }, [form?.values?.version]);

  useEffect(() => {
    if (
      errors.some(
        (error) =>
          error === 'Product already exists.' ||
          error === 'Product already exists by name and version.',
      )
    ) {
      form.setFieldError('version', <VersionErrors />);
    }
  }, [errors]);

  return {
    form,
    typesOptions,
    mutateProduct,
    isError,
    isLoading,
    setFieldValue: form.setFieldValue,
    values: form.values,
    reset: form.reset,
  };
};
