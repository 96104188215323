export const createAndClickLink = ({
  url,
  fileName,
}: {
  url: string;
  fileName: string;
}) => {
  const downloadLink = document.createElement('a');
  downloadLink.href = url;
  downloadLink.download = fileName;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};
