import { APIResponse } from '../types/api_response.model';
import { AxiosProxy } from './axiosProxy/axiosProxy';

export type GenerateSBOMResponse = {
  link: string;
  sbomId: string;
};

export type ProductCapabilityResponse = {
  capabilities: {
    canMerge: boolean;
    reason?: 'asset formats mismatching' | 'assets limit reached';
  };
};

export const postGenerateSBOM = async (
  productId: string,
  productName: string,
  productVersion: string,
): Promise<APIResponse<GenerateSBOMResponse>> => {
  const response: APIResponse<GenerateSBOMResponse> = await AxiosProxy.post({
    url: `product/${productId}/generate_sbom`,
    withCredentials: true,
    body: {
      name: productName,
      version: productVersion,
    },
  });
  return response;
};

export const getProductCapabilities = async (
  productId: string,
): Promise<APIResponse<ProductCapabilityResponse>> => {
  const response: APIResponse<ProductCapabilityResponse> = await AxiosProxy.get({
    url: `product/${productId}/capabilities`,
  });
  return response;
};
