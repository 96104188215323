import { Button, Flex, Input, Modal, Select, Tooltip } from '@mantine/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';
import Icon from '../Icon';
import './OssIngestModal.scss';

interface Props {
  open: boolean;
  onClose: () => void;
}

type RepoMeta = {
  groupName?: string;
  repoName?: string;
  versions: Array<{ value: string; label: string }>;
};

export const OssIngestModal = ({ open, onClose }: Props) => {
  const { t } = useTranslation();
  const [newUrlToIngest, setNewUrlToIngest] = useState('');
  const [newUrlVersionToIngest, setNewUrlVersionToIngest] = useState('latest');
  const [isQueuing, setIsQueuing] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const [repoMeta, setRepoMeta] = useState<RepoMeta | null>({
    versions: [{ value: 'latest', label: 'latest' }],
  });
  const [isFetchingMeta, setIsFetchingMeta] = useState(false);

  const [errors, setErrors] = useState<string[]>([]);
  const navigate = useNavigate();

  const cleanupClosedModal = () => {
    setNewUrlToIngest('');
    setErrors([]);
  };

  const handleCancel = () => {
    cleanupClosedModal();
    onClose();
  };

  const handleStartIngest = async () => {
    if (!newUrlToIngest || newUrlToIngest.length < 1) {
      setErrors([t(`page.uploads.ossIngestModal.error`)]);
    } else {
      setIsQueuing(true);

      const startIngestAttempt = await AxiosProxy.post({
        url: 'asset/add-from-oss',
        withCredentials: true,
        body: {
          ossTargetType: 'github',
          ossTargetUrl: newUrlToIngest,
          ossTargetVersion: newUrlVersionToIngest,
          isActive: isActive,
        },
      });

      if (startIngestAttempt && startIngestAttempt?.success) {
        navigate('/uploads?tab=ingests', { replace: false });
        cleanupClosedModal();
        onClose();
      } else {
        setErrors([...(startIngestAttempt?.errors || [])]);
      }

      setIsQueuing(false);
    }
  };

  return (
    <Modal opened={open} onClose={onClose} className="oss-ingest-modal">
      <Modal.Body>
        <div className="modal-inner">
          <div className="modal-header">
            <h4>{t('page.uploads.ossIngestModal.header')}</h4>
            <span className="subheader">
              {t(`page.uploads.ossIngestModal.subheader`)}
            </span>
          </div>
          {errors.length > 0 && (
            <div className="page-errors anim-slideInUpShort">{errors.join('. ')}</div>
          )}
          <div className="url-input">
            <span className="title">
              <label htmlFor="url-ingest">
                {t('page.uploads.ossIngestModal.url-title')}
              </label>
              <Tooltip
                label={t('page.uploads.ossIngestModal.url-tooltip')}
                style={{ whiteSpace: 'pre-line', textAlign: 'left' }}
              >
                <span style={{ marginLeft: '5px' }}>
                  <Icon
                    icon="exclamation-circle"
                    style={{ position: 'relative', top: '1px' }}
                  />
                </span>
              </Tooltip>
            </span>

            <span className="details">
              {t('page.uploads.ossIngestModal.url-details')}
            </span>
            <Input
              name="url-ingest"
              value={newUrlToIngest}
              onChange={(e) => setNewUrlToIngest(e.target.value)}
              placeholder={t('page.uploads.ossIngestModal.url-placeholder')}
            />
          </div>

          <div className="split-input">
            <div className="split-input-right">
              <span className="title">
                <label htmlFor="asset-version">
                  {t('page.uploads.ossIngestModal.version')}
                </label>
                <Tooltip label={t('page.uploads.ossIngestModal.version-tooltip')}>
                  <span style={{ marginLeft: '5px' }}>
                    <Icon
                      icon="question-circle"
                      style={{ position: 'relative', top: '1px' }}
                    />
                  </span>
                </Tooltip>
              </span>
              <Select
                data={repoMeta?.versions || []}
                nothingFound="Nothing found"
                name="asset-version"
                searchable
                creatable
                defaultValue={newUrlVersionToIngest}
                disabled={isFetchingMeta}
                getCreateLabel={(query) =>
                  `${t('page.uploads.ossIngestModal.asset-version-create', {
                    version: query,
                  })}`
                }
                onCreate={(query) => {
                  const item = { value: query, label: query };
                  setRepoMeta((current) => ({
                    ...(current as RepoMeta),
                    versions: [...(current?.versions || []), item],
                  }));
                  setNewUrlVersionToIngest(query);
                  return item;
                }}
              />
            </div>
          </div>
          <Flex mt={8} direction="column" gap={8}>
            <span className="title">
              <label htmlFor="asset-status">
                {t('page.uploads.ossIngestModal.activeInactiveLabel')}
              </label>
            </span>
            <Select
              data={[
                { value: 'true', label: t('global.active') },
                { value: 'false', label: t('global.inactive') },
              ]}
              defaultValue="false"
              name="asset-status"
              onChange={(value) => setIsActive(value === 'true')}
            />
          </Flex>
        </div>
      </Modal.Body>
      <Flex className="modal-footer">
        <Button
          variant="default"
          onClick={() => {
            handleCancel();
          }}
        >
          {t(`global.cancel`)}
        </Button>
        <Button
          loading={isQueuing || isFetchingMeta}
          disabled={false}
          onClick={() => {
            handleStartIngest();
          }}
        >
          {t(`global.import`)}
        </Button>
      </Flex>
    </Modal>
  );
};

export default OssIngestModal;
