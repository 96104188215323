import { useState } from 'react';

type LocalStoreageValue<T> = T | null | undefined;

export const useLocalStorage = <T,>(
  keyName: string,
  defaultValue?: LocalStoreageValue<T>,
): [LocalStoreageValue<T>, (state: LocalStoreageValue<T>) => void] => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window.localStorage.getItem(keyName);

      if (value) {
        return JSON.parse(value) as T;
      } else {
        window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });
  const setValue = (newValue: LocalStoreageValue<T>) => {
    const newVal = newValue instanceof Function ? newValue(storedValue) : newValue;
    try {
      window.localStorage.setItem(keyName, JSON.stringify(newVal));
    } catch (err) {}
    setStoredValue(newVal);
  };

  return [storedValue, setValue];
};
