import { AssetLevelOfSupport } from '@manifest-cyber/types/interface/dbTables';
import { TFunction } from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateCustomField } from '../../../../api/customFields/useCreateCustomField';
import { useGetCustomFields } from '../../../../api/customFields/useGetCustomFields';
import {
  CreationalSelect,
  CreationalSelectElement,
} from '../../../../components/CreationalSelect/CreationalSelect';
import ManifestModal from '../../../../components/ManifestModal/ManifestModal';
import { captureExceptionWithMessage } from '../../../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';

const getInitialOptions = (t: TFunction): CreationalSelectElement[] => [
  {
    value: 'ACTIVELY_MAINTAINED',
    label: t('tables.components.supportLevel.activelyMaintained'),
    group: t('tables.components.supportLevelGropus.recommendations'),
    iconName: 'check',
  },
  {
    value: 'NO_LONGER_MAINTAINED',
    label: t('tables.components.supportLevel.noMaintained'),
    group: t('tables.components.supportLevelGropus.recommendations'),
    iconName: 'close',
  },
  {
    value: 'ABANDONED',
    label: t('tables.components.supportLevel.abandoned'),
    group: t('tables.components.supportLevelGropus.recommendations'),
    iconName: 'ban',
  },
  {
    value: 'UNKNOWN',
    label: t('tables.components.supportLevel.unknown'),
    group: t('tables.components.supportLevelGropus.recommendations'),
    iconName: 'question',
  },
];

export const LevelOfSupportModal = ({
  onClose,
  onUpdate,
  showLoadingButton,
  componentName,
  initialValue,
}: {
  onClose: () => void;
  onUpdate: (levelOfSupprt: AssetLevelOfSupport) => void;
  showLoadingButton?: boolean;
  componentName: string;
  initialValue?: AssetLevelOfSupport;
}) => {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState<AssetLevelOfSupport | undefined>(
    initialValue,
  );
  const [options, setOptions] = useState<CreationalSelectElement[]>(getInitialOptions(t));
  const { data: fields } = useGetCustomFields({
    fieldType: 'level-of-support',
  });
  const { mutateAsync: createCustomField } = useCreateCustomField();

  const addField = async (value: string) => {
    try {
      await createCustomField({
        body: {
          value,
          field: 'level-of-support',
        },
      });
    } catch (error) {
      captureExceptionWithMessage(
        `Failed to create custom field, value: ${value}`,
        error,
      );
    }
  };

  useEffect(() => {
    const mappedFields: CreationalSelectElement[] = fields
      ? fields.map((value) => ({
          label: value,
          value: value,
          group: t('global.custom'),
          iconName: 'pen-to-square',
        }))
      : [];

    setOptions([...getInitialOptions(t), ...mappedFields]);
  }, [fields]);

  return (
    <ManifestModal
      opened
      withCloseButton
      onClose={onClose}
      title={`${t('global.update')} ${t('tables.components.headers.levelOfSupport')}`}
      subtitle={`${t('tables.components.forComponent')}: ${componentName}`}
      body={
        <div>
          <CreationalSelect
            getCreateItemText={(query) =>
              t('tables.components.supportLevel.newLevelOfSupport', { value: query })
            }
            addItem={(query: string) => {
              const newOption: CreationalSelectElement = {
                label: query,
                value: query,
                group: t('global.custom'),
                iconName: 'pen-to-square',
              };
              setSelectedValue(query);
              addField(query);

              return newOption;
            }}
            data={options}
            searchable
            withinPortal
            placeholder={t('tables.components.supportLevelPlaceholder')}
            onChange={(value) => setSelectedValue(value as AssetLevelOfSupport)}
            value={selectedValue}
          />
        </div>
      }
      primaryAction={{
        label: t('global.update'),
        isDisabled: !selectedValue,

        onClick: () => {
          if (!selectedValue) {
            return;
          }

          onUpdate(selectedValue);
        },
        isLoading: showLoadingButton,
        color: 'cyan',
      }}
      secondaryAction={{
        label: t('global.cancel'),
        onClick: onClose,
      }}
    />
  );
};
