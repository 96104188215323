import { Radio, RadioGroup } from 'rsuite';
import { useTranslation } from 'react-i18next';
import { usePostGitHubDefaultSettings } from '../../../../../hooks/mutations/usePostGitHubDefaultSettings';
import Loading from '../../../../../components/Loading';
import { InterfaceFullGithubIntegration } from '../../../../../types/FullInterfaces';
import { useOrganizationId } from '../../../../../hooks/utils/useOrganizationId';

interface Props {
  fetchedGitHubIntegration: InterfaceFullGithubIntegration;
  isLoadingGitHubIntegration: boolean;
}

export const GitHubOrganizationSettings = ({
  fetchedGitHubIntegration,
  isLoadingGitHubIntegration,
}: Props) => {
  const { t } = useTranslation();
  const [currentOrgId] = useOrganizationId();
  const { mutateAsync: postGitHubDefaultSettings } = usePostGitHubDefaultSettings();

  const githubIntegration = fetchedGitHubIntegration.githubIntegration[0];
  return (
    <>
      {isLoadingGitHubIntegration ? (
        <Loading />
      ) : (
        <div className="github-integration-edit-settings anim-slideInUpShort">
          <h4>
            {t('page.userSettings.integrations.github.default-sbom-generation-frequency')}
          </h4>
          <RadioGroup
            disabled={githubIntegration.status === 'suspended'}
            name="generation-frequency"
            inline
            defaultValue={githubIntegration.defaultRepositoryOptions?.triggerType}
            onChange={(value: any) => {
              postGitHubDefaultSettings({
                manifestOrganizationId: (githubIntegration?.manifestOrganizationId || currentOrgId)?.toString()!,
                githubOrganizationId: githubIntegration.githubOrganizationId?.toString()!,
                githubIntegrationId: githubIntegration._id?.toString()!,
                defaultRepositoryOptions: {
                  triggerType: value,
                },
              });
            }}
          >
            <Radio value="push">
              {t('page.userSettings.integrations.github.commit-push')}
            </Radio>
            <Radio value="time">
              {t('page.userSettings.integrations.github.time-based')}
            </Radio>
          </RadioGroup>
        </div>
      )}
    </>
  );
};
