import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';
import { rejectFailedRequestAcceptEmptyData } from '../../lib/request/rejectFailedRequest/rejectFailedRequest';
import { InterfaceOrganizationMemberUser } from '../../types/FullInterfaces';

interface Params {
  user: InterfaceOrganizationMemberUser;
}

export const usePostOrganizationUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ user }: Params) => {
      const response = await AxiosProxy.post({
        url: `member/new`,
        withCredentials: true,
        body: {
          ...user,
        },
      });
      const successfulResponse = await rejectFailedRequestAcceptEmptyData(response);

      return successfulResponse;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['organizationUsers']);
      queryClient.invalidateQueries(['currentOrganization']);
    },
  });
};
